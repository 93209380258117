import { Action } from 'redux';
import { connect } from 'react-redux';
import { dispatchAsync } from 'react-redux-dispatch-async';
import { PasswordForgottenDumb } from './PasswordForgotten.dumb';
import { sendPasswordForgottenEmailRequest } from '../../usecases/Auth.actions';

const mapState = () => ({});

const mapDispatch = (dispatch: (a: Action) => void) => ({
  sendPasswordForgottenEmailAsync: (email: string) => dispatchAsync(dispatch, sendPasswordForgottenEmailRequest(email)),
});

export const PasswordForgottenSmart = connect(mapState, mapDispatch)(PasswordForgottenDumb);
