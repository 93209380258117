import { Action } from 'redux';
import { connect } from 'react-redux';
import { dispatchAsync } from 'react-redux-dispatch-async';
import { sendEmailRequest } from 'generalContext/usecases/General.actions';
import { MailObj } from 'generalContext/domain/entities/MailObj';
import { ContactDumb } from './Contact.dumb';
import { AppState } from '../../../common/configuration/app.states';
import { getConnectedUser } from '../../../authContext/usecases/Auth.selectors';
import { getStateMagasins, getStateWishProducts } from '../../usecases/General.selectors';

const mapState = (state: AppState) => ({
  stateWishProducts: getStateWishProducts(state),
  stateConnectedUser: getConnectedUser(state),
  stateMagasins: getStateMagasins(state),
});

const mapDispatch = (dispatch: (a: Action) => void) => ({
  sendEmailAsync: (data: MailObj) => dispatchAsync(dispatch, sendEmailRequest(data)),
});

export const ContactSmart = connect(mapState, mapDispatch)(ContactDumb);
