export type NotificationContextType = {
  message: NotificationMessage | undefined;
  setMessage: React.Dispatch<React.SetStateAction<NotificationMessage>>;
};

export interface NotificationMessage {
  message: string;
  status?: string;
}

export const emptyNotificationMessage: NotificationMessage = {
  message: '',
  status: 'warn',
};
