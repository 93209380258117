export interface StyleSheet {
  [key: string]: React.CSSProperties;
}

// COLORS
export enum Color {
  PRIMARY = '#3189E7',
  PRIMARY_HOVER = '#3189E790',
  SECONDARY = '#FF0808',
  APPBLACK = '#212121',
  APPWHITE = '#fafafa',
  GREY = '#808080',
  TRANSPARENT_BLACK = '#00000050',
  DANGER = '#FF3333',
  ERROR = '#ffa4a4',
  SUCCESS = '#a4ffac',
  WARNING = '#ecf078',
}

// FONT WEIGHTS
export enum FontWeight {
  REGULAR = 400,
  MEDIUM = 500,
  EMPHASIS = 600,
  BOLD = 700,
  BOLDER = 900,
}

// FONT SIZES
export enum FontSize {
  H1 = 48,
  H6 = 24,
  P1 = 18,
  P2 = 16,
  P3 = 14,
  P4 = 12,
  P5 = 10,
  CAPTION = 12,
}

// PADDINGS
export enum Padds {
  TINY1 = 1,
  TINY2 = 2,
  TINY4 = 4,
  DEFAULT = 8,
  PADD1 = 16,
  PADD2 = 24,
  PADD3 = 32,
  PADD4 = 40,
}

export enum BorderSize {
  CORNERS_DEFAULT = 5,
  CORNERS_SMALL1 = 10,
  CORNERS_SMALL2 = 20,
}

export const TopBarHeight = 48;
export const SideBarMaxWidth = 240;
export const sideBarWidth = 60;
export const sideBarExpandedWidth = 300;
export const maxContainerWidth = 1261;
export const middleHeaderHeight = 85;
