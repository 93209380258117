import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import { Navigation } from 'swiper';
import styled from 'styled-components';
import { rSize } from 'core/util/ResponsiveSizeAdapter';
import { Testimonial } from '../../domain/entities/Testimonial';

interface TestimonialSliderProps {
  testimonials: Testimonial[];
}

export const TestimonialSlider = ({ testimonials }: TestimonialSliderProps) => {
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);

  const rStyles = {
    global: {
      h2: {
        fontSize: rSize(28),
      },
      h6: {
        fontSize: rSize(20),
      },
      p: {
        fontSize: rSize(18),
      },
    },
    signUpText: {
      marginTop: rSize(10),
    },
    swiperButton: {
      width: rSize(30),
      height: rSize(30),
    },
    swiperButtonIcon: {
      width: rSize(20),
      height: rSize(20),
    },
    swiperContainer: {
      spaceBetween: rSize(20),
    },
    faStar: {
      fontSize: rSize(20),
    },
  };

  return (
    <Wrapper>
      <div className="container">
        <div className="testimonial">
          <div className="section-head book-align">
            <div>
              <h2 className="title mb-0" style={{ ...rStyles.global.h2 }}>
                Vos témoignages
              </h2>
              <p className="m-b0" style={{ ...rStyles.global.p }}>
                Les clients satisfaits partagent leur expérience avec vous
              </p>
            </div>
            <div className="pagination-align style-1">
              <div
                className="testimonial-button-prev swiper-button-prev"
                style={{ ...rStyles.swiperButton }}
                ref={navigationPrevRef}
              >
                <i className="fa-solid fa-angle-left" style={{ ...rStyles.swiperButtonIcon }} />
              </div>
              <div className="swiper-pagination-three" />
              <div
                className="testimonial-button-next swiper-button-next"
                style={{ ...rStyles.swiperButton }}
                ref={navigationNextRef}
              >
                <i className="fa-solid fa-angle-right" style={{ ...rStyles.swiperButtonIcon }} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Swiper
        className="swiper-container  testimonial-swiper"
        slidesPerView={4}
        spaceBetween={rStyles.swiperContainer.spaceBetween}
        loop
        navigation={{
          prevEl: '.testimonial .swiper-button-prev',
          nextEl: '.testimonial .swiper-button-next',
        }}
        pagination={{
          el: '.swiper-pagination-three',
          clickable: true,
        }}
        autoplay={{
          delay: 4000,
        }}
        onSwiper={(swiper) => {
          setTimeout(() => {
            if (swiper?.navigation) {
              swiper?.navigation?.destroy();
              swiper?.navigation?.init();
              swiper?.navigation?.update();
            }
          });
        }}
        modules={[Navigation]}
        breakpoints={{
          0: {
            slidesPerView: 1,
          },
          600: {
            slidesPerView: 2,
          },
          767: {
            slidesPerView: 3,
          },
          1200: {
            slidesPerView: 4,
          },
        }}
      >
        {testimonials.map((item, i) => (
          <SwiperSlide key={`${item.author}-testimonial-key-${i}`}>
            <div className="testimonial-1">
              <div className="testimonial-info">
                <ul className="dz-rating">
                  <li style={{ ...rStyles.faStar }}>
                    <i className="fa fa-star text-yellow" />
                  </li>
                  <li style={{ ...rStyles.faStar }}>
                    <i className="fa fa-star text-yellow" />
                  </li>
                  <li style={{ ...rStyles.faStar }}>
                    <i className="fa fa-star text-yellow" />
                  </li>
                  <li style={{ ...rStyles.faStar }}>
                    <i className="fa fa-star text-yellow" />
                  </li>
                  <li style={{ ...rStyles.faStar }}>
                    <i className="fa fa-star text-yellow" />
                  </li>
                </ul>
                <div className="testimonial-text">
                  <p style={{ ...rStyles.global.p }}>{item.text}</p>
                </div>
                <div className="testimonial-detail">
                  <div className="info-right">
                    <h6 className="testimonial-name" style={{ ...rStyles.global.h6 }}>
                      {item.title}
                    </h6>
                    <span className="testimonial-position" style={{ ...rStyles.global.p }}>
                      {item.position}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .fa-solid {
    text-align: center;
    align-content: center;
  }
`;
