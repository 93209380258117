import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { rSize } from 'core/util/ResponsiveSizeAdapter';
import logo from '../../../../../assets/images/logo.png';

interface LogoProps {
  theme?: 'dark' | 'light';
  width: number;
}
export const Logo = ({ theme, width }: LogoProps) => {
  const rStyles = {
    logo: {
      margin: `0 ${rSize(20)}px 0 0`,
    },
  };
  return (
    <Wrapper>
      <div className="logo" style={{ ...rStyles.logo }}>
        <Link to="/">
          <img src={logo} alt="logo" style={{ width, minWidth: width }} />
        </Link>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .logo {
    margin: 0 20px 0 0;
  }
`;
