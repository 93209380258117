import { useEffect, useState } from 'react';

const useResponsiveSize = (baseSize: number): number => {
  const [size, setSize] = useState<number>(baseSize);

  useEffect(() => {
    const updateSize = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      const shortestSide = Math.min(width, height);

      // Reference screen width for different devices
      const referenceScreenWidthDesktop = 1000; // Example value for desktop
      const referenceScreenWidthTablet = 800; // Example value for tablet
      const referenceScreenWidthMobile = 600; // Example value for mobile

      // Determine device type based on width
      const isMobile = width <= 600;
      const isTablet = width > 600 && width <= 1000;

      // Adjust scaling based on device type
      let screenWidthRatio;
      if (isMobile) {
        screenWidthRatio = shortestSide / referenceScreenWidthMobile;
      } else if (isTablet) {
        screenWidthRatio = shortestSide / referenceScreenWidthTablet;
      } else {
        screenWidthRatio = shortestSide / referenceScreenWidthDesktop;
      }
      // Ensure a minimum size to avoid very small elements on mobile
      const newSize = Math.max(baseSize * screenWidthRatio, baseSize * 0.5); // Adjust 0.5 factor as needed

      setSize(newSize);
    };

    updateSize();
    window.addEventListener('resize', updateSize);

    return () => window.removeEventListener('resize', updateSize);
  }, [baseSize]);

  return size;
};

const rSize = useResponsiveSize;
export { useResponsiveSize, rSize };
