import styled from 'styled-components';
import { Link } from 'react-router-dom';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import { Logo } from 'common/ui/layouts/Header/components/Logo';
import { Category } from 'generalContext/domain/entities/Category';
import { MagasinState } from 'generalContext/domain/entities/Magasin';
import { rSize } from 'core/util/ResponsiveSizeAdapter';
import { AppContainer } from 'common/ui/elements/AppContainer';
import { FooterColumn } from './FooterColumn';
import { ImgText } from './ImgText';

export const footerPages = [
  {
    title: 'Accueil',
    link: '/',
  },
  {
    title: 'Boutique',
    link: '/boutique',
  },
  {
    title: 'Événements',
    link: '/evenements',
  },
  {
    title: 'Contact',
    link: '/contact',
  },
];

interface FooterPagesProps {
  stateCategories: Category[] | undefined;
  magasins?: MagasinState | undefined;
}
export const FooterPages = ({ stateCategories, magasins }: FooterPagesProps) => {
  const rStyles = {
    global: {
      icon: {
        fontSize: rSize(22),
      },
    },
    wrapper: {
      padding: `${rSize(50)}px 0`,
    },
    row: {
      rowGap: rSize(60),
    },
  };
  return (
    <AppContainer>
      <Wrapper>
        <div className="row justify-content-between" style={{ ...rStyles.row }}>
          <div className="col-12 col-sm-12 col-md-12 col-lg-3 me-2">
            <FooterColumn
              content={
                <div className="footer-logo-container">
                  <Logo width={rSize(250)} />
                </div>
              }
            />
          </div>
          {magasins?.current && (
            <div className="col-12 col-sm-6 col-md-3 col-lg-2">
              <FooterColumn
                title="Contact"
                content={
                  magasins?.current.id ? (
                    <>
                      <ImgText
                        img={<LocationOnIcon style={{ ...rStyles.global.icon }} />}
                        text={magasins?.current?.magasinAddress}
                      />
                      <ImgText
                        img={<LocalPhoneIcon style={{ ...rStyles.global.icon }} />}
                        text={magasins?.current?.magasinPhone}
                      />
                      <div className="email">
                        <ImgText
                          img={<EmailIcon style={{ ...rStyles.global.icon }} />}
                          text={magasins?.current?.magasinEmail}
                        />
                      </div>
                    </>
                  ) : null
                }
              />
            </div>
          )}
          <div className="col-12 col-sm-6 col-md-3 col-lg-1">
            <FooterColumn
              title="Pages"
              content={
                <>
                  {footerPages.map((menuItem, i) => (
                    <Link key={i} to={menuItem.link}>
                      {menuItem.title}
                    </Link>
                  ))}
                </>
              }
            />
          </div>
          <div className="col-12 col-sm-6 col-md-3 col-lg-1">
            <FooterColumn
              title="Catégories"
              content={
                <>
                  {stateCategories?.map((cat, i) =>
                    i < 5 ? (
                      <Link key={i} to={`/boutique?category=${cat.categoryNumber}`}>
                        {cat.name.toLocaleLowerCase()}
                      </Link>
                    ) : null,
                  )}
                  <Link to="/boutique">Voir plus</Link>
                </>
              }
            />
          </div>

          {magasins?.current?.magasinHoraire && (
            <div className="col-12 col-sm-6 col-md-3 col-lg-2">
              <FooterColumn
                title="Horaires"
                content={magasins?.current?.magasinHoraire.split('<br/>')?.map((line, i) => (
                  <p key={i}>{line}</p>
                ))}
              />
            </div>
          )}
        </div>
      </Wrapper>
    </AppContainer>
  );
};

const Wrapper = styled.section`
  padding: 30px 0;
  text-align: center;
  .footer-logo-container {
    place-self: center;
  }
`;
