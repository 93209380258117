import { Product } from './Product';
import { hardcodedUsers, User } from '../../../authContext/domain/entities/User';

export interface Review {
  id?: number;
  text?: string;
  rating: number;
  user: User;
  productEan: string;
  product?: Product;
  public?: boolean;
  createdAt?: string;
  updatedAt?: string;
}
export interface SubmitReviewProps {
  text?: string;
  rating: number;
}

export const hardCodedEmptyReview: Review = {
  id: 1,
  rating: 1,
  user: hardcodedUsers[0],
  productEan: '9780141439846',
};

export const hardCodedReviews: Review[] = [
  {
    id: 2,
    text: "Un classique intemporel de la littérature anglaise ! J'ai adoré l'histoire de Elizabeth Bennet et de Mr. Darcy, deux personnages forts et attachants. L'écriture de Jane Austen est élégante et pleine d'humour, et son portrait de la société britannique du XIXe siècle est fascinant. Je recommande chaudement ce livre !",
    rating: 5,
    user: hardcodedUsers[0],
    productEan: '9780141439846',
  },
  {
    id: 3,
    text: "Je n'ai pas du tout accroché à ce livre. Les personnages m'ont paru superficiels et ennuyeux, et l'intrigue est trop prévisible. J'ai eu du mal à me plonger dans l'univers de Jane Austen et je ne comprends pas pourquoi ce livre est considéré comme un chef-d'œuvre. Peut-être que je suis passé à côté de quelque chose, mais pour moi, c'était une déception.",
    rating: 2,
    user: hardcodedUsers[1],
    productEan: '9780141439846',
  },
  {
    id: 4,
    text: "Un roman d'amour magnifique et subtil ! J'ai été transporté par la relation tumultueuse entre Elizabeth et Darcy, qui est pleine de rebondissements et d'émotions.",
    rating: 4,
    user: hardcodedUsers[1],
    productEan: '9780141439846',
  },
];
