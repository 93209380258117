import styled from 'styled-components';

interface SectionTitleProps {
  title: string;
  subTitle?: string;
}
export const SectionTitle = ({ title, subTitle }: SectionTitleProps) => (
  <Wrapper>
    <div className="title-container">
      <h2 className="title">{title}</h2>
      <p className="sub-title">{subTitle}</p>
    </div>
  </Wrapper>
);

const Wrapper = styled.div`
  padding: 0px 0;
  display: flex;
  margin-bottom: 10px;
  justify-content: center;
  .title-container {
    width: fit-content;
  }
  .title {
    font-size: 2.5rem;
    font-weight: 700;
    color: var(--primary);
    text-align: center;
    border-bottom: 5px solid var(--secondary);
    margin-bottom: 10px;
    line-height: 1.5em;
  }
  .sub-title {
    font-size: 1rem;
    font-weight: 400;
    color: var(--black);
    text-align: center;
  }
`;
