import { Action } from 'redux';
import { connect } from 'react-redux';
import { dispatchAsync } from 'react-redux-dispatch-async';
import { OrderInformationDumb } from './OrderInformation.dumb';
import { AppState } from '../../../common/configuration/app.states';
import { getConnectedUser } from '../../../authContext/usecases/Auth.selectors';
import { getOrderHistoryRequest } from '../../usecases/General.actions';
import { getStateMagasins } from '../../usecases/General.selectors';

const mapState = (state: AppState) => ({
  stateMagasins: getStateMagasins(state),
  stateConnectedUser: getConnectedUser(state),
});

const mapDispatch = (dispatch: (a: Action) => void) => ({
  // navigateTo: (nav: NavigationPayload) => dispatch(navigateTo(nav)),
  getOrderHistoryAsync: () => dispatchAsync(dispatch, getOrderHistoryRequest()),
});

export const OrderInformationSmart = connect(mapState, mapDispatch)(OrderInformationDumb);
