import { Action } from 'redux-actions';
import {
  getUserDataSuccess,
  removeTokensSuccess,
  resetPasswordSuccess,
  sendAutoSignInSuccess,
  sendPasswordForgottenEmailRequest,
  sendSignInSuccess,
  sendSignOutFailure,
  sendSignOutSuccess,
  sendSignUpSuccess,
  setLoadingSuccess,
  updateUserDataSuccess,
} from './Auth.actions';
import { createTypedHandler, handleTypedActions } from '../../common/usecases/helpers/actions.helper';
import { AuthState } from '../configuration/Auth.state';
import { AuthTokens } from '../../common/domain/entities/AuthTokens';
import { User } from '../domain/entities/User';

const defaultState: AuthState = {
  connectedUser: undefined,
  authTokens: undefined,
  loading: true,
};

const connectedReducer = (state: AuthState, action: Action<AuthTokens>) => {
  const newState: AuthState = { ...state, authTokens: action.payload };
  return newState;
};

const userDataReducer = (state: AuthState, action: Action<User>) => {
  const newState: AuthState = { ...state, connectedUser: action.payload };
  return newState;
};
const setLoadingReducer = (state: AuthState, action: Action<boolean>) => {
  const newState: AuthState = { ...state, loading: action.payload };
  return newState;
};

const sendSignOutReducer = () => ({ ...defaultState, loading: false });

const removeTokensReducer = (state: AuthState) => {
  const newState: AuthState = { ...state, authTokens: undefined, connectedUser: undefined };
  return newState;
};

export const authReducer = handleTypedActions(
  [
    // app ready
    createTypedHandler(setLoadingSuccess, setLoadingReducer),
    // auth tokens
    // createTypedHandler(renewTokensSuccess, connectedReducer),
    createTypedHandler(removeTokensSuccess, removeTokensReducer),
    createTypedHandler(sendSignInSuccess, connectedReducer),
    createTypedHandler(sendSignUpSuccess, connectedReducer),
    createTypedHandler(sendAutoSignInSuccess, connectedReducer),
    createTypedHandler(sendSignOutSuccess, sendSignOutReducer),
    createTypedHandler(sendSignOutFailure, sendSignOutReducer),
    // user data
    createTypedHandler(getUserDataSuccess, userDataReducer),
    createTypedHandler(updateUserDataSuccess, userDataReducer),
    // password
    createTypedHandler(sendPasswordForgottenEmailRequest, sendSignOutReducer),
    createTypedHandler(resetPasswordSuccess, connectedReducer),
  ],
  defaultState,
);
