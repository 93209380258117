import { useState } from 'react';
import styled from 'styled-components';

interface StarRatingProps {
  size?: number;
  showNumber?: boolean;
  value: number;
  onChange?: (newValue: number) => void;
}

export const StarRating = ({ size = 20, showNumber, value, onChange }: StarRatingProps) => {
  const [hoveredValue, setHoveredValue] = useState<number | null>(null);

  const handleClick = (newValue: number) => {
    if (onChange) {
      onChange(newValue);
    }
  };

  const handleMouseEnter = (newValue: number) => {
    if (onChange) {
      setHoveredValue(newValue);
    }
  };

  const handleMouseLeave = () => {
    if (onChange) {
      setHoveredValue(null);
    }
  };

  return (
    <Wrapper changeable={!!onChange} size={size}>
      <div className="star-rating">
        {[...Array(5)].map((_, i) => {
          const ratingValue = i + 1;
          const filled = ratingValue <= (hoveredValue ?? value) ? 'filled' : '';
          const selected = ratingValue <= value ? 'selected' : '';
          return (
            <span
              role="button"
              tabIndex={0}
              key={`${ratingValue}-review-rating-key-${i}`}
              className={`star ${filled} ${selected}`}
              onClick={() => handleClick(ratingValue)}
              onMouseEnter={() => handleMouseEnter(ratingValue)}
              onMouseLeave={() => handleMouseLeave()}
              onKeyDown={() => handleClick(ratingValue)}
            >
              <i className="fa fa-star" />
            </span>
          );
        })}
        {showNumber && <h6 className="m-b0">{!Number.isNaN(value) && value.toFixed(2)}</h6>}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ changeable: boolean; size: number }>`
  .star-rating {
    display: flex;
    align-items: center;
    * {
      transition: all 100ms ease-out;
      font-size: ${({ size }) => `${size}px`};
    }
  }

  .star {
    cursor: ${({ changeable }) => (changeable ? 'pointer' : 'default')}!important;
    color: var(--mono-grey-2);
    margin-right: 5px;
    cursor: pointer;
  }
  .star:hover {
    color: ${({ changeable }) => (changeable ? 'var(--star)' : '')};
  }

  .star.selected,
  .star.filled {
    color: var(--star);
  }
`;
