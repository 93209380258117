export interface Product {
  id?: number;
  Art_Ean13: string;
  Art_Titre: string;
  Art_Auteur: string;
  Art_Editeur: string;
  Art_EtatCom: string;
  ProductStock: ProductStock[];
  Art_DateParu: string;
  Art_Description: string;
  Art_Prix: number;
  Art_Prix_Promo?: number;
  Art_DateDPromo?: string;
  Art_DateFPromo?: string;
  Art_NmbrPages?: number;
  Art_Poids?: number;
  Art_Hauteur?: number;
  Art_Largeur?: number;
  Art_Epaisseur?: number;
  Art_Quantity?: number;
  subCategoryNumbers: number[];
  Art_Points: number;
  Art_Images: string[];
  review_score?: number;
}
export interface ProductStock {
  storeId: number;
  Art_Stock: number;
}
export interface GetProductsProps {
  query?: string;
  Art_Ean13?: string;
  Art_Auteur?: string;
  Art_Editeur?: string;
  categoryNumber?: string;
  subCategoryNumber?: string;
  skip?: number;
  take?: number;
}

export const hardCodedEmptyProduct: Product = {
  id: 1,
  Art_Ean13: '',
  Art_Titre: '',
  Art_Auteur: '',
  Art_Editeur: '',
  Art_EtatCom: '',
  ProductStock: [],
  Art_DateParu: '',
  Art_Description: '',
  Art_Prix: 0,
  Art_Prix_Promo: 0,
  Art_DateDPromo: '',
  Art_DateFPromo: '',
  Art_NmbrPages: 0,
  Art_Poids: 0,
  subCategoryNumbers: [0],
  Art_Points: 0,
  Art_Images: [],
};

export const hardCodedProducts: Product[] = [
  {
    id: 1,
    Art_Ean13: '9780141439846',
    Art_Titre: 'Pride and Prejudice',
    Art_Auteur: 'author imane',
    Art_Editeur: 'editor fayçal',
    Art_EtatCom: 'Used',
    ProductStock: [],
    Art_DateParu: '1813-01-28T00:00:00.000Z',
    Art_Description:
      'Pride and Prejudice is a novel by Jane Austen, first published in 1813. The story follows Elizabeth Bennet, the second of five daughters of a country gentleman. With a dry wit and an eye for absurdity, Austen explores the themes of marriage, social class, and the roles of women in the society of the time.',
    Art_Prix: 10.99,
    Art_DateDPromo: '2023-01-28T00:00:00.000Z',
    Art_DateFPromo: '2024-01-28T00:00:00.000Z',
    Art_NmbrPages: 4000,
    Art_Poids: 1500,
    subCategoryNumbers: [1, 2],
    Art_Points: 5,
    Art_Images: [
      'https://m.media-amazon.com/images/I/91vLVy3AQSL.jpg',
      'https://kbimages1-a.akamaihd.net/afcd8653-3b27-4423-bee9-570fb1441aed/353/569/90/False/pride-and-prejudice-71.jpg',
      'https://theperksofbeingnourablog.files.wordpress.com/2021/01/pride-and-prejudice-barnes-noble-collectible-editions-1.jpg',
    ],
  },
  {
    id: 2,
    Art_Ean13: '9780061122415',
    Art_Titre: 'To Kill a Mockingbird',
    Art_Auteur: 'Harper Lee',
    Art_Editeur: 'editor fayçal',
    Art_EtatCom: 'New',
    ProductStock: [],
    Art_DateParu: '1960-07-11T00:00:00.000Z',
    Art_Description:
      'To Kill a Mockingbird is a novel by Harper Lee, published in 1960. The story takes place during the Great Depression and follows the life of Scout Finch, a young girl growing up in a small Alabama town. Through her eyes, we see the racism and social injustice that permeate the town, and the courage and moral strength of her father, Atticus Finch, as he defends a black man accused of rape.',
    Art_Prix: 12.99,
    Art_Prix_Promo: 9.99,
    subCategoryNumbers: [1],
    Art_Points: 7,
    Art_Images: [
      'https://m.media-amazon.com/images/I/71FxgtFKcQL.jpg',
      'https://crosscut.com/sites/default/files/images/articles/Mockingbird_HERO_0.jpg',
      'https://deseret.brightspotcdn.com/dims4/default/ff63a97/2147483647/strip/false/crop/3000x1958+0+0/resize/1486x970!/quality/90/?url=https%3A%2F%2Fcdn.vox-cdn.com%2Fthumbor%2FhQElo4QWlGjGFnOrkpLHaqMNC6E%3D%2F0x0%3A3000x1958%2F3000x1958%2Ffilters%3Afocal%281500x979%3A1501x980%29%2Fcdn.vox-cdn.com%2Fuploads%2Fchorus_asset%2Ffile%2F23202594%2Fmerlin_2905504.jpg',
    ],
  },
  {
    id: 3,
    Art_Ean13: '9780547928227',
    Art_Titre: 'The Hobbit',
    Art_Auteur: 'J.R.R. Tolkien',
    Art_Editeur: 'editor fayçal',
    Art_EtatCom: 'New',
    ProductStock: [],
    Art_DateParu: '1937-09-21T00:00:00.000Z',
    Art_Description:
      'The Hobbit is a novel by J.R.R. Tolkien, published in 1937. It tells the story of Bilbo Baggins, a hobbit who is recruited by the wizard Gandalf to join a group of dwarves on a quest to reclaim their stolen treasure from the dragon Smaug. Along the way, they encounter trolls, goblins, and a mysterious creature named Gollum.',
    Art_Prix: 15.99,
    Art_Prix_Promo: 12.99,
    subCategoryNumbers: [2],
    Art_Points: 8,
    Art_Images: [
      'https://sulfurbooks.com/wp-content/uploads/2021/03/original-825.jpeg',
      'https://m.media-amazon.com/images/I/71q7-bs1nvL.jpg',
      'https://g.christianbook.com/g/slideshow/7/71224/main/71224_1_ftc_dp.jpg',
    ],
  },
  {
    id: 4,
    Art_Ean13: '9780735219090',
    Art_Titre: 'Little Fires Everywhere',
    Art_Auteur: 'Celeste Ng',
    Art_Editeur: 'editor fayçal',
    Art_EtatCom: 'New',
    ProductStock: [],
    Art_DateParu: '2017-09-12T00:00:00.000Z',
    Art_Description:
      'Little Fires Everywhere is a novel by Celeste Ng, published in 2017. The story is set in the late 1990s and follows two families in Shaker Heights, Ohio: the Richardsons, who are wealthy and privileged, and the Warrens, who are poor and struggling. When the Warrens rent a house from the Richardsons, their lives become intertwined, and long-held secrets are revealed.',
    Art_Prix: 9.99,
    Art_Prix_Promo: 7.99,
    subCategoryNumbers: [2],
    Art_Points: 4,
    Art_Images: [
      'https://kbimages1-a.akamaihd.net/7acbc963-081c-4a34-8b9b-b5f2214eb2e1/1200/1200/False/little-fires-everywhere-1.jpg',
    ],
  },
  {
    id: 5,
    Art_Ean13: '9780385314261',
    Art_Titre: 'The God of Small Things',
    Art_Auteur: 'Arundhati Roy',
    Art_Editeur: 'editor fayçal',
    Art_EtatCom: 'New',
    ProductStock: [],
    Art_DateParu: '1997-04-04T00:00:00.000Z',
    Art_Description:
      'The God of Small Things is a novel by Arundhati Roy, published in 1997. The story takes place in Ayemenem, a small village in Kerala, India, and follows the lives of twins Estha and Rahel as they grow up in a society that is deeply divided by caste and religion. Through their experiences, the novel explores themes of love, loss, and the struggle for self-determination.',
    Art_Prix: 11.99,
    Art_Prix_Promo: 8.99,
    subCategoryNumbers: [3],
    Art_Points: 6,
    Art_Images: ['https://m.media-amazon.com/images/I/81ZTovcnHuL.jpg'],
  },
  {
    id: 6,
    Art_Ean13: '9781400031702',
    Art_Titre: 'Invisible Man',
    Art_Auteur: 'Ralph Ellison',
    Art_Editeur: 'editor imane',
    Art_EtatCom: 'New',
    ProductStock: [],
    Art_DateParu: '1952-04-14T00:00:00.000Z',
    Art_Description:
      'Invisible Man is a novel by Ralph Ellison, published in 1952. The story follows the experiences of an unnamed African American narrator as he navigates issues of identity and race in mid-20th century America.',
    Art_Prix: 13.99,
    Art_Prix_Promo: 10.99,
    subCategoryNumbers: [3],
    Art_Points: 8,
    Art_Images: ['https://m.media-amazon.com/images/I/71KA2gr8xBL.jpg'],
  },
  {
    id: 7,
    Art_Ean13: '9780316769174',
    Art_Titre: 'The Catcher in the Rye',
    Art_Auteur: 'J.D. Salinger',
    Art_Editeur: 'editor imane',
    Art_EtatCom: 'New',
    ProductStock: [],
    Art_DateParu: '1951-07-16T00:00:00.000Z',
    Art_Description:
      'The Catcher in the Rye is a novel by J.D. Salinger, published in 1951. The story follows the experiences of Holden Caulfield, a teenage boy who has been expelled from his prep school and is struggling to find his place in the world.',
    Art_Prix: 11.99,
    Art_Prix_Promo: 8.99,
    subCategoryNumbers: [4],
    Art_Points: 6,
    Art_Images: ['https://m.media-amazon.com/images/I/91HPG31dTwL.jpg'],
  },
  {
    id: 8,
    Art_Ean13: '9780451524935',
    Art_Titre: '1984',
    Art_Auteur: 'George Orwell',
    Art_Editeur: 'editor imane',
    Art_EtatCom: 'New',
    ProductStock: [],
    Art_DateParu: '1949-06-08T00:00:00.000Z',
    Art_Description:
      '1984 is a dystopian novel by George Orwell, published in 1949. The story takes place in a totalitarian society in the year 1984, and follows the experiences of the protagonist, Winston Smith, as he rebels against the oppressive regime.',
    Art_Prix: 10.99,
    Art_Prix_Promo: 7.99,
    subCategoryNumbers: [4],
    Art_Points: 5,
    Art_Images: ['https://m.media-amazon.com/images/I/71NvkZxn-fL.jpg'],
  },
  {
    id: 9,
    Art_Ean13: '9781501167717',
    Art_Titre: 'A Higher Loyalty: Truth, Lies, and Leadership',
    Art_Auteur: 'James Comey',
    Art_Editeur: 'editor imane',
    Art_EtatCom: 'New',
    ProductStock: [],
    Art_DateParu: '2018-04-17T00:00:00.000Z',
    Art_Description:
      'A Higher Loyalty: Truth, Lies, and Leadership is a memoir by James Comey, former Director of the Federal Bureau of Investigation (FBI). In the book, Comey recounts his experiences in government, including his interactions with President Trump, and discusses the importance of ethical leadership in public service.',
    Art_Prix: 15.99,
    Art_Prix_Promo: 12.99,
    subCategoryNumbers: [5],
    Art_Points: 7,
    Art_Images: ['https://m.media-amazon.com/images/I/31psjQoH6XL._SX327_BO1,204,203,200_.jpg'],
  },
  {
    id: 10,
    Art_Ean13: '9780553573404',
    Art_Titre: 'A Game of Thrones',
    Art_Auteur: 'George R. R. Martin',
    Art_Editeur: 'editor imane',
    Art_EtatCom: 'New',
    ProductStock: [],
    Art_DateParu: '1996-08-01T00:00:00.000Z',
    Art_Description:
      'A Game of Thrones is the first novel in the A Song of Ice and Fire series by George R. R. Martin. Set in the fictional world of Westeros, the story follows the political intrigue and warfare between several noble houses vying for control of the Iron Throne, the seat of the King of the Seven Kingdoms.',
    Art_Prix: 14.99,
    Art_Prix_Promo: 11.99,
    subCategoryNumbers: [5],
    Art_Points: 8,
    Art_Images: [
      'https://kbimages1-a.akamaihd.net/388d9a08-ba12-4f50-9659-d5250744ac57/1200/1200/False/a-game-of-thrones-the-story-continues-books-1-5-a-game-of-thrones-a-clash-of-kings-a-storm-of-swords-a-feast-for-crows-a-dance-with-dragons-a-song-of-ice-and-fire.jpg',
    ],
  },
  {
    id: 11,
    Art_Ean13: '9780062561029',
    Art_Titre: 'The Sun and Her Flowers',
    Art_Auteur: 'Rupi Kaur',
    Art_Editeur: 'editor imane',
    Art_EtatCom: 'New',
    ProductStock: [],
    Art_DateParu: '2017-10-03T00:00:00.000Z',
    Art_Description:
      "The Sun and Her Flowers is a poetry collection by Rupi Kaur, published in 2017. The book is divided into five sections, each exploring a different aspect of life, such as love, loss, and growth, through the author's personal experiences and observations.",
    Art_Prix: 9.99,
    Art_Prix_Promo: 7.99,
    subCategoryNumbers: [6],
    Art_Points: 4,
    Art_Images: ['https://m.media-amazon.com/images/I/711kwLCWW+L.jpg'],
  },
  {
    id: 12,
    Art_Ean13: '9780446675536',
    Art_Titre: 'The Four Agreements: A Practical Guide to Personal Freedom',
    Art_Auteur: 'Don Miguel Ruiz',
    Art_Editeur: 'editor imane',
    Art_EtatCom: 'New',
    ProductStock: [],
    Art_DateParu: '1997-11-07T00:00:00.000Z',
    Art_Description:
      'The Four Agreements is a self-help book by Don Miguel Ruiz, published in 1997. The book presents four principles to live by that can lead to personal freedom and happiness. These principles include being impeccable with your word, not taking things personally, not making assumptions, and always doing your best.',
    Art_Prix: 7.99,
    Art_Prix_Promo: 5.99,
    subCategoryNumbers: [6],
    Art_Points: 4,
    Art_Images: ['https://m.media-amazon.com/images/I/81hHy5XrdKL.jpg'],
  },
  {
    id: 13,
    Art_Ean13: '9780385490818',
    Art_Titre: 'The Alchemist',
    Art_Auteur: 'author imane',
    Art_Editeur: 'editor imane',
    Art_EtatCom: 'New',
    ProductStock: [],
    Art_DateParu: '1988-01-01T00:00:00.000Z',
    Art_Description:
      'The Alchemist is a novel by Paulo Coelho, first published in 1988. The story follows Santiago, a shepherd boy who dreams of finding treasure and embarks on a journey to Egypt to fulfill his destiny. Along the way, he learns about the importance of following his heart and the power of the universe to guide him.',
    Art_Prix: 11.99,
    Art_Prix_Promo: 9.99,
    subCategoryNumbers: [7],
    Art_Points: 5,
    Art_Images: ['https://m.media-amazon.com/images/I/41ybG235TcL.jpg'],
  },
  {
    id: 14,
    Art_Ean13: '9780812993547',
    Art_Titre: 'Hillbilly Elegy: A Memoir of a Family and Culture in Crisis',
    Art_Auteur: 'author imane',
    Art_Editeur: 'editor imane',
    Art_EtatCom: 'New',
    ProductStock: [],
    Art_DateParu: '2016-06-28T00:00:00.000Z',
    Art_Description:
      "Hillbilly Elegy is a memoir by J. D. Vance, published in 2016. The book chronicles Vance's upbringing in a working-class family in Appalachia and explores the social, economic, and cultural factors that contribute to poverty and the cycle of despair in the region.",
    Art_Prix: 13.99,
    Art_Prix_Promo: 10.99,
    subCategoryNumbers: [7],
    Art_Points: 7,
    Art_Images: ['https://m.media-amazon.com/images/I/911KoQFhQaL.jpg'],
  },
  {
    id: 15,
    Art_Ean13: '9781501146586',
    Art_Titre: 'The Girl on the Train',
    Art_Auteur: 'author imane',
    Art_Editeur: 'editor imane',
    Art_EtatCom: 'New',
    ProductStock: [],
    Art_DateParu: '2015-01-13T00:00:00.000Z',
    Art_Description:
      'The Girl on the Train is a psychological thriller novel by Paula Hawkins, published in 2015. The story follows Rachel Watson, a woman who becomes involved in a missing persons investigation after witnessing something while commuting on a train. The book explores themes of memory, truth, and perception.',
    Art_Prix: 8.99,
    Art_Prix_Promo: 6.99,
    subCategoryNumbers: [8],
    Art_Points: 3,
    Art_Images: ['https://m.media-amazon.com/images/I/8183Y1myPvL.jpg'],
  },
];
