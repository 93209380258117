import { Color } from '../styles/constantStyles';

export interface CloseIconProps {
  color?: string;
  size?: number;
  strokeWidth?: number;
}

export const CloseIcon = ({ color = Color.SECONDARY, size = 90, strokeWidth = 60 }: CloseIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{ width: size, height: size, minWidth: size, minHeight: size }}
    viewBox="0 0 608 608"
  >
    <path
      d="M16,592L592,16"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={strokeWidth}
    />
    <path
      d="M16,16L592,592"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={strokeWidth}
    />
  </svg>
);
