import styled from 'styled-components';
import { FooterPages } from 'generalContext/ui/components/FooterPages';
import { MagasinState } from 'generalContext/domain/entities/Magasin';
import { NewsLettersForm } from 'generalContext/ui';
import { Category } from 'generalContext/domain/entities/Category';
import { FooterVisaImages } from 'generalContext/ui/components/FooterVisaImages';
import { FooterBottom } from 'generalContext/ui/components/FooterBottom';
import { CounterSection } from '../../../generalContext/ui/components/CounterSection';
import { AppContainer } from '../elements/AppContainer';

interface FooterProps {
  footerChange: string;
  magasins?: MagasinState | undefined;
  stateCategories: Category[] | undefined;
}
export const Footer = ({ footerChange, magasins, stateCategories }: FooterProps) => (
  <AppContainer>
    <Wrapper>
      <footer className={`site-footer ${footerChange}`}>
        <NewsLettersForm />
        <CounterSection />
        <FooterPages stateCategories={stateCategories} magasins={magasins} />
        <FooterVisaImages />
        <FooterBottom phone={magasins?.current?.magasinPhone} magazinTitle={magasins?.current?.magasinName} />
      </footer>
    </Wrapper>
  </AppContainer>
);

const Wrapper = styled.div`
  .footer-category {
    .toggle-btn {
      background: transparent;
    }
  }
`;
