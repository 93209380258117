import styled from 'styled-components';
import { FaArrowUp } from 'react-icons/fa';
import { rSize } from 'core/util/ResponsiveSizeAdapter';

export const AppScrollToTop = () => {
  const rStyles = {
    wrapper: {
      bottom: rSize(20),
      right: rSize(20),
      padding: rSize(10),
      boxShadow: `0 0 ${rSize(10)}px rgba(0, 0, 0, 0.2)`,
    },
    arrowUp: {
      size: rSize(40),
    },
  };
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <Wrapper style={{ ...rStyles.wrapper }} onClick={scrollToTop}>
      <FaArrowUp size={rStyles.arrowUp.size} color="white" />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 200;
  background-color: var(--primary);
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
`;
