export type User = {
  id?: number;
  admin?: boolean;
  email: string;
  password?: string;
  passwordRepeat?: string;
  phone?: string;
  firstName?: string;
  lastName?: string;
  addressMain?: string;
  addressSecond?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
};

export const emptyUser: User = {
  email: '',
  firstName: '',
  lastName: '',
  password: '',
  passwordRepeat: '',
};

export const hardcodedUsers: User[] = [
  {
    email: 'raiss.hassan@example.com',
    firstName: 'Hassan',
    lastName: 'Raiss',
    phone: '+212617263549',
    addressMain: 'address 1 - Rue 01 Nr 05',
  },
  {
    email: 'amina.laarbi@example.com',
    firstName: 'Amina',
    lastName: 'Laarbi',
    addressMain: 'address 1 - Rue 01 Nr 05',
  },
];
