import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import {
  BEST_SELLERS_SUBCATEGORY_NUMBER,
  HEART_SUBCATEGORY_NUMBER,
  OTHER_CATEGORY_NUMBER,
  PROMO_SUBCATEGORY_NUMBER,
  RECOMMENDED_SUBCATEGORY_NUMBER,
} from 'configuration/helpers/constants';
import { rSize } from 'core/util/ResponsiveSizeAdapter';
import { ArrowExpandMore } from '../../../../assets/svg/ArrowExpandMore';
import { ArrowExpandLess } from '../../../../assets/svg/ArrowExpandLess';
import { Color } from '../../../../assets/styles/constantStyles';
import { Category } from '../../../domain/entities/Category';
import { SubCategory } from '../../../domain/entities/SubCategory';

interface CategoriesFilterProps {
  categories?: Category[];
  subCategories: SubCategory[];
  onSelectionChange?: (selectedSubCategoryNumbers: number[]) => void;
}

export const CategoriesFilter = ({ categories, subCategories, onSelectionChange }: CategoriesFilterProps) => {
  const [selectedSubCategoryNumbers, setSelectedSubCategoryNumbers] = useState<number[]>([]);
  const [expandedCategories, setExpandedCategories] = useState<number[]>([]);
  const [checkable] = useState<boolean>(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const paramCategory = searchParams.get('category');
  const paramSubCategory = searchParams.get('subcategory');

  const rStyles = {
    categoryHeader: {
      padding: `${rSize(10)}px 0 0`,
      fontSize: rSize(14),
    },
    categoryTitle: {
      fontSize: rSize(16),
    },
    collapsible: {
      expanded: {
        maxHeight: rSize(400),
      },
    },
    categoryContent: {
      marginLeft: rSize(10),
      gap: rSize(5),
    },
    subCategoryBox: {
      gap: rSize(5),
    },
    input: {
      fontSize: rSize(20),
      width: rSize(25),
      height: rSize(25),
    },
    subCategoryTitle: {
      fontSize: rSize(12),
      marginLeft: rSize(10),
    },
    expandIcon: {
      size: rSize(28),
    },
  };

  useEffect(() => {
    if (paramCategory) {
      const preSelected = subCategories
        .filter((item) => item.categoryNumber === +paramCategory)
        .map((item) => item.subCategoryNumber);
      setSelectedSubCategoryNumbers(preSelected);
    }
    if (paramSubCategory) {
      const preSelected = subCategories
        .filter((item) => item.subCategoryNumber === +paramSubCategory)
        .map((item) => item.subCategoryNumber);
      setSelectedSubCategoryNumbers(preSelected);
    }
  }, [location]);

  useEffect(() => {
    if (onSelectionChange) {
      onSelectionChange(selectedSubCategoryNumbers);
    }
  }, [selectedSubCategoryNumbers]);

  const getSubCatName = (subCat: SubCategory) => {
    switch (subCat.subCategoryNumber) {
      case RECOMMENDED_SUBCATEGORY_NUMBER:
        return 'Recommandés';
      case BEST_SELLERS_SUBCATEGORY_NUMBER:
        return 'Meilleurs Ventes';
      case HEART_SUBCATEGORY_NUMBER:
        return 'Nos Coups de coeur';
      case PROMO_SUBCATEGORY_NUMBER:
        return 'Promotions';
      default:
        return '';
    }
  };

  const handleSubCategoryClick = (clicked: number) => {
    setSelectedSubCategoryNumbers((prevSelectedSubCategoryNumbers) => {
      let newArr = [];
      if (prevSelectedSubCategoryNumbers.find((a) => a === clicked)) {
        newArr = prevSelectedSubCategoryNumbers.filter((val) => val !== clicked);
      } else {
        newArr = [...prevSelectedSubCategoryNumbers, clicked];
      }
      return newArr;
    });
  };

  const handleCategoryToggle = (categoryNumber: number) => {
    setExpandedCategories((prevExpandedCategories) => {
      if (prevExpandedCategories.includes(categoryNumber)) {
        return prevExpandedCategories.filter((number) => number !== categoryNumber);
      }
      return [...prevExpandedCategories, categoryNumber];
    });
  };

  const toggleCategoryExpansion = (categoryNumber: number) => {
    setExpandedCategories((prevExpandedCategories) => {
      if (prevExpandedCategories.includes(categoryNumber)) {
        return prevExpandedCategories;
      }
      return [...prevExpandedCategories, categoryNumber];
    });
  };

  return (
    <Wrapper>
      {categories?.map((category, i) => {
        const foundFilledsCats = subCategories.filter(
          (subCat) => subCat.categoryNumber === category.categoryNumber && !!subCat.productCount,
        );
        if (!foundFilledsCats.length) {
          return null;
        }
        return (
          <div key={`cat-filter-key-${category.id}${i}`} className="category-box">
            <div className="category-header" style={{ ...rStyles.categoryHeader }}>
              <Link
                key={`${category.id}-shop-side-bar-cat-menu-cat-key-${i}`}
                to={{ pathname: '/boutique/', search: `?category=${category.categoryNumber}` }}
              >
                <div
                  className="category-title"
                  style={{ ...rStyles.categoryTitle }}
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    toggleCategoryExpansion(category.categoryNumber);
                  }}
                >
                  {category.categoryNumber === OTHER_CATEGORY_NUMBER ? '' : category.name.toLocaleLowerCase()}
                  {category.productCount !== undefined && `(${category.productCount})`}
                </div>
              </Link>
              {category.categoryNumber !== OTHER_CATEGORY_NUMBER && (
                <div
                  className="category-toggle icon"
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    handleCategoryToggle(category.categoryNumber);
                  }}
                >
                  {expandedCategories.includes(category.categoryNumber) ? (
                    <ArrowExpandLess size={rStyles.expandIcon.size} />
                  ) : (
                    <ArrowExpandMore size={rStyles.expandIcon.size} />
                  )}
                </div>
              )}
            </div>
            <div
              className={`collapsible ${
                expandedCategories.includes(category.categoryNumber) ||
                category.categoryNumber === OTHER_CATEGORY_NUMBER
                  ? 'expanded'
                  : ''
              }`}
            >
              {subCategories
                .filter((subCat) => subCat.categoryNumber === category.categoryNumber)
                .map((subCat, j) => {
                  if (!subCat.productCount) {
                    return null;
                  }
                  return (
                    <div
                      key={`scat-key-${subCat.id}${j}`}
                      className="sub-category-box"
                      style={{ ...rStyles.subCategoryBox }}
                      onClick={(e) => {
                        e.stopPropagation(); // Prevents collapsing the category when a subcategory is clicked
                        handleSubCategoryClick(subCat.subCategoryNumber);
                      }}
                      role="button"
                      tabIndex={0}
                      onKeyPress={(e) => e.key === 'Enter' && handleSubCategoryClick(subCat.subCategoryNumber)}
                    >
                      {checkable && (
                        <input
                          type="checkbox"
                          checked={selectedSubCategoryNumbers.includes(subCat.subCategoryNumber)}
                          className="form-check-input"
                          style={{ ...rStyles.input }}
                          readOnly
                        />
                      )}
                      <Link
                        key={`${subCat.id}-shop-side-bar-subcat-menu-scat-key-${i}`}
                        to={{ pathname: '/boutique/', search: `?subcategory=${subCat.subCategoryNumber}` }}
                      >
                        <label
                          className="sub-category-title"
                          htmlFor={`productCheckBox${j + 1}`}
                          style={
                            selectedSubCategoryNumbers.includes(subCat.subCategoryNumber)
                              ? {
                                  color: Color.PRIMARY,
                                  fontWeight: 800,
                                }
                              : {}
                          }
                        >
                          {subCat.categoryNumber === OTHER_CATEGORY_NUMBER
                            ? getSubCatName(subCat)
                            : subCat.name.toLocaleLowerCase()}
                          {subCat.productCount !== undefined && `(${subCat.productCount})`}
                        </label>
                      </Link>
                    </div>
                  );
                })}
            </div>
          </div>
        );
      })}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .category-box {
    display: flex;
    flex-direction: column;
    .category-header {
      display: flex;
      align-items: center;
      cursor: pointer;
      color: var(--black);
      font-size: 12px;
      font-weight: 700;
      text-transform: capitalize;
      &:hover {
        text-decoration: underline;
      }
      &:focus {
        outline: none;
      }
    }
    .category-title {
      display: flex;
      align-items: center;
      font-size: 14px;
    }
    .category-toggle {
      display: flex;
      align-items: center;
    }
    .collapsible {
      overflow: hidden;
      transition: max-height 0.3s ease-in-out;
      max-height: 0;
    }
    .collapsible.expanded {
      max-height: fit-content;
    }
    .category-content {
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      box-sizing: border-box;
      gap: 5px;
    }
    .sub-category-box {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 5px;
      a {
        display: flex;
        align-items: center;
        padding: 5px 0;
      }
      * {
        cursor: pointer !important;
      }
      &:focus {
        outline: none;
      }
    }
    input {
      font-size: 20px;
      width: 25px;
      height: 25px;
      display: block;
      flex-shrink: 0;
      flex-grow: 0;
    }
    input:disabled {
      cursor: text !important;
      opacity: 1;
      z-index: 9;
    }
    .sub-category-title {
      cursor: pointer;
      color: var(--black);
      font-size: 12px;
      text-transform: capitalize;
      margin-left: 10px;
      padding: 0;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  a {
    color: black;
    text-decoration: none;
  }
`;
