import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { rSize } from 'core/util/ResponsiveSizeAdapter';
import { Product } from '../../../domain/entities/Product';
import { SubCategory } from '../../../domain/entities/SubCategory';

interface CategoriesTrProps {
  product: Product;
  subCategories: SubCategory[];
}
export const CategoriesTr = ({ product, subCategories }: CategoriesTrProps) => {
  const rStyles = {
    global: {
      p: {
        fontSize: rSize(18),
      },
      link: {
        fontSize: rSize(18),
        height: `fit-content`,
      },
      td: {
        fontSize: rSize(18),
        gap: rSize(12),
      },
    },
  };
  return (
    <TrWrapper>
      <th style={{ ...rStyles.global.p }}>Catégories</th>
      <td style={{ ...rStyles.global.td }}>
        {product.subCategoryNumbers.map((subCat, i) => {
          const catName = subCategories.find((s) => s.subCategoryNumber === subCat)?.name;
          if (!catName) {
            return null;
          }
          return (
            <Link
              key={`${subCat}-cat-tr-link-key-${i}`}
              className="badge me-1"
              style={{ ...rStyles.global.link }}
              to={{ pathname: '/boutique/', search: `?subcategory=${subCat}` }}
            >
              {catName}
            </Link>
          );
        })}
      </td>
    </TrWrapper>
  );
};

const TrWrapper = styled.tr`
  td {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }
`;
