import { ENV } from '../../configuration';
import { InMemoryAuthInteractor } from '../adapter/inmemory/InMemoryAuthInteractor';
import { RealAuthInteractor } from '../adapter/real/RealAuthInteractor';
import { AuthInteractor } from '../domain/gateways/Auth.interactor';

export class AuthContextFactory {
  static authInteractor(): AuthInteractor {
    switch (ENV) {
      case 'prod':
      case 'dev':
        return new RealAuthInteractor();
      default:
        return new InMemoryAuthInteractor();
    }
  }
}
