/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import styled from 'styled-components';
import { useState } from 'react';
import { rSize } from 'core/util/ResponsiveSizeAdapter';
import { StarRating } from './StarRating';
import { Review, SubmitReviewProps } from '../../../generalContext/domain/entities/Review';
import { Product } from '../../../generalContext/domain/entities/Product';
import { User } from '../../../authContext/domain/entities/User';

const emptyReview: SubmitReviewProps = { text: '', rating: 0 };
interface ProductReviewsProps {
  product: Product;
  reviews: Review[];
  stateConnectedUser?: User;
  sendNewReview: (newReview: Review) => void;
  deleteReview: (review: Review) => void;
}
export const ProductReviews = ({
  product,
  reviews,
  stateConnectedUser,
  sendNewReview,
  deleteReview,
}: ProductReviewsProps) => {
  const [newReview, setNewReview] = useState<SubmitReviewProps>({ ...emptyReview });
  const rStyles = {
    global: {
      h3: {
        fontSize: rSize(26),
      },
      p: {
        fontSize: rSize(18),
      },
    },
    reviewBox: {
      padding: `0 ${rSize(10)}px`,
    },
    userName: {
      borderLeft: `${rSize(1)}px solid var(--mono-grey-2)`,
      paddingLeft: rSize(6),
      fontSize: rSize(20),
    },
    reviewAndStars: {
      gap: rSize(10),
      fontSize: rSize(20),
      padding: `${rSize(1)}px solid var(--mono-grey-2)`,
    },
    reviewText: {
      padding: rSize(10),
      fontSize: rSize(18),
    },
    deleteLink: {
      fontSize: rSize(14),
    },
    reviews: {
      marginBottom: rSize(20),
      padding: rSize(15),
      borderRadius: rSize(4),
      borderBottom: `${rSize(5)}px solid var(--secondary)`,
    },
    reviewFormContainer: {
      padding: rSize(15),
      borderRadius: rSize(4),
      borderBottom: `${rSize(5)}px solid var(--secondary)`,
    },
    button: {
      width: rSize(150),
      FontSize: rSize(20),
    },
    textarea: {
      padding: rSize(10),
      borderRadius: rSize(4),
    },
    ratingPart: {
      fontSize: rSize(20),
      marginBottom: rSize(10),
    },
    textPart: {
      fontSize: rSize(20),
      marginBottom: rSize(10),
    },
  };

  const newRatingChange = (newRating: number) => {
    setNewReview((v) => ({ ...v, rating: newRating }));
  };
  return (
    <Wrapper>
      {reviews.filter((review) => review.user.id === stateConnectedUser?.id).length !== 0 && (
        <OwnReviews style={{ ...rStyles.reviews }}>
          {reviews
            .filter((review) => review.user.id === stateConnectedUser?.id)
            .map((review, i) => (
              <div
                className="review-box"
                style={{ ...rStyles.reviewBox }}
                key={`${product.Art_Ean13}-review-own-key-${i}`}
              >
                <div className="review-and-stars" style={{ ...rStyles.reviewAndStars }}>
                  <StarRating size={rStyles.reviewAndStars.fontSize} value={review.rating} showNumber />
                  <span className="user-name" style={{ ...rStyles.userName }}>
                    {review.user.firstName} {review.user.lastName} (Vous)
                  </span>
                  <button
                    className="delete-link"
                    style={{ ...rStyles.deleteLink }}
                    onClick={() => deleteReview(review)}
                    type="submit"
                  >
                    Suprimer
                  </button>
                </div>
                {review.text && (
                  <div className="review-text" style={{ ...rStyles.reviewText }}>
                    {review.text}
                  </div>
                )}
              </div>
            ))}
        </OwnReviews>
      )}
      {reviews.filter((review) => review.user.id !== stateConnectedUser?.id).length !== 0 && (
        <OtherReviews style={{ ...rStyles.reviews }}>
          {reviews
            .filter((review) => review.user.id !== stateConnectedUser?.id)
            .map((review, i) => (
              <div
                className="review-box"
                style={{ ...rStyles.reviewBox }}
                key={`${product.Art_Ean13}-review-other-key-${i}`}
              >
                <div className="review-and-stars" style={{ ...rStyles.reviewAndStars }}>
                  <StarRating size={rStyles.reviewAndStars.fontSize} value={review.rating} showNumber />
                  <span className="user-name" style={{ ...rStyles.userName }}>
                    {review.user.firstName} {review.user.lastName}
                  </span>
                </div>
                {review.text && (
                  <div className="review-text" style={{ ...rStyles.reviewText }}>
                    {review.text}
                  </div>
                )}
              </div>
            ))}
        </OtherReviews>
      )}
      {stateConnectedUser ? (
        <ReviewFormContainer style={{ ...rStyles.reviewFormContainer }}>
          <h3 style={{ ...rStyles.global.h3 }}>Notez ce produit</h3>
          <form>
            <div className="rating-part" style={{ ...rStyles.ratingPart }}>
              <label style={{ ...rStyles.global.p }} htmlFor="rating">
                Votre note:
              </label>
              <StarRating
                size={rStyles.reviewAndStars.fontSize}
                value={newReview.rating}
                onChange={newRatingChange}
                showNumber
              />
            </div>
            <div className="text-part" style={{ ...rStyles.textPart }}>
              <label style={{ ...rStyles.global.p }} htmlFor="comment">
                Votre commentaire:
              </label>
              <textarea
                id="comment"
                name="comment"
                style={{ ...rStyles.textarea }}
                value={newReview.text}
                onChange={(e) => setNewReview((v) => ({ ...v, text: e.target.value }))}
              />
            </div>
            <button
              type="button"
              onClick={() => {
                const reviewTosend: Review = {
                  rating: newReview.rating,
                  text: newReview.text,
                  user: stateConnectedUser,
                  productEan: product.Art_Ean13,
                };
                sendNewReview(reviewTosend);
                setNewReview({ ...emptyReview });
              }}
              className="btn btn-primary btnhover mt-2"
              style={{ ...rStyles.button }}
            >
              <p style={{ fontSize: rStyles.button.FontSize }}>Envoyer</p>
            </button>
          </form>
        </ReviewFormContainer>
      ) : (
        <p style={{ ...rStyles.global.p }}>Vous devez être connecté pour donner vos avis.</p>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.section`
  width: 100%;
  .review-box {
    background-color: var(--mono-grey-3);
    padding: 0 10px;
    .user-name {
      text-transform: capitalize;
      max-width: 50%;
      border-left: 1px solid var(--mono-grey-2);
      padding-left: 1em;
      font-size: 20px;
    }
    .review-and-stars {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      font-size: 1.5rem;
      font-weight: 600;
      padding: 10px 0;
    }
    .review-text {
      padding: 10px;
      background-color: var(--mono-grey-3);
      font-size: 18px;
    }
    .delete-link {
      font-size: 14px;
      text-decoration: underline;
    }
    .delete-link:hover {
      color: red;
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

const OwnReviews = styled.div`
  background-color: white;
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 4px;
  border-bottom: 5px solid var(--secondary);
`;
const OtherReviews = styled.div`
  background-color: white;
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 4px;
  border-bottom: 5px solid var(--secondary);
`;

const ReviewFormContainer = styled.div`
  background-color: white;
  padding: 15px;
  border-radius: 4px;
  border-bottom: 5px solid var(--secondary);
  * {
    gap: 10px;
  }
  label {
    width: 20%;
  }
  form {
    display: flex;
    flex-direction: column;
    justify-content: end;
  }
  button {
    width: 150px;
    align-self: flex-end;
  }
  textarea {
    padding: 10px;
    border-radius: 4px;
    width: 80%;
  }
  .rating-part {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .text-part {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
    display: flex;
    justify-content: start;
    align-items: flex-start;
  }
`;
