// COLORS
export enum ShowcaseType {
  None = 'None',
  Default = 'Default',
  Promo = 'Promo',
}

export interface ShowcaseItem {
  id?: string;
  imageBase64?: string; // base64 string representation of the image
  type?: ShowcaseType;
  startDate?: string;
  endDate?: string;
  createdAt?: string;
  updatedAt?: string;
}

export const hardCodedShowcaseImage: ShowcaseItem = {
  id: '8a25c5d1-bf0d-45ff-89b8-bea52e77123e',
};

export const hardCodedShowcaseImages: ShowcaseItem[] = [
  {
    id: '1e61c0f7-84db-4f70-8093-a1e98786659d',
    imageBase64: '',
    type: ShowcaseType.None,
  },
  {
    id: 'b339e49d-17b2-449b-ac54-44b679d87657',
    imageBase64: '',
    type: ShowcaseType.Default,
  },
  {
    id: 'b339e49d-17b2-449b-ac54-44b679d87655',
    imageBase64: '',
    type: ShowcaseType.Promo,
  },
];
