import React from 'react';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import './assets/styles/scss/main.scss';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import reportWebVitals from 'reportWebVitals';
import { App } from './App';
import { IESupport } from './common/ui/old/IESupport';
import { appStore, history } from './configuration/redux/store';
import { LoaderContextProvider } from './common/configuration/loaderContextProvider';
import { NotificationContextProvider } from './common/configuration/notificationContextProvider';

// @ts-ignore // gotta take care of those IE users
const isIE = /* @cc_on!@ */ false || !!document.documentMode;

let ShowingApp = (
  <Provider store={appStore.store}>
    <Router history={history}>
      <ConnectedRouter history={history}>
        <NotificationContextProvider>
          <LoaderContextProvider>
            <App />
          </LoaderContextProvider>
        </NotificationContextProvider>
      </ConnectedRouter>
    </Router>
  </Provider>
);

if (isIE) {
  ShowingApp = <IESupport />;
}

// TODO: Update loading screen
ReactDOM.render(<React.StrictMode>{ShowingApp}</React.StrictMode>, document.getElementById('root'));

// Log results to console or send to analytics endpoint
reportWebVitals(console.log);
