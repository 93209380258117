import { AuthTokens } from '../../../common/domain/entities/AuthTokens';
import { ChangePasswordProps } from '../../../common/domain/entities/ChangePasswordProps';
import { User } from '../../domain/entities/User';
import { AuthInteractor } from '../../domain/gateways/Auth.interactor';

export class InMemoryAuthInteractor implements AuthInteractor {
  updatePassword(props: ChangePasswordProps): Promise<boolean> {
    throw new Error('Method not implemented.');
  }

  passwordForgotten(email: string): Promise<boolean> {
    throw new Error('Method not implemented.');
  }

  sendPasswordForgotten(email: string): Promise<void> {
    throw new Error('Method not implemented.');
  }

  getUserData(): Promise<User> {
    throw new Error('Method not implemented.');
  }

  sendSignUp(newUser: User): Promise<AuthTokens> {
    throw new Error('Method not implemented.');
  }

  updateUserData(userData: User): Promise<User> {
    throw new Error('Method not implemented.');
  }

  sendSignIn(user: User): Promise<AuthTokens> {
    throw new Error('Method not implemented.');
  }

  sendSignOut(): Promise<boolean> {
    throw new Error('Method not implemented.');
  }

  renewTokens(): Promise<AuthTokens> {
    throw new Error('Method not implemented.');
  }

  sendPasswordForgottenEmail(email: string): Promise<boolean> {
    throw new Error('Method not implemented.');
  }

  resetPassword(): Promise<AuthTokens> {
    throw new Error('Method not implemented.');
  }
}
