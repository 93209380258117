import styled from 'styled-components';
import { rSize } from 'core/util/ResponsiveSizeAdapter';

interface ProductBadgeProps {
  label: string;
  color?: string;
}
export const ProductBadge = ({ label, color = 'red' }: ProductBadgeProps) => {
  const rStyles = {
    wrapper: {
      fontSize: rSize(16),
      padding: `${rSize(5)}px ${rSize(10)}px`,
      borderRadius: rSize(10),
    },
  };
  return (
    <Wrapper color={color} style={{ ...rStyles.wrapper }}>
      {label}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ color: string }>`
  font-size: 16px;
  color: white;
  background-color: ${({ color }) => `${color}`};
  padding: 5px 10px;
  border-radius: 25px;
`;
