/* eslint-disable jsx-a11y/anchor-is-valid */
import styled from 'styled-components';
import { rSize } from 'core/util/ResponsiveSizeAdapter';

interface SocialLinksProps {
  fontSize?: number;
  color?: string;
}
export const SocialLinks = ({ fontSize = 20, color = 'var(--primary)' }: SocialLinksProps) => {
  const rStyles = {
    headerRight: {
      gap: rSize(50),
    },
  };
  return (
    <Wrapper fontSize={fontSize}>
      <div className="header-right" style={{ ...rStyles.headerRight }}>
        <a href="#" className="header-item">
          <i className="fa-brands fa-facebook" style={{ color }} />
        </a>
        <a href="#" className="header-item">
          <i className="fa-brands fa-twitter" style={{ color }} />
        </a>
        <a href="#" className="header-item">
          <i className="fa-brands fa-instagram" style={{ color }} />
        </a>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section<{ fontSize: number }>`
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size: ${({ fontSize }) => `${fontSize}px`};
  .header-right {
    justify-content: center;
    gap: 30px;
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    i {
      color: var(--primary);
    }
  }
`;
