import { useEffect, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import styled from 'styled-components';
import { rSize } from 'core/util/ResponsiveSizeAdapter';

interface WishButtonProps {
  id: string;
  disabled?: boolean;
  isActive?: boolean;
  toggleWished: () => void;
}

export const WishButton = ({ id, disabled, isActive: initialIsActive, toggleWished }: WishButtonProps) => {
  const [isActive, setIsActive] = useState<boolean>(initialIsActive || false);

  useEffect(() => {
    setIsActive(initialIsActive || false);
  }, [initialIsActive]);

  const handleClick = () => {
    if (!disabled) {
      setIsActive(!isActive);
      toggleWished();
    }
  };

  const styles = {
    tooltip: {
      fontSize: rSize(16),
    },
    label: {
      fontSize: rSize(24),
      width: rSize(50),
      height: rSize(50),
      borderRadius: rSize(4),
      padding: rSize(10),
    },
  };

  return (
    <Wrapper>
      <div className="wishlist-button">
        <input
          disabled={disabled}
          className={`wishlist-checkbox ${disabled || !isActive ? '' : 'active'}`}
          type="checkbox"
          id={`wishlist-checkbox-${id}`}
          onChange={handleClick}
          checked={isActive}
        />
        <Tooltip
          title={
            disabled
              ? 'Veuillez vous connecter pour ajouter des produits à votre liste de souhaits.'
              : 'Ajouter à votre liste de souhaits.'
          }
          componentsProps={{
            tooltip: {
              sx: {
                fontSize: styles.tooltip.fontSize,
              },
            },
          }}
        >
          <label htmlFor={`wishlist-checkbox-${id}`} style={styles.label}>
            <i className="flaticon-heart" />
          </label>
        </Tooltip>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .wishlist-button {
    position: relative;
  }

  .wishlist-checkbox {
    display: none;
  }

  .wishlist-checkbox:checked + label,
  .wishlist-checkbox.active + label {
    color: #fff;
    background-color: var(--bs-danger);
    border: 1px solid var(--bs-danger);
  }

  label {
    font-size: 24px;
    cursor: pointer;
    width: 50px;
    height: 50px;
    border-radius: 4px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media only screen and (max-width: 991px) {
    .wishlist-button.style-2 label {
      top: 25px;
      right: 25px;
    }
  }

  @media only screen and (max-width: 575px) {
    .wishlist-button.style-2 label {
      top: 30px;
      right: 30px;
    }
  }
`;
