import { Action } from 'redux';
import { connect } from 'react-redux';
import { dispatchAsync } from 'react-redux-dispatch-async';
import {
  getStateCategories,
  getStateCookiesAccepted,
  getStateMagasins,
} from 'generalContext/usecases/General.selectors';
import { cookiesAcceptRequest } from 'generalContext/usecases/General.actions';
import { NavigatorDumb } from './Navigator.dumb';
import { AppState } from '../../../common/configuration/app.states';
import { getConnectedUser, getLoading } from '../../../authContext/usecases/Auth.selectors';
import { NavigationPayload } from '../../domain/entities/NavigationPayload';
import { navigateToRequest } from '../../usecases/navigation.actions';

const mapState = (state: AppState) => ({
  stateConnectedUser: getConnectedUser(state),
  stateLoading: getLoading(state),
  stateMagasins: getStateMagasins(state),
  stateCategories: getStateCategories(state),
  stateCookiesAccepted: getStateCookiesAccepted(state),
});

const mapDispatch = (dispatch: (a: Action) => void) => ({
  navigateTo: (p: NavigationPayload) => dispatch(navigateToRequest(p)),
  cookiesAcceptAsync: () => dispatchAsync(dispatch, cookiesAcceptRequest()),
});

export const NavigatorSmart = connect(mapState, mapDispatch)(NavigatorDumb);
