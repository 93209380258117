import { Action } from 'redux';
import { connect } from 'react-redux';
import { dispatchAsync } from 'react-redux-dispatch-async';
import { SignUpDumb } from './SignUp.dumb';
import { AppState } from '../../../common/configuration/app.states';
import { getConnectedUser } from '../../usecases/Auth.selectors';
import { sendSignUpRequest, updateUserDataRequest } from '../../usecases/Auth.actions';
import { User } from '../../domain/entities/User';

const mapState = (state: AppState) => ({
  stateConnectedUser: getConnectedUser(state),
});

const mapDispatch = (dispatch: (a: Action) => void) => ({
  // navigateTo: (nav: NavigationPayload) => dispatch(navigateTo(nav)),
  sendSignUpAsync: (newUser: User) => dispatchAsync(dispatch, sendSignUpRequest(newUser)),
  updateUserDataAsync: (userData: User) => dispatchAsync(dispatch, updateUserDataRequest(userData)),
});

export const SignUpSmart = connect(mapState, mapDispatch)(SignUpDumb);
