import { useEffect, useState } from 'react';
import { DispatchAsyncResult } from 'react-redux-dispatch-async/lib/dispatchAsync';
import styled from 'styled-components';
import _ from 'lodash';
import { Link, useHistory } from 'react-router-dom';
import { PageTitle } from '../../../common/ui/layouts/PageTitle';
import { AppButton } from '../../../common/ui/elements/AppButton';
import { FormErrorList } from '../../../common/ui/elements/FormErrorList';
import {
  ERROR_PASS_LENGTH,
  ERROR_PASSWORDS_NOT_MATCH,
  ERROR_RECLICK_EMAIL_LINK,
  ERROR_RESETING_PASS,
  passwordMandatoryLength,
  SUCCESS_RESET_PASS,
} from '../../../configuration/helpers/constants';
import { useNotificationContext } from '../../../common/configuration/notificationContextProvider';
import { AuthTokens } from '../../../common/domain/entities/AuthTokens';
import { emptyResetPassProps, ResetPasswordProps } from '../../../common/domain/entities/ResetPasswordProps';
import { ResetPasswordForm } from '../../../common/ui/elements/ResetPasswordForm';

interface ResetPasswordScreenProps {
  resetPasswordAsync(props: ResetPasswordProps): Promise<DispatchAsyncResult<AuthTokens>>;
}
export const ResetPasswordScreenDumb = ({ resetPasswordAsync }: ResetPasswordScreenProps) => {
  const history = useHistory();
  const notifications = useNotificationContext();
  const [resetPassProps, setResetPassProps] = useState<ResetPasswordProps>({ ...emptyResetPassProps });
  const [showError, setShowError] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<string[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    validateForm();
  }, [resetPassProps]);

  useEffect(() => {
    const params: ResetPasswordProps = history?.location?.state as ResetPasswordProps;
    setResetPassProps(params);
  }, [history?.location?.state]);

  const validateForm = () => {
    const errors: string[] = [];
    if (
      _.isEmpty(resetPassProps.newPassword) ||
      (resetPassProps.newPassword && resetPassProps.newPassword.length < passwordMandatoryLength)
    ) {
      errors.push(ERROR_PASS_LENGTH);
    }
    if (resetPassProps.newPassword !== resetPassProps.newPasswordConfirm) {
      errors.push(ERROR_PASSWORDS_NOT_MATCH);
    }
    setFormErrors(errors);
  };

  const ResetPassword = async () => {
    setShowError(true);
    if (formErrors.length || !resetPassProps.accessToken || !resetPassProps.id) {
      return;
    }
    setIsSubmitting(true);
    try {
      const response = await resetPasswordAsync(resetPassProps);
      if (response.success) {
        // success
        history.push('/profile');
        notifications.setMessage({
          message: SUCCESS_RESET_PASS,
          status: 'success',
        });
        setFormErrors([]);
      } else {
        // fail
        setFormErrors([`${ERROR_RESETING_PASS}, ${ERROR_RECLICK_EMAIL_LINK}`]);
      }
    } catch (error) {
      // Handle other errors
      notifications.setMessage({
        message: `${ERROR_RESETING_PASS}, ${ERROR_RECLICK_EMAIL_LINK}`,
        status: 'error',
      });
      console.error('ResetPassword caught error', error);
    } finally {
      setIsSubmitting(false);
    }
  };
  if (!resetPassProps) {
    return null;
  }
  return (
    <Wrapper>
      <div className="page-content">
        <PageTitle pageTitle="Connexion" />
        <section className="content-inner shop-account">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-6 mb-4">
                <div className="login-area">
                  <h4 className="text-secondary">Mot de passe oublié</h4>
                  <p className="font-weight-600">Réinitialisation</p>
                  <br />
                  {showError && <FormErrorList errors={formErrors} />}
                  <ResetPasswordForm
                    showError={showError}
                    resetPassProps={resetPassProps}
                    setResetPassProps={setResetPassProps}
                    enterFunc={ResetPassword}
                  />
                  <AppButton label="Réinitialiser" disabled={isSubmitting} action={ResetPassword} />
                  <p className="pass-forgotten-text">
                    Vous avez déjà un compte?
                    <Link to="/login"> Se connecter ici !</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .pass-forgotten-text {
    margin-top: 10px;
    font-size: 16px;
  }
`;
