import styled from 'styled-components';
import { AppContainer } from 'common/ui/elements/AppContainer';
import { rSize } from 'core/util/ResponsiveSizeAdapter';
import { TestimonialSlider } from './TestimonialSlider';
import defaultTestimonials from '../../data/Testimonials.json';

export const TestimonialsSection = () => {
  const rStyles = {
    global: {},
    wrapper: {
      gap: rSize(10),
      marginTop: rSize(10),
      marginBottom: rSize(30),
    },
  };
  return (
    <AppContainer>
      <Wrapper style={{ ...rStyles.wrapper }}>
        <section className="content-inner-4 testimonial-wrapper">
          <TestimonialSlider testimonials={defaultTestimonials} />
        </section>
      </Wrapper>
    </AppContainer>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1%;
  margin-bottom: 20px;
  .two-small {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 40%;
  }
  .one-big {
    width: 60%;
  }
  .triplet-image {
    background-color: var(--secondary);
    width: 100%;
    height: 100%;
  }
`;
