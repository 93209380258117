import { Action } from 'redux';
import { connect } from 'react-redux';
import { dispatchAsync } from 'react-redux-dispatch-async';
import { HeaderDumb } from './Header.dumb';
import { getAuthTokens, getConnectedUser } from '../../../../authContext/usecases/Auth.selectors';
import {
  deleteCartProductRequest,
  getAdminPreferancesRequest,
  getCartProductsRequest,
  getCategoriesRequest,
  getDeliveryProductsRequest,
  getMagasinListRequest,
  getProductsBySubCategoryNumberRequest,
  getSubCategoriesRequest,
  getWishProductsRequest,
} from '../../../../generalContext/usecases/General.actions';
import {
  getAdminPreferances,
  getStateCart,
  getStateCategories,
  getStateSubCategories,
  getStateWishProducts,
} from '../../../../generalContext/usecases/General.selectors';
import { AppState } from '../../../configuration/app.states';
import { getUserDataRequest, sendSignOutRequest } from '../../../../authContext/usecases/Auth.actions';

const mapState = (state: AppState) => ({
  stateAdminPreferances: getAdminPreferances(state),
  stateConnectedUser: getConnectedUser(state),
  stateWishProducts: getStateWishProducts(state),
  stateCart: getStateCart(state),
  stateCategories: getStateCategories(state),
  stateSubCategories: getStateSubCategories(state),
  stateAuthTokens: getAuthTokens(state),
});

const mapDispatch = (dispatch: (a: Action) => void) => ({
  getUserDataAsync: () => dispatchAsync(dispatch, getUserDataRequest()),
  getCategoriesAsync: () => dispatchAsync(dispatch, getCategoriesRequest()),
  getSubCategoriesAsync: () => dispatchAsync(dispatch, getSubCategoriesRequest()),
  signOutAsync: () => dispatchAsync(dispatch, sendSignOutRequest()),
  getAdminPreferancesAsync: () => dispatchAsync(dispatch, getAdminPreferancesRequest()),
  // wish
  getWishProductsAsync: () => dispatchAsync(dispatch, getWishProductsRequest()),
  // cart
  getCartProductsAsync: () => dispatchAsync(dispatch, getCartProductsRequest()),
  deleteCartProductAsync: (Art_Ean13: string) => dispatchAsync(dispatch, deleteCartProductRequest(Art_Ean13)),
  // magasin
  getMagasinListAsync: () => dispatchAsync(dispatch, getMagasinListRequest()),
  // delivery products
  getDeliveryProductsAsync: () => dispatchAsync(dispatch, getDeliveryProductsRequest()),
  getProductsBySubCategoryNumberAsync: (id: number) =>
    dispatchAsync(dispatch, getProductsBySubCategoryNumberRequest(id)),
});

export const HeaderSmart = connect(mapState, mapDispatch)(HeaderDumb);
