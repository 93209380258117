import { authContextDependencies } from '../authContext/configuration/AuthContextDependencies';
import { navigationContextDependencies } from '../navigationContext/configuration/navigationContextDependencies';
import { generalContextDependencies } from '../generalContext/configuration/GeneralContextDependencies';

export const dependencies = {
  ...generalContextDependencies,
  ...navigationContextDependencies,
  ...authContextDependencies,
};

export type DependenciesType = typeof dependencies;
