import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { DispatchAsyncResult } from 'react-redux-dispatch-async/lib/dispatchAsync';
import { useHistory } from 'react-router-dom';
import { getProfileDataFormErrors } from 'configuration/helpers/HelperFunctions';
import { rSize } from 'core/util/ResponsiveSizeAdapter';
import { User } from '../../../../authContext/domain/entities/User';
import { AppButton } from '../../../../common/ui/elements/AppButton';
import { FormErrorList } from '../../../../common/ui/elements/FormErrorList';
import { UserDataForm } from '../../../../common/ui/elements/UserDataForm';
import { useNotificationContext } from '../../../../common/configuration/notificationContextProvider';

interface UserDataProps {
  user: User;
  setUser: Dispatch<SetStateAction<User>>;
  stateConnectedUser?: User;
  updateUserDataAsync(userData: User): Promise<DispatchAsyncResult<User>>;
}
export const UserData = ({ user, setUser, stateConnectedUser, updateUserDataAsync }: UserDataProps) => {
  const notifications = useNotificationContext();
  const history = useHistory();
  const [showError, setShowError] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<string[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isFirstTime = !stateConnectedUser?.firstName;

  const rStyles = {
    global: {
      h5: {
        fontSize: rSize(22),
      },
      p: {
        fontSize: rSize(18),
      },
    },
  };

  useEffect(() => {
    if (stateConnectedUser) {
      setUser({ ...stateConnectedUser });
    }
  }, [stateConnectedUser]);

  useEffect(() => {
    setFormErrors(getProfileDataFormErrors(user));
  }, [user]);

  const updateUserData = async () => {
    setShowError(true);
    if (formErrors.length) {
      return;
    }
    setIsSubmitting(true);
    try {
      const response: any = await updateUserDataAsync(user);
      if (response.success) {
        // Scenario A: updated data successfully
        setFormErrors([]);
        notifications.setMessage({
          message: 'Enregistré avec succès',
          status: 'success',
        });
      } else {
        notifications.setMessage({
          message: "Une erreur s'est produite lors de la tentative de mise à jour de vos données",
          status: 'error',
        });
        console.error('updateUserData caught error', response.error);
      }
    } catch (error) {
      // Handle other errors
      notifications.setMessage({
        message: "Une erreur s'est produite lors de la tentative de mise à jour de vos données",
        status: 'error',
      });
      console.error('updateUserData caught error', error);
    } finally {
      setIsSubmitting(false);
      if (isFirstTime) {
        history.push('/');
      }
    }
  };

  return (
    <div className="shop-bx shop-profile">
      <div className="shop-bx-title clearfix">
        <h5 className="text-uppercase" style={{ ...rStyles.global.h5 }}>
          Informations de base
        </h5>
      </div>
      <FormErrorList errors={formErrors} fontSize={rStyles.global.p.fontSize} />
      <UserDataForm showError={showError} user={user} setUser={setUser} />
      <AppButton label="Enregistrer" disabled={isSubmitting} action={updateUserData} />
    </div>
  );
};
