import styled from 'styled-components';
import { rSize } from 'core/util/ResponsiveSizeAdapter';
import { ImgText } from './ImgText';
import visaPaypalImg from '../../../assets/images/visa-paypal.png';
import thumbsUp from '../../../assets/images/master-thumbs-up.png';
import medal from '../../../assets/images/medal.png';

export const FooterVisaImages = () => {
  const rStyles = {
    wrapper: {
      gap: rSize(20),
      margin: `${rSize(20)}px 0`,
    },
    row: {
      rowGap: rSize(60),
    },
    img: {
      width: rSize(70),
    },
  };
  return (
    <Wrapper>
      <div className="row justify-content-center" style={{ ...rStyles.row }}>
        <div className="col-12 col-sm-5 col-md-5 col-lg-5 me-1">
          <div className="bigger-container">
            <ImgText img={<img src={visaPaypalImg} alt="visaPaypalImg" />} text="Paiement sécurisé." />
          </div>
        </div>
        <div className="col-5 col-sm-3 col-md-3 col-lg-3 me-1">
          <ImgText
            img={<img src={medal} alt="visaPaypalImg" style={{ ...rStyles.img }} />}
            text="Librairie Labellisée."
          />
        </div>
        <div className="col-5 col-sm-3 col-md-3 col-lg-3 me-1">
          <ImgText
            img={<img src={thumbsUp} alt="visaPaypalImg" style={{ ...rStyles.img }} />}
            text="Votre satisfaction, notre priorité !"
          />
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  gap: 20px;
  margin: 20px 0;
  text-align: center;
  .bigger-container {
    flex: 2;
  }
`;
