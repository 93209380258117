import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { DispatchAsyncResult } from 'react-redux-dispatch-async/lib/dispatchAsync';
import { useEffect, useState } from 'react';
import { rSize } from 'core/util/ResponsiveSizeAdapter';
import { User } from 'authContext/domain/entities/User';
import { Product } from '../../../../generalContext/domain/entities/Product';
import { CartButton } from '../CartButton';
import { WishButton } from '../WishButton';
import { Cart } from '../../../../generalContext/domain/entities/Cart';
import noImageImage from '../../../../assets/images/NoImageImage.png';
import { ProductPrice } from '../ProductPrice';
import { StarRating } from '../StarRating';
import { ProductBadge } from '../ProductBadge';

interface ProductCardProps {
  product: Product;
  stateWishProducts: Product[] | undefined;
  stateCart: Cart | undefined;
  stateConnectedUser: User | undefined;
  // wish
  saveWishProductAsync(product: Product): Promise<DispatchAsyncResult<Product>>;
  deleteWishProductAsync(Art_Ean13: string): Promise<DispatchAsyncResult<Product>>;
  // cart
  saveCartProductAsync(product: Product): Promise<DispatchAsyncResult<Product>>;
  deleteCartProductAsync(Art_Ean13: string): Promise<DispatchAsyncResult<boolean>>;
}

export const ProductCardDumb = ({
  product,
  stateWishProducts,
  stateCart,
  stateConnectedUser,
  saveWishProductAsync,
  deleteWishProductAsync,
  saveCartProductAsync,
  deleteCartProductAsync,
}: ProductCardProps) => {
  const [isInCart, setIsInCart] = useState<boolean>(false);
  const [isInWish, setIsInWish] = useState<boolean>(false);
  const [isUserConnected, setIsUserConnected] = useState<boolean>(false);

  const rStyles = {
    global: {
      starRatingSize: rSize(16),
      priceCartContainerSize: rSize(24),
    },
    wrapper: {
      padding: `${rSize(34)}px ${rSize(24)}px ${rSize(20)}px ${rSize(24)}px`,
    },
    productImageContainer: {
      height: rSize(340),
      marginBottom: rSize(15),
    },
    wishContainer: {
      top: rSize(0),
      right: rSize(0),
    },
    badgeContainer: {
      top: rSize(0),
      left: rSize(0),
    },
    productName: {
      fontSize: rSize(20),
      minHeight: rSize(36),
    },
  };

  useEffect(() => {
    if (stateConnectedUser) {
      setIsUserConnected(true);
    } else {
      setIsUserConnected(false);
    }
  }, [stateConnectedUser]);

  useEffect(() => {
    setIsInCart(!!stateCart?.items.find((item) => item.Art_Ean13 === product.Art_Ean13));
  }, [stateCart]);

  useEffect(() => {
    setIsInWish(!!stateWishProducts?.find((item) => item.Art_Ean13 === product.Art_Ean13));
  }, [stateWishProducts]);

  const wishButtonClicked = () => {
    if (isInWish) {
      deleteWishProductAsync(product.Art_Ean13);
    } else if (product) {
      saveWishProductAsync({ ...product, Art_Quantity: 1 });
    }
  };
  const cartButtonClicked = () => {
    if (isInCart) {
      deleteCartProductAsync(product.Art_Ean13);
    } else if (product) {
      saveCartProductAsync({ ...product, Art_Quantity: 1 });
    }
  };

  return (
    <Wrapper style={{ ...rStyles.wrapper }}>
      <div className="product-image-container" style={{ ...rStyles.productImageContainer }}>
        <div className="wish-container" style={{ ...rStyles.wishContainer }}>
          <WishButton
            id={product.Art_Ean13}
            isActive={isInWish}
            disabled={!stateCart || !isUserConnected}
            toggleWished={wishButtonClicked}
          />
        </div>
        {product.Art_Prix_Promo && (
          <div className="badge-container" style={{ ...rStyles.badgeContainer }}>
            <ProductBadge label="PROMO" />
          </div>
        )}
        <Link to={`/product-details/${product.Art_Ean13}`}>
          <img
            className="product-fay-image"
            src={product.Art_Images[0] ? `${product.Art_Images[0]}` : `${noImageImage}`}
            alt="product-img"
          />
        </Link>
      </div>
      <h6 className="product-name" style={{ ...rStyles.productName }}>
        <Link
          to={`/product-details/${product.Art_Ean13}`}
          title={product.Art_Titre}
          style={{ fontSize: rStyles.productName.fontSize }}
        >
          {product.Art_Titre}
        </Link>
      </h6>
      <StarRating value={product.review_score ?? 0} size={rStyles.global.starRatingSize} />
      <div className="price-cart-container">
        <ProductPrice product={product} fontSize={rStyles.global.priceCartContainerSize} />
        <CartButton inCart={isInCart} action={cartButtonClicked} />
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  a:hover {
    color: var(--primary) !important;
    text-decoration: none;
  }
  .product-image-container {
    width: 100%;
    height: 200px;
    max-height: 100%;
    margin-bottom: 15px;
    a {
      max-height: 100%;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .product-fay-image {
        max-height: 100%;
        object-fit: contain;
      }
    }
  }
  .product-name {
    font-size: 1.2em;
    font-weight: 400;
    text-align: center;
    min-height: 36px;
    overflow: hidden;
    text-overflow: ellipsis; /* add an ellipsis if the text overflows */
    white-space: nowrap; /* prevent wrapping */
    max-width: 100%;
  }
  .wish-container,
  .badge-container {
    transition: all 300ms ease;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .badge-container {
    right: auto;
    left: 10px;
  }
  .price-cart-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
`;
