import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Dropdown } from 'react-bootstrap';
import { rSize } from 'core/util/ResponsiveSizeAdapter';
import { User } from '../../../../../authContext/domain/entities/User';
import { AppButton } from '../../../elements/AppButton';

interface UserMenuProps {
  stateConnectedUser?: User;
  signOut: () => void;
}
export const UserMenu = ({ stateConnectedUser, signOut }: UserMenuProps) => {
  const rStyles = {
    global: {
      svgSize: rSize(20),
      spanFontSize: rSize(18),
    },
    wrapper: {
      boxShadow: `0 0 ${rSize(15)}px rgba(0, 0, 0, 0.15)`,
      borderRadius: rSize(5),
      paddingBottom: rSize(2),
      authMenu: {
        padding: `${rSize(5)}px ${rSize(10)}px`,
      },
      disconnecte: {
        margin: rSize(10),
      },
    },
  };
  if (stateConnectedUser) {
    return (
      <Wrapper
        style={{
          boxShadow: rStyles.wrapper.boxShadow,
          borderRadius: rStyles.wrapper.borderRadius,
          paddingBottom: rStyles.wrapper.paddingBottom,
        }}
      >
        <div className="dropdown-header">
          <h6
            className="m-0"
            style={{
              fontSize: rStyles.global.spanFontSize,
            }}
          >{`${stateConnectedUser.firstName} ${stateConnectedUser.lastName}`}</h6>
          <span
            style={{
              fontSize: rStyles.global.spanFontSize,
            }}
          >
            {stateConnectedUser.email}
          </span>
        </div>
        <div className="dropdown-body">
          <Dropdown.Item
            as={Link}
            to="/profile#profile"
            className="dropdown-item d-flex justify-content-between align-items-center ai-icon"
          >
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height={rStyles.global.svgSize}
                viewBox="0 0 24 24"
                width={rStyles.global.svgSize}
                fill="#000000"
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path d="M12 6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2m0 10c2.7 0 5.8 1.29 6 2H6c.23-.72 3.31-2 6-2m0-12C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 10c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
              </svg>
              <span
                className="ms-2"
                style={{
                  fontSize: rStyles.global.spanFontSize,
                }}
              >
                Profil
              </span>
            </div>
          </Dropdown.Item>
          <Dropdown.Item
            as={Link}
            to="/panier"
            className="dropdown-item d-flex justify-content-between align-items-center ai-icon"
          >
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height={rStyles.global.svgSize}
                viewBox="0 0 24 24"
                width={rStyles.global.svgSize}
                fill="#000000"
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path d="M15.55 13c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.37-.66-.11-1.48-.87-1.48H5.21l-.94-2H1v2h2l3.6 7.59-1.35 2.44C4.52 15.37 5.48 17 7 17h12v-2H7l1.1-2h7.45zM6.16 6h12.15l-2.76 5H8.53L6.16 6zM7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zm10 0c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z" />
              </svg>
              <span
                className="ms-2"
                style={{
                  fontSize: rStyles.global.spanFontSize,
                }}
              >
                Mon panier
              </span>
            </div>
          </Dropdown.Item>
          <Dropdown.Item
            as={Link}
            to="/profile#historique"
            className="dropdown-item d-flex justify-content-between align-items-center ai-icon"
          >
            <div>
              <i className="fa fa-credit-card" aria-hidden="true" />
              <span
                className="ms-2"
                style={{
                  fontSize: rStyles.global.spanFontSize,
                }}
              >
                Historique des commandes
              </span>
            </div>
          </Dropdown.Item>
        </div>
        <div
          className="disconnecte"
          style={{
            margin: rStyles.wrapper.disconnecte.margin,
          }}
        >
          <AppButton label="Se Déconnecter" action={signOut} />
        </div>
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      <div
        className="auth-menu"
        style={{
          padding: rStyles.wrapper.authMenu.padding,
        }}
      >
        <Dropdown.Item>
          <AppButton label="Se connecter" to="/login" />
        </Dropdown.Item>
        <Dropdown.Item>
          <AppButton label="S'inscrire" to="/inscription" variant="v2" />{' '}
        </Dropdown.Item>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  background-color: white;
  padding-bottom: 2px;
  .auth-menu .dropdown-item {
    margin: 0;
    padding: 5px 10px;
  }
  .disconnecte {
    margin: 10px;
    margin-top: 0;
  }
  .dropdown-item {
    cursor: pointer;
    display: block;
  }
  .dropdown-link {
    display: flex;
    flex-direction: row;
    justify-content: center flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
  }
`;
