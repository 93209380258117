import { all, call, put, takeLatest } from 'redux-saga/effects';
import { Action } from 'redux-actions';
import { goBack, push, replace } from 'connected-react-router';
import {
  getUserDataFailure,
  getUserDataRequest,
  getUserDataSuccess,
  removeTokensFailure,
  removeTokensRequest,
  removeTokensSuccess,
  resetPasswordFailure,
  resetPasswordRequest,
  resetPasswordSuccess,
  sendPasswordForgottenEmailFailure,
  sendPasswordForgottenEmailRequest,
  sendPasswordForgottenEmailSuccess,
  sendSignInFailure,
  sendSignInRequest,
  sendSignInSuccess,
  sendSignOutFailure,
  sendSignOutRequest,
  sendSignOutSuccess,
  sendSignUpFailure,
  sendSignUpRequest,
  sendSignUpSuccess,
  updatePasswordFailure,
  updatePasswordRequest,
  updatePasswordSuccess,
  updateUserDataFailure,
  updateUserDataRequest,
  updateUserDataSuccess,
} from './Auth.actions';
import { getDependencies } from '../../common/usecases/common.sagas';
import { DependenciesType } from '../../configuration/dependencies';
import { User } from '../domain/entities/User';
import { AuthTokens } from '../../common/domain/entities/AuthTokens';
import { ChangePasswordProps } from '../../common/domain/entities/ChangePasswordProps';
import { ResetPasswordProps } from '../../common/domain/entities/ResetPasswordProps';

function* sendSignUpSaga(action: Action<User>) {
  try {
    const dep: DependenciesType = yield getDependencies();
    const res: AuthTokens = yield call(dep.authInteractor.sendSignUp, action.payload);
    yield put(sendSignUpSuccess(res));
  } catch (error) {
    yield put(sendSignUpFailure(error));
  }
}

function* sendSignInSaga(action: Action<User>) {
  try {
    const dep: DependenciesType = yield getDependencies();
    const res: AuthTokens = yield call(dep.authInteractor.sendSignIn, action.payload);
    yield put(sendSignInSuccess(res));
  } catch (error) {
    yield put(sendSignInFailure(error));
  }
}

/* function* renewTokensSaga() {
  try {
    yield put(setLoadingSuccess(true));
    const dep: DependenciesType = yield getDependencies();
    const res: AuthTokens = yield call(dep.authInteractor.renewTokens);
    if (res) {
      setTokensToFetch(res);
      yield put(sendAutoSignInSuccess(res));
      yield put(setLoadingSuccess(false));
    } else {
      yield put(sendAutoSignInFailure(undefined));
      yield put(sendSignOutRequest());
      yield put(setLoadingSuccess(false));
    }
  } catch (error) {
    yield put(sendSignOutRequest());
    yield put(sendAutoSignInFailure(error));
  }
} */

function* removeTokensSaga() {
  try {
    yield put(removeTokensSuccess());
    yield put(push(`/login`, { expiredSession: true }));
  } catch (error) {
    yield put(removeTokensFailure(error));
  }
}

function* sendSignOutSaga() {
  try {
    const dep: DependenciesType = yield getDependencies();
    const res: boolean = yield call(dep.authInteractor.sendSignOut);
    yield put(sendSignOutSuccess(res));
  } catch (error) {
    yield put(sendSignOutFailure(error));
  }
}

function* getUserDataSaga() {
  try {
    const dep: DependenciesType = yield getDependencies();
    const res: User = yield call(dep.authInteractor.getUserData);
    yield put(getUserDataSuccess(res));
  } catch (error) {
    yield put(getUserDataFailure(error));
  }
}

function* updateUserDataSaga(action: Action<User>) {
  try {
    const dep: DependenciesType = yield getDependencies();
    const res: User = yield call(dep.authInteractor.updateUserData, action.payload);
    yield put(updateUserDataSuccess(res));
  } catch (error) {
    yield put(updateUserDataFailure(error));
  }
}

function* updatePasswordSaga(action: Action<ChangePasswordProps>) {
  try {
    const dep: DependenciesType = yield getDependencies();
    const res: boolean = yield call(dep.authInteractor.updatePassword, action.payload);
    yield put(updatePasswordSuccess(res));
  } catch (error) {
    yield put(updatePasswordFailure(error));
  }
}

function* passwordForgottenSaga(action: Action<string>) {
  try {
    const dep: DependenciesType = yield getDependencies();
    const res: boolean = yield call(dep.authInteractor.sendPasswordForgottenEmail, action.payload);
    yield put(sendPasswordForgottenEmailSuccess(res));
  } catch (error) {
    yield put(sendPasswordForgottenEmailFailure(error));
  }
}

function* resetPasswordSaga(action: Action<ResetPasswordProps>) {
  try {
    const dep: DependenciesType = yield getDependencies();
    const res: AuthTokens = yield call(dep.authInteractor.resetPassword, action.payload);
    yield put(resetPasswordSuccess(res));
  } catch (error) {
    yield put(resetPasswordFailure(error));
  }
}

export function* authSagas() {
  yield all([
    takeLatest(sendSignUpRequest, sendSignUpSaga),
    takeLatest(sendSignInRequest, sendSignInSaga),
    // takeLatest([renewTokensRequest, sendAutoSignInRequest], renewTokensSaga),
    takeLatest(removeTokensRequest, removeTokensSaga),
    takeLatest(sendSignOutRequest, sendSignOutSaga),
    takeLatest([getUserDataRequest, sendSignInSuccess], getUserDataSaga),
    takeLatest(updateUserDataRequest, updateUserDataSaga),
    takeLatest(updatePasswordRequest, updatePasswordSaga),
    takeLatest(sendPasswordForgottenEmailRequest, passwordForgottenSaga),
    takeLatest(resetPasswordRequest, resetPasswordSaga),
  ]);
}
