/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { DispatchAsyncResult } from 'react-redux-dispatch-async/lib/dispatchAsync';
import { rSize } from 'core/util/ResponsiveSizeAdapter';
import { ShowcaseItem } from 'generalContext/domain/entities/ShowcaseItem';
import { AdminPreferances } from 'generalContext/domain/entities/AdminPreferances';
import { UserData } from './components/UserData';
import { OrderHistory } from './components/OrderHistory';
import { ReviewsCenter } from './components/ReviewsCenter';
import { PasswordChange } from './components/PasswordChange';
import { ShowcaseManagement } from './components/ShowcaseManagement';
import { emptyUser, User } from '../../../authContext/domain/entities/User';
import { TermsOfServicePlain } from '../TermsOfService/TermsOfService';
import { Review } from '../../domain/entities/Review';
import { ChangePasswordProps } from '../../../common/domain/entities/ChangePasswordProps';

interface Tab {
  id: TabType;
  name: string;
  iconClass: string;
  badge?: JSX.Element;
  hash?: string;
  to: string;
  action?: () => void;
}
type TabType = 'profile' | 'tos' | 'orders' | 'password' | 'home-showcase-management' | 'comments' | 'disconnect';

export interface ProfileRouteParams {
  tab: TabType;
}
interface ProfileProps {
  stateConnectedUser?: User;
  updateUserDataAsync(userData: User): Promise<DispatchAsyncResult<User>>;
  sendSignOutAsync(): Promise<DispatchAsyncResult<void>>;
  getOrderHistoryAsync(): Promise<DispatchAsyncResult<void>>;
  getAdminReviewsAsync(): Promise<DispatchAsyncResult<void>>;
  approveReviewAsync(rev: Review): Promise<DispatchAsyncResult<Review>>;
  deleteReviewAsync(rev: Review): Promise<DispatchAsyncResult<boolean>>;
  updatePasswordAsync(props: ChangePasswordProps): Promise<DispatchAsyncResult<boolean>>;
  // admin Preferences --------------
  getAdminPreferancesAsync(): Promise<DispatchAsyncResult<AdminPreferances>>;
  updateAdminPreferencesAsync(adminPreferences: AdminPreferances): Promise<DispatchAsyncResult<AdminPreferances>>;
  // showcase -----
  getShowcaseItemsAsync(): Promise<DispatchAsyncResult<ShowcaseItem[]>>;
  getDefaultShowcaseItemsAsync(): Promise<DispatchAsyncResult<ShowcaseItem[]>>;
  getPromoShowcaseItemsAsync(): Promise<DispatchAsyncResult<ShowcaseItem[]>>;
  getPrioritizedShowcaseItemsAsync(): Promise<DispatchAsyncResult<ShowcaseItem[]>>;
  createShowcaseItemAsync(item: ShowcaseItem): Promise<DispatchAsyncResult<ShowcaseItem>>;
  updateShowcaseItemAsync(item: ShowcaseItem): Promise<DispatchAsyncResult<ShowcaseItem>>;
  updatePromoShowcaseAsync(
    itemId: string,
    startDate?: Date,
    endDate?: Date,
  ): Promise<DispatchAsyncResult<ShowcaseItem>>;
  deleteShowcaseItemAsync(id: string): Promise<DispatchAsyncResult<boolean>>;
  addDefaultShowcaseItemAsync(itemId: string): Promise<DispatchAsyncResult<ShowcaseItem>>;
  removeDefaultShowcaseItemAsync(itemId: string): Promise<DispatchAsyncResult<boolean>>;
  addPromoShowcaseItemAsync(
    itemId: string,
    startDate?: Date,
    endDate?: Date,
  ): Promise<DispatchAsyncResult<ShowcaseItem>>;
  removePromoShowcaseItemAsync(itemId: string): Promise<DispatchAsyncResult<boolean>>;
  switchDefaultShowcaseOrderAsync(itemId: string, direction: 'up' | 'down'): Promise<DispatchAsyncResult<boolean>>;
  switchPromoShowcaseOrderAsync(itemId: string, direction: 'up' | 'down'): Promise<DispatchAsyncResult<boolean>>;
}
export const ProfileDumb = ({
  stateConnectedUser,
  updateUserDataAsync,
  sendSignOutAsync,
  getOrderHistoryAsync,
  getAdminReviewsAsync,
  approveReviewAsync,
  deleteReviewAsync,
  updatePasswordAsync,
  // admin prefereneces ...
  getAdminPreferancesAsync,
  updateAdminPreferencesAsync,
  getShowcaseItemsAsync,
  getDefaultShowcaseItemsAsync,
  getPromoShowcaseItemsAsync,
  getPrioritizedShowcaseItemsAsync,
  createShowcaseItemAsync,
  updateShowcaseItemAsync,
  updatePromoShowcaseAsync,
  deleteShowcaseItemAsync,
  addDefaultShowcaseItemAsync,
  removeDefaultShowcaseItemAsync,
  addPromoShowcaseItemAsync,
  removePromoShowcaseItemAsync,
  switchDefaultShowcaseOrderAsync,
  switchPromoShowcaseOrderAsync,
}: ProfileProps) => {
  const tabs: Tab[] = [
    {
      id: 'profile',
      name: 'Profil',
      iconClass: 'fa fa-user',
      to: 'profile',
      hash: '#profile',
    },
    {
      id: 'orders',
      name: 'Historique des commandes',
      iconClass: 'fa fa-bell',
      to: '',
      hash: '#historique',
    },
    {
      id: 'password',
      name: 'Changement de mot de passe',
      iconClass: 'fa fa-key',
      to: '',
    },
    {
      id: 'home-showcase-management',
      name: 'Gestionnaire de slides',
      iconClass: 'fa fa-image',
      to: '',
    },
    {
      id: 'comments',
      name: 'Avis en attente',
      iconClass: 'fa fa-comment',
      to: '',
      badge: <div>xxxx</div>,
    },
    {
      id: 'tos',
      name: "Conditions d'utilisation",
      iconClass: 'fa fa-lock',
      to: '',
    },
    {
      id: 'disconnect',
      name: 'Se déconnecter',
      iconClass: 'fas fa-sign-out-alt',
      to: '',
      action: () => {
        signOut();
      },
    },
  ];

  const history = useHistory();
  const location = useLocation();
  const [user, setUser] = useState<User>(emptyUser);
  const [currentTab, setCurrentTab] = useState<string>(tabs[0].id);

  const rStyles = {
    global: {
      h4: {
        fontSize: rSize(24),
      },
      p: {
        fontSize: rSize(18),
      },
    },
    signUpText: {
      marginTop: rSize(10),
    },
  };

  useEffect(() => {
    setCurrentTab(tabs.find((tab) => tab.hash === location.hash)?.id ?? tabs[0].id);
  }, []);

  const signOut = async () => {
    try {
      const response: any = await sendSignOutAsync();
      if (response.success) {
        history.replace('/');
      }
    } catch (error) {
      // console.log('signOutAsync caught error', error);
    }
  };

  const getActiveTab = () => {
    switch (
      currentTab // 'profile' | 'tos' | 'orders' | 'password' | 'comments' | 'disconnect'
    ) {
      case 'profile':
        return (
          <UserData
            user={user}
            setUser={setUser}
            stateConnectedUser={stateConnectedUser}
            updateUserDataAsync={updateUserDataAsync}
          />
        );
      case 'password':
        return <PasswordChange stateConnectedUser={stateConnectedUser} updatePasswordAsync={updatePasswordAsync} />;
      case 'orders':
        return <OrderHistory getOrderHistoryAsync={getOrderHistoryAsync} />;
      case 'tos':
        return <TermsOfServicePlain />;
      case 'home-showcase-management':
        return (
          <ShowcaseManagement
            stateConnectedUser={stateConnectedUser}
            getAdminPreferancesAsync={getAdminPreferancesAsync}
            updateAdminPreferencesAsync={updateAdminPreferencesAsync}
            getShowcaseItemsAsync={getShowcaseItemsAsync}
            getDefaultShowcaseItemsAsync={getDefaultShowcaseItemsAsync}
            getPromoShowcaseItemsAsync={getPromoShowcaseItemsAsync}
            getPrioritizedShowcaseItemsAsync={getPrioritizedShowcaseItemsAsync}
            createShowcaseItemAsync={createShowcaseItemAsync}
            updateShowcaseItemAsync={updateShowcaseItemAsync}
            updatePromoShowcaseAsync={updatePromoShowcaseAsync}
            deleteShowcaseItemAsync={deleteShowcaseItemAsync}
            addDefaultShowcaseItemAsync={addDefaultShowcaseItemAsync}
            removeDefaultShowcaseItemAsync={removeDefaultShowcaseItemAsync}
            addPromoShowcaseItemAsync={addPromoShowcaseItemAsync}
            removePromoShowcaseItemAsync={removePromoShowcaseItemAsync}
            switchDefaultShowcaseOrderAsync={switchDefaultShowcaseOrderAsync}
            switchPromoShowcaseOrderAsync={switchPromoShowcaseOrderAsync}
          />
        );
      case 'comments':
        return (
          <ReviewsCenter
            stateConnectedUser={stateConnectedUser}
            getAdminReviewsAsync={getAdminReviewsAsync}
            approveReviewAsync={approveReviewAsync}
            deleteReviewAsync={deleteReviewAsync}
          />
        );
      default:
        return null;
    }
  };

  if (!stateConnectedUser) {
    return null;
  }
  return (
    <div className="page-content bg-white">
      <div className="content-block">
        <section className="content-inner bg-white">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-4 m-b30">
                <div className="sticky-top">
                  <div className="shop-account">
                    <div className="account-detail text-center">
                      <div className="account-title">
                        <div className="">
                          <h4 className="m-b5" style={{ ...rStyles.global.h4 }}>{`${user?.firstName ?? ''} ${
                            user?.lastName ?? ''
                          }`}</h4>
                          <p className="m-b5" style={{ ...rStyles.global.p }}>
                            {user?.email}
                          </p>
                        </div>
                      </div>
                    </div>
                    <ul className="account-list">
                      {tabs.map((tab) => {
                        if (
                          (tab.id === 'comments' || tab.id === 'home-showcase-management') &&
                          !stateConnectedUser.admin
                        ) {
                          return null;
                        }
                        return (
                          <li key={tab.id}>
                            <Link
                              to={'#'}
                              className={currentTab === tab.id ? 'active' : ''}
                              onClick={() => {
                                setCurrentTab(tab.id);
                                tab.action && tab.action();
                              }}
                            >
                              <i className={tab.iconClass} style={{ ...rStyles.global.p }} />
                              <span style={{ ...rStyles.global.p }}>{tab.name}</span>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-9 col-lg-8 m-b30">{getActiveTab()}</div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
