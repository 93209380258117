import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import styled from 'styled-components';
import { rSize } from 'core/util/ResponsiveSizeAdapter';
import { Product } from '../../domain/entities/Product';
import { SectionTitle } from '../../../common/ui/elements/SectionTitle';
import { ProductCard } from '../../../common/ui';

interface AppProductsSliderProps {
  title?: string;
  subTitle?: string;
  products: Product[];
}
export const AppProductsSlider = ({ title, subTitle, products }: AppProductsSliderProps) => {
  const productsToShowInSlider = 10;

  const rStyles = {
    global: {
      h3: {
        fontSize: rSize(26),
        lineHeight: `normal`,
      },
    },
    productCardContainer: {
      padding: `${rSize(0)}px ${rSize(0)}px`,
      border: `${rSize(1)}px  solid var(--mono-grey-2)`,
    },
  };

  if (!products.length) {
    return null;
  }

  const centerSlidePercentage = () => {
    if (window.innerWidth <= 456) {
      return 90;
    }
    if (window.innerWidth <= 650) {
      return 50;
    }
    if (window.innerWidth <= 1001) {
      return 36;
    }
    if (window.innerWidth <= 1342) {
      return 28;
    }
    if (window.innerWidth <= 1418) {
      return 23;
    }
    return 28;
  };

  return (
    <Wrapper>
      {title && <SectionTitle title={title} subTitle={subTitle} />}
      <Carousel
        autoPlay
        interval={3000}
        transitionTime={600}
        showArrows
        infiniteLoop
        showStatus={false}
        showThumbs={false}
        emulateTouch
        showIndicators={false}
        swipeable
        centerMode
        centerSlidePercentage={centerSlidePercentage()}
      >
        {products.slice(0, productsToShowInSlider).map((product, i) => (
          <div
            className="product-card-container carousel-slide"
            style={{ ...rStyles.productCardContainer }}
            key={`carousel-slide-${i}`}
          >
            <ProductCard product={product} />
          </div>
        ))}
      </Carousel>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .carousel .control-arrow,
  .carousel.carousel-slider .control-arrow {
    -webkit-transition: all 0.25s ease-in;
    -moz-transition: all 0.25s ease-in;
    -ms-transition: all 0.25s ease-in;
    -o-transition: all 0.25s ease-in;
    transition: all 0.25s ease-in;
    opacity: 1;
    filter: alpha(opacity=100);
    z-index: 2;
    background: rgba(0, 0, 0, 0.4);
    width: 50px;
    height: 50px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .carousel .control-prev.control-arrow {
    left: 0;
  }

  .carousel .slide {
    filter: none !important; /* Remove any brightness filter applied */
  }

  .carousel .control-next.control-arrow {
    right: 0;
  }

  .carousel .control-prev.control-arrow:before {
    border-right: 8px solid #fff; /* White arrow */
  }

  .carousel .control-next.control-arrow:before {
    border-left: 8px solid #fff; /* White arrow */
  }
  .product-card-container {
    display: flex;
    width: 90%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 0px;
    border: 1px solid var(--mono-grey-2);
  }
`;
