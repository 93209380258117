import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { Action } from 'redux-actions';
import { ProductState } from 'generalContext/domain/entities/ProductState';
import { GetProductsProps, Product } from 'generalContext/domain/entities/Product';
import { MagasinState } from 'generalContext/domain/entities/Magasin';
import { MailObj } from 'generalContext/domain/entities/MailObj';
import { AdminPreferances } from 'generalContext/domain/entities/AdminPreferances';
import { InitializePaymentProps } from 'generalContext/domain/entities/Payment';
import { ShowcaseItem } from 'generalContext/domain/entities/ShowcaseItem';
import {
  addDefaultShowcaseItemFailure,
  addDefaultShowcaseItemRequest,
  addDefaultShowcaseItemSuccess,
  addPromoShowcaseItemFailure,
  addPromoShowcaseItemRequest,
  addPromoShowcaseItemSuccess,
  approveReviewFailure,
  approveReviewRequest,
  approveReviewSuccess,
  assertPaymentFailure,
  assertPaymentRequest,
  assertPaymentSuccess,
  cookiesAcceptFailure,
  cookiesAcceptRequest,
  cookiesAcceptSuccess,
  createShowcaseItemFailure,
  createShowcaseItemRequest,
  createShowcaseItemSuccess,
  deleteCartProductFailure,
  deleteCartProductRequest,
  deleteCartProductSuccess,
  deleteProductReviewFailure,
  deleteProductReviewRequest,
  deleteProductReviewSuccess,
  deleteShowcaseItemFailure,
  deleteShowcaseItemRequest,
  deleteShowcaseItemSuccess,
  deleteWishProductFailure,
  deleteWishProductRequest,
  deleteWishProductSuccess,
  getAdminPreferancesFailure,
  getAdminPreferancesRequest,
  getAdminPreferancesSuccess,
  getAdminReviewsFailure,
  getAdminReviewsRequest,
  getAdminReviewsSuccess,
  getCartProductsFailure,
  getCartProductsRequest,
  getCartProductsSuccess,
  getCategoriesFailure,
  getCategoriesRequest,
  getCategoriesSuccess,
  getDefaultShowcaseItemsFailure,
  getDefaultShowcaseItemsRequest,
  getDefaultShowcaseItemsSuccess,
  getDeliveryProductsFailure,
  getDeliveryProductsRequest,
  getDeliveryProductsSuccess,
  getMagasinListFailure,
  getMagasinListRequest,
  getMagasinListSuccess,
  getOfflineCartProductsSuccess,
  getOrderHistoryFailure,
  getOrderHistoryRequest,
  getOrderHistorySuccess,
  getPrioritizedShowcaseItemsFailure,
  getPrioritizedShowcaseItemsRequest,
  getPrioritizedShowcaseItemsSuccess,
  getProductReviewsFailure,
  getProductReviewsRequest,
  getProductReviewsSuccess,
  getProductsBySubCategoryNumberFailure,
  getProductsBySubCategoryNumberRequest,
  getProductsBySubCategoryNumberSuccess,
  getProductsFailure,
  getProductsRequest,
  getProductsSuccess,
  getPromoShowcaseItemsFailure,
  getPromoShowcaseItemsRequest,
  getPromoShowcaseItemsSuccess,
  getShowcaseItemsFailure,
  getShowcaseItemsRequest,
  getShowcaseItemsSuccess,
  getSubCategoriesFailure,
  getSubCategoriesRequest,
  getSubCategoriesSuccess,
  getWishProductsFailure,
  getWishProductsRequest,
  getWishProductsSuccess,
  initializePaymentFailure,
  initializePaymentRequest,
  initializePaymentSuccess,
  insertOrderFailure,
  insertOrderRequest,
  insertOrderSuccess,
  insertProductReviewFailure,
  insertProductReviewRequest,
  insertProductReviewSuccess,
  removeDefaultShowcaseItemFailure,
  removeDefaultShowcaseItemRequest,
  removeDefaultShowcaseItemSuccess,
  removePromoShowcaseItemFailure,
  removePromoShowcaseItemRequest,
  removePromoShowcaseItemSuccess,
  saveCartProductFailure,
  saveCartProductRequest,
  saveCartProductSuccess,
  saveWishProductFailure,
  saveWishProductRequest,
  saveWishProductSuccess,
  sendEmailFailure,
  sendEmailRequest,
  sendEmailSuccess,
  sendNewsLettersSignUpFailure,
  sendNewsLettersSignUpRequest,
  sendNewsLettersSignUpSuccess,
  switchDefaultShowcaseOrderFailure,
  switchDefaultShowcaseOrderRequest,
  switchDefaultShowcaseOrderSuccess,
  switchPromoShowcaseOrderFailure,
  switchPromoShowcaseOrderRequest,
  switchPromoShowcaseOrderSuccess,
  updateAdminPreferencesFailure,
  updateAdminPreferencesRequest,
  updateAdminPreferencesSuccess,
  updatePromoShowcaseFailure,
  updatePromoShowcaseRequest,
  updatePromoShowcaseSuccess,
  updateShowcaseItemFailure,
  updateShowcaseItemRequest,
  updateShowcaseItemSuccess,
} from './General.actions';
import { getStateMagasins, getStateOfflineCart } from './General.selectors';
import { getDependencies } from '../../common/usecases/common.sagas';
import { DependenciesType } from '../../configuration/dependencies';
import { SubCategory } from '../domain/entities/SubCategory';
import { Category } from '../domain/entities/Category';
import { Review } from '../domain/entities/Review';
import { Order } from '../domain/entities/Order';
import { Cart } from '../domain/entities/Cart';
import { getConnectedUser } from '../../authContext/usecases/Auth.selectors';
import { User } from '../../authContext/domain/entities/User';
import { sendSignInSuccess, sendSignUpSuccess } from '../../authContext/usecases/Auth.actions';

function* sendEmailSaga(action: Action<MailObj>) {
  try {
    const stateMagasins: MagasinState | undefined = yield select(getStateMagasins);
    const data: MailObj = {
      ...action.payload,
      subject: `${action.payload.subject} - ${stateMagasins?.current?.magasinName}`,
    };
    const dep: DependenciesType = yield getDependencies();
    const res: MailObj = yield call(dep.generalInteractor.sendEmail, data);
    yield put(sendEmailSuccess(res));
  } catch (error) {
    yield put(sendEmailFailure(error));
  }
}
function* sendNewsLettersSignUpSaga(action: Action<string>) {
  try {
    const dep: DependenciesType = yield getDependencies();
    const res: boolean = yield call(dep.generalInteractor.sendNewsLettersSignUp, action.payload);
    yield put(sendNewsLettersSignUpSuccess(res));
  } catch (error) {
    yield put(sendNewsLettersSignUpFailure(error));
  }
}

function* getDeliveryProductsSaga() {
  try {
    const dep: DependenciesType = yield getDependencies();
    const res: Product[] = yield call(dep.generalInteractor.getDeliveryProducts);
    yield put(getDeliveryProductsSuccess(res));
  } catch (error) {
    yield put(getDeliveryProductsFailure(error));
  }
}

function* getProductsSaga(action: Action<GetProductsProps | undefined>) {
  try {
    const dep: DependenciesType = yield getDependencies();
    const res: ProductState = yield call(dep.generalInteractor.getProducts, action.payload);
    yield put(getProductsSuccess(res));
  } catch (error) {
    yield put(getProductsFailure(error));
  }
}

function* getProductsBySubCategoryNumberSaga(action: Action<number>) {
  try {
    const id: number = action.payload;
    const dep: DependenciesType = yield getDependencies();
    const res: ProductState = yield call(dep.generalInteractor.getProductsBySubCategoryNumber, id);
    yield put(getProductsBySubCategoryNumberSuccess(res));
  } catch (error) {
    yield put(getProductsBySubCategoryNumberFailure(error));
  }
}

function* getCartProductsSaga() {
  const stateConnectedUser: User | undefined = yield select(getConnectedUser);
  if (stateConnectedUser) {
    try {
      const dep: DependenciesType = yield getDependencies();
      const res: Product[] = yield call(dep.generalInteractor.getCartProducts);
      yield put(getCartProductsSuccess(res));
    } catch (error) {
      yield put(getCartProductsFailure(error));
    }
  } else {
    const stateOfflineCart: Cart | undefined = yield select(getStateOfflineCart);
    const newCartProducts: Product[] = stateOfflineCart?.items ?? [];
    yield put(getOfflineCartProductsSuccess([...newCartProducts]));
    yield put(getCartProductsSuccess([...newCartProducts]));
  }
}

function* saveOfflineCartToCartSaga() {
  const stateOfflineCart: Cart | undefined = yield select(getStateOfflineCart);
  const products = stateOfflineCart?.items ?? [];
  if (products) {
    try {
      const dep: DependenciesType = yield getDependencies();
      const results: Product[] = yield all(products.map((item) => call(dep.generalInteractor.saveCartProduct, item)));
      yield put(saveCartProductSuccess(results[results.length - 1]));
      yield put(getOfflineCartProductsSuccess([]));
    } catch (error) {
      yield put(saveCartProductFailure(error));
    }
  }
}

function* saveCartProductSaga(action: Action<Product>) {
  const stateConnectedUser: User | undefined = yield select(getConnectedUser);
  if (stateConnectedUser) {
    try {
      const dep: DependenciesType = yield getDependencies();
      const res: Product = yield call(dep.generalInteractor.saveCartProduct, action.payload);
      yield put(saveCartProductSuccess(res));
    } catch (error) {
      yield put(saveCartProductFailure(error));
    }
  } else {
    const stateOfflineCart: Cart | undefined = yield select(getStateOfflineCart);
    let newCartProducts: Product[] = stateOfflineCart?.items ?? [];
    newCartProducts = [...newCartProducts, action.payload];
    yield put(getOfflineCartProductsSuccess([...newCartProducts]));
    yield put(getCartProductsSuccess([...newCartProducts]));
  }
}

function* deleteCartProductSaga(action: Action<string>) {
  const stateConnectedUser: User | undefined = yield select(getConnectedUser);
  if (stateConnectedUser) {
    try {
      const dep: DependenciesType = yield getDependencies();
      const res: boolean = yield call(dep.generalInteractor.deleteCartProduct, action.payload);
      yield put(deleteCartProductSuccess(res));
    } catch (error) {
      yield put(deleteCartProductFailure(error));
    }
  } else {
    const stateOfflineCart: Cart | undefined = yield select(getStateOfflineCart);
    let newCartProducts: Product[] = stateOfflineCart?.items ?? [];
    newCartProducts = newCartProducts.filter((prod) => prod.Art_Ean13 !== action.payload);
    yield put(getOfflineCartProductsSuccess([...newCartProducts]));
    yield put(getCartProductsSuccess([...newCartProducts]));
  }
}

function* getWishProductsSaga() {
  try {
    const dep: DependenciesType = yield getDependencies();
    const res: Product[] = yield call(dep.generalInteractor.getWishProducts);
    yield put(getWishProductsSuccess(res));
  } catch (error) {
    yield put(getWishProductsFailure(error));
  }
}
function* saveWishProductSaga(action: Action<Product>) {
  try {
    const dep: DependenciesType = yield getDependencies();
    const res: Product = yield call(dep.generalInteractor.saveWishProduct, action.payload);
    yield put(saveWishProductSuccess(res));
  } catch (error) {
    yield put(saveWishProductFailure(error));
  }
}
function* deleteWishProductSaga(action: Action<string>) {
  try {
    const dep: DependenciesType = yield getDependencies();
    const res: boolean = yield call(dep.generalInteractor.deleteWishProduct, action.payload);
    yield put(deleteWishProductSuccess(res));
  } catch (error) {
    yield put(deleteWishProductFailure(error));
  }
}

function* getCategoriesSaga() {
  try {
    const dep: DependenciesType = yield getDependencies();
    const res: Category[] = yield call(dep.generalInteractor.getCategories);
    yield put(getCategoriesSuccess(res));
  } catch (error) {
    yield put(getCategoriesFailure(error));
  }
}

function* getSubCategoriesSaga() {
  try {
    const dep: DependenciesType = yield getDependencies();
    const res: SubCategory[] = yield call(dep.generalInteractor.getSubCategories);
    yield put(getSubCategoriesSuccess(res));
  } catch (error) {
    yield put(getSubCategoriesFailure(error));
  }
}

function* getProductReviewsSaga(action: Action<string>) {
  try {
    const dep: DependenciesType = yield getDependencies();
    const res: Review[] = yield call(dep.generalInteractor.getProductReviews, action.payload);
    yield put(getProductReviewsSuccess(res));
  } catch (error) {
    yield put(getProductReviewsFailure(error));
  }
}
function* getAdminReviewsSaga() {
  try {
    const dep: DependenciesType = yield getDependencies();
    const res: Review[] = yield call(dep.generalInteractor.getAdminReviews);
    yield put(getAdminReviewsSuccess(res));
  } catch (error) {
    yield put(getAdminReviewsFailure(error));
  }
}

function* approveReviewSaga(action: Action<Review>) {
  try {
    const dep: DependenciesType = yield getDependencies();
    const res: Review = yield call(dep.generalInteractor.approveReview, action.payload);
    yield put(approveReviewSuccess(res));
  } catch (error) {
    yield put(approveReviewFailure(error));
  }
}

function* insertProductReviewSaga(action: Action<Review>) {
  try {
    const dep: DependenciesType = yield getDependencies();
    const res: Review = yield call(dep.generalInteractor.insertProductReview, action.payload);
    yield put(insertProductReviewSuccess(res));
  } catch (error) {
    yield put(insertProductReviewFailure(error));
  }
}

function* deleteProductReviewSaga(action: Action<Review>) {
  try {
    const dep: DependenciesType = yield getDependencies();
    const res: Review = yield call(dep.generalInteractor.deleteProductReview, action.payload);
    yield put(deleteProductReviewSuccess(res));
  } catch (error) {
    yield put(deleteProductReviewFailure(error));
  }
}

function* getOrderHistorySaga() {
  try {
    const dep: DependenciesType = yield getDependencies();
    const res: Order[] = yield call(dep.generalInteractor.getOrderHistory);
    yield put(getOrderHistorySuccess(res));
  } catch (error) {
    yield put(getOrderHistoryFailure(error));
  }
}

function* insertOrderSaga(action: Action<Order>) {
  try {
    const dep: DependenciesType = yield getDependencies();
    const res: Order = yield call(dep.generalInteractor.insertOrder, action.payload);
    yield put(insertOrderSuccess(res));
  } catch (error) {
    yield put(insertOrderFailure(error));
  }
}

function* getMagasinListSaga() {
  try {
    const dep: DependenciesType = yield getDependencies();
    const res: MagasinState = yield call(dep.generalInteractor.getMagasinList);
    yield put(getMagasinListSuccess(res));
  } catch (error) {
    yield put(getMagasinListFailure(error));
  }
}

// Admin preferences ----------------------------------------------------------------------

function* getAdminPreferancesSaga() {
  try {
    const dep: DependenciesType = yield getDependencies();
    const res: AdminPreferances = yield call(dep.generalInteractor.getAdminPreferances);
    yield put(getAdminPreferancesSuccess(res));
  } catch (error) {
    yield put(getAdminPreferancesFailure(error));
  }
}

function* updateAdminPreferancesSaga(action: Action<AdminPreferances>) {
  try {
    const dep: DependenciesType = yield getDependencies();
    const res: AdminPreferances = yield call(dep.generalInteractor.updateAdminPreferances, action.payload);
    yield put(updateAdminPreferencesSuccess(res));
  } catch (error) {
    yield put(updateAdminPreferencesFailure(error));
  }
}

// cookies ---------------------------------------

function* cookiesAcceptSaga() {
  try {
    yield put(cookiesAcceptSuccess(true));
    // const dep: DependenciesType = yield getDependencies();
    // const res: void = yield call(dep.generalInteractor.getAdminPreferances);
  } catch (error) {
    yield put(cookiesAcceptFailure(error));
  }
}

// payment sagas ---------------------------------------------
function* initializePaymentSaga(action: Action<InitializePaymentProps>): Generator<any, void, any> {
  try {
    const dep: DependenciesType = yield getDependencies();
    const res: any = yield call(dep.generalInteractor.initializePayment, action.payload);
    yield put(initializePaymentSuccess(res));
  } catch (error) {
    yield put(initializePaymentFailure(error));
  }
}

function* assertPaymentSaga(action: Action<string>): Generator<any, void, any> {
  try {
    const dep: DependenciesType = yield getDependencies();
    const res: any = yield call(dep.generalInteractor.assertPayment, action.payload);
    yield put(assertPaymentSuccess(res));
  } catch (error) {
    yield put(assertPaymentFailure(error));
  }
}

// Showcase sagas ---------------------------------------------
function* getShowcaseItemsSaga() {
  try {
    const dep: DependenciesType = yield getDependencies();
    const res: ShowcaseItem[] = yield call(dep.generalInteractor.getShowcaseItems);
    yield put(getShowcaseItemsSuccess(res));
  } catch (error) {
    yield put(getShowcaseItemsFailure(error));
  }
}

function* getDefaultShowcaseItemsSaga() {
  try {
    const dep: DependenciesType = yield getDependencies();
    const res: ShowcaseItem[] = yield call(dep.generalInteractor.getDefaultShowcaseItems);
    yield put(getDefaultShowcaseItemsSuccess(res));
  } catch (error) {
    yield put(getDefaultShowcaseItemsFailure(error));
  }
}

function* getPromoShowcaseItemsSaga() {
  try {
    const dep: DependenciesType = yield getDependencies();
    const res: ShowcaseItem[] = yield call(dep.generalInteractor.getPromoShowcaseItems);
    yield put(getPromoShowcaseItemsSuccess(res));
  } catch (error) {
    yield put(getPromoShowcaseItemsFailure(error));
  }
}

function* getPrioritizedShowcaseItemsSaga() {
  try {
    const dep: DependenciesType = yield getDependencies();
    const res: ShowcaseItem[] = yield call(dep.generalInteractor.getPrioritizedShowcaseItems);
    yield put(getPrioritizedShowcaseItemsSuccess(res));
  } catch (error) {
    yield put(getPrioritizedShowcaseItemsFailure(error));
  }
}

function* createShowcaseItemSaga(action: Action<ShowcaseItem>) {
  try {
    const dep: DependenciesType = yield getDependencies();
    const res: ShowcaseItem = yield call(dep.generalInteractor.createShowcaseItem, action.payload);
    yield put(createShowcaseItemSuccess(res));
  } catch (error) {
    yield put(createShowcaseItemFailure(error));
  }
}

function* updateShowcaseItemSaga(action: Action<ShowcaseItem>) {
  try {
    const dep: DependenciesType = yield getDependencies();
    const res: ShowcaseItem = yield call(dep.generalInteractor.updateShowcaseItem, action.payload);
    yield put(updateShowcaseItemSuccess(res));
  } catch (error) {
    yield put(updateShowcaseItemFailure(error));
  }
}

function* updatePromoShowcaseSaga(action: Action<{ itemId: string; startDate?: Date; endDate?: Date }>) {
  try {
    const dep: DependenciesType = yield getDependencies();
    const res: ShowcaseItem = yield call(
      dep.generalInteractor.updatePromoShowcase,
      action.payload.itemId,
      action.payload.startDate,
      action.payload.endDate,
    );
    yield put(updatePromoShowcaseSuccess(res));
  } catch (error) {
    yield put(updatePromoShowcaseFailure(error));
  }
}

function* deleteShowcaseItemSaga(action: Action<string>) {
  try {
    const dep: DependenciesType = yield getDependencies();
    const res: boolean = yield call(dep.generalInteractor.deleteShowcaseItem, action.payload);
    yield put(deleteShowcaseItemSuccess(res));
  } catch (error) {
    yield put(deleteShowcaseItemFailure(error));
  }
}

function* addDefaultShowcaseItemSaga(action: Action<string>) {
  try {
    const dep: DependenciesType = yield getDependencies();
    const res: ShowcaseItem = yield call(dep.generalInteractor.addDefaultShowcaseItem, action.payload);
    yield put(addDefaultShowcaseItemSuccess(res));
  } catch (error) {
    yield put(addDefaultShowcaseItemFailure(error));
  }
}

function* removeDefaultShowcaseItemSaga(action: Action<string>) {
  try {
    const dep: DependenciesType = yield getDependencies();
    const res: boolean = yield call(dep.generalInteractor.removeDefaultShowcaseItem, action.payload);
    yield put(removeDefaultShowcaseItemSuccess(res));
  } catch (error) {
    yield put(removeDefaultShowcaseItemFailure(error));
  }
}

function* addPromoShowcaseItemSaga(action: Action<{ itemId: string; startDate?: Date; endDate?: Date }>) {
  try {
    const dep: DependenciesType = yield getDependencies();
    const res: ShowcaseItem = yield call(
      dep.generalInteractor.addPromoShowcaseItem,
      action.payload.itemId,
      action.payload.startDate,
      action.payload.endDate,
    );
    yield put(addPromoShowcaseItemSuccess(res));
  } catch (error) {
    yield put(addPromoShowcaseItemFailure(error));
  }
}

function* removePromoShowcaseItemSaga(action: Action<string>) {
  try {
    const dep: DependenciesType = yield getDependencies();
    const res: boolean = yield call(dep.generalInteractor.removePromoShowcaseItem, action.payload);
    yield put(removePromoShowcaseItemSuccess(res));
  } catch (error) {
    yield put(removePromoShowcaseItemFailure(error));
  }
}

function* switchDefaultShowcaseOrderSaga(action: Action<{ itemId: string; direction: 'up' | 'down' }>) {
  try {
    const dep: DependenciesType = yield getDependencies();
    const res: boolean = yield call(
      dep.generalInteractor.switchDefaultShowcaseOrder,
      action.payload.itemId,
      action.payload.direction,
    );
    yield put(switchDefaultShowcaseOrderSuccess(res));
  } catch (error) {
    yield put(switchDefaultShowcaseOrderFailure(error));
  }
}

function* switchPromoShowcaseOrderSaga(action: Action<{ itemId: string; direction: 'up' | 'down' }>) {
  try {
    const dep: DependenciesType = yield getDependencies();
    const res: boolean = yield call(
      dep.generalInteractor.switchPromoShowcaseOrder,
      action.payload.itemId,
      action.payload.direction,
    );
    yield put(switchPromoShowcaseOrderSuccess(res));
  } catch (error) {
    yield put(switchPromoShowcaseOrderFailure(error));
  }
}
export function* generalSagas() {
  yield all([
    // products
    takeLatest(getDeliveryProductsRequest, getDeliveryProductsSaga),
    takeLatest(getProductsRequest, getProductsSaga),
    takeLatest(getCategoriesRequest, getCategoriesSaga),
    takeLatest(getSubCategoriesRequest, getSubCategoriesSaga),
    takeLatest(getProductsBySubCategoryNumberRequest, getProductsBySubCategoryNumberSaga),
    // cart
    takeLatest([getCartProductsRequest, saveCartProductSuccess, deleteCartProductSuccess], getCartProductsSaga),
    takeLatest(saveCartProductRequest, saveCartProductSaga),
    takeLatest(deleteCartProductRequest, deleteCartProductSaga),
    takeLatest([sendSignInSuccess, sendSignUpSuccess], saveOfflineCartToCartSaga),
    // wish
    takeLatest(getWishProductsRequest, getWishProductsSaga),
    takeLatest([getWishProductsRequest, saveWishProductSuccess, deleteWishProductSuccess], getWishProductsSaga),
    takeLatest(saveWishProductRequest, saveWishProductSaga),
    takeLatest(deleteWishProductRequest, deleteWishProductSaga),
    // reviews
    takeLatest(
      [getProductReviewsRequest, deleteProductReviewSuccess, insertProductReviewSuccess],
      getProductReviewsSaga,
    ),
    takeLatest(getAdminReviewsRequest, getAdminReviewsSaga),
    takeLatest(approveReviewRequest, approveReviewSaga),
    takeLatest(insertProductReviewRequest, insertProductReviewSaga),
    takeLatest(deleteProductReviewRequest, deleteProductReviewSaga),
    // orders
    takeLatest(getOrderHistoryRequest, getOrderHistorySaga),
    takeLatest(insertOrderRequest, insertOrderSaga),
    // magasin
    takeLatest(getMagasinListRequest, getMagasinListSaga),
    // Emails
    takeLatest(sendEmailRequest, sendEmailSaga),
    takeLatest(sendNewsLettersSignUpRequest, sendNewsLettersSignUpSaga),
    // Settings
    takeLatest(getAdminPreferancesRequest, getAdminPreferancesSaga),
    takeLatest(updateAdminPreferencesRequest, updateAdminPreferancesSaga),
    // Cookies
    takeLatest(cookiesAcceptRequest, cookiesAcceptSaga),
    // Payment
    takeLatest(initializePaymentRequest, initializePaymentSaga),
    takeLatest(assertPaymentRequest, assertPaymentSaga),
    // showcase
    takeLatest(getShowcaseItemsRequest, getShowcaseItemsSaga),
    takeLatest(getDefaultShowcaseItemsRequest, getDefaultShowcaseItemsSaga),
    takeLatest(getPromoShowcaseItemsRequest, getPromoShowcaseItemsSaga),
    takeLatest(getPrioritizedShowcaseItemsRequest, getPrioritizedShowcaseItemsSaga),
    takeLatest(createShowcaseItemRequest, createShowcaseItemSaga),
    takeLatest(updateShowcaseItemRequest, updateShowcaseItemSaga),
    takeLatest(updatePromoShowcaseRequest, updatePromoShowcaseSaga),
    takeLatest(deleteShowcaseItemRequest, deleteShowcaseItemSaga),
    takeLatest(addDefaultShowcaseItemRequest, addDefaultShowcaseItemSaga),
    takeLatest(removeDefaultShowcaseItemRequest, removeDefaultShowcaseItemSaga),
    takeLatest(addPromoShowcaseItemRequest, addPromoShowcaseItemSaga),
    takeLatest(removePromoShowcaseItemRequest, removePromoShowcaseItemSaga),
    takeLatest(switchDefaultShowcaseOrderRequest, switchDefaultShowcaseOrderSaga),
    takeLatest(switchPromoShowcaseOrderRequest, switchPromoShowcaseOrderSaga),
  ]);
}
