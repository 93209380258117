import { Color } from '../styles/constantStyles';

export interface WishSVGProps {
  color?: string;
  size?: number;
  strokeWidth?: number;
}

export const WishSVG = ({ color = Color.SECONDARY, size = 95, strokeWidth = 5 }: WishSVGProps) => (
  <svg
    viewBox="0 0 95 95"
    xmlns="http://www.w3.org/2000/svg"
    style={{ width: size, height: size, minWidth: size, minHeight: size }}
  >
    <path
      d="M3.0445 27.5C3 26.9313 3 26.3188 3 25.75C3 20.4908 4.8534 15.394 8.24486 11.3268C11.6363 7.25959 16.3563 4.47325 21.6019 3.44182C26.8474 2.4104 32.2944 3.19761 37.0161 5.66952C41.7378 8.14143 45.4425 12.1453 47.5 17V17C49.5575 12.1453 53.2622 8.14143 57.9839 5.66952C62.7056 3.19761 68.1526 2.4104 73.3981 3.44182C78.6437 4.47325 83.3637 7.25959 86.7551 11.3268C90.1466 15.394 92 20.4908 92 25.75C92 55.5 47.5 80 47.5 80C47.5 80 29.7445 70.2 16.6615 55.5"
      stroke={color}
      fill="none"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
