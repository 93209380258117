import { ENV } from '../../configuration';
import { InMemoryGeneralInteractor } from '../adapter/inmemory/InMemoryGeneralInteractor';
import { RealGeneralInteractor } from '../adapter/real/RealGeneralInteractor';
import { GeneralInteractor } from '../domain/gateways/General.interactor';

export class GeneralContextFactory {
  static generalInteractor(): GeneralInteractor {
    switch (ENV) {
      case 'prod':
      case 'dev':
        return new RealGeneralInteractor();
      default:
        return new InMemoryGeneralInteractor();
    }
  }
}
