import { Action } from 'redux';
import { connect } from 'react-redux';
import { dispatchAsync } from 'react-redux-dispatch-async';
import { ResetPasswordScreenDumb } from './ResetPasswordScreen.dumb';
import { resetPasswordRequest } from '../../usecases/Auth.actions';
import { ResetPasswordProps } from '../../../common/domain/entities/ResetPasswordProps';

const mapState = () => ({});

const mapDispatch = (dispatch: (a: Action) => void) => ({
  resetPasswordAsync: (props: ResetPasswordProps) => dispatchAsync(dispatch, resetPasswordRequest(props)),
});

export const ResetPasswordScreenSmart = connect(mapState, mapDispatch)(ResetPasswordScreenDumb);
