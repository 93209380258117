import { BsCartX, BsFillCartPlusFill } from 'react-icons/bs';
import { styled, Tooltip } from '@mui/material';
import { rSize } from 'core/util/ResponsiveSizeAdapter';

interface CartButtonProps {
  widthLabel?: boolean;
  inCart: boolean;
  action: () => void;
}
export const CartButton = ({ widthLabel, inCart, action }: CartButtonProps) => {
  const rStyles = {
    toolTip: {
      fontSize: rSize(16),
    },
    span: {
      fontSize: rSize(16),
    },
    fayButton: {
      borderRadius: rSize(4),
      padding: rSize(12),
      fontSize: rSize(26),
    },
  };

  return (
    <Wrapper onClick={action}>
      <Tooltip
        title="Ajouter à votre panier."
        componentsProps={{
          tooltip: {
            sx: {
              fontSize: rStyles.toolTip.fontSize,
            },
          },
        }}
      >
        <div
          className={`fay-button ${inCart ? 'fay-button-secondary' : 'fay-button-primary'} btnhover btnhover2`}
          style={{ ...rStyles.fayButton }}
        >
          {inCart ? <BsCartX /> : <BsFillCartPlusFill />}
          {widthLabel && <span style={{ ...rStyles.span }}>{inCart ? 'Retirer du panier' : 'Ajouter Au Panier'}</span>}
        </div>
      </Tooltip>
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  border: none;
  outline: none;
  span {
    margin-left: 5px;
  }
  .MuiTooltip-tooltip {
    font-size: 26px !important;
  }
  .fay-button {
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    width: fit-content;
    padding: 12px;
    cursor: pointer;
    font-size: 26px;
    span {
      font-size: 16px;
    }
    svg {
      flex-shrink: 0;
      flex-grow: 0;
    }
  }
  .fay-button-primary {
    background-color: var(--primary);
  }
  .fay-button-secondary {
    background-color: var(--secondary);
  }
`;
