import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import styled from 'styled-components';
import { DispatchAsyncResult } from 'react-redux-dispatch-async/lib/dispatchAsync';
import { MagasinState } from 'generalContext/domain/entities/Magasin';
import { Category } from 'generalContext/domain/entities/Category';
import { AppCookiesBanner } from 'common/ui/elements/AppCookiesBanner';
import { AppScrollToTop } from 'common/ui/elements/AppScrollToTop';
import { User } from '../../../authContext/domain/entities/User';
import { useLoaderContext } from '../../../common/configuration/loaderContextProvider';
import { ComingSoon } from '../../../common/ui/old/ComingSoon';
import { Footer } from '../../../common/ui/layouts/Footer';

import {
  Checkout,
  Contact,
  Events,
  Home,
  OrderInformation,
  ProductDetails,
  Profile,
  Shop,
  ShopCart,
  SuccessfulPayment,
  TermsOfService,
  WishList,
} from '../../../generalContext/ui';
import { Header } from '../../../common/ui';
import { ErrorPage } from '../../../common/ui/old/ErrorPage';
import { UnderConstruction } from '../../../common/ui/old/UnderConstruction';
import { PasswordForgotten, ResetPasswordScreen, SignIn, SignUp } from '../../../authContext/ui';
import { ResetPasswordProps } from '../../../common/domain/entities/ResetPasswordProps';

interface NavigatorProps {
  stateCookiesAccepted: boolean | undefined;
  stateMagasins: MagasinState | undefined;
  stateConnectedUser?: User;
  stateLoading?: boolean;
  stateCategories: Category[] | undefined;
  cookiesAcceptAsync(): Promise<DispatchAsyncResult<void>>;
}
export const NavigatorDumb = ({
  stateCookiesAccepted,
  stateMagasins,
  stateConnectedUser,
  stateLoading,
  stateCategories,
  cookiesAcceptAsync,
}: NavigatorProps) => {
  const location = useLocation();
  const history = useHistory();
  const loader = useLoaderContext();

  useEffect(() => {
    if (stateLoading) {
      loader.setMessage('Chargement');
    } else {
      loader.setMessage('');
    }
  }, [stateLoading]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    if (location.pathname.includes('profile' || 'wishlist' || 'panier' || 'checkout') && !stateConnectedUser) {
      history.replace('/login');
    } else if (location.pathname.includes('login' || 'inscription') && stateConnectedUser) {
      history.replace('/profile');
    } else if (location.pathname.includes('Réinitialisation du mot de passe')) {
      const searchParams = new URLSearchParams(location.search);
      const userId = searchParams.get('userId');
      const accessToken = searchParams.get('accessToken');
      const params: ResetPasswordProps = {
        id: +(userId ?? 0),
        accessToken: accessToken ?? '',
        newPassword: '',
        newPasswordConfirm: '',
      };
      history.replace('/Reinitialisation-du-mot-de-passe', params);
    }
  }, [location, stateConnectedUser]);

  return (
    <div className="page-wraper">
      <AppCookiesBanner stateCookiesAccepted={stateCookiesAccepted} onAccept={() => cookiesAcceptAsync()} />
      <Switch>
        <Route path="/error-404" component={ErrorPage} />
        <Route path="/under-construction" component={UnderConstruction} />
        <Route path="/coming-soon" component={ComingSoon} />
        <Route>
          <Wrapper>
            <div className="sidebar-content-container">
              {/* <SideBar /> */}
              <div className="main-container">
                <Header />
                <div>
                  <Switch>
                    <Route path="/" exact component={Home} />
                    <Route path="/panier" exact component={ShopCart} />
                    <Route path="/wishlist" exact component={WishList} />
                    <Route path="/profile" exact component={Profile} />
                    <Route path="/paiement" exact component={Checkout} />
                    <Route path="/successful-payment" exact component={SuccessfulPayment} />
                    <Route path="/product-details/:id" exact component={ProductDetails} />
                    <Route path="/contact" exact component={Contact} />
                    <Route path="/evenements" exact component={Events} />
                    <Route path="/boutique/:searchquery?/:subcategory?/:category?" exact component={Shop} />
                    <Route path="/inscription" exact component={SignUp} />
                    <Route path="/login" exact component={SignIn} />
                    <Route path="/mot-de-passe-oublie" exact component={PasswordForgotten} />
                    <Route path="/Reinitialisation-du-mot-de-passe" exact component={ResetPasswordScreen} />
                    <Route path="/order-information" exact component={OrderInformation} />
                    <Route path="/terms" exact component={TermsOfService} />
                    <Route path="/terms" exact component={TermsOfService} />
                  </Switch>
                </div>
                <Footer magasins={stateMagasins} stateCategories={stateCategories} footerChange="style-1" />
              </div>
            </div>
          </Wrapper>
        </Route>
      </Switch>
      <AppScrollToTop />
    </div>
  );
};

const Wrapper = styled.div`
  .sidebar-content-container {
    max-width: 100vw;
  }
  .main-container {
    padding: 0;
    margin: 0;
    flex: 1;
    flex-grow: 0;
    flex-shrink: 0;
  }
`;
