import styled from 'styled-components';
import { rSize } from 'core/util/ResponsiveSizeAdapter';

interface PageTitleProps {
  pageTitle: string;
}
export const PageTitle = ({ pageTitle }: PageTitleProps) => {
  const rStyles = {
    h1: {
      fontSize: rSize(32),
      marginBottom: rSize(20),
    },
  };

  return (
    <Wrapper>
      <div className="container">
        <h1 style={{ ...rStyles.h1 }}>{pageTitle}</h1>
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  h1 {
    font-size: 32px;
    text-align: center;
  }
`;
