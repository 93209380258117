import { useEffect, useState } from 'react';
import { DispatchAsyncResult } from 'react-redux-dispatch-async/lib/dispatchAsync';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { rSize } from 'core/util/ResponsiveSizeAdapter';
import { AppButton } from '../../../../common/ui/elements/AppButton';
import { FormErrorList } from '../../../../common/ui/elements/FormErrorList';
import { PasswordChangeForm } from '../../../../common/ui/elements/PasswordChangeForm';
import { User } from '../../../../authContext/domain/entities/User';
import {
  ERROR_PASS_LENGTH,
  ERROR_PASSWORDS_NOT_MATCH,
  passwordMandatoryLength,
} from '../../../../configuration/helpers/constants';
import { useNotificationContext } from '../../../../common/configuration/notificationContextProvider';
import { ChangePasswordProps, emptyChangePasswordProps } from '../../../../common/domain/entities/ChangePasswordProps';
import { useLoaderContext } from '../../../../common/configuration/loaderContextProvider';

interface PasswordChangeProps {
  stateConnectedUser?: User;
  updatePasswordAsync(passObj: ChangePasswordProps): Promise<DispatchAsyncResult<boolean>>;
}
export const PasswordChange = ({ stateConnectedUser, updatePasswordAsync }: PasswordChangeProps) => {
  const history = useHistory();
  const loader = useLoaderContext();
  const notifications = useNotificationContext();
  const [passChangeProps, setPassChangeProps] = useState<ChangePasswordProps>(emptyChangePasswordProps);
  const [showError, setShowError] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<string[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const rStyles = {
    global: {
      h5: {
        fontSize: rSize(22),
      },
      p: {
        fontSize: rSize(18),
      },
    },
    wrapper: {
      maxWidth: rSize(700),
    },
  };

  useEffect(() => {
    if (!stateConnectedUser?.id) {
      history.push('/login');
    }
  }, [stateConnectedUser]);

  useEffect(() => {
    validateForm();
  }, [passChangeProps]);

  const validateForm = () => {
    const errors: string[] = [];
    if (
      _.isEmpty(passChangeProps.currentPassword) ||
      _.isEmpty(passChangeProps.newPassword) ||
      (passChangeProps.newPassword && passChangeProps.newPassword.length < passwordMandatoryLength) ||
      (passChangeProps.currentPassword && passChangeProps.currentPassword.length < passwordMandatoryLength)
    ) {
      errors.push(ERROR_PASS_LENGTH);
    }
    if (passChangeProps.newPassword !== passChangeProps.newPasswordConfirm) {
      errors.push(ERROR_PASSWORDS_NOT_MATCH);
    }
    setFormErrors(errors);
  };

  const updatePassword = async () => {
    setShowError(true);
    if (formErrors.length || !stateConnectedUser?.id) {
      return;
    }
    setIsSubmitting(true);
    try {
      loader.setMessage('_');
      const response: any = await updatePasswordAsync(passChangeProps);
      if (response.success) {
        // Scenario A: updated data successfully
        setFormErrors([]);
        notifications.setMessage({
          message: 'Le mot de passe a été changé avec succès',
          status: 'success',
        });
      } else {
        setFormErrors([response.error.payload.cause]);
        console.error('updatePassword error', response.error);
      }
    } catch (error) {
      let message = "Une erreur s'est produite lors de la tentative de mise à jour de votre mot de passe";
      if ((error as Error).message.includes('403')) {
        message = (error as any).payload.cause;
      }
      notifications.setMessage({
        message,
        status: 'error',
      });
      console.error('updatePassword caught error', error);
    } finally {
      loader.setMessage('');
      setIsSubmitting(false);
    }
  };

  return (
    <div className="shop-bx shop-profile">
      <div className="shop-bx-title clearfix">
        <h5 className="text-uppercase" style={{ ...rStyles.global.h5 }}>
          Changement de mot de passe
        </h5>
      </div>
      <Wrapper style={{ ...rStyles.wrapper }}>
        <FormErrorList errors={showError ? formErrors : []} fontSize={rStyles.global.p.fontSize} />
        <PasswordChangeForm
          showError={showError}
          passChangeProps={passChangeProps}
          setPassChangeProps={setPassChangeProps}
          enterFunc={updatePassword}
        />
        <AppButton label="Enregistrer" disabled={isSubmitting} action={updatePassword} />
      </Wrapper>
    </div>
  );
};

const Wrapper = styled.div`
  margin: 0 auto;
`;
