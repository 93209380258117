import styled from 'styled-components';
import { Carousel } from 'react-responsive-carousel';
import { AppContainer } from 'common/ui/elements/AppContainer';
import { rSize } from 'core/util/ResponsiveSizeAdapter';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

interface ShowcaseProps {
  imagesBase64: string[];
  isTriplet?: boolean;
}
export const Showcase = ({ imagesBase64, isTriplet = false }: ShowcaseProps) => {
  const rStyles = {
    global: {},
    tripletWrapper: {
      gap: rSize(10),
      marginTop: rSize(30),
      marginBottom: rSize(10),
    },
    twoSmall: {
      gap: rSize(10),
    },
    oneBig: {
      minHeight: rSize(300),
    },
    sliderWrapper: {
      marginTop: rSize(30),
    },
    sliderImage: {
      height: rSize(300),
    },
  };
  const renderContent = () => {
    if (isTriplet && imagesBase64.length >= 3) {
      return (
        <TripletWrapper style={{ ...rStyles.tripletWrapper }}>
          <div className="row g-2">
            <div className="col-lg-7 col-md-7 col-sm-12">
              <img className="triplet-image" src={`data:image/jpg;base64,${imagesBase64[0]}`} alt="triplet-img" />
            </div>
            <div className="col-lg-5 col-md-5 col-sm-12">
              <div className="row">
                <div className="col-12 mb-2">
                  <img className="triplet-image" src={`data:image/jpg;base64,${imagesBase64[1]}`} alt="triplet-img" />
                </div>
                <div className="col-12">
                  <img className="triplet-image" src={`data:image/jpg;base64,${imagesBase64[2]}`} alt="triplet-img" />
                </div>
              </div>
            </div>
          </div>
        </TripletWrapper>
      );
    }
    if (imagesBase64.length > 0) {
      return (
        <SliderWrapper style={{ ...rStyles.sliderWrapper }}>
          <Carousel
            autoPlay
            interval={3000}
            transitionTime={600}
            showArrows
            infiniteLoop
            showStatus={false}
            showThumbs={false}
            emulateTouch
          >
            {imagesBase64.map((imageBase64, i) => (
              <div className="carousel-slide" key={`carousel-slide-${i}`}>
                <img
                  className="slider-image"
                  style={{ ...rStyles.sliderImage }}
                  src={`data:image/jpg;base64,${imageBase64}`}
                  alt={`showcase-item-${i + 1}`}
                />
              </div>
            ))}
          </Carousel>
        </SliderWrapper>
      );
    }
    return (
      <PlaceholderMessage>Aucune image trouvée. Veuillez ajouter au moins une image pour l'aperçu.</PlaceholderMessage>
    );
  };

  return <AppContainer>{renderContent()}</AppContainer>;
};

const TripletWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1%;
  margin-bottom: 20px;
  .two-small {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 40%;
  }
  .one-big {
    width: 60%;
  }
  .triplet-image {
    background-color: var(--secondary);
    width: 100%;
    height: 100%;
  }
`;

const SliderWrapper = styled.div`
  width: 100%;
  .slider-image {
    width: 100%;
    min-height: 28vw;
  }
`;

const PlaceholderMessage = styled.div`
  width: 100%;
  padding: 60px 16px;
  background-color: #333;
  color: #fff;
  text-align: center;
  font-size: 1.2rem;
`;
