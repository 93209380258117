import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { ArcElement, Chart } from 'chart.js';
import { Color } from '../../../assets/styles/constantStyles';

Chart.register(ArcElement);

interface DonutChartProps {
  value: string;
  backgroundColor: string;
  backgroundColor2: string;
}
export const DonutChart = ({ value, backgroundColor, backgroundColor2 }: DonutChartProps) => {
  const data = {
    weight: 0,
    defaultFontFamily: 'Poppins',
    datasets: [
      {
        data: [20, 80],
        borderWidth: 0,
        backgroundColor: [Color.PRIMARY, Color.APPBLACK],
      },
    ],
  };

  const options: any = {
    // width: 75,
    // cutoutPercentage: 50,
    cutout: '93%',
    responsive: true,
    maintainAspectRatio: true,
    tooltips: { enabled: false },
    hover: { mode: null },
  };
  return (
    <div className="donught-chart">
      <Doughnut data={data} options={options} height={165} width={165} />
    </div>
  );
};
