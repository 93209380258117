import { useEffect, useState } from 'react';
import { DispatchAsyncResult } from 'react-redux-dispatch-async/lib/dispatchAsync';
import styled from 'styled-components';
import { TbBooks } from 'react-icons/tb';
import { HiMagnifyingGlassCircle } from 'react-icons/hi2';
import { useLocation } from 'react-router-dom';
import { useLoaderContext } from 'common/configuration/loaderContextProvider';
import { productsPerPage } from 'configuration/helpers/constants';
import { GetProductsProps } from 'generalContext/domain/entities/Product';
import { defaultProductState, ProductState } from 'generalContext/domain/entities/ProductState';
import { rSize } from 'core/util/ResponsiveSizeAdapter';
import { ShopSidebar } from './components/ShopSidebar';
import { ProductsGrid } from './components/ProductsGrid';
import { SubCategory } from '../../domain/entities/SubCategory';
import { Category } from '../../domain/entities/Category';
import { useNotificationContext } from '../../../common/configuration/notificationContextProvider';

interface ShopProps {
  stateSubCategories: SubCategory[] | undefined;
  stateCategories: Category[] | undefined;
  getProductsAsync(props?: GetProductsProps): Promise<DispatchAsyncResult<ProductState>>;
}
export const ShopDumb = ({ stateSubCategories, stateCategories, getProductsAsync }: ShopProps) => {
  const [productsState, setProductsState] = useState<ProductState>(defaultProductState);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const loader = useLoaderContext();
  const notifications = useNotificationContext();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const paramSearchQuery = searchParams.get('searchquery');
  const paramsCategory = searchParams.get('category');
  const paramsSubCategory = searchParams.get('subcategory');

  const rStyles = {
    title: {
      fontSize: rSize(26),
      gap: rSize(10),
      paddingBottom: rSize(10),
    },
  };

  useEffect(() => {
    setCurrentPage(0);
  }, [paramSearchQuery, paramsCategory, paramsSubCategory]);

  useEffect(() => {
    getProducts();
  }, [currentPage, paramSearchQuery, paramsCategory, paramsSubCategory]);

  const getProducts = async () => {
    try {
      loader.setMessage('_');
      const response = await getProductsAsync({
        skip: currentPage * productsPerPage,
        take: productsPerPage,
        query: paramSearchQuery ?? undefined,
        categoryNumber: paramsCategory ?? undefined,
        subCategoryNumber: paramsSubCategory ?? undefined,
      });
      if (!response.success) {
        notifications.setMessage({
          message: "Une erreur s'est produite lors du chargement des produits",
          status: 'error',
        });
        console.error('getProducts caught error', response.error);
      } else {
        setProductsState(response.result);
      }
    } catch (error) {
      notifications.setMessage({
        message: "Une erreur s'est produite lors du chargement des produits",
        status: 'error',
      });
      console.error('getProducts caught error', error);
    } finally {
      loader.setMessage('');
    }
  };

  if (!stateSubCategories || !stateCategories) {
    return null;
  }
  return (
    <Wrapper>
      <div className="page-content">
        <section className="content-inner-1">
          <div className="page-content">
            <div className="border-bottom">
              <div className="container">
                <div className="row ">
                  <div className="col-xl-3">
                    <ShopSidebar stateCategories={stateCategories} stateSubCategories={stateSubCategories} />
                  </div>
                  <div className="col-xl-9">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="title" style={{ ...rStyles.title }}>
                        {paramSearchQuery ? <HiMagnifyingGlassCircle /> : <TbBooks />}
                        <h5 style={{ fontSize: rStyles.title.fontSize }}>
                          {paramSearchQuery ? (
                            <>
                              Affichage des produits correspondants à la recherche du mot:
                              <span className="search-query"> {` "${paramSearchQuery}".`}</span>
                            </>
                          ) : (
                            'Articles'
                          )}
                        </h5>
                      </div>
                    </div>
                    <ProductsGrid productsState={productsState} setCurrentPage={setCurrentPage} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .display-option {
    border: none;
    background-color: transparent;
  }
  .title {
    font-size: 20px;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    padding-bottom: 10px;
    width: 100%;
  }
  .search-query {
    text-transform: none;
  }
  .sub-title {
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 600;
  }
  .border-bottom {
    padding-bottom: 1em;
  }
`;
