/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import { DispatchAsyncResult } from 'react-redux-dispatch-async/lib/dispatchAsync';
import { Link } from 'react-router-dom';
import { rSize } from 'core/util/ResponsiveSizeAdapter';
import { PageTitle } from '../../../common/ui/layouts/PageTitle';
import { Cart } from '../../domain/entities/Cart';
import noImageImage from '../../../assets/images/NoImageImage.png';
import { Product } from '../../domain/entities/Product';
import { priceDisplay } from '../../../configuration/helpers/HelperFunctions';

interface ShopCartProps {
  stateCart: Cart | undefined;
  getCartProductsAsync(): Promise<DispatchAsyncResult<void>>;
  // cart
  saveCartProductAsync(product: Product): Promise<DispatchAsyncResult<Product>>;
  deleteCartProductAsync(Art_Ean13: string): Promise<DispatchAsyncResult<boolean>>;
}
export const ShopCartDumb = ({
  stateCart,
  getCartProductsAsync,
  saveCartProductAsync,
  deleteCartProductAsync,
}: ShopCartProps) => {
  const rStyles = {
    global: {
      h4: {
        fontSize: rSize(24),
      },
      p: {
        fontSize: rSize(18),
      },
      th: {
        fontSize: rSize(18),
      },
    },
    quantity: {
      width: rSize(150),
    },
    quantityButton: {
      width: rSize(50),
      height: rSize(50),
      fontSize: rSize(16),
    },
    quantityInput: {
      fontSize: rSize(16),
      height: rSize(50),
    },
    closeButton: {
      width: rSize(40),
      height: rSize(40),
      lineHeight: `${rSize(40)}px`,
      fontSize: rSize(20),
    },
  };

  useEffect(() => {
    getCartProductsAsync();
  }, []);

  const handleDeleteClick = (product: Product) => {
    deleteCartProductAsync(product.Art_Ean13);
  };
  const handleNumPlus = (product: Product) => {
    saveCartProductAsync({ ...product, Art_Quantity: (product.Art_Quantity ?? 0) + 1 });
  };
  const handleNumMinus = (product: Product) => {
    if (product.Art_Quantity && product.Art_Quantity > 1) {
      saveCartProductAsync({ ...product, Art_Quantity: (product.Art_Quantity ?? 0) - 1 });
    }
  };

  return (
    <div className="page-content">
      <PageTitle pageTitle="Panier" />
      <section className="content-inner shop-account">
        {/* <!-- Product --> */}
        <div className="container">
          <div className="row mb-5">
            <div className="col-lg-12">
              <div className="table-responsive">
                <table className="table check-tbl">
                  <thead>
                    <tr>
                      <th style={{ ...rStyles.global.th }}>Image</th>
                      <th style={{ ...rStyles.global.th }}>Titre</th>
                      <th style={{ ...rStyles.global.th }}>Prix unitaire</th>
                      <th style={{ ...rStyles.global.th }}>Quantité</th>
                      <th style={{ ...rStyles.global.th }}>Total</th>
                      <th className="text-end" style={{ ...rStyles.global.th }}>
                        Retirer
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {stateCart?.items.map((data, i) => {
                      const price = data.Art_Prix_Promo ?? data.Art_Prix;
                      const quantity = data.Art_Quantity ?? 1;

                      return (
                        <tr key={`cart-prod-key-${data?.id}${i}`}>
                          <td className="product-item-img">
                            <img
                              src={data.Art_Images.length ? `${data.Art_Images[0]}` : `${noImageImage}`}
                              alt="product display"
                            />
                          </td>
                          <td className="product-item-name" style={{ ...rStyles.global.p }}>
                            {data.Art_Titre}
                          </td>
                          <td className="product-item-price" style={{ ...rStyles.global.p }}>
                            {priceDisplay(price)}
                          </td>
                          <td className="product-item-quantity">
                            <div className="quantity btn-quantity style-1 me-3" style={{ ...rStyles.quantity }}>
                              <button
                                className="btn btn-plus"
                                style={{ ...rStyles.quantityButton }}
                                type="button"
                                onClick={() => {
                                  handleNumPlus(data);
                                }}
                              >
                                <i className="ti-plus" />
                              </button>
                              <input
                                type="text"
                                id={`cart_book_${data.id}`}
                                className="quantity-input quantity"
                                style={{ ...rStyles.quantityInput }}
                                value={quantity}
                                disabled
                              />
                              <button
                                className="btn btn-minus "
                                style={{ ...rStyles.quantityButton }}
                                type="button"
                                onClick={() => {
                                  handleNumMinus(data);
                                }}
                              >
                                <i className="ti-minus" />
                              </button>
                            </div>
                          </td>
                          <td
                            className="product-item-total"
                            style={{ ...rStyles.global.p }}
                            id={`total_book_${data.id}`}
                          >
                            {priceDisplay(price * quantity)}
                          </td>
                          <td className="product-item-close">
                            <Link
                              to="#"
                              className="ti-close"
                              style={{ ...rStyles.closeButton }}
                              onClick={() => handleDeleteClick(data)}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="row">
            <div>
              <div className="widget">
                <h4 className="widget-title" style={{ ...rStyles.global.h4 }}>
                  Sous-total Du Panier
                </h4>
                <table className="table-bordered check-tbl m-b25">
                  <tbody>
                    <tr>
                      <td style={{ ...rStyles.global.p }}>Sous-total De La Commande</td>
                      <td id="orderSubtotal" style={{ ...rStyles.global.p }}>
                        {priceDisplay(stateCart?.total)}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="form-group m-b25">
                  <Link
                    to="/paiement"
                    className="btn btn-primary btnhover"
                    style={{ ...rStyles.global.p }}
                    type="button"
                  >
                    Passer au Paiement
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Product END --> */}
      </section>
    </div>
  );
};
