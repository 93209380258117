import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { rSize } from 'core/util/ResponsiveSizeAdapter';
import { Category } from 'generalContext/domain/entities/Category';
import { SubCategory } from 'generalContext/domain/entities/SubCategory';
import { SocialLinks } from 'generalContext/ui/components/SocialLinks';
import { CloseIcon } from '../../../../assets/svg/CloseIcon';
import { Logo } from '../Header/components/Logo';
import { ArrowExpandLess } from '../../../../assets/svg/ArrowExpandLess';
import { ArrowExpandMore } from '../../../../assets/svg/ArrowExpandMore';

interface SideMenuProps {
  isOpen: boolean;
  toggleSideMenu: () => void;
  stateSubCategories: SubCategory[] | undefined;
  stateCategories: Category[] | undefined;
}

export const SideMenuDumb = ({ isOpen, toggleSideMenu, stateCategories, stateSubCategories }: SideMenuProps) => {
  const [isBoutiqueExpanded, setIsBoutiqueExpanded] = useState(false);
  const [expandedCategories, setExpandedCategories] = useState<{ [key: string]: boolean }>({});

  const rStyles = {
    global: {},
    sideMenuWrapper: {
      padding: `${rSize(30)}px ${rSize(30)}px`,
    },
    logo: {
      width: rSize(260),
    },
    closeIcon: {
      fontSize: rSize(34),
    },
    nav: {
      gap: rSize(28),
      padding: `${rSize(50)}px 0 ${rSize(30)}px 0`,
    },
    horizontalLine: {
      minHeight: rSize(1),
      margin: `${rSize(20)}px 0 ${rSize(30)}px 0`,
    },
    styledLink: {
      fontSize: rSize(30),
    },
    categoryLink: {
      fontSize: rSize(23),
      fontWeight: 'bold',
      paddingLeft: rSize(40),
    },
    subCategoryLink: {
      fontSize: rSize(21),
      fontWeight: 'bold',
    },
    boutiqueItem: {
      gap: rSize(10),
      paddingLeft: rSize(40),
    },
    boutiqueExpandIcon: {
      size: rSize(60),
    },
    expandIcon: {
      size: rSize(40),
    },
    categoryList: {
      gap: rSize(16),
    },
    subCategoryList: {
      gap: rSize(8),
    },
  };

  const menuItems = [
    {
      title: 'Accueil',
      link: '/',
    },
    {
      title: 'Événements',
      link: '/evenements',
    },
    {
      title: 'Contact',
      link: '/contact',
    },
  ];

  const handleBoutiqueClick = () => {
    setIsBoutiqueExpanded(!isBoutiqueExpanded);
  };

  const handleCategoryClick = (categoryId: number) => {
    setExpandedCategories({
      ...expandedCategories,
      [categoryId]: !expandedCategories[categoryId],
    });
  };

  return (
    <>
      {isOpen && <Overlay onClick={toggleSideMenu} />}
      <SideMenuWrapper style={{ ...rStyles.sideMenuWrapper }} className={`sideMenu ${isOpen ? 'open' : 'close'}`}>
        <div className="sideMenu-header">
          <Logo width={rStyles.logo.width} />
          <button className="close-btn" type="button" onClick={toggleSideMenu}>
            <CloseIcon size={rStyles.closeIcon.fontSize} />
          </button>
        </div>
        <HorizontalLine style={{ ...rStyles.horizontalLine }} />

        <Nav style={{ ...rStyles.nav }}>
          {menuItems.map((item) => (
            <MenuItem key={item.title}>
              <StyledLink to={item.link} style={{ ...rStyles.styledLink }} onClick={toggleSideMenu}>
                <span>{item.title}</span>
              </StyledLink>
            </MenuItem>
          ))}

          <CategoryList style={{ ...rStyles.categoryList }}>
            <MenuItem style={{ ...rStyles.boutiqueItem }}>
              <StyledLink
                className="boutique-styled-link"
                style={{ ...rStyles.styledLink }}
                to="/boutique/"
                onClick={() => {
                  setIsBoutiqueExpanded(false);
                  setExpandedCategories({});
                  toggleSideMenu();
                }}
              >
                <span>Boutique</span>
              </StyledLink>
              <div
                className="arrow-expand-toggle"
                role="button"
                tabIndex={0}
                onClick={(event) => {
                  event.preventDefault();
                  handleBoutiqueClick();
                }}
              >
                {isBoutiqueExpanded ? (
                  <ArrowExpandLess size={rStyles.boutiqueExpandIcon.size} />
                ) : (
                  <ArrowExpandMore size={rStyles.boutiqueExpandIcon.size} />
                )}
              </div>
            </MenuItem>

            {isBoutiqueExpanded &&
              stateCategories &&
              stateCategories.map((category) => {
                const foundFilledsCats =
                  stateSubCategories &&
                  stateSubCategories.filter(
                    (subCategory) =>
                      subCategory.categoryNumber === category.categoryNumber && !!subCategory.productCount,
                  );
                if (!foundFilledsCats || !foundFilledsCats.length) {
                  return null;
                }
                return (
                  <div key={category.id}>
                    <MenuItem>
                      <StyledLink
                        to={{ pathname: '/boutique/', search: `?category=${category.categoryNumber}` }}
                        style={{ ...rStyles.styledLink, ...rStyles.categoryLink }}
                        onClick={() => {
                          setIsBoutiqueExpanded(false);
                          setExpandedCategories({});
                          toggleSideMenu();
                        }}
                      >
                        <span>{category.name}</span>
                      </StyledLink>
                      <div
                        className="arrow-expand-toggle"
                        role="button"
                        tabIndex={0}
                        onClick={(event) => {
                          event.preventDefault();
                          handleCategoryClick(category.id);
                        }}
                      >
                        {expandedCategories[category.id] ? (
                          <ArrowExpandLess size={rStyles.expandIcon.size} />
                        ) : (
                          <ArrowExpandMore size={rStyles.expandIcon.size} />
                        )}
                      </div>
                    </MenuItem>
                    {expandedCategories[category.id] && (
                      <SubCategoryList style={{ ...rStyles.subCategoryList }}>
                        {stateSubCategories &&
                          stateSubCategories
                            .filter((subCategory) => subCategory.categoryNumber === category.categoryNumber)
                            .map((subCategory) => {
                              if (!subCategory.productCount) {
                                return null;
                              }
                              return (
                                <MenuItem key={subCategory.id}>
                                  <SubCategoryLink
                                    to={{
                                      pathname: '/boutique/',
                                      search: `?subcategory=${subCategory.subCategoryNumber}`,
                                    }}
                                    style={{ ...rStyles.styledLink, ...rStyles.subCategoryLink }}
                                    onClick={() => {
                                      setIsBoutiqueExpanded(false);
                                      setExpandedCategories({});
                                      toggleSideMenu();
                                    }}
                                  >
                                    <span>{subCategory.name}</span>
                                  </SubCategoryLink>
                                </MenuItem>
                              );
                            })}
                      </SubCategoryList>
                    )}
                  </div>
                );
              })}
          </CategoryList>
        </Nav>
        <SocialLinksWrapper>
          <SocialLinks fontSize={20} color="#1a191c" />
        </SocialLinksWrapper>
      </SideMenuWrapper>
    </>
  );
};

const SideMenuWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 92%;
  height: 100%;
  background-color: #fff;
  color: black;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  z-index: 1001;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 20px;
  overflow-y: overlay;
  overscroll-behavior-y: contain;
  &.open {
    transform: translateX(0);
  }

  &.close {
    transform: translateX(100%);
  }

  .sideMenu-header {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
  }

  .close-btn {
    align-self: flex-start;
    border: none;
    background: none;
    color: black;
    cursor: pointer;
  }

  .close-btn {
    position: absolute;
    top: 0;
    right: 0;
    background: none;
    border: none;
    cursor: pointer;
  }

  .boutique-styled-link-div {
    display: flex;
    width: 100%;
  }
`;

const Nav = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  flex: 1;
  overflow-y: auto;
`;

const HorizontalLine = styled.hr`
  min-height: 1px;
  background: #888;
  width: 100%;
  margin: 20px 0 30px 0;
  z-index: 1;
`;

const MenuItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledLink = styled(Link)`
  color: #1a191c;
  text-decoration: none;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 900;
  font-style: normal;
  text-align: center;
`;

const SubCategoryLink = styled(Link)`
  color: #9c9c9c;
  text-decoration: none;
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 900;
  font-style: normal;
`;

const CategoryList = styled.div`
  display: flex;
  flex-direction: column;
`;

const SubCategoryList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1000;
`;

const SocialLinksWrapper = styled.div`
  margin-top: auto;
  width: 100%;
  display: flex;
  justify-content: center;
`;
