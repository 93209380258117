import { rSize } from 'core/util/ResponsiveSizeAdapter';

export const TermsOfServicePlain = () => {
  const rStyles = {
    global: {
      h4: {
        fontSize: rSize(24),
      },
      h5: {
        fontSize: rSize(22),
      },
      p: {
        fontSize: rSize(18),
      },
      th: {
        fontSize: rSize(18),
      },
    },
    container: {
      gap: rSize(20),
    },
  };
  return (
    <div className="shop-bx shop-profile">
      <div className="shop-bx-title clearfix">
        <h5 className="text-uppercase" style={{ ...rStyles.global.h4 }}>
          Conditions d'utilisation
        </h5>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          ...rStyles.container,
        }}
      >
        <p style={{ ...rStyles.global.p }}>
          Bienvenue sur la Librairie en Ligne. En accédant ou en utilisant notre site web, vous acceptez de vous
          conformer aux termes et conditions d'utilisation suivants, qui, avec notre politique de confidentialité,
          régissent notre relation avec vous par rapport à ce site web.
        </p>
        <h5 style={{ ...rStyles.global.h5 }}>1. Conditions Générales</h5>
        <p style={{ ...rStyles.global.p }}>
          Vous devez avoir au moins 18 ans pour utiliser ce site. En utilisant ce site, vous déclarez avoir au moins 18
          ans. Nous nous réservons le droit de refuser le service à toute personne pour quelque raison que ce soit et à
          tout moment.
        </p>
        <h5 style={{ ...rStyles.global.h5 }}>2. Tarification et Paiement</h5>
        <p style={{ ...rStyles.global.p }}>
          Tous les prix sont indiqués en dollars américains. Nous nous réservons le droit de modifier nos prix à tout
          moment sans préavis. Nous acceptons les paiements par carte de crédit et PayPal. Tous les paiements sont
          traités de manière sécurisée par notre prestataire de paiement.
        </p>
        <h5 style={{ ...rStyles.global.h5 }}>3. Livraison et Retours</h5>
        <p style={{ ...rStyles.global.p }}>
          Nous proposons des téléchargements numériques de livres. Une fois que vous avez terminé le processus de
          commande, vous recevrez un lien pour télécharger les livres que vous avez achetés. En raison de la nature des
          téléchargements numériques, nous n'acceptons pas les retours ni n'offrons de remboursements.
        </p>
        <h5 style={{ ...rStyles.global.h5 }}>4. Propriété Intellectuelle</h5>
        <p style={{ ...rStyles.global.p }}>
          Tout le contenu de ce site, y compris, mais sans s'y limiter, le texte, les graphiques, les logos, les icônes
          de boutons, les images, les clips audio, les téléchargements numériques et les logiciels, est la propriété de
          la Librairie en Ligne ou de ses fournisseurs de contenu et est protégé par les lois françaises et
          internationales sur le droit d'auteur.
        </p>
      </div>
    </div>
  );
};
