import { Action } from 'redux-actions';
import { AdminPreferances } from 'generalContext/domain/entities/AdminPreferances';
import { MagasinState } from 'generalContext/domain/entities/Magasin';
import { ShowcaseItem } from 'generalContext/domain/entities/ShowcaseItem';
import {
  addDefaultShowcaseItemSuccess,
  addPromoShowcaseItemSuccess,
  assertPaymentSuccess,
  cookiesAcceptSuccess,
  createShowcaseItemSuccess,
  deleteShowcaseItemSuccess,
  getAdminPreferancesSuccess,
  getCartProductsSuccess,
  getCategoriesSuccess,
  getDefaultShowcaseItemsSuccess,
  getDeliveryProductsSuccess,
  getMagasinListSuccess,
  getOfflineCartProductsSuccess,
  getPrioritizedShowcaseItemsSuccess,
  getPromoShowcaseItemsSuccess,
  getShowcaseItemsSuccess,
  getSubCategoriesSuccess,
  getWishProductsSuccess,
  initializePaymentSuccess,
  removeDefaultShowcaseItemSuccess,
  removePromoShowcaseItemSuccess,
  switchDefaultShowcaseOrderSuccess,
  switchPromoShowcaseOrderSuccess,
  updateAdminPreferencesSuccess,
  updatePromoShowcaseSuccess,
  updateShowcaseItemSuccess,
} from './General.actions';
import { createTypedHandler, handleTypedActions } from '../../common/usecases/helpers/actions.helper';
import { GeneralState } from '../configuration/General.state';
import { Product } from '../domain/entities/Product';
import { SubCategory } from '../domain/entities/SubCategory';
import { Category } from '../domain/entities/Category';
import { sendSignOutSuccess } from '../../authContext/usecases/Auth.actions';

const defaultState: GeneralState = {
  cookiesAccepted: undefined,
  magasins: undefined,
  categories: undefined,
  subCategories: undefined,
  cart: undefined,
  offlineCart: undefined,
  deliveryProducts: undefined,
  wishProducts: undefined,
  adminPreferances: undefined,
  initializePaymentResponse: undefined,
  assertPaymentResponse: undefined,
  prioritizedShowcaseItems: undefined,
  showcaseItems: undefined,
  defaultShowcaseItems: undefined,
  promoShowcaseItems: undefined,
  showcaseItemResponse: undefined,
  addDefaultShowcaseResponse: undefined,
  addPromoShowcaseResponse: undefined,
  removeDefaultShowcaseResponse: undefined,
  removePromoShowcaseResponse: undefined,
  switchDefaultShowcaseOrderResponse: undefined,
  switchPromoShowcaseOrderResponse: undefined,
};

const getCartReducer = (state: GeneralState, action: Action<Product[] | undefined>) => {
  const products = action.payload;
  if (products) {
    const totalPrice = products?.reduce((total, product) => {
      const price = product.Art_Prix_Promo ?? product.Art_Prix;
      return total + price * (product.Art_Quantity ?? 0);
    }, 0);
    return { ...state, cart: { total: +totalPrice.toFixed(2), items: products } };
  }
  return { ...state, cart: undefined };
};

const getOfflineCartReducer = (state: GeneralState, action: Action<Product[] | undefined>) => {
  const products = action.payload;
  if (products) {
    const totalPrice = products?.reduce((total, product) => {
      const price = product.Art_Prix_Promo ?? product.Art_Prix;
      return total + price * (product.Art_Quantity ?? 0);
    }, 0);
    return { ...state, offlineCart: { total: totalPrice, items: products } };
  }
  return { ...state, offlineCart: undefined };
};

const getWishProductsReducer = (state: GeneralState, action: Action<Product[] | undefined>) => ({
  ...state,
  wishProducts: action.payload,
});

const getCategoriesReducer = (state: GeneralState, action: Action<Category[] | undefined>) => ({
  ...state,
  categories: action.payload,
});

const getSubCategoriesReducer = (state: GeneralState, action: Action<SubCategory[] | undefined>) => ({
  ...state,
  subCategories: action.payload,
});

const getMagasinListReducer = (state: GeneralState, action: Action<MagasinState | undefined>) => ({
  ...state,
  magasins: action.payload,
});

const getDeliveryProductsReducer = (state: GeneralState, action: Action<Product[] | undefined>) => ({
  ...state,
  deliveryProducts: action.payload,
});

const sendSignOutReducer = (state: GeneralState) => ({
  ...state,
  cart: defaultState.cart,
  wishProducts: defaultState.wishProducts,
});

// admin --------------------------------

const getAdminPreferancesReducer = (state: GeneralState, action: Action<AdminPreferances | undefined>) => ({
  ...state,
  adminPreferances: action.payload,
});

const updateAdminPreferancesReducer = (state: GeneralState, action: Action<AdminPreferances | undefined>) => ({
  ...state,
  adminPreferances: action.payload,
});

// cookies --------------------

const cookiesAcceptReducer = (state: GeneralState, action: Action<boolean | undefined>) => ({
  ...state,
  cookiesAccepted: action.payload,
});

// payment -------------------------------------------
const initializePaymentReducer = (state: GeneralState, action: Action<any>) => ({
  ...state,
  initializePaymentResponse: action.payload,
});

const assertPaymentReducer = (state: GeneralState, action: Action<any>) => ({
  ...state,
  assertPaymentResponse: action.payload,
});

// Showcase -------------------------------------------
const getShowcaseItemsReducer = (state: GeneralState, action: Action<ShowcaseItem[] | undefined>) => ({
  ...state,
  showcaseItems: action.payload,
});

const getDefaultShowcaseItemsReducer = (state: GeneralState, action: Action<ShowcaseItem[] | undefined>) => ({
  ...state,
  defaultShowcaseItems: action.payload,
});

const getPromoShowcaseItemsReducer = (state: GeneralState, action: Action<ShowcaseItem[] | undefined>) => ({
  ...state,
  promoShowcaseItems: action.payload,
});

const getPrioritizedShowcaseItemsReducer = (state: GeneralState, action: Action<ShowcaseItem[] | undefined>) => ({
  ...state,
  prioritizedShowcaseItems: action.payload,
});

const createShowcaseItemReducer = (state: GeneralState, action: Action<ShowcaseItem | undefined>) => ({
  ...state,
  showcaseItemResponse: action.payload,
});

const updateShowcaseItemReducer = (state: GeneralState, action: Action<ShowcaseItem | undefined>) => ({
  ...state,
  showcaseItemResponse: action.payload,
});

const updatePromoShowcaseReducer = (state: GeneralState, action: Action<ShowcaseItem | undefined>) => ({
  ...state,
  showcaseItemResponse: action.payload,
});

const deleteShowcaseItemReducer = (state: GeneralState, action: Action<boolean | undefined>) => ({
  ...state,
  showcaseItemResponse: action.payload ? undefined : state.showcaseItemResponse,
});

const addDefaultShowcaseItemReducer = (state: GeneralState, action: Action<ShowcaseItem | undefined>) => ({
  ...state,
  addDefaultShowcaseResponse: action.payload,
});

const removeDefaultShowcaseItemReducer = (state: GeneralState, action: Action<boolean | undefined>) => ({
  ...state,
  removeDefaultShowcaseResponse: action.payload,
});

const addPromoShowcaseItemReducer = (state: GeneralState, action: Action<ShowcaseItem | undefined>) => ({
  ...state,
  addPromoShowcaseResponse: action.payload,
});

const removePromoShowcaseItemReducer = (state: GeneralState, action: Action<boolean | undefined>) => ({
  ...state,
  removePromoShowcaseResponse: action.payload,
});

const switchDefaultShowcaseOrderReducer = (state: GeneralState, action: Action<boolean | undefined>) => ({
  ...state,
  switchDefaultShowcaseOrderResponse: action.payload,
});

const switchPromoShowcaseOrderReducer = (state: GeneralState, action: Action<boolean | undefined>) => ({
  ...state,
  switchPromoShowcaseOrderResponse: action.payload,
});

export const generalReducer = handleTypedActions(
  [
    createTypedHandler(getDeliveryProductsSuccess, getDeliveryProductsReducer),
    createTypedHandler(getCartProductsSuccess, getCartReducer),
    createTypedHandler(getOfflineCartProductsSuccess, getOfflineCartReducer),
    createTypedHandler(getWishProductsSuccess, getWishProductsReducer),
    createTypedHandler(getCategoriesSuccess, getCategoriesReducer),
    createTypedHandler(getSubCategoriesSuccess, getSubCategoriesReducer),
    createTypedHandler(sendSignOutSuccess, sendSignOutReducer),
    createTypedHandler(getMagasinListSuccess, getMagasinListReducer),
    createTypedHandler(getAdminPreferancesSuccess, getAdminPreferancesReducer),
    createTypedHandler(updateAdminPreferencesSuccess, updateAdminPreferancesReducer),
    createTypedHandler(cookiesAcceptSuccess, cookiesAcceptReducer),
    createTypedHandler(initializePaymentSuccess, initializePaymentReducer),
    createTypedHandler(assertPaymentSuccess, assertPaymentReducer),
    // showcase ---------------------------
    createTypedHandler(getShowcaseItemsSuccess, getShowcaseItemsReducer),
    createTypedHandler(getDefaultShowcaseItemsSuccess, getDefaultShowcaseItemsReducer),
    createTypedHandler(getPromoShowcaseItemsSuccess, getPromoShowcaseItemsReducer),
    createTypedHandler(getPrioritizedShowcaseItemsSuccess, getPrioritizedShowcaseItemsReducer),
    createTypedHandler(createShowcaseItemSuccess, createShowcaseItemReducer),
    createTypedHandler(updateShowcaseItemSuccess, updateShowcaseItemReducer),
    createTypedHandler(updatePromoShowcaseSuccess, updatePromoShowcaseReducer),
    createTypedHandler(deleteShowcaseItemSuccess, deleteShowcaseItemReducer),
    createTypedHandler(addDefaultShowcaseItemSuccess, addDefaultShowcaseItemReducer),
    createTypedHandler(removeDefaultShowcaseItemSuccess, removeDefaultShowcaseItemReducer),
    createTypedHandler(addPromoShowcaseItemSuccess, addPromoShowcaseItemReducer),
    createTypedHandler(removePromoShowcaseItemSuccess, removePromoShowcaseItemReducer),
    createTypedHandler(switchDefaultShowcaseOrderSuccess, switchDefaultShowcaseOrderReducer),
    createTypedHandler(switchPromoShowcaseOrderSuccess, switchPromoShowcaseOrderReducer),
  ],
  defaultState,
);
