import { useEffect, useState } from 'react';
import { DispatchAsyncResult } from 'react-redux-dispatch-async/lib/dispatchAsync';
import styled from 'styled-components';
import _ from 'lodash';
import { Link, useHistory } from 'react-router-dom';
import { rSize } from 'core/util/ResponsiveSizeAdapter';
import { AppContainer } from 'common/ui/elements/AppContainer';
import { PageTitle } from '../../../common/ui/layouts/PageTitle';
import { emptyUser, User } from '../../domain/entities/User';
import { SignUpForm } from '../../../common/ui/elements/SignUpForm';
import { AppButton } from '../../../common/ui/elements/AppButton';
import { isValidEmail } from '../../../configuration/helpers/HelperFunctions';
import {
  ERROR_ENTER_VALID_EMAIL,
  ERROR_PASS_LENGTH,
  ERROR_PASSWORDS_NOT_MATCH,
  passwordMandatoryLength,
} from '../../../configuration/helpers/constants';
import { FormErrorList } from '../../../common/ui/elements/FormErrorList';
import { useLoaderContext } from '../../../common/configuration/loaderContextProvider';

interface SignUpProps {
  stateConnectedUser?: User;
  sendSignUpAsync(newUser: User): Promise<DispatchAsyncResult<void>>;
}
export const SignUpDumb = ({ stateConnectedUser, sendSignUpAsync }: SignUpProps) => {
  const history = useHistory();
  const [user, setUser] = useState<User>(emptyUser);
  const [showError, setShowError] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<string[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const loader = useLoaderContext();

  const rStyles = {
    global: {
      h4: {
        fontSize: rSize(24),
      },
      p: {
        fontSize: rSize(18),
      },
    },
    signUpText: {
      marginTop: rSize(10),
    },
  };

  useEffect(() => {
    if (stateConnectedUser) {
      history.replace('/profile');
    }
  }, [stateConnectedUser]);

  useEffect(() => {
    loader.setMessage(isSubmitting ? 'Chargement' : '');
  }, [isSubmitting]);

  useEffect(() => {
    validateForm();
  }, [user]);

  const validateForm = () => {
    const errors: string[] = [];
    if (!isValidEmail(user.email)) {
      errors.push(ERROR_ENTER_VALID_EMAIL);
    }
    if (_.isEmpty(user.password) || (user?.password && user?.password.length < passwordMandatoryLength)) {
      errors.push(ERROR_PASS_LENGTH);
    }
    if (user.password !== user.passwordRepeat) {
      errors.push(ERROR_PASSWORDS_NOT_MATCH);
    }
    setFormErrors(errors);
  };

  const sendSignUp = async () => {
    setShowError(true);
    if (formErrors.length) {
      return;
    }
    setIsSubmitting(true);
    try {
      const response: any = await sendSignUpAsync(user);
      if (response.success) {
        // Scenario A: account created successfully
        setUser(response.result);
        setShowError(false);
      } else {
        // Scenario B: account already exists
        setShowError(true);
        if (response?.error?.payload?.toString().includes('Email already exists')) {
          setFormErrors(['Ce compte existe déjà']);
        } else {
          setFormErrors(response.error.payload.toString());
        }
      }
    } catch (error) {
      // Handle other errors
      setShowError(true);
      setFormErrors(["Une erreur s'est produite lors de la tentative d'inscription"]);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <AppContainer>
      <Wrapper>
        <div className="page-content">
          <PageTitle pageTitle="Inscription" />
          <section className="content-inner shop-account">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6 col-md-6 mb-4">
                  <div className="login-area">
                    <h4 className="text-secondary" style={{ ...rStyles.global.h4 }}>
                      Inscription
                    </h4>
                    <p className="font-weight-600" style={{ ...rStyles.global.p }}>
                      Merci de remplir ce formulaire pour créer votre compte :
                    </p>
                    <br />
                    {showError && <FormErrorList errors={formErrors} fontSize={rStyles.global.p.fontSize} />}
                    <SignUpForm showError={showError} user={user} setUser={setUser} enterFunc={sendSignUp} />
                    <AppButton label="S'inscrire" disabled={isSubmitting} action={sendSignUp} />
                    <p className="sign-up-text" style={{ ...rStyles.signUpText, ...rStyles.global.p }}>
                      Vous avez déjà un compte?
                      <Link to="/login"> Se connecter ici !</Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Wrapper>
    </AppContainer>
  );
};

const Wrapper = styled.div`
  .sign-up-text {
    margin-top: 10px;
    font-size: 16px;
  }
  .text-buttons {
    gap: 30px;
    display: flex;
    margin-top: 30px;
    button {
      padding: 30px;
    }

    button,
    a {
      transition: all 300ms ease;
      font-size: 13px;
      width: 100%;
      background-color: var(--primary);
      border-color: var(--primary);
      color: white;
    }

    button.disabled {
      background-color: grey;
      border-color: grey;
    }
  }
`;
