import { useEffect } from 'react';

export const SuccessfulPaymentDumb = () => {
  useEffect(() => {
    window.close();
  }, []);

  return null; // Render nothing in the component
};
// can be costumized to show onFail and on abort UI
