import { Action } from 'redux';
import { connect } from 'react-redux';
import { dispatchAsync } from 'react-redux-dispatch-async';
import { sendEmailRequest } from 'generalContext/usecases/General.actions';
import { MailObj } from 'generalContext/domain/entities/MailObj';
import { EventsDumb } from './Events.dumb';
import { AppState } from '../../../common/configuration/app.states';

const mapState = (state: AppState) => ({});

const mapDispatch = (dispatch: (a: Action) => void) => ({
  sendEmailAsync: (data: MailObj) => dispatchAsync(dispatch, sendEmailRequest(data)),
});

export const EventsSmart = connect(mapState, mapDispatch)(EventsDumb);
