import styled from 'styled-components';
import { rSize } from 'core/util/ResponsiveSizeAdapter';
import { AppContainer } from 'common/ui/elements/AppContainer';

const iconBlog = [
  {
    title: 'Livraison Rapide',
    subTitle: 'Livraison fiable et rapide de vos commandes.',
    iconClass: 'flaticon-power',
  },
  {
    title: 'Paiement sécurisé',
    subTitle: 'Transactions sûres avec des protocoles sécurisés.',
    iconClass: 'flaticon-shield ',
  },
  {
    title: 'Meilleure qualité',
    subTitle: 'Sélection de produits haut de gamme pour votre satisfaction.',
    iconClass: 'flaticon-like',
  },
  {
    title: 'Garantie de retour',
    subTitle: ' Retour sous 30 jours pour remboursement ou échange.',
    iconClass: 'flaticon-star',
  },
];

export const StoreFlexingSection = () => {
  const rStyles = {
    wrapper: {
      padding: `${rSize(50)}px 0`,
    },
    container: {
      padding: `${rSize(50)}px 0`,
    },
    separator: {
      height: rSize(1),
    },
    row: {
      rowGap: rSize(60),
    },
  };
  return (
    <AppContainer>
      <Wrapper style={{ ...rStyles.wrapper }}>
        <div className="separator" style={{ ...rStyles.separator }} />
        <div className="container" style={{ ...rStyles.container }}>
          <div className="row" style={{ ...rStyles.row }}>
            {iconBlog.map((data, ind) => (
              <div className="col-lg-3 col-sm-6" key={ind}>
                <div className="icon-bx-wraper style-1 text-center">
                  <div className="icon-bx-sm m-b10">
                    <i className={`icon-cell ${data.iconClass}`} />
                  </div>
                  <div className="icon-content">
                    <h5 className="dz-title m-b10">{data.title}</h5>
                    <p>{data.subTitle}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="separator" style={{ ...rStyles.separator }} />
      </Wrapper>
    </AppContainer>
  );
};

const Wrapper = styled.section`
  padding: 40px 0;
  .container {
    padding: 50px 0;
  }
  .separator {
    height: 1px;
    background-color: lightgrey;
    width: 80%;
    margin: 0 auto;
  }
`;
