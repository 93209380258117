import { Dispatch, SetStateAction, useEffect } from 'react';
import styled from 'styled-components';
import { AppTextInput } from './AppTextInput';
import { User } from '../../../authContext/domain/entities/User';
import { isValidEmail } from '../../../configuration/helpers/HelperFunctions';
import { ERROR_PASS_LENGTH, passwordMandatoryLength } from '../../../configuration/helpers/constants';

interface SignInFormProps {
  showError?: boolean;
  user: User;
  setUser: Dispatch<SetStateAction<User>>;
  enterFunc: () => void;
}
export const SignInForm = ({ showError, user, setUser, enterFunc }: SignInFormProps) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        enterFunc();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [enterFunc]);

  return (
    <Wrapper>
      <div className="row">
        <AppTextInput
          showError={showError && !isValidEmail(user.email)}
          label="Email*"
          placeholder="email@exemple.com"
          currentVal={user?.email}
          onChange={(newVal: string) => {
            setUser((v) => ({ ...v, email: newVal }));
          }}
        />
        <AppTextInput
          showError={
            !!(showError && (!user.password || (user.password && user.password?.length < passwordMandatoryLength)))
          }
          isPass
          label="Mot de passe*"
          placeholder="Mot de passe"
          errorHint={ERROR_PASS_LENGTH}
          currentVal={user?.password}
          onChange={(newVal: string) => {
            setUser((v) => ({ ...v, password: newVal }));
          }}
        />
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div``;
