import styled from 'styled-components';
import { useEffect } from 'react';
import { Color } from 'assets/styles/constantStyles';
import { emptyNotificationMessage } from 'common/domain/entities/NotificationContextType';
import { useNotificationContext } from '../../configuration/notificationContextProvider';
import { notificationDuration } from '../../../configuration/helpers/constants';

export const Notifications = () => {
  const notifications = useNotificationContext();

  useEffect(() => {
    setTimeout(() => {
      if (notifications.message?.message !== '') {
        notifications.setMessage(emptyNotificationMessage);
      }
    }, notificationDuration);
  }, [notifications?.message?.message]);

  return (
    <Wrapper
      style={{
        right: notifications?.message?.message ? '3%' : '-400px',
        backgroundColor:
          notifications.message?.status === 'success'
            ? Color.SUCCESS
            : notifications.message?.status === 'error'
            ? Color.ERROR
            : Color.WARNING,
      }}
    >
      <p>{notifications.message?.message}</p>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  z-index: 999;
  padding: 10px;
  width: 300px;
  max-width: 100%;
  max-height: 50%;
  min-height: 50px;
  position: fixed;
  top: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  transition: all 500ms ease;
  p {
    font-size: 20px;
    text-align: center;
  }
`;
