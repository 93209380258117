import { Action } from 'redux';
import { connect } from 'react-redux';
import { SuccessfulPaymentDumb } from './SuccessfulPayment.dumb';
import { AppState } from '../../../common/configuration/app.states';

const mapState = (state: AppState) => ({});

const mapDispatch = (dispatch: (a: Action) => void) => ({});

export const SuccessfulPaymentSmart = connect(mapState, mapDispatch)(SuccessfulPaymentDumb);
