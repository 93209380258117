import { ShowcaseItem } from 'generalContext/domain/entities/ShowcaseItem';
import { AppState } from '../../common/configuration/app.states';

export const getStateCart = (state: AppState) => state.general.cart;
export const getStateOfflineCart = (state: AppState) => state.general.offlineCart;
export const getStateWishProducts = (state: AppState) => state.general.wishProducts;
export const getStateCategories = (state: AppState) => state.general.categories;
export const getStateSubCategories = (state: AppState) => state.general.subCategories;
export const getStateMagasins = (state: AppState) => state.general.magasins;
export const getDeliveryProducts = (state: AppState) => state.general.deliveryProducts;
export const getAdminPreferances = (state: AppState) => state.general.adminPreferances;
export const getStateCookiesAccepted = (state: AppState) => state.general.cookiesAccepted;
export const initializePaymentResponse = (state: AppState) => state.general.initializePaymentResponse;
export const assertPaymentResponse = (state: AppState) => state.general.assertPaymentResponse;
// Showcase ----------------
export const getShowcaseItems = (state: AppState): ShowcaseItem[] | undefined => state.general.showcaseItems;
export const getDefaultShowcaseItems = (state: AppState): ShowcaseItem[] | undefined =>
  state.general.defaultShowcaseItems;
export const getPromoShowcaseItems = (state: AppState): ShowcaseItem[] | undefined => state.general.promoShowcaseItems;
export const getPrioritizedShowcaseItems = (state: AppState): ShowcaseItem[] | undefined =>
  state.general.prioritizedShowcaseItems;
export const getShowcaseItemResponse = (state: AppState): ShowcaseItem | undefined =>
  state.general.showcaseItemResponse;
export const getAddDefaultShowcaseResponse = (state: AppState): ShowcaseItem | undefined =>
  state.general.addDefaultShowcaseResponse;
export const getAddPromoShowcaseResponse = (state: AppState): ShowcaseItem | undefined =>
  state.general.addPromoShowcaseResponse;
export const getRemoveDefaultShowcaseResponse = (state: AppState): boolean | undefined =>
  state.general.removeDefaultShowcaseResponse;
export const getRemovePromoShowcaseResponse = (state: AppState): boolean | undefined =>
  state.general.removePromoShowcaseResponse;
export const getSwitchDefaultShowcaseOrderResponse = (state: AppState): boolean | undefined =>
  state.general.switchDefaultShowcaseOrderResponse;
export const getSwitchPromoShowcaseOrderResponse = (state: AppState): boolean | undefined =>
  state.general.switchPromoShowcaseOrderResponse;
