import styled from 'styled-components';

interface FormErrorListProps {
  errors?: string[];
  fontSize?: number;
}
export const FormErrorList = ({ errors = [], fontSize = 18 }: FormErrorListProps) =>
  errors.length ? (
    <Wrapper>
      {errors.map((err, i) => (
        <div key={`form-error-number-${i}`} className="form-error">
          <p style={{ fontSize }}>{err}</p>
        </div>
      ))}
    </Wrapper>
  ) : null;

const Wrapper = styled.div`
  .form-error {
    color: var(--error);
    font-size: 20px;
    font-weight: 600;
    margin-top: 10px;
    text-align: center;
  }
`;
