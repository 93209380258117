import { useEffect, useState } from 'react';
import { DispatchAsyncResult } from 'react-redux-dispatch-async/lib/dispatchAsync';
import styled from 'styled-components';
import _ from 'lodash';
import { Link, useHistory } from 'react-router-dom';
import { rSize } from 'core/util/ResponsiveSizeAdapter';
import { PageTitle } from '../../../common/ui/layouts/PageTitle';
import { AppButton } from '../../../common/ui/elements/AppButton';
import { isValidEmail } from '../../../configuration/helpers/HelperFunctions';
import { FormErrorList } from '../../../common/ui/elements/FormErrorList';
import { ERROR_EMPTY_FIELDS, ERROR_ENTER_VALID_EMAIL } from '../../../configuration/helpers/constants';
import { useNotificationContext } from '../../../common/configuration/notificationContextProvider';
import { PasswordForgottenForm } from '../../../common/ui/elements/PasswordForgottenForm';
import { useLoaderContext } from '../../../common/configuration/loaderContextProvider';

interface PasswordForgottenProps {
  sendPasswordForgottenEmailAsync(email: string): Promise<DispatchAsyncResult<void>>;
}
export const PasswordForgottenDumb = ({ sendPasswordForgottenEmailAsync }: PasswordForgottenProps) => {
  const history = useHistory();
  const loader = useLoaderContext();
  const notifications = useNotificationContext();
  const [email, setEmail] = useState<string>('');
  const [showError, setShowError] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<string[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const rStyles = {
    global: {
      h4: {
        fontSize: rSize(24),
      },
      p: {
        fontSize: rSize(18),
      },
    },
    passForgottenText: {
      marginTop: rSize(10),
    },
  };

  useEffect(() => {
    validateForm();
  }, [email]);

  const validateForm = () => {
    const errors: string[] = [];
    if (_.isEmpty(email)) {
      errors.push(ERROR_EMPTY_FIELDS);
    } else if (!isValidEmail(email)) {
      errors.push(ERROR_ENTER_VALID_EMAIL);
    }
    setFormErrors(errors);
  };

  const sendPasswordForgottenEmail = async () => {
    setShowError(true);
    if (formErrors.length) {
      return;
    }
    setIsSubmitting(true);
    try {
      loader.setMessage('_');
      const response: any = await sendPasswordForgottenEmailAsync(email);
      if (response.success) {
        // success
        history.push('/login');
        notifications.setMessage({
          message: `Un lien de réinitialisation du mot de passe a été envoyé à votre adresse e-mail : ${email}`,
          status: 'success',
        });
        setFormErrors([]);
      } else {
        // fail
        setFormErrors([response.error.payload.message]);
      }
    } catch (error) {
      // Handle other errors
      notifications.setMessage({
        message: "Une erreur s'est produite lors de la tentative de réinitialisation de votre mot de passe",
        status: 'error',
      });
      console.error('passwordForgotten caught error', error);
    } finally {
      loader.setMessage('');
      setIsSubmitting(false);
    }
  };
  return (
    <Wrapper>
      <div className="page-content">
        <PageTitle pageTitle="Mot de passe oublié" />
        <section className="content-inner shop-account">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-6 mb-4">
                <div className="login-area">
                  <h4 className="text-secondary" style={{ ...rStyles.global.h4 }}>
                    Mot de passe oublié
                  </h4>
                  <p className="font-weight-600" style={{ ...rStyles.global.p }}>
                    Réinitialisation
                  </p>
                  <br />
                  {showError && <FormErrorList errors={formErrors} fontSize={rStyles.global.p.fontSize} />}
                  <PasswordForgottenForm
                    showError={showError}
                    email={email}
                    setEmail={setEmail}
                    enterFunc={sendPasswordForgottenEmail}
                  />
                  <AppButton label="Réinitialiser" disabled={isSubmitting} action={sendPasswordForgottenEmail} />
                  <p className="pass-forgotten-text" style={{ ...rStyles.passForgottenText, ...rStyles.global.p }}>
                    Vous avez déjà un compte?
                    <Link to="/login"> Se connecter ici !</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .pass-forgotten-text {
    margin-top: 10px;
    font-size: 16px;
  }
`;
