import React, { ReactNode } from 'react';
import styled from 'styled-components';

type ContainerProps = {
  widthInPx?: number;
};

const ContainerWrapper = styled.div<ContainerProps>`
  width: ${({ widthInPx }) => widthInPx || '92%'};
  margin: 0 auto;
`;

type ContainerComponentProps = ContainerProps & {
  children: ReactNode;
};

export const AppContainer: React.FC<ContainerComponentProps> = ({ widthInPx, children }) => (
  <ContainerWrapper widthInPx={widthInPx}>{children}</ContainerWrapper>
);
