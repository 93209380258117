import { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import { AppButton } from 'common/ui/elements/AppButton';
import { PAYPAL_CLIENT_ID } from '../../../../configuration';
import { Cart } from '../../../domain/entities/Cart';
import { appCurrency } from '../../../../configuration/helpers/constants';
import { useNotificationContext } from '../../../../common/configuration/notificationContextProvider';

interface PaymentButtonsProps {
  stateCart: Cart;
  deliveryCost: number;
  setOrderResponse: Dispatch<SetStateAction<any>>;
}

export const PaymentButtons = ({ stateCart, deliveryCost, setOrderResponse }: PaymentButtonsProps) => {
  const notifications = useNotificationContext();
  return (
    <Wrapper className="widget">
      <AppButton
        label="Terminer la commande"
        action={() =>
          setOrderResponse({ id: Math.floor(100000 + Math.random() * 900000).toString(), status: 'COMPLETED' })
        }
      />
    </Wrapper>
  );
  return (
    <Wrapper className="widget">
      <h4 className="widget-title">Mode de paiement</h4>
      <div className="paypal-fay-container">
        <PayPalScriptProvider options={{ 'client-id': PAYPAL_CLIENT_ID, currency: appCurrency.name, locale: 'fr_FR' }}>
          <PayPalButtons
            fundingSource="card" // to remove paypal, leave only card
            // style={{ color: 'silver', height: 48, tagline: false }}
            createOrder={(data, actions) =>
              actions.order.create({
                purchase_units: [
                  {
                    amount: {
                      value: (stateCart.total + deliveryCost).toFixed(2).toString(), // Set the order amount
                    },
                  },
                ],
              })
            }
            onApprove={async (data, actions) => {
              const order = await actions?.order?.capture();
              setOrderResponse(order);
            }}
            onError={(err) => {
              // Handle the error that occurred during the payment process
              notifications.setMessage({
                message: "Une erreur s'est produite lors du paiement, veuillez réessayer plus tard.",
                status: 'error',
              });
              console.error('An error occurred during payment', err);
            }}
          />
        </PayPalScriptProvider>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .paypal-fay-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    * {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }
  iframe {
    .paypal-buttons {
      width: 500px !important;
      max-width: 100% !important;
    }
  }
`;
