import basicInfo from '../env.json';

export const ENV: `dev` | `prod` = basicInfo.env as any;

export const BASE_URL = basicInfo.base_url;
export const API_KEY = basicInfo.api_key;

export const PAYPAL_CLIENT_ID = basicInfo.paypal_client_id;
export const KEY_OFFLINE_CART_ITEMS = 'offline-cart-items';

export const ENDPOINTS = {
  // auth
  signUp: `${BASE_URL}auth/signUp`,
  signIn: `${BASE_URL}auth/signIn`,
  resetPassword: `${BASE_URL}auth/resetPassword`,
  signOut: `${BASE_URL}auth/signOut`,
  renewAccess: `${BASE_URL}auth/renewAccess`,

  sendPasswordForgottenEmail: `${BASE_URL}auth/sendPasswordForgottenEmail`,

  // user
  getUserData: `${BASE_URL}user/getUserData`,
  updateUserData: `${BASE_URL}user/updateUserData`,
  deleteUser: `${BASE_URL}user/deleteUser`,
  updatePassword: `${BASE_URL}user/updatePassword`,

  // products
  getDeliveryProducts: `${BASE_URL}product/getDeliveryProducts`,
  getProductsList: `${BASE_URL}product/getProductsList`,
  getProductsByCategoryNumber: `${BASE_URL}product/getProductsByCategoryNumber`,
  getProductsBySubCategoryNumber: `${BASE_URL}product/getProductsBySubCategoryNumber`,
  getProductsBySearchQuery: `${BASE_URL}product/getProductsBySearchQuery`,

  // categories
  getCategoryList: `${BASE_URL}category/getCategoryList`,
  getSubCategoryList: `${BASE_URL}subCategory/getSubCategoryList`,
  getSubCategoriesByCategoryNumber: `${BASE_URL}subCategory/getSubCategoriesByCategoryNumber`,
  // settings
  userSettings: `${BASE_URL}settings`,
  getAdminPreferances: `${BASE_URL}adminpreferences/loadAdminPreferances`,
  updateAdminPreferances: `${BASE_URL}adminpreferences/updateAdminPreferences`,
  // cart
  getCartProductList: `${BASE_URL}cart/getCartProductList`,
  saveCartProduct: `${BASE_URL}cart/saveCartProduct`,
  deleteCartProduct: `${BASE_URL}cart/deleteCartProduct`,
  // wish
  getWishProductList: `${BASE_URL}wish/getWishProductList`,
  saveWishProduct: `${BASE_URL}wish/saveWishProduct`,
  deleteWishProduct: `${BASE_URL}wish/deleteWishProduct`,
  // reviews
  getProductReviews: `${BASE_URL}auth/getProductReviews`,
  getAdminReviews: `${BASE_URL}review/getAdminReviews`,
  getUserReviews: `${BASE_URL}review/getUserReviews`,
  insertUserReview: `${BASE_URL}review/insertUserReview`,
  updateReviewStatus: `${BASE_URL}review/updateReviewStatus`,
  deleteReview: `${BASE_URL}review/deleteReview`,
  // events
  event: `${BASE_URL}event`,
  // order
  getOrderHistory: `${BASE_URL}order/getOrderHistory`,
  insertOrder: `${BASE_URL}order/insertOrder`,
  // store
  getMagasinList: `${BASE_URL}magasin/getMagasinList`,
  getMagasinById: `${BASE_URL}magasin/getMagasinById`,
  // send email
  sendEmail: `${BASE_URL}auth/sendContactForm`,
  sendNewsLettersSignUp: `${BASE_URL}auth/sendNewsLettersSignUp`,
  // payment
  initializePayment: `${BASE_URL}payment/initialize-payment`,
  assertPayment: `${BASE_URL}payment/assert-payment`,
  // showcase
  getShowcaseItems: `${BASE_URL}showcase/getShowcaseItems`,
  getDefaultShowcaseItems: `${BASE_URL}showcase/getDefaultShowcaseItems`,
  getPromoShowcaseItems: `${BASE_URL}showcase/getPromoShowcaseItems`,
  getPrioritizedShowcaseItems: `${BASE_URL}showcase/getPrioritizedShowcaseItems`,
  createShowcaseItem: `${BASE_URL}showcase/createShowcaseItem`,
  updateShowcaseItem: `${BASE_URL}showcase/updateShowcaseItem`,
  updatePromoShowcase: `${BASE_URL}showcase/updatePromoShowcase`,
  deleteShowcaseItem: `${BASE_URL}showcase/deleteShowcaseItem`,
  addDefaultShowcaseItem: `${BASE_URL}showcase/addDefaultShowcaseItem`,
  removeDefaultShowcaseItem: `${BASE_URL}showcase/removeDefaultShowcaseItem`,
  addPromoShowcaseItem: `${BASE_URL}showcase/addPromoShowcaseItem`,
  removePromoShowcaseItem: `${BASE_URL}showcase/removePromoShowcaseItem`,
  switchDefaultShowcaseOrder: `${BASE_URL}showcase/switchDefaultShowcaseOrder`,
  switchPromoShowcaseOrder: `${BASE_URL}showcase/switchPromoShowcaseOrder`,
};
