import { ProductState } from 'generalContext/domain/entities/ProductState';
import { Magasin, MagasinState } from 'generalContext/domain/entities/Magasin';
import { MailObj } from 'generalContext/domain/entities/MailObj';
import { AdminPreferances } from 'generalContext/domain/entities/AdminPreferances';
import { InitializePaymentProps } from 'generalContext/domain/entities/Payment';
import { ShowcaseItem } from 'generalContext/domain/entities/ShowcaseItem';
import { ENDPOINTS } from '../../../configuration';
import { fetchWithApi, fetchWithToken } from '../../../configuration/helpers/fetch.helper';
import { Category } from '../../domain/entities/Category';
import { GetProductsProps, hardCodedEmptyProduct, Product } from '../../domain/entities/Product';
import { hardCodedEmptyReview, Review } from '../../domain/entities/Review';
import { SubCategory } from '../../domain/entities/SubCategory';
import { GeneralInteractor } from '../../domain/gateways/General.interactor';
import { magasinIdCurrent, productsPerPage } from '../../../configuration/helpers/constants';
import { hardCodedEmptyOrder, Order } from '../../domain/entities/Order';

export class RealGeneralInteractor implements GeneralInteractor {
  async getAdminPreferances(): Promise<AdminPreferances> {
    const url = `${ENDPOINTS.getAdminPreferances}`;
    const response = await fetchWithToken(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Accept-Encoding': 'gzip',
        'Content-Type': 'application/json',
      },
    });
    const res = await response.json();
    if (response.ok) {
      return res.data;
    }

    if (response.status === 401 && res.error === 'Expired Session') {
      console.log('Session is expired please try signing again.');
    } else if (res.error) {
      const error = new Error(res.message);
      error.cause = res.data;
      throw error;
    }
    return {};
  }

  async updateAdminPreferances(adminPreferences: AdminPreferances): Promise<AdminPreferances> {
    const url = `${ENDPOINTS.updateAdminPreferances}`;
    const response = await fetchWithToken(url, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Accept-Encoding': 'gzip',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(adminPreferences),
    });
    const res = await response.json();
    if (response.ok) {
      return res.data;
    }

    if (response.status === 401 && res.error === 'Expired Session') {
      console.log('Session is expired please try signing again.');
    } else if (res.error) {
      const error = new Error(res.message);
      error.cause = res.data;
      throw error;
    }
    return {};
  }

  async getProducts(props?: GetProductsProps): Promise<ProductState> {
    const url = `${ENDPOINTS.getProductsList}`;
    const objectToSend: GetProductsProps = {
      Art_Ean13: props?.Art_Ean13,
      Art_Auteur: props?.Art_Auteur,
      Art_Editeur: props?.Art_Editeur,
      query: props?.query,
      categoryNumber: props?.categoryNumber,
      subCategoryNumber: props?.subCategoryNumber,
      skip: props?.skip ?? 0,
      take: props?.take ?? productsPerPage,
    };
    const response = await fetchWithApi(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Accept-Encoding': 'gzip',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(objectToSend),
    });
    const res = await response.json();
    if (!response.ok || res.error) {
      const error = new Error(res.message);
      error.cause = res.data;
      throw error;
    }
    return res.data;
  }

  async getDeliveryProducts(): Promise<Product[]> {
    const url = `${ENDPOINTS.getDeliveryProducts}`;
    const response = await fetchWithApi(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Accept-Encoding': 'gzip',
        'Content-Type': 'application/json',
      },
    });
    const res = await response.json();
    if (!response.ok || res.error) {
      const error = new Error(res.message);
      error.cause = res.data;
      throw error;
    }
    return res.data;
  }

  async getProductsBySubCategoryNumber(SubCategoryNumber: number): Promise<ProductState> {
    const url = `${ENDPOINTS.getProductsBySubCategoryNumber}`;
    const response = await fetchWithApi(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Accept-Encoding': 'gzip',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ SubCategoryNumber }),
    });
    const res = await response.json();
    if (!response.ok || res.error) {
      const error = new Error(res.message);
      error.cause = res.data;
      throw error;
    }
    return res.data;
  }

  async getProductsByCategoryNumber(CategoryNumber: number): Promise<Product[]> {
    const url = `${ENDPOINTS.getProductsByCategoryNumber}`;
    const response = await fetchWithApi(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Accept-Encoding': 'gzip',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ CategoryNumber }),
    });
    const res = await response.json();
    if (!response.ok || res.error) {
      const error = new Error(res.message);
      error.cause = res.data;
      throw error;
    }
    return res.data;
  }

  async getCategories(): Promise<Category[]> {
    const url = `${ENDPOINTS.getCategoryList}`;
    const response = await fetchWithApi(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Accept-Encoding': 'gzip',
        'Content-Type': 'application/json',
      },
    });
    const res = await response.json();
    if (!response.ok || res.error) {
      const error = new Error(res.message);
      error.cause = res.data;
      throw error;
    }
    return res.data;
    // .filter((cat: Category) => cat.categoryNumber !== OTHER_CATEGORY_NUMBER);
  }

  async getSubCategories(): Promise<SubCategory[]> {
    const url = `${ENDPOINTS.getSubCategoryList}`;
    const response = await fetchWithApi(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Accept-Encoding': 'gzip',
        'Content-Type': 'application/json',
      },
    });
    const res = await response.json();
    if (!response.ok || res.error) {
      const error = new Error(res.message);
      error.cause = res.data;
      throw error;
    }
    return res.data;
    // .filter(
    //   (subCat: SubCategory) =>
    //     subCat.subCategoryNumber !== RECOMMENDED_SUBCATEGORY_NUMBER &&
    //     subCat.subCategoryNumber !== BEST_SELLERS_SUBCATEGORY_NUMBER &&
    //     subCat.subCategoryNumber !== HEART_SUBCATEGORY_NUMBER &&
    //     subCat.subCategoryNumber !== PROMO_SUBCATEGORY_NUMBER,
    // );
  }

  async getProductReviews(productEan: string): Promise<Review[]> {
    const url = `${ENDPOINTS.getProductReviews}?Art_Ean13=${productEan}`;
    const response = await fetchWithApi(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Accept-Encoding': 'gzip',
        'Content-Type': 'application/json',
      },
    });
    const res = await response.json();
    if (!response.ok || res.error) {
      const error = new Error(res.message);
      error.cause = res.data;
      throw error;
    }
    return res.data;
  }

  async getAdminReviews(): Promise<Review[]> {
    const url = `${ENDPOINTS.getAdminReviews}`;
    const response = await fetchWithToken(url);
    const res = await response.json();
    if (response.ok) {
      return res.data;
    }

    if (response.status === 401 && res.error === 'Expired Session') {
      console.log('Session is expired please try signing again.');
    } else if (res.error) {
      const error = new Error(res.message);
      error.cause = res.data;
      throw error;
    }
    return [];
  }

  async approveReview(review: Review): Promise<Review> {
    const url = `${ENDPOINTS.updateReviewStatus}`;
    const objectToSend = {
      ...review,
      public: true,
    };
    const response = await fetchWithToken(url, {
      method: 'PUT',
      body: JSON.stringify(objectToSend),
    });
    const res = await response.json();
    if (response.ok) {
      return res.data;
    }

    if (response.status === 401 && res.error === 'Expired Session') {
      console.log('Session is expired please try signing again.');
    } else if (res.error) {
      const error = new Error(res.message);
      error.cause = res.data;
      throw error;
    }
    return hardCodedEmptyReview;
  }

  async insertProductReview(review: Review): Promise<Review> {
    const url = `${ENDPOINTS.insertUserReview}`;
    const objectToSend = {
      text: review.text,
      rating: review.rating,
      Art_Ean13: review.productEan,
    };
    const response = await fetchWithToken(url, {
      method: 'POST',
      body: JSON.stringify(objectToSend),
    });
    const res = await response.json();
    if (response.ok) {
      return res.data;
    }

    if (response.status === 401 && res.error === 'Expired Session') {
      console.log('Session is expired please try signing again.');
    } else if (res.error) {
      const error = new Error(res.message);
      error.cause = res.data;
      throw error;
    }
    return hardCodedEmptyReview;
  }

  async deleteProductReview(review: Review): Promise<Review> {
    const url = `${ENDPOINTS.deleteReview}`;
    const response = await fetchWithToken(url, {
      method: 'DELETE',
      body: JSON.stringify({ id: review.id }),
    });
    const res = await response.json();
    if (response.ok) {
      return res.data;
    }

    if (response.status === 401 && res.error === 'Expired Session') {
      console.log('Session is expired please try signing again.');
    } else if (res.error) {
      const error = new Error(res.message);
      error.cause = res.data;
      throw error;
    }
    return hardCodedEmptyReview;
  }

  // cart ---------------------------------------------------------------
  async getCartProducts(): Promise<Product[]> {
    const url = `${ENDPOINTS.getCartProductList}`;
    const response = await fetchWithToken(url);
    const res = await response.json();
    if (!response.ok || res.error) {
      const error = new Error(res.message);
      error.cause = res.data;
      throw error;
    }
    if (response.ok) {
      return res.data.map((item: any) => ({ ...item.product, Art_Quantity: item.Art_Quantity }));
    }

    if (response.status === 401 && res.error === 'Expired Session') {
      console.log('Session is expired please try signing again.');
    } else if (res.error) {
      const error = new Error(res.message);
      error.cause = res.data;
      throw error;
    }
    return [];
  }

  async saveCartProduct(product: Product): Promise<Product> {
    const url = `${ENDPOINTS.saveCartProduct}`;
    const response = await fetchWithToken(url, {
      method: 'POST',
      body: JSON.stringify({ Art_Ean13: product.Art_Ean13, Art_Quantity: product.Art_Quantity ?? 1 }),
    });
    const res = await response.json();
    if (response.ok) {
      return res.data;
    }

    if (response.status === 401 && res.error === 'Expired Session') {
      console.log('Session is expired please try signing again.');
    } else if (res.error) {
      const error = new Error(res.message);
      error.cause = res.data;
      throw error;
    }
    return hardCodedEmptyProduct;
  }

  async deleteCartProduct(Art_Ean13: string): Promise<boolean> {
    const url = `${ENDPOINTS.deleteCartProduct}`;
    const response = await fetchWithToken(url, {
      method: 'DELETE',
      body: JSON.stringify({ Art_Ean13 }),
    });
    const res = await response.json();
    if (response.ok) {
      return res.data;
    }

    if (response.status === 401 && res.error === 'Expired Session') {
      console.log('Session is expired please try signing again.');
    } else if (res.error) {
      const error = new Error(res.message);
      error.cause = res.data;
      throw error;
    }
    return false;
  }

  // wish ---------------------------------------------------------------
  async getWishProducts(): Promise<Product[]> {
    const url = `${ENDPOINTS.getWishProductList}`;
    const response = await fetchWithToken(url);
    const res = await response.json();
    if (response.ok) {
      return res.data.map((item: any) => ({ ...item.product, Art_Quantity: item.Art_Quantity }));
    }
    if (response.status === 401 && res.error === 'Expired Session') {
      console.log('Session is expired please try signing again.');
    } else if (res.error) {
      const error = new Error(res.message);
      error.cause = res.data;
      throw error;
    }
    return [];
  }

  async saveWishProduct(newProduct: Product): Promise<Product> {
    const url = `${ENDPOINTS.saveWishProduct}`;
    const response = await fetchWithToken(url, {
      method: 'POST',
      body: JSON.stringify({ Art_Ean13: newProduct.Art_Ean13, Art_Quantity: newProduct.Art_Quantity ?? 1 }),
    });
    const res = await response.json();
    if (response.ok) {
      return res.data;
    }

    if (response.status === 401 && res.error === 'Expired Session') {
      console.log('Session is expired please try signing again.');
    } else if (res.error) {
      const error = new Error(res.message);
      error.cause = res.data;
      throw error;
    }
    return hardCodedEmptyProduct;
  }

  async deleteWishProduct(Art_Ean13: string): Promise<boolean> {
    const url = `${ENDPOINTS.deleteWishProduct}`;
    const response = await fetchWithToken(url, {
      method: 'DELETE',
      body: JSON.stringify({ Art_Ean13 }),
    });
    const res = await response.json();
    if (response.ok) {
      return res.data;
    }

    if (response.status === 401 && res.error === 'Expired Session') {
      console.log('Session is expired please try signing again.');
    } else if (res.error) {
      const error = new Error(res.message);
      error.cause = res.data;
      throw error;
    }
    return false;
  }

  async getOrderHistory(): Promise<Order[]> {
    const url = `${ENDPOINTS.getOrderHistory}`;
    const response = await fetchWithToken(url);
    const res = await response.json();
    if (response.ok) {
      return res.data;
    }

    if (response.status === 401 && res.error === 'Expired Session') {
      console.log('Session is expired please try signing again.');
    } else if (res.error) {
      const error = new Error(res.message);
      error.cause = res.data;
      throw error;
    }
    return [];
  }

  async insertOrder(order: Order): Promise<Order> {
    const url = `${ENDPOINTS.insertOrder}`;
    const objectToSend = { ...order };
    const response = await fetchWithToken(url, {
      method: 'POST',
      body: JSON.stringify(objectToSend),
    });
    const res = await response.json();
    if (response.ok) {
      return res.data;
    }

    if (response.status === 401 && res.error === 'Expired Session') {
      console.log('Session is expired please try signing again.');
    } else if (res.error) {
      const error = new Error(res.message);
      error.cause = res.data;
      throw error;
    }
    return hardCodedEmptyOrder;
  }

  async getMagasinList(): Promise<MagasinState> {
    const url = `${ENDPOINTS.getMagasinList}`;
    const response = await fetchWithApi(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Accept-Encoding': 'gzip',
        'Content-Type': 'application/json',
      },
    });
    const res = await response.json();
    if (!response.ok || res.error) {
      const error = new Error(res.message);
      error.cause = res.data;
      throw error;
    }
    const all: Magasin[] = res.data.magasins;
    const toReturn: MagasinState = {
      all,
      current: all && !!all.length ? all.find((mag) => mag.magasinId === magasinIdCurrent) : undefined,
    };
    return toReturn;
  }

  async sendEmail(data: MailObj): Promise<boolean> {
    const objectToSend = { ...data };
    const url = `${ENDPOINTS.sendEmail}`;
    const response = await fetchWithApi(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Accept-Encoding': 'gzip',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(objectToSend),
    });

    const res = await response.json();
    if (!response.ok || res.error) {
      const error = new Error(res.message);
      error.cause = res.data;
      throw error;
    }
    return res.data;
  }

  async sendNewsLettersSignUp(email: string): Promise<boolean> {
    const objectToSend = { email };
    const url = `${ENDPOINTS.sendNewsLettersSignUp}`;
    const response = await fetchWithApi(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Accept-Encoding': 'gzip',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(objectToSend),
    });

    const res = await response.json();
    if (!response.ok || res.error) {
      const error = new Error(res.message);
      error.cause = res.data;
      throw error;
    }
    return res.data;
  }

  // payment ---------------------------------------------------------------
  async initializePayment(data: InitializePaymentProps): Promise<any> {
    const objectToSend = { ...data };
    const url = `${ENDPOINTS.initializePayment}`;
    const response = await fetchWithToken(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Accept-Encoding': 'gzip',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(objectToSend),
    });

    const res = await response.json();
    if (response.ok) {
      return res.data;
    }

    if (response.status === 401 && res.error === 'Expired Session') {
      console.log('Session is expired please try signing again.');
    } else if (res.error) {
      const error = new Error(res.message);
      error.cause = res.data;
      throw error;
    }
    return {};
  }

  async assertPayment(token: string): Promise<any> {
    const url = `${ENDPOINTS.assertPayment}`;
    const objectToSend = {
      token,
    };
    const response = await fetchWithToken(url, {
      method: 'POST',
      body: JSON.stringify(objectToSend),
    });
    const res = await response.json();
    if (response.ok) {
      return res.data;
    }

    if (response.status === 401 && res.error === 'Expired Session') {
      console.log('Session is expired please try signing again.');
    } else if (res.error) {
      const error = new Error(res.message);
      error.cause = res.data;
      throw error;
    }
    return {};
  }

  // showcase ----------------------------------------------
  async getShowcaseItems(): Promise<ShowcaseItem[]> {
    const url = `${ENDPOINTS.getShowcaseItems}`;
    const response = await fetchWithToken(url);
    const res = await response.json();
    if (response.ok) {
      return res.data;
    }

    if (response.status === 401 && res.error === 'Expired Session') {
      console.log('Session is expired please try signing again.');
    } else if (res.error) {
      const error = new Error(res.message);
      error.cause = res.data;
      throw error;
    }

    return [];
  }

  async getDefaultShowcaseItems(): Promise<ShowcaseItem[]> {
    const url = `${ENDPOINTS.getDefaultShowcaseItems}`;
    const response = await fetchWithToken(url);
    const res = await response.json();
    if (response.ok) {
      return res.data;
    }

    if (response.status === 401 && res.error === 'Expired Session') {
      console.log('Session is expired please try signing again.');
    } else if (res.error) {
      const error = new Error(res.message);
      error.cause = res.data;
      throw error;
    }

    return [];
  }

  async getPromoShowcaseItems(): Promise<ShowcaseItem[]> {
    const url = `${ENDPOINTS.getPromoShowcaseItems}`;
    const response = await fetchWithToken(url);
    const res = await response.json();
    if (response.ok) {
      return res.data;
    }

    if (response.status === 401 && res.error === 'Expired Session') {
      console.log('Session is expired please try signing again.');
    } else if (res.error) {
      const error = new Error(res.message);
      error.cause = res.data;
      throw error;
    }

    return [];
  }

  async getPrioritizedShowcaseItems(): Promise<ShowcaseItem[]> {
    const url = `${ENDPOINTS.getPrioritizedShowcaseItems}`;
    const response = await fetchWithApi(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Accept-Encoding': 'gzip',
        'Content-Type': 'application/json',
      },
    });
    const res = await response.json();
    if (!response.ok || res.error) {
      const error = new Error(res.message);
      error.cause = res.data;
      throw error;
    }
    return res.data;
  }

  async createShowcaseItem(item: ShowcaseItem): Promise<ShowcaseItem> {
    const url = `${ENDPOINTS.createShowcaseItem}`;
    const response = await fetchWithToken(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Accept-Encoding': 'gzip',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(item),
    });
    const res = await response.json();
    if (response.ok) {
      return res.data;
    }

    if (response.status === 401 && res.error === 'Expired Session') {
      console.log('Session is expired please try signing again.');
    } else if (res.error) {
      const error = new Error(res.message);
      error.cause = res.data;
      throw error;
    }
    return {};
  }

  async updateShowcaseItem(item: ShowcaseItem): Promise<ShowcaseItem> {
    const url = `${ENDPOINTS.updateShowcaseItem}`;
    const response = await fetchWithToken(url, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Accept-Encoding': 'gzip',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(item),
    });
    const res = await response.json();
    if (response.ok) {
      return res.data;
    }

    if (response.status === 401 && res.error === 'Expired Session') {
      console.log('Session is expired please try signing again.');
    } else if (res.error) {
      const error = new Error(res.message);
      error.cause = res.data;
      throw error;
    }
    return {};
  }

  async updatePromoShowcase(itemId: string, startDate?: Date, endDate?: Date): Promise<ShowcaseItem> {
    const url = `${ENDPOINTS.updatePromoShowcase}`;
    const body: { itemId: string; startDate?: string; endDate?: string } = { itemId };

    if (startDate) {
      body.startDate = startDate.toISOString();
    }

    if (endDate) {
      body.endDate = endDate.toISOString();
    }
    const response = await fetchWithToken(url, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Accept-Encoding': 'gzip',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    const res = await response.json();
    if (response.ok) {
      return res.data;
    }

    if (response.status === 401 && res.error === 'Expired Session') {
      console.log('Session is expired please try signing again.');
    } else if (res.error) {
      const error = new Error(res.message);
      error.cause = res.data;
      throw error;
    }
    return {};
  }

  async deleteShowcaseItem(id: string): Promise<boolean> {
    const url = `${ENDPOINTS.deleteShowcaseItem}`;
    const response = await fetchWithToken(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id }),
    });
    const res = await response.json();
    if (response.ok) {
      return true;
    }

    if (response.status === 401 && res.error === 'Expired Session') {
      console.log('Session is expired please try signing again.');
    } else if (res.error) {
      const error = new Error(res.message);
      error.cause = res.data;
      throw error;
    }
    return false;
  }

  async addDefaultShowcaseItem(itemId: string): Promise<ShowcaseItem> {
    const url = `${ENDPOINTS.addDefaultShowcaseItem}`;
    const response = await fetchWithToken(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Accept-Encoding': 'gzip',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ itemId }),
    });
    const res = await response.json();
    if (response.ok) {
      return res.data;
    }

    if (response.status === 401 && res.error === 'Expired Session') {
      console.log('Session is expired please try signing again.');
    } else if (res.error) {
      const error = new Error(res.message);
      error.cause = res.data;
      throw error;
    }
    return {};
  }

  async removeDefaultShowcaseItem(itemId: string): Promise<boolean> {
    const url = `${ENDPOINTS.removeDefaultShowcaseItem}`;
    const response = await fetchWithToken(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ itemId }),
    });
    const res = await response.json();
    if (response.ok) {
      return true;
    }

    if (response.status === 401 && res.error === 'Expired Session') {
      console.log('Session is expired please try signing again.');
    } else if (res.error) {
      const error = new Error(res.message);
      error.cause = res.data;
      throw error;
    }
    return false;
  }

  async addPromoShowcaseItem(itemId: string, startDate?: Date, endDate?: Date): Promise<ShowcaseItem> {
    const url = `${ENDPOINTS.addPromoShowcaseItem}`;

    const body: { itemId: string; startDate?: string; endDate?: string } = { itemId };

    if (startDate) {
      body.startDate = startDate.toISOString();
    }

    if (endDate) {
      body.endDate = endDate.toISOString();
    }

    const response = await fetchWithToken(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Accept-Encoding': 'gzip',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    const res = await response.json();
    if (response.ok) {
      return res.data;
    }

    if (response.status === 401 && res.error === 'Expired Session') {
      console.log('Session is expired please try signing again.');
    } else if (res.error) {
      const error = new Error(res.message);
      error.cause = res.data;
      throw error;
    }
    return {};
  }

  async removePromoShowcaseItem(itemId: string): Promise<boolean> {
    const url = `${ENDPOINTS.removePromoShowcaseItem}`;
    const response = await fetchWithToken(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ itemId }),
    });
    const res = await response.json();
    if (response.ok) {
      return true;
    }

    if (response.status === 401 && res.error === 'Expired Session') {
      console.log('Session is expired please try signing again.');
    } else if (res.error) {
      const error = new Error(res.message);
      error.cause = res.data;
      throw error;
    }
    return false;
  }

  async switchDefaultShowcaseOrder(itemId: string, direction: 'up' | 'down'): Promise<boolean> {
    const url = `${ENDPOINTS.switchDefaultShowcaseOrder}`;
    const response = await fetchWithToken(url, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Accept-Encoding': 'gzip',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ itemId, direction }), // Include both id and direction in the body
    });
    const res = await response.json();
    if (response.ok) {
      return true;
    }

    if (response.status === 401 && res.error === 'Expired Session') {
      console.log('Session is expired please try signing again.');
    } else if (res.error) {
      const error = new Error(res.message);
      error.cause = res.data;
      throw error;
    }
    return false;
  }

  async switchPromoShowcaseOrder(itemId: string, direction: 'up' | 'down'): Promise<boolean> {
    const url = `${ENDPOINTS.switchPromoShowcaseOrder}`;
    const response = await fetchWithToken(url, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Accept-Encoding': 'gzip',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ itemId, direction }),
    });
    const res = await response.json();
    if (response.ok) {
      return true;
    }

    if (response.status === 401 && res.error === 'Expired Session') {
      console.log('Session is expired please try signing again.');
    } else if (res.error) {
      const error = new Error(res.message);
      error.cause = res.data;
      throw error;
    }
    return false;
  }
}
