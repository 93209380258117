import { Dispatch, SetStateAction, useEffect } from 'react';
import styled from 'styled-components';
import { AppTextInput } from './AppTextInput';
import { ERROR_PASS_LENGTH } from '../../../configuration/helpers/constants';
import { ResetPasswordProps } from '../../domain/entities/ResetPasswordProps';

interface ResetPasswordFormProps {
  showError?: boolean;
  resetPassProps: ResetPasswordProps;
  setResetPassProps: Dispatch<SetStateAction<ResetPasswordProps>>;
  enterFunc: () => void;
}
export const ResetPasswordForm = ({
  showError,
  resetPassProps,
  setResetPassProps,
  enterFunc,
}: ResetPasswordFormProps) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        enterFunc();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [enterFunc]);

  return (
    <Wrapper>
      <div className="row">
        <AppTextInput
          showError={
            !!(
              showError &&
              (!resetPassProps.newPassword || (resetPassProps.newPassword && resetPassProps.newPassword?.length < 6))
            )
          }
          isPass
          label="Nouveau mot de passe*"
          placeholder="Nouveau mot de passe"
          errorHint={ERROR_PASS_LENGTH}
          currentVal={resetPassProps.newPassword}
          onChange={(newVal: string) => {
            setResetPassProps((v) => ({ ...v, newPassword: newVal }));
          }}
        />
        <AppTextInput
          showError={showError && resetPassProps.newPasswordConfirm !== resetPassProps.newPassword}
          isPass
          label="Confirmation mot de passe*"
          placeholder="Confirmation mot de passe"
          errorHint="les mots de passe ne correspondent pas"
          currentVal={resetPassProps.newPasswordConfirm}
          onChange={(newVal: string) => {
            setResetPassProps((v) => ({ ...v, newPasswordConfirm: newVal }));
          }}
        />
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div``;
