import styled from 'styled-components';
import { rSize } from 'core/util/ResponsiveSizeAdapter';
import { Cart } from '../../../domain/entities/Cart';
import { priceDisplay } from '../../../../configuration/helpers/HelperFunctions';
import { appCurrency, TAKE_OUT_DELIVERY_QUESTION } from '../../../../configuration/helpers/constants';

interface OrderDetailsProps {
  stateCart: Cart | undefined;
  deliveryLabel: string;
  deliveryCost: number;
  deliveryTime?: string;
}

export const OrderDetails = ({ deliveryLabel, stateCart, deliveryTime, deliveryCost }: OrderDetailsProps) => {
  const rStyles = {
    global: {
      h4: {
        fontSize: rSize(24),
      },
      p: {
        fontSize: rSize(18),
      },
    },
  };

  return (
    <Wrapper className="widget">
      <h4 style={{ ...rStyles.global.h4 }} className="widget-title">
        Total de la commande
      </h4>
      <table className="table-bordered check-tbl">
        <tbody>
          <tr>
            <td style={{ ...rStyles.global.p }}>Sous-total</td>
            <td className="product-price" style={{ ...rStyles.global.p }}>
              {priceDisplay(stateCart?.total)}
            </td>
          </tr>
          <tr>
            <td style={{ ...rStyles.global.p }}>Livraison</td>
            <td style={{ ...rStyles.global.p }}>
              {/* <p>
              {`${deliveryLabel}`} : {`${deliveryCost}${appCurrency.symbol}`}
            </p>
            <p>{deliveryTime}</p> */}
              {TAKE_OUT_DELIVERY_QUESTION} (0 {appCurrency.symbol})
            </td>
          </tr>
          <tr>
            <td style={{ ...rStyles.global.p }}>Total</td>
            <td className="product-price-total" style={{ ...rStyles.global.p }}>
              {priceDisplay((stateCart?.total ?? 0) + deliveryCost)}
            </td>
          </tr>
        </tbody>
      </table>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  td {
    width: 50%;
  }
`;
