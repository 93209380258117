/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import styled from 'styled-components';
import { BiCookie } from 'react-icons/bi';
import { useState } from 'react';
import cookiesImg from 'assets/images/cookies-hello.png';
import { rSize } from 'core/util/ResponsiveSizeAdapter';

interface AppCookiesBannerProps {
  stateCookiesAccepted: boolean | undefined;
  onAccept?: () => void;
}
// we not saving the acceptance anywhere because the cnset text says "by continuing you accept"
export const AppCookiesBanner = ({ stateCookiesAccepted, onAccept }: AppCookiesBannerProps) => {
  const [showBanner, setShowBanner] = useState<boolean>(false);
  const rStyles = {
    global: {
      h2: {
        fontSize: rSize(28),
      },
      h5: {
        fontSize: rSize(22),
      },
      p: {
        fontSize: rSize(18),
      },
      th: {
        fontSize: rSize(18),
      },
      biCookie: {
        size: rSize(40),
      },
    },
    wrapper: {
      bottom: rSize(20),
      left: rSize(20),
      boxShadow: `0 0 ${rSize(10)}px rgba(0, 0, 0, 0.3)`,
    },
    cookiesToggle: {
      padding: rSize(10),
    },
    button: {
      height: rSize(40),
      borderTop: `${rSize(1)}px solid lightgrey`,
      borderRadius: rSize(10),
    },
    content: {
      padding: rSize(10),
    },
    bannerHeader: {
      borderRadius: rSize(10),
      padding: rSize(10),
    },
    img: {
      margin: `${rSize(-10)}px ${rSize(-10)}px 0 0`,
    },
    cookiesBanner: {
      boxShadow: `0 0 ${rSize(10)}px rgba(0, 0, 0, 0.2)`,
      width: rSize(400),
      minHeight: rSize(200),
      bottom: rSize(80),
      borderRadius: rSize(10),
      left: rSize(-2000),
    },
  };

  return (
    <Wrapper style={{ ...rStyles.wrapper }}>
      <div
        className="cookies-toggle"
        style={{ ...rStyles.cookiesToggle }}
        onClick={() => setShowBanner((prev) => !prev)}
      >
        <BiCookie size={rStyles.global.biCookie.size} color="white" />
      </div>
      <div className="banner-container">
        <div
          className="cookies-banner"
          style={{ ...rStyles.cookiesBanner, left: showBanner ? 0 : rStyles.cookiesBanner.left }}
        >
          <div className="banner-header" style={{ ...rStyles.bannerHeader }}>
            <div className="text">
              <h2 style={{ ...rStyles.global.h2 }}>Bonjour, </h2>
              <h5 style={{ ...rStyles.global.h5 }}>c'est nous, les cookies !</h5>
            </div>
            <img src={cookiesImg} alt="cookies" style={{ ...rStyles.img }} />
          </div>
          <p className="content" style={{ ...rStyles.content, ...rStyles.global.p }}>
            Nous sommes là pour améliorer votre expérience de navigation. En continuant, vous nous donnez votre accord.
            Nous recueillons des informations pour personnaliser votre expérience et effectuons quelques analyses sur
            nos visiteurs, que ce soit sur ce site ou d'autres endroits sur le web.
          </p>
          <button style={{ ...rStyles.button }} onClick={() => setShowBanner(false)}>
            Je comprends
          </button>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 200;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  border-radius: 100%;
  * {
    transition: all 300ms ease;
  }
  .cookies-toggle {
    padding: 10px;
    background-color: var(--secondary);
    border-radius: 100%;
    overflow: hidden;
    cursor: pointer;
    flex-grow: 0;
    flex-shrink: 0;
  }
  .banner-container {
    font-family: 'Rubik', sans-serif;
    color: #666666;
    font-size: 13px;
    position: relative;
    button {
      width: 100%;
      height: 40px;
      border: none;
      border-top: 1px solid lightgrey;
      background-color: var(--mono-grey-3);
      border-radius: 10px;
      &:hover {
        background-color: var(--mono-grey-2);
      }
    }
    .content {
      padding: 10px;
    }
    .banner-header {
      /* background-color: rgb(185 227 243); */
      background-color: var(--secondary-ghost-darker);
      border-radius: 10px;
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      * {
        color: black;
      }
      .text {
        color: white;
      }
      img {
        max-width: 40%;
        margin: -10px -10px 0 0;
      }
    }
    .cookies-banner {
      background-color: var(--white);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      width: 400px;
      min-height: 200px;
      position: absolute;
      bottom: 80px;
      border-radius: 10px;
    }
  }
`;
