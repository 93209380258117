import { useState } from 'react';
import styled from 'styled-components';
import { DispatchAsyncResult } from 'react-redux-dispatch-async/lib/dispatchAsync';
import { useNotificationContext } from 'common/configuration/notificationContextProvider';
import {
  ERROR_ENTER_VALID_EMAIL,
  ERROR_NEWSLETTERS_SUB,
  SUCCESS_NEWSLETTERS_SUB,
} from 'configuration/helpers/constants';
import { isValidEmail } from 'configuration/helpers/HelperFunctions';
import { rSize } from 'core/util/ResponsiveSizeAdapter';
import { AppContainer } from 'common/ui/elements/AppContainer';
import newLettersImg from '../../../assets/images/newsletters-bg-simple.png';
import newLettersKidBooks from '../../../assets/images/newsletters-bg-kidbooks.png';

interface NewsLettersProps {
  sendNewsLettersSignUpAsync(email: string): Promise<DispatchAsyncResult<boolean>>;
}

export const NewsLettersDumb = ({ sendNewsLettersSignUpAsync }: NewsLettersProps) => {
  const [isSending, setIsSending] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const notifications = useNotificationContext();

  const rStyles = {
    global: {
      h4: {
        fontSize: rSize(24),
        lineHeight: `normal`,
      },
      p: {
        fontSize: rSize(18),
      },
    },
    wrapper: {
      borderRadius: rSize(10),
    },
    container: {
      padding: `${rSize(100)}px ${rSize(50)}px`,
    },
    content: {
      gap: rSize(10),
    },
    inputButtonContainer: {
      height: rSize(55),
    },
    input: {
      textIndent: rSize(20),
      fontSize: rSize(20),
    },
    button: {
      fontSize: rSize(16),
      padding: `0 ${rSize(50)}px`,
    },
  };

  const sendEmail = async (e: any) => {
    e.preventDefault();
    if (!isValidEmail(email)) {
      notifications.setMessage({
        message: ERROR_ENTER_VALID_EMAIL,
        status: 'error',
      });
    } else {
      try {
        setIsSending(true);
        const response: any = await sendNewsLettersSignUpAsync(email);
        if (response.success) {
          setEmail('');
          notifications.setMessage({
            message: SUCCESS_NEWSLETTERS_SUB,
            status: 'success',
          });
        }
      } catch (error) {
        notifications.setMessage({
          message: ERROR_NEWSLETTERS_SUB,
          status: 'error',
        });
        // console.log('sendNewsLettersSignUpAsync caught error', error);
      } finally {
        setIsSending(false);
      }
    }
  };

  return (
    <AppContainer>
      <Wrapper style={{ ...rStyles.wrapper }}>
        <div className="container" style={{ ...rStyles.container }}>
          <div className="first-layer-container" />
          <div className="second-layer-container col-12 col-md-8 col-lg-6">
            <div className="content" style={{ ...rStyles.content }}>
              <h4 style={{ ...rStyles.global.h4 }}>Recevez nos nouveautés & promotions.</h4>
              <div className="input-button-container" style={{ ...rStyles.inputButtonContainer }}>
                <input
                  disabled={isSending}
                  value={email}
                  placeholder="Votre adresse e-mail."
                  onChange={(e) => setEmail(e.target.value)}
                  style={{ ...rStyles.input }}
                />
                <button type="button" onClick={sendEmail} style={{ ...rStyles.button }}>
                  S'abonner
                </button>
              </div>
              <p style={{ ...rStyles.global.p }}>
                En cliquant, vous confirmez votre souhait de recevoir nos offres promotionnelles.
              </p>
            </div>
          </div>
        </div>
      </Wrapper>
    </AppContainer>
  );
};

const Wrapper = styled.section`
  background-image: url(${newLettersImg});
  background-repeat: no-repeat;
  background-position: top right;
  background-size: cover;
  border-radius: 20px;
  .container {
    position: relative;
    padding: 80px 0px;
  }

  .first-layer-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(${newLettersKidBooks});
    background-repeat: no-repeat;
    background-position: center right;
    background-size: contain;
    z-index: 0;
  }

  .second-layer-container {
    position: relative;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .input-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    height: 55px;
    width: 100%;
    border-radius: 50vw;
    overflow: hidden;
    background-color: var(--white);

    input {
      height: 100%;
      width: 100%;
      text-indent: 18px;
      border: none;
      font-size: 18px;
      color: black;
    }
    button {
      font-size: 18px;
      border-radius: 50vw;
      background-color: var(--primary);
      color: white;
      height: 100%;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
    }
  }
  .bg-decoration {
  }
`;
