import styled from 'styled-components';
// import phoneGreen from 'assets/images/phone-green.png';
// import { ImgText } from './ImgText';
import { rSize } from 'core/util/ResponsiveSizeAdapter';
import { AppContainer } from 'common/ui/elements/AppContainer';
import { SocialLinks } from './SocialLinks';

interface FooterBottomProps {
  phone?: string;
  magazinTitle?: string;
}
export const FooterBottom = ({ phone, magazinTitle }: FooterBottomProps) => {
  const rStyles = {
    global: {
      fontSize: rSize(20),
    },
    wrapper: {
      margin: `${rSize(20)}px 0`,
    },
    row: {
      rowGap: rSize(20),
    },
  };
  return (
    <AppContainer>
      <Wrapper style={{ ...rStyles.wrapper }}>
        <div className="row justify-content-between" style={{ ...rStyles.row }}>
          <div className="col-12 col-sm-12 col-md-5 col-lg-4 me-1 justify-content-center">
            <p
              className="copyright-text"
              style={{ fontSize: rStyles.global.fontSize }}
            >{`© ${new Date().getFullYear()} ${magazinTitle ?? ''} - Tous droits réservés.`}</p>
          </div>
          <div className="col-12 col-sm-12 col-md-3 col-lg-2 me1 justify-content-center">
            <SocialLinks fontSize={rStyles.global.fontSize} />
          </div>
        </div>

        {/* {phone && (
      <div className="phones">
        <ImgText img={<img src={phoneGreen} alt="phone" width={40} />} text={phone} />
      </div>
    )} */}
      </Wrapper>
    </AppContainer>
  );
};

const Wrapper = styled.section`
  margin: 20px 0;
  .copyright-text {
    text-align: center;
  }
`;
