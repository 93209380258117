/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import MenuIcon from '@mui/icons-material/Menu';
import { AppContainer } from 'common/ui/elements/AppContainer';
import { OTHER_CATEGORY_NUMBER, PROMO_SUBCATEGORY_NUMBER } from 'configuration/helpers/constants';
import { rSize } from 'core/util/ResponsiveSizeAdapter';
import { DropdownLevel } from './DropdownLevel';
import { Category } from '../../domain/entities/Category';
import { SubCategory } from '../../domain/entities/SubCategory';

interface CategoriesMenuProps {
  showPromo: boolean;
  stateCategories: Category[];
  stateSubCategories: SubCategory[];
}

const mainMenuItems = [
  {
    title: 'Accueil',
    link: '/',
  },
  {
    title: 'Boutique',
    link: '/boutique',
  },
  {
    title: 'Événements',
    link: '/evenements',
  },
  {
    title: 'Contact',
    link: '/contact',
  },
];

export const CategoriesMenu = ({ showPromo, stateCategories, stateSubCategories }: CategoriesMenuProps) => {
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);

  const rStyles = {
    global: { fontSize: rSize(16) },
    wrapper: {
      margin: `${rSize(15)}px 0 ${rSize(20)}px 0`,
      borderRadius: rSize(10),
      minHeight: rSize(60),
    },
    faySideMenuButton: {
      borderRadius: rSize(10),
      gap: rSize(10),
      padding: rSize(10),
    },
  };

  const filledSubCatsCatNumbers = stateSubCategories
    .filter((subCat) => !!subCat.productCount && subCat.categoryNumber !== OTHER_CATEGORY_NUMBER)
    .map((subCat) => subCat.categoryNumber);
  const filledCats = stateCategories.filter((cat) => filledSubCatsCatNumbers.includes(cat.categoryNumber));

  return (
    <AppContainer>
      <Wrapper style={{ ...rStyles.wrapper }}>
        {menuIsOpen && <div className="dark-bg" onClick={() => setMenuIsOpen(false)} />}
        <div
          className="fay-side-menu-button"
          style={{ ...rStyles.faySideMenuButton }}
          onClick={() => setMenuIsOpen(true)}
        >
          <MenuIcon />
          <h4 style={{ fontSize: rStyles.global.fontSize }}>Catégories</h4>
          <DropdownLevel
            isOpen={menuIsOpen}
            setMenuIsOpen={() => {
              setMenuIsOpen(false);
            }}
            items={[
              ...filledCats.map((cat) => ({
                name: cat.name,
                number: cat.categoryNumber,
                link: `/boutique?category=${cat.categoryNumber}`,
                children: stateSubCategories
                  .filter((subCat) => subCat.categoryNumber === cat.categoryNumber)
                  .map((subCat) => ({
                    name: subCat.name,
                    number: subCat.categoryNumber,
                    link: `/boutique?subcategory=${subCat.subCategoryNumber}`,
                  })),
              })),
            ]}
          />
        </div>

        <div className="fay-main-menu-box">
          {mainMenuItems.map((menuItem, i) => (
            <Link key={i} to={menuItem.link} style={{ fontSize: rStyles.global.fontSize }}>
              {menuItem.title}
            </Link>
          ))}
          {showPromo && (
            <Link
              className="promo-tab"
              style={{ fontSize: rStyles.global.fontSize }}
              to={{ pathname: '/boutique/', search: `?subcategory=${PROMO_SUBCATEGORY_NUMBER}` }}
            >
              Promo
            </Link>
          )}
        </div>
      </Wrapper>
    </AppContainer>
  );
};

const Wrapper = styled.div`
  margin: 5px 0 20px 0;
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  min-height: 60px;
  position: relative;
  background-color: var(--secondary-ghost-darker);

  .dark-bg {
    background-color: rgba(0, 0, 0, 0.2);
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 200;
  }
  * {
    transition: all 300ms ease;
    font-size: 16px;
  }

  .fay-main-menu-box {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }
  .bg-darkness-menu {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    width: 100vw;
    height: 100vh;
    display: none;
    z-index: 201;
  }
  a,
  a:hover {
    text-decoration: none;
    color: var(--black);
    font-weight: 700;
    text-transform: uppercase;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    height: 100%;
    border-radius: 10px;
  }
  a:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
  .promo-tab,
  .promo-tab:hover {
    color: red;
  }
  .fay-side-menu-button {
    position: relative;
    background-color: var(--secondary);
    border-radius: 10px;
    display: flex;
    min-width: 27%;
    justify-content: center;
    align-items: center;
    color: white;
    gap: 10px;
    cursor: pointer;
    text-transform: uppercase;
  }
`;
