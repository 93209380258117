import { Action } from 'redux';
import { connect } from 'react-redux';
import { dispatchAsync } from 'react-redux-dispatch-async';
import { sendNewsLettersSignUpRequest } from 'generalContext/usecases/General.actions';
import { NewsLettersDumb } from './NewsLetters.dumb';
import { AppState } from '../../../common/configuration/app.states';

const mapState = (state: AppState) => ({});

const mapDispatch = (dispatch: (a: Action) => void) => ({
  sendNewsLettersSignUpAsync: (email: string) => dispatchAsync(dispatch, sendNewsLettersSignUpRequest(email)),
});

export const NewsLettersSmart = connect(mapState, mapDispatch)(NewsLettersDumb);
