/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { CircularProgress, Tooltip } from '@mui/material';
import { IoIosCloseCircle } from 'react-icons/io';
import { TbBooks } from 'react-icons/tb';
import { BsMenuButtonWideFill } from 'react-icons/bs';
import { DispatchAsyncResult } from 'react-redux-dispatch-async/lib/dispatchAsync';
import _ from 'lodash';
import { useNotificationContext } from 'common/configuration/notificationContextProvider';
import { ERROR_SEARCH, productsPerSearch } from 'configuration/helpers/constants';
import { Category } from 'generalContext/domain/entities/Category';
import { SubCategory } from 'generalContext/domain/entities/SubCategory';
import { ProductState } from 'generalContext/domain/entities/ProductState';
import { GetProductsProps } from 'generalContext/domain/entities/Product';
import { rSize } from 'core/util/ResponsiveSizeAdapter';
import { ProductPrice } from '../elements/ProductPrice';
import noImageImage from '../../../assets/images/NoImageImage.png';
import { AppButton } from '../elements/AppButton';

interface SearchBoxProps {
  stateCategories: Category[] | undefined;
  stateSubCategories: SubCategory[] | undefined;
  getProductsAsync(props?: GetProductsProps): Promise<DispatchAsyncResult<ProductState>>;
}
export const SearchBoxDumb = ({ stateCategories, stateSubCategories, getProductsAsync }: SearchBoxProps) => {
  const history = useHistory();
  const location = useLocation();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const notifications = useNotificationContext();
  const [searchResults, setSearchResults] = useState<ProductState>();
  const [searchOverlayOn, setSearchOverlayOn] = useState<boolean>(false);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const searchParams = new URLSearchParams(location.search);
  const paramSearchQuery = searchParams.get('searchquery');

  const rStyles = {
    global: {
      iconSize: rSize(30),
      flatIconSize: rSize(18),
    },
    searchResultsContainer: {
      padding: rSize(20),
      borderRadius: rSize(20),
      marginTop: rSize(4),
    },
    searchResultsStyles: {
      gap: rSize(20),
    },
    searchResults: {
      gap: rSize(20),
    },
    iconTitle: {
      gap: rSize(20),
      h6: { FontSize: rSize(22) },
      tbBooks: { size: rSize(25) },
      paddingBottom: rSize(5),
    },
    resultsContainer: {
      padding: `${rSize(10)}px 0`,
    },
    productSearchResult: {
      margin: `${rSize(10)}px 0`,
      imgTitleContainer: { gap: rSize(10), prodImgContainer: { width: rSize(50), height: rSize(50) } },
      prodTitle: { p: { FontSize: rSize(18) } },
    },
    subcategorySearchResult: {
      gap: rSize(10),
      categoryName: { fontSize: rSize(18) },
      toolTip: {
        fontSize: rSize(16),
        mentionCount: { fontSize: rSize(18), padding: rSize(8), margin: `${rSize(10)}px 0`, minWidth: rSize(50) },
      },
    },
    noResults: {
      fontSize: rSize(20),
    },
  };

  useEffect(() => {
    setSearchOverlayOn(searchQuery.length > 2);
    if (searchQuery.length < 2) {
      setSearchResults(undefined);
    }
  }, [searchQuery]);

  const ajaxCall = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchQuery(value);
    getProductsBySearchQuery(value);
  };
  const delayedCallback = useCallback(_.debounce(ajaxCall, 1000), []);

  useEffect(() => () => delayedCallback.cancel(), [delayedCallback]);

  useEffect(() => {
    setSearchQuery(paramSearchQuery ?? '');
  }, [paramSearchQuery]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (searchQuery.length > 2) {
        getProductsBySearchQuery(searchQuery);
      }
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    setSearchQuery(event.target.value);
    delayedCallback(event);
  };

  const getProductsBySearchQuery = async (value: string) => {
    try {
      setIsSearching(true);
      const response: any = await getProductsAsync({
        query: value.trim(),
        skip: 0,
        take: productsPerSearch,
      });
      if (response.success) {
        setSearchResults(response.result);
      } else {
        notifications.setMessage({
          message: ERROR_SEARCH,
          status: 'success',
        });
      }
    } catch (error) {
      // Handle other errors
      notifications.setMessage({
        message: ERROR_SEARCH,
        status: 'error',
      });
      console.error('getProductsBySearchQuery caught error', error);
    } finally {
      setIsSearching(false);
    }
  };

  const goToSearchPage = () => {
    setSearchOverlayOn(false);
    history.push({
      pathname: '/boutique/',
      search: searchQuery ? `?searchquery=${searchQuery}` : '',
    });
  };

  return (
    <Wrapper>
      <div
        className="bg-darkness-search"
        style={searchOverlayOn ? { display: 'block' } : {}}
        onClick={() => setSearchOverlayOn(false)}
      />
      <div className={`header-search-nav ${searchOverlayOn ? 'search-above' : ''}`}>
        <form>
          <div className="fay-search-box" style={{ height: rSize(55), borderRadius: rSize(40) }}>
            <input
              type="text"
              className="fay-search-input"
              style={{ fontSize: rSize(18), textIndent: rSize(15) }}
              aria-label="search text input"
              placeholder="Que cherchez-vous ?"
              value={searchQuery}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              onFocus={() => setSearchOverlayOn(searchQuery.length > 2)}
            />
            <div className="fay-search-box-buttons" style={{ gap: rSize(10) }}>
              {searchQuery && (
                <IoIosCloseCircle
                  size={rStyles.global.iconSize}
                  color="lightgrey"
                  className="close-icon"
                  onClick={() => setSearchQuery('')}
                />
              )}
              <button
                disabled={isSearching}
                className="fay-search-box-submit"
                style={{ width: rSize(54), height: rSize(54), borderRadius: rSize(55) }}
                type="button"
                onClick={() => getProductsBySearchQuery(searchQuery)}
              >
                {isSearching ? (
                  <CircularProgress size={rStyles.global.iconSize} color="inherit" />
                ) : (
                  <i className="flaticon-loupe" style={{ fontSize: rStyles.global.flatIconSize }} />
                )}
              </button>
            </div>
          </div>
        </form>

        {searchOverlayOn && (
          <div className="search-results-container" style={{ ...rStyles.searchResultsContainer }}>
            {searchResults === undefined ? (
              <CircularProgress color="inherit" />
            ) : (
              <>
                <div
                  className="search-results"
                  style={{
                    gap: rStyles.searchResultsStyles.gap,
                  }}
                >
                  <div className="product-side">
                    <div
                      className="icon-title"
                      style={{
                        gap: rStyles.iconTitle.gap,
                        paddingBottom: rStyles.iconTitle.paddingBottom,
                      }}
                    >
                      <TbBooks size={rStyles.iconTitle.tbBooks.size} />
                      <h6
                        style={{
                          fontSize: rStyles.iconTitle.h6.FontSize,
                        }}
                      >
                        Articles
                      </h6>
                    </div>
                    <div
                      className="results-container"
                      style={{
                        padding: rStyles.resultsContainer.padding,
                      }}
                    >
                      {searchResults.products.length ? (
                        searchResults.products.map((prod, i) => (
                          <div
                            key={i}
                            className="product-search-result"
                            style={{
                              margin: rStyles.productSearchResult.margin,
                            }}
                          >
                            <a
                              className="img-title-container"
                              style={{
                                gap: rStyles.productSearchResult.imgTitleContainer.gap,
                              }}
                              href={`/product-details/${prod.Art_Ean13}`}
                            >
                              <div
                                className="prod-img-container"
                                style={{
                                  width: rStyles.productSearchResult.imgTitleContainer.prodImgContainer.width,
                                  height: rStyles.productSearchResult.imgTitleContainer.prodImgContainer.height,
                                }}
                              >
                                <img
                                  src={prod.Art_Images[0] ? `${prod.Art_Images[0]}` : `${noImageImage}`}
                                  alt="search-prod-img"
                                />
                              </div>
                              <div className="prod-title">
                                <p
                                  style={{
                                    fontSize: rStyles.productSearchResult.prodTitle.p.FontSize,
                                  }}
                                >
                                  {prod.Art_Titre}
                                </p>
                              </div>
                            </a>
                            <ProductPrice product={prod} fontSize={rStyles.productSearchResult.prodTitle.p.FontSize} />
                          </div>
                        ))
                      ) : (
                        <div
                          className="no-results"
                          style={{
                            fontSize: rStyles.noResults.fontSize,
                          }}
                        >
                          Aucun résultat n'a été trouvé correspondant à votre recherche
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="category-side">
                    <div
                      className="icon-title"
                      style={{
                        gap: rStyles.iconTitle.gap,
                        paddingBottom: rStyles.iconTitle.paddingBottom,
                      }}
                    >
                      <BsMenuButtonWideFill size={rStyles.iconTitle.tbBooks.size} />
                      <h6
                        style={{
                          fontSize: rStyles.iconTitle.h6.FontSize,
                        }}
                      >
                        Catégories
                      </h6>
                    </div>
                    <div
                      className="results-container"
                      style={{
                        padding: rStyles.resultsContainer.padding,
                      }}
                    >
                      {stateSubCategories && searchResults.subCategoryMentions ? (
                        searchResults.subCategoryMentions.map((mention, i) => {
                          const foundSubCat = stateSubCategories?.find(
                            (subCat) => subCat.subCategoryNumber === mention.subCategoryNumber,
                          );
                          const foundCat = stateCategories?.find(
                            (cat) => cat.categoryNumber === foundSubCat?.categoryNumber,
                          );
                          if (!foundSubCat) {
                            return null;
                          }
                          return (
                            <div
                              key={i}
                              className="subcategory-search-result"
                              style={{
                                gap: rStyles.subcategorySearchResult.gap,
                              }}
                            >
                              <div
                                className="category-name"
                                style={{
                                  fontSize: rStyles.subcategorySearchResult.categoryName.fontSize,
                                }}
                              >
                                <a
                                  href={`/boutique/?category=${foundCat?.categoryNumber}`}
                                  title={`Aller à la ${foundCat?.name}`}
                                >
                                  {foundCat?.name}
                                </a>
                                {' > '}
                              </div>
                              <div
                                className="subCategory-name"
                                style={{
                                  fontSize: rStyles.subcategorySearchResult.categoryName.fontSize,
                                }}
                              >
                                <a
                                  href={`/boutique/?subcategory=${foundSubCat?.subCategoryNumber}`}
                                  title={`Aller à la ${foundSubCat?.name}`}
                                >
                                  {foundSubCat?.name}
                                </a>
                              </div>

                              <Tooltip
                                title={`Trouvé ${mention.mentionsCount} fois dans cette sous-catégorie`}
                                componentsProps={{
                                  tooltip: {
                                    sx: {
                                      fontSize: rStyles.subcategorySearchResult.toolTip.fontSize,
                                    },
                                  },
                                }}
                              >
                                <div
                                  className="mention-count"
                                  style={{
                                    fontSize: rStyles.subcategorySearchResult.toolTip.mentionCount.fontSize,
                                    padding: rStyles.subcategorySearchResult.toolTip.mentionCount.padding,
                                    margin: rStyles.subcategorySearchResult.toolTip.mentionCount.margin,
                                    minWidth: rStyles.subcategorySearchResult.toolTip.mentionCount.minWidth,
                                  }}
                                >
                                  {mention.mentionsCount}
                                </div>
                              </Tooltip>
                            </div>
                          );
                        })
                      ) : (
                        <div
                          className="no-results"
                          style={{
                            fontSize: rStyles.noResults.fontSize,
                          }}
                        >
                          -
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <AppButton
                  label={`Voir tous les résultats de recherche (${searchResults.count})`}
                  action={goToSearchPage}
                />
              </>
            )}
          </div>
        )}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;

  .search-results-container {
    position: absolute;
    background-color: white;
    margin-top: 10px;
    width: 100%;
    padding: 20px;
    min-height: 40%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    top: 100%;
    border-radius: 20px;
  }
  .search-above {
    z-index: 206;
    position: relative;
  }
  .bg-darkness-search {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    width: 100vw;
    height: 100vh;
    transition: all 300ms ease;
    display: none;
    z-index: 206;
  }
  .no-results {
    font-size: 20px;
    font-weight: 500;
  }
  .results-container {
    padding: 10px 0;
    max-width: 100%;
  }
  .search-results {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    gap: 20px;
  }
  .product-side {
    /* width: 100%; */
    height: 100%;
    flex: 2;
  }
  .category-side {
    width: 100%;
    height: 100%;
    flex: 1;
  }
  .icon-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    gap: 10px;
    h6 {
      font-size: 22px;
    }
    border-bottom: 2px solid lightgrey;
    padding-bottom: 5px;
  }
  .fay-search-box {
    background-color: white;
    border: 2px solid var(--secondary);
    border-radius: 100%;
    overflow: hidden;
    width: 100%;
    height: 52px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
  .fay-search-box-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 10px;
  }
  .fay-search-input {
    background: transparent;
    display: block;
    height: 100% !important;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    font-size: 18px;
    border: none;
    line-height: 1.5em;
    text-indent: 15px;
  }
  .fay-search-input::placeholder {
    color: grey;
  }
  .fay-search-box-submit {
    width: 52px;
    height: 52px;
    border-radius: 52px;
    background-color: var(--secondary);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    i {
      color: white;
      font-size: 20px;
    }
    svg {
      color: white;
    }
  }
  .close-icon {
    cursor: pointer;
  }
  .subcategory-search-result {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
    * {
      color: var(--black);
      text-decoration: none !important;
    }
    .subCategory-name,
    .category-name {
      font-size: 18px;
      font-weight: 500;
    }
    .mention-count {
      background-color: var(--secondary-ghost-darker);
      font-size: 18px;
      font-weight: 500;
      padding: 8px;
      border-radius: 8px;
      margin: 10px 0;
      min-width: 50px;
      justify-content: center;
      display: flex;
    }
  }
  .product-search-result {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin: 10px 0;
    * {
      color: var(--black);
      text-decoration: none !important;
    }
    .img-title-container {
      flex: 1;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
      gap: 10px;
      .prod-img-container {
        width: 50px;
        height: 50px;
        overflow: hidden;
        flex-shrink: 0;
      }
      .prod-title,
      .prod-title:hover {
        width: 100%;
        /* overflow: hidden;
        white-space: nowrap; */
        p {
          /* max-width: 500px; */
          font-size: 18px;
          font-weight: 500;
          /* display: inline-block;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap; */
        }
      }
    }
  }
`;
