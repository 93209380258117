import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { DispatchAsyncResult } from 'react-redux-dispatch-async/lib/dispatchAsync';
import { rSize } from 'core/util/ResponsiveSizeAdapter';
import { formatDate } from '../../../../configuration/helpers/HelperFunctions';
import { Review } from '../../../domain/entities/Review';
import { AppButton } from '../../../../common/ui/elements/AppButton';
import { User } from '../../../../authContext/domain/entities/User';
import { useNotificationContext } from '../../../../common/configuration/notificationContextProvider';

interface ReviewsCenterProps {
  stateConnectedUser?: User;
  getAdminReviewsAsync(): Promise<DispatchAsyncResult<void>>;
  approveReviewAsync(rev: Review): Promise<DispatchAsyncResult<Review>>;
  deleteReviewAsync(rev: Review): Promise<DispatchAsyncResult<boolean>>;
}
export const ReviewsCenter = ({
  stateConnectedUser,
  getAdminReviewsAsync,
  approveReviewAsync,
  deleteReviewAsync,
}: ReviewsCenterProps) => {
  const notifications = useNotificationContext();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [adminReviews, setAdminReviews] = useState<Review[]>([]);
  const rStyles = {
    global: {
      h4: {
        fontSize: rSize(24),
      },
      p: {
        fontSize: rSize(18),
      },
      th: {
        fontSize: rSize(18),
      },
    },
    noReviews: {
      fontSize: rSize(20),
      paddingLeft: rSize(15),
    },
    checkTbl: {
      borderRight: `${rSize(1)}px solid white`,
    },
    productItemActions: {
      gap: rSize(20),
    },
  };

  useEffect(() => {
    if (stateConnectedUser) {
      if (stateConnectedUser.admin) {
        getAdminReviews();
      }
    }
  }, [stateConnectedUser]);

  const getAdminReviews = async () => {
    try {
      const response: any = await getAdminReviewsAsync();
      if (response.success && !response.result.error) {
        setAdminReviews(response.result);
      } else {
        notifications.setMessage({
          message: response.result.message,
          status: 'success',
        });
      }
    } catch (error) {
      notifications.setMessage({
        message: "Une erreur s'est produite à 'getAdminReviews'",
        status: 'error',
      });
      console.error('getAdminReviews caught error', error);
    }
  };

  const approveReview = async (rev: Review) => {
    try {
      setIsSubmitting(true);
      const response: any = await approveReviewAsync(rev);
      if (response.success && !response.result.error) {
        getAdminReviews();
      } else {
        notifications.setMessage({
          message: response.result.message,
          status: 'success',
        });
      }
    } catch (error) {
      notifications.setMessage({
        message: "Une erreur s'est produite à 'approveReview'",
        status: 'error',
      });
      console.error('approveReview caught error', error);
    } finally {
      setIsSubmitting(false);
    }
  };
  const rejectReview = async (rev: Review) => {
    try {
      setIsSubmitting(true);
      const response: any = await deleteReviewAsync(rev);
      if (response.success && !response.result.error) {
        getAdminReviews();
      } else {
        notifications.setMessage(response.result.message);
      }
    } catch (error) {
      notifications.setMessage({
        message: "Une erreur s'est produite à 'rejectReview'",
        status: 'error',
      });
      console.error('rejectReview caught error', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Wrapper>
      <div className="shop-bx shop-profile">
        <div className="shop-bx-title clearfix">
          <h5 className="text-uppercase">Avis en attente</h5>
        </div>
        <div className="table-responsive">
          <table className="table check-tbl" style={{ ...rStyles.checkTbl }}>
            <thead>
              <tr>
                <th style={{ ...rStyles.global.th }}>Date</th>
                <th style={{ ...rStyles.global.th }}>Articles</th>
                <th style={{ ...rStyles.global.th }}>Commentaire</th>
                <th style={{ ...rStyles.global.th }}>Notation</th>
                <th style={{ ...rStyles.global.th }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {adminReviews.length ? (
                adminReviews.map((review, i) => (
                  <tr key={`review-key-${review.id}${i}`}>
                    <td className="product-item-name" style={{ ...rStyles.global.p }}>
                      {formatDate(review.createdAt) ?? '-'}
                    </td>
                    <td className="product-item-price" style={{ ...rStyles.global.p }}>
                      {review.product?.Art_Titre}
                    </td>
                    <td className="product-item-price" style={{ ...rStyles.global.p }}>
                      {review.text}
                    </td>
                    <td className="product-item-price" style={{ ...rStyles.global.p }}>
                      {review.rating}
                    </td>
                    <td className="product-item-actions" style={{ ...rStyles.productItemActions }}>
                      <AppButton
                        label="Approuver"
                        variant="v2"
                        disabled={isSubmitting}
                        action={() => approveReview(review)}
                      />
                      <AppButton label="Supprimer" disabled={isSubmitting} action={() => rejectReview(review)} />
                    </td>
                  </tr>
                ))
              ) : (
                <div className="no-reviews" style={{ ...rStyles.noReviews }}>
                  Il n'y a pas d'avis en attente pour le moment.
                </div>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .no-reviews {
    font-size: 20px;
    padding-left: 15px;
  }
  .check-tbl thead th {
    border-right: 1px solid white;
  }
  .product-item-actions {
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex: 2;
    justify-content: space-evenly;
  }
`;
