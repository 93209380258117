import { Action } from 'redux';
import { connect } from 'react-redux';
import { dispatchAsync } from 'react-redux-dispatch-async';
import { GetProductsProps } from 'generalContext/domain/entities/Product';
import { ShopDumb } from './Shop.dumb';
import { AppState } from '../../../common/configuration/app.states';
import { getProductsRequest } from '../../usecases/General.actions';
import { getStateCategories, getStateSubCategories } from '../../usecases/General.selectors';

const mapState = (state: AppState) => ({
  stateCategories: getStateCategories(state),
  stateSubCategories: getStateSubCategories(state),
});

const mapDispatch = (dispatch: (a: Action) => void) => ({
  getProductsAsync: (props?: GetProductsProps) => dispatchAsync(dispatch, getProductsRequest(props)),
});

export const ShopSmart = connect(mapState, mapDispatch)(ShopDumb);
