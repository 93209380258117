import { Color } from '../styles/constantStyles';

export interface CartSVGProps {
  color?: string;
  size?: number;
}

export const CartSVG = ({ color = Color.SECONDARY, size = 95 }: CartSVGProps) => (
  <svg
    viewBox="0 0 95 95"
    style={{ width: size, height: size, minWidth: size, minHeight: size }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M67.4378 64.6H23.4708L12.7293 5.541C12.6036 4.83373 12.2347 4.19264 11.6863 3.72863C11.1379 3.26462 10.4446 3.00688 9.72631 3H2.75781"
      stroke={color}
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M28.9344 80.0016C33.187 80.0016 36.6344 76.5542 36.6344 72.3016C36.6344 68.049 33.187 64.6016 28.9344 64.6016C24.6818 64.6016 21.2344 68.049 21.2344 72.3016C21.2344 76.5542 24.6818 80.0016 28.9344 80.0016Z"
      stroke={color}
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M68.9812 80.0016C73.2338 80.0016 76.6812 76.5542 76.6812 72.3016C76.6812 68.049 73.2338 64.6016 68.9812 64.6016C64.7287 64.6016 61.2812 68.049 61.2812 72.3016C61.2812 76.5542 64.7287 80.0016 68.9812 80.0016Z"
      stroke={color}
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M20.6606 49.1984H69.0166C70.4564 49.2028 71.8516 48.6996 72.9571 47.7771C74.0626 46.8547 74.8076 45.5722 75.0611 44.1549L79.7581 18.3984H15.0781"
      stroke={color}
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </svg>
);
