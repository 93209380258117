import { Dispatch, SetStateAction, useEffect } from 'react';
import styled from 'styled-components';
import { AppTextInput } from './AppTextInput';
import { isValidEmail } from '../../../configuration/helpers/HelperFunctions';
import { ERROR_ENTER_VALID_EMAIL } from '../../../configuration/helpers/constants';

interface PasswordForgottenFormProps {
  showError?: boolean;
  email: string;
  setEmail: Dispatch<SetStateAction<string>>;
  enterFunc: () => void;
}
export const PasswordForgottenForm = ({ showError, email, setEmail, enterFunc }: PasswordForgottenFormProps) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        enterFunc();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [enterFunc]);

  return (
    <Wrapper>
      <div className="row">
        <AppTextInput
          showError={showError && !isValidEmail(email)}
          label="Email*"
          errorHint={ERROR_ENTER_VALID_EMAIL}
          placeholder="email@exemple.com"
          currentVal={email}
          onChange={(newVal: string) => {
            setEmail(newVal);
          }}
        />
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div``;
