import {
  deleteTokensTofetch,
  fetchWithApi,
  fetchWithToken,
  setTokensToFetch,
} from '../../../configuration/helpers/fetch.helper';
import { AuthTokens } from '../../../common/domain/entities/AuthTokens';
import { emptyUser, User } from '../../domain/entities/User';
import { AuthInteractor } from '../../domain/gateways/Auth.interactor';
import { ENDPOINTS } from '../../../configuration';
import { ChangePasswordProps } from '../../../common/domain/entities/ChangePasswordProps';
import { ResetPasswordProps } from '../../../common/domain/entities/ResetPasswordProps';

export class RealAuthInteractor implements AuthInteractor {
  async sendSignUp(newUser: User): Promise<AuthTokens> {
    const url = `${ENDPOINTS.signUp}`;
    const objectToSend = {
      email: newUser.email,
      password: newUser.password,
    };
    const response = await fetchWithApi(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Accept-Encoding': 'gzip',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(objectToSend),
    });
    const res = await response.json();
    if (!response.ok || res.error) {
      const error = new Error(res.message);
      error.cause = res.data;
      throw error;
    }
    const newTokens: AuthTokens = {
      accessToken: res.accessToken,
      renewToken: res.renewToken,
    };
    setTokensToFetch(newTokens);
    return newTokens;
  }

  async sendSignIn(userAccount: User): Promise<AuthTokens> {
    const url = `${ENDPOINTS.signIn}`;
    const objectToSend = {
      email: userAccount.email,
      password: userAccount.password,
    };
    const response = await fetchWithApi(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Accept-Encoding': 'gzip',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(objectToSend),
    });
    const res = await response.json();
    if (!response.ok || res.error) {
      const error = new Error(res.message);
      error.cause = res.data;
      throw error;
    }
    const newTokens: AuthTokens = {
      accessToken: res.accessToken,
      renewToken: res.renewToken,
    };
    setTokensToFetch(newTokens);
    return newTokens;
  }

  async resetPassword(props: ResetPasswordProps): Promise<AuthTokens> {
    const url = `${ENDPOINTS.resetPassword}`;
    const objectToSend = {
      userId: props.id,
      newPassword: props.newPassword,
      newPasswordConfirm: props.newPasswordConfirm,
    };
    const response = await fetchWithApi(url, {
      method: 'PUT',
      headers: {
        Authorization: props.accessToken ?? '',
        Accept: 'application/json',
        'Accept-Encoding': 'gzip',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(objectToSend),
    });
    const res = await response.json();
    if (!response.ok || res.error) {
      const error = new Error(res.message);
      error.cause = res.data;
      throw error;
    }
    const newTokens: AuthTokens = {
      accessToken: res.accessToken,
      renewToken: res.renewToken,
    };
    setTokensToFetch(newTokens);
    return newTokens;
  }

  async sendSignOut(): Promise<boolean> {
    /* const url = `${ENDPOINTS.signOut}`;
    const response = await fetchWithApi(url, { method: 'DELETE' });
    const res = await response.json();
    if (!response.ok || res.error) {
      const error = new Error(res.message);
      error.cause = res.data;
      throw error;
    } */
    deleteTokensTofetch();
    return true;
  }

  async sendPasswordForgottenEmail(email: string): Promise<boolean> {
    const url = `${ENDPOINTS.sendPasswordForgottenEmail}`;
    const objectToSend = {
      email,
    };
    const response = await fetchWithApi(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Accept-Encoding': 'gzip',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(objectToSend),
    });
    const res = await response.json();
    if (!response.ok || res.error) {
      const error = new Error(res.message);
      error.cause = res.data;
      throw error;
    }
    return true;
  }

  async updateUserData(userData: User): Promise<User> {
    const url = `${ENDPOINTS.updateUserData}`;
    const objectToSend = {
      addressMain: userData.addressMain,
      addressSecond: userData.addressSecond,
      city: userData.city,
      country: userData.country,
      firstName: userData.firstName,
      lastName: userData.lastName,
      phone: userData.phone,
      state: userData.state,
      zip: userData.zip,
    };
    const response = await fetchWithToken(url, { method: 'PUT', body: JSON.stringify(objectToSend) });
    const res = await response.json();
    if (!response.ok || res.error) {
      const error = new Error(res.message);
      error.cause = res.data;
      throw error;
    }
    return res.data;
  }

  async getUserData(): Promise<User> {
    const url = `${ENDPOINTS.getUserData}`;
    const response = await fetchWithToken(url);
    const res = await response.json();
    if (response.ok) {
      return res.data;
    }

    if (response.status === 401 && res.error === 'Expired Session') {
      console.log('Session is expired please try signing again.');
    } else if (res.error) {
      const error = new Error(res.message);
      error.cause = res.data;
      throw error;
    }
    return emptyUser;
  }

  async updatePassword(passwords: ChangePasswordProps): Promise<boolean> {
    const url = `${ENDPOINTS.updatePassword}`;
    const objectToSend = { ...passwords };
    const response = await fetchWithToken(url, {
      method: 'PUT',
      body: JSON.stringify(objectToSend),
    });
    const res = await response.json();
    if (response.ok) {
      return res.data;
    }

    if (response.status === 401 && res.error === 'Expired Session') {
      console.log('Session is expired please try signing again.');
    } else if (res.error) {
      const error = new Error(res.message);
      error.cause = res.data;
      throw error;
    }
    return false;
  }
}
