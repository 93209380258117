import React, { ReactNode, useContext, useMemo, useState } from 'react';
import { NotificationContextType, NotificationMessage } from '../domain/entities/NotificationContextType';

export const NotificationContext = React.createContext<NotificationContextType | null>(null);

export const NotificationContextProvider = ({ children }: { children: ReactNode }) => {
  const [message, setMessage] = useState<NotificationMessage>({ message: '', status: 'warn' });
  const props = useMemo(() => ({ message, setMessage }), [message]);
  return <NotificationContext.Provider value={props}>{children}</NotificationContext.Provider>;
};

export const useNotificationContext = () => {
  const context = useContext(NotificationContext);

  if (!context) {
    throw new Error('<useNotificationState> must be provided');
  }
  return context;
};
