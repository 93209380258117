import { useEffect, useState } from 'react';
import { DispatchAsyncResult } from 'react-redux-dispatch-async/lib/dispatchAsync';
import { Product } from 'generalContext/domain/entities/Product';
import { ProductState } from 'generalContext/domain/entities/ProductState';
import { AdminPreferances } from 'generalContext/domain/entities/AdminPreferances';
import { ShowcaseItem } from 'generalContext/domain/entities/ShowcaseItem';
import { StoreFlexingSection } from '../components/StoreFlexingSection';
import { TestimonialsSection } from '../components/TestimonialsSection';
import {
  BEST_SELLERS_SUBCATEGORY_NUMBER,
  COIN_SUBCATEGORY_NUMBER,
  PROMO_SUBCATEGORY_NUMBER,
  RECOMMENDED_SUBCATEGORY_NUMBER,
} from '../../../configuration/helpers/constants';
import { useNotificationContext } from '../../../common/configuration/notificationContextProvider';
// import { HeroTripleController } from '../components/HeroTripleController';
import { CategoryProductsSlider } from '../components/CategoryProductsSlider';
import { Color } from '../../../assets/styles/constantStyles';
import { SubCategory } from '../../domain/entities/SubCategory';
import { Category } from '../../domain/entities/Category';
import { Showcase } from '../components/Showcase';

interface HomeProps {
  stateAdminPreferances?: AdminPreferances;
  stateCategories: Category[] | undefined;
  stateSubCategories: SubCategory[] | undefined;
  getProductsBySubCategoryNumberAsync(id: number): Promise<DispatchAsyncResult<ProductState>>;
  getPrioritizedShowcaseItemsAsync(): Promise<DispatchAsyncResult<ShowcaseItem[]>>;
}
export const HomeDumb = ({
  stateAdminPreferances,
  stateCategories,
  stateSubCategories,
  getProductsBySubCategoryNumberAsync,
  getPrioritizedShowcaseItemsAsync,
}: HomeProps) => {
  const [prioritizedShowcaseItems, setPrioritizedShowcaseItems] = useState<ShowcaseItem[] | undefined>(undefined);
  const [recommendedProducts, setRecommendedProducts] = useState<Product[]>([]);
  const [saleProducts, setSaleProducts] = useState<Product[]>([]);
  const [promoProducts, setPromoProducts] = useState<Product[]>([]);
  const [coinProducts, setCoinProducts] = useState<Product[]>([]);
  const notifications = useNotificationContext();

  useEffect(() => {
    getPrioritizedShowcaseItems();
    // getRecommendedProducts(2600);
    getRecommendedProducts(RECOMMENDED_SUBCATEGORY_NUMBER);
  }, []);

  const getPrioritizedShowcaseItems = async () => {
    try {
      const response: any = await getPrioritizedShowcaseItemsAsync();
      if (response.success && !response.result.error) {
        setPrioritizedShowcaseItems(response.result);
      } else {
        notifications.setMessage({
          message: response.result.message,
          status: 'error',
        });
      }
    } catch (error) {
      notifications.setMessage({
        message: 'An error occurred while fetching prioritized showcase items.',
        status: 'error',
      });
      console.error('getPrioritizedShowcaseItems caught error', error);
    }
  };

  const getRecommendedProducts = async (subCatNum: number) => {
    try {
      const response: any = await getProductsBySubCategoryNumberAsync(subCatNum);
      if (response.success && !response.result.error) {
        setRecommendedProducts(response.result.products);
        getBestSallersProducts(BEST_SELLERS_SUBCATEGORY_NUMBER);
      } else {
        notifications.setMessage({
          message: response.result.message,
          status: 'success',
        });
      }
    } catch (error) {
      notifications.setMessage({
        message: "Une erreur s'est produite à 'getRecommendedProducts'",
        status: 'error',
      });
      console.error('getRecommendedProducts caught error', error);
    }
  };
  const getBestSallersProducts = async (subCatNum: number) => {
    try {
      const response: any = await getProductsBySubCategoryNumberAsync(subCatNum);
      if (response.success && !response.result.error) {
        setSaleProducts(response.result.products);
        getPromoProducts(PROMO_SUBCATEGORY_NUMBER);
      } else {
        notifications.setMessage({
          message: response.result.message,
          status: 'error',
        });
      }
    } catch (error) {
      notifications.setMessage({
        message: "Une erreur s'est produite à 'getBestSallersProducts'",
        status: 'error',
      });
      console.error('getBestSallersProducts caught error', error);
    }
  };
  const getPromoProducts = async (subCatNum: number) => {
    try {
      const response: any = await getProductsBySubCategoryNumberAsync(subCatNum);
      if (response.success && !response.result.error) {
        setPromoProducts(response.result.products);
        getCoinProducts(COIN_SUBCATEGORY_NUMBER);
      } else {
        notifications.setMessage({
          message: response.result.message,
          status: 'error',
        });
      }
    } catch (error) {
      notifications.setMessage({
        message: "Une erreur s'est produite à 'getPromoProducts'",
        status: 'error',
      });

      console.error('getPromoProducts caught error', error);
    }
  };

  const getCoinProducts = async (subCatNum: number) => {
    try {
      const response: any = await getProductsBySubCategoryNumberAsync(subCatNum);
      if (response.success && !response.result.error) {
        setCoinProducts(response.result.products);
      } else {
        notifications.setMessage({
          message: response.result.message,
          status: 'error',
        });
      }
    } catch (error) {
      notifications.setMessage({
        message: "Une erreur s'est produite à 'getCoinProducts'",
        status: 'error',
      });
      console.error('getCoinProducts caught error', error);
    }
  };

  if (!stateCategories || !stateSubCategories) {
    return null;
  }
  return (
    <div className="page-content bg-white">
      {prioritizedShowcaseItems !== undefined && prioritizedShowcaseItems.length !== 0 && (
        <Showcase
          isTriplet={stateAdminPreferances?.sliderType === 'TRIPLE'}
          imagesBase64={prioritizedShowcaseItems.map((item: ShowcaseItem) => item.imageBase64 ?? '')}
        />
      )}
      {/* stateAdminPreferances?.sliderType === 'SINGLE' ? <HeroSlider /> : <HeroTriple /> */}
      <CategoryProductsSlider
        stateCategories={stateCategories}
        stateSubCategories={stateSubCategories}
        title="Nos Coups de coeur"
        products={recommendedProducts}
      />
      <CategoryProductsSlider
        stateCategories={stateCategories}
        stateSubCategories={stateSubCategories}
        title="Meilleurs Ventes"
        products={saleProducts}
        color={Color.SECONDARY}
      />
      <CategoryProductsSlider
        stateCategories={stateCategories}
        stateSubCategories={stateSubCategories}
        title="Promotions"
        products={promoProducts}
      />
      <CategoryProductsSlider
        stateCategories={stateCategories}
        stateSubCategories={stateSubCategories}
        title="Coin bons affaires"
        products={coinProducts}
        color={Color.SECONDARY}
      />
      <StoreFlexingSection />
      <TestimonialsSection />
    </div>
  );
};
