/* eslint-disable jsx-a11y/no-static-element-interactions */
import styled from 'styled-components';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Dispatch, SetStateAction, useState } from 'react';
import { Link } from 'react-router-dom';
import { rSize } from 'core/util/ResponsiveSizeAdapter';

interface DropDownLevelItem {
  name: string;
  link: string;
  number: number;
  children?: DropDownLevelItem[];
}

interface DropdownLevelProps {
  isOpen: boolean;
  setMenuIsOpen: Dispatch<SetStateAction<boolean>>;
  items: DropDownLevelItem[];
}

export const DropdownLevel = ({ isOpen, setMenuIsOpen, items }: DropdownLevelProps) => {
  const [level2Items, setLevel2Items] = useState<DropDownLevelItem[]>([]);

  const rStyles = {
    global: { fontSize: rSize(16) },
    wrapper: {
      marginTop: rSize(5),
      borderRadius: rSize(10),
      boxShadow: `0 0 ${rSize(16)} rgba(0, 0, 0, 0.2)`,
    },
    levels: {
      item: {
        padding: rSize(12),
        gap: rSize(10),
      },
    },
  };

  if (!isOpen) {
    return null;
  }
  return (
    <Wrapper onMouseLeave={() => setMenuIsOpen(false)} style={{ ...rStyles.wrapper }}>
      <ul className="level-1" style={level2Items.length ? { borderRight: `1px solid grey` } : {}}>
        {items.map((item, i) => (
          <Link key={i} to={item.link}>
            <li
              className="item"
              style={{ ...rStyles.levels.item }}
              onMouseEnter={() => setLevel2Items(item.children ?? [])}
            >
              <p style={{ fontSize: rStyles.global.fontSize }}>{item.name}</p>
              {!!item.children?.length && (
                <ArrowBackIosIcon className="arrow" style={{ fontSize: rStyles.global.fontSize }} />
              )}
            </li>
          </Link>
        ))}
      </ul>
      {!!level2Items.length && (
        <ul className="level-2">
          {level2Items.map((subItem, j) => (
            <Link key={j} to={subItem.link} className="item" style={{ ...rStyles.levels.item }}>
              <li key={j}>
                <p style={{ fontSize: rStyles.global.fontSize }}>{subItem.name}</p>
                {!!subItem.children?.length && (
                  <ArrowBackIosIcon className="arrow" style={{ fontSize: rStyles.global.fontSize }} />
                )}
              </li>
            </Link>
          ))}
        </ul>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  z-index: 201;
  min-width: 100%;
  width: fit-content;
  max-height: 70vh;
  color: black;
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 5px;
  border-radius: 10px;
  overflow: hidden;
  padding: 0;
  border: none;
  overflow-y: auto;
  display: flex;
  flex-direction: row;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  background-color: var(--white);
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #000000;
  }

  .level-1,
  .level-2 {
    margin: 0;
    padding: 0;
    width: 100%;
    .item {
      position: relative;
      white-space: nowrap;
      padding: 12px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-weight: 600;
      gap: 10px;
      &:hover {
        color: var(--primary);
        background-color: var(--secondary-ghost-darker);
      }
      * {
        transition: all 100ms ease;
      }
      .arrow {
        transform: rotate(180deg);
        font-size: 15px;
      }
    }
    a,
    a:hover {
      display: flex;
      height: auto;
    }
  }
`;
