import { Link } from 'react-router-dom';

import ucimage from '../../../assets/images/background/uc.jpg';
import logo from '../../../assets/images/logo.png';

interface UnderConstructionProps {
  maintenance?: boolean;
}
export const UnderConstruction = ({ maintenance }: UnderConstructionProps) => (
  <div className="under-construct">
    <div className="inner-box">
      <div className="logo-header logo-dark">
        <Link to="/">
          <img src={logo} alt="" />
        </Link>
      </div>
      {maintenance ? (
        <div className="dz-content">
          <h2 className="dz-title">
            Le site est hors service <br />
            Pour <span className="text-primary">Maintenance</span>
          </h2>
        </div>
      ) : (
        <div className="dz-content">
          <h2 className="dz-title">Le site est en panne</h2>
          <p>
            il semble que nous rencontrons des problèmes techniques,
            <br /> nous serons de retour sous peu, veuillez réessayer plus tard
          </p>
        </div>
      )}
    </div>
    <img src={ucimage} className="uc-bg" alt="" />
  </div>
);
