import styled from 'styled-components';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { DispatchAsyncResult } from 'react-redux-dispatch-async/lib/dispatchAsync';
import { rSize } from 'core/util/ResponsiveSizeAdapter';
import { ShowcaseItem, ShowcaseType } from 'generalContext/domain/entities/ShowcaseItem';
import { formatDate, inputDate } from 'configuration/helpers/HelperFunctions';
import { AppButton } from 'common/ui/elements/AppButton';
import { Showcase } from 'generalContext/ui/components/Showcase';
import { AdminPreferances } from 'generalContext/domain/entities/AdminPreferances';
import { User } from '../../../../authContext/domain/entities/User';
import { useNotificationContext } from '../../../../common/configuration/notificationContextProvider';

interface ShowcaseManagementProps {
  stateConnectedUser?: User;
  getAdminPreferancesAsync(): Promise<DispatchAsyncResult<AdminPreferances>>;
  updateAdminPreferencesAsync(adminPreferences: AdminPreferances): Promise<DispatchAsyncResult<AdminPreferances>>;
  // showcase -----
  getShowcaseItemsAsync(): Promise<DispatchAsyncResult<ShowcaseItem[]>>;
  getDefaultShowcaseItemsAsync(): Promise<DispatchAsyncResult<ShowcaseItem[]>>;
  getPromoShowcaseItemsAsync(): Promise<DispatchAsyncResult<ShowcaseItem[]>>;
  getPrioritizedShowcaseItemsAsync(): Promise<DispatchAsyncResult<ShowcaseItem[]>>;
  createShowcaseItemAsync(item: ShowcaseItem): Promise<DispatchAsyncResult<ShowcaseItem>>;
  updateShowcaseItemAsync(item: ShowcaseItem): Promise<DispatchAsyncResult<ShowcaseItem>>;
  updatePromoShowcaseAsync(
    itemId: string,
    startDate?: Date,
    endDate?: Date,
  ): Promise<DispatchAsyncResult<ShowcaseItem>>;
  deleteShowcaseItemAsync(id: string): Promise<DispatchAsyncResult<boolean>>;
  addDefaultShowcaseItemAsync(itemId: string): Promise<DispatchAsyncResult<ShowcaseItem>>;
  removeDefaultShowcaseItemAsync(itemId: string): Promise<DispatchAsyncResult<boolean>>;
  addPromoShowcaseItemAsync(
    itemId: string,
    startDate?: Date,
    endDate?: Date,
  ): Promise<DispatchAsyncResult<ShowcaseItem>>;
  removePromoShowcaseItemAsync(itemId: string): Promise<DispatchAsyncResult<boolean>>;
  switchDefaultShowcaseOrderAsync(itemId: string, direction: 'up' | 'down'): Promise<DispatchAsyncResult<boolean>>;
  switchPromoShowcaseOrderAsync(itemId: string, direction: 'up' | 'down'): Promise<DispatchAsyncResult<boolean>>;
}

export const ShowcaseManagement = ({
  stateConnectedUser,
  getAdminPreferancesAsync,
  updateAdminPreferencesAsync,
  getShowcaseItemsAsync,
  getDefaultShowcaseItemsAsync,
  getPromoShowcaseItemsAsync,
  getPrioritizedShowcaseItemsAsync,
  createShowcaseItemAsync,
  updateShowcaseItemAsync,
  updatePromoShowcaseAsync,
  deleteShowcaseItemAsync,
  addDefaultShowcaseItemAsync,
  removeDefaultShowcaseItemAsync,
  addPromoShowcaseItemAsync,
  removePromoShowcaseItemAsync,
  switchDefaultShowcaseOrderAsync,
  switchPromoShowcaseOrderAsync,
}: ShowcaseManagementProps) => {
  const notifications = useNotificationContext();
  const [isShowcaseTriplet, setIsShowcaseTriplet] = useState<boolean>(false);
  const [prioritizedShowcaseItems, setPrioritizedShowcaseItems] = useState<ShowcaseItem[] | undefined>(undefined);
  const [showcaseItems, setShowcaseItems] = useState<ShowcaseItem[]>([]);
  const [defaultShowcaseItems, setDefaultShowcaseItems] = useState<ShowcaseItem[]>([]);
  const [promoShowcaseItems, setPromoShowcaseItems] = useState<ShowcaseItem[]>([]);

  const addImageFileInputRef = useRef<HTMLInputElement>(null);
  const updateImageFileInputRef = useRef<HTMLInputElement>(null);
  const [updateImageItemId, setUpdateImageItemId] = useState<string | undefined>(undefined);

  const rStyles = {
    global: {
      h6: {
        fontSize: rSize(20),
      },
      h4: {
        fontSize: rSize(24),
      },
      p: {
        fontSize: rSize(18),
      },
      th: {
        fontSize: rSize(18),
      },
    },
    widgetHeader: {
      gap: rSize(6),
      marginBottom: rSize(16),
    },
    showcaseItemImage: {
      width: rSize(150),
      height: 'auto',
    },
    styledText: {
      fontSize: rSize(16),
      padding: `${rSize(8)}px ${rSize(12)}px`,
      borderRadius: 6,
    },
    orderContainer: {
      gap: rSize(6),
    },
    btnOrder: {
      fontSize: rSize(16),
      padding: `${rSize(8)}px ${rSize(12)}px`,
      borderRadius: rSize(6),
    },
    actionsContainer: {
      gap: rSize(20),
    },
    allImagesActionButton: {
      width: rSize(200),
    },
    noItemFound: {
      fontSize: rSize(20),
      paddingLeft: rSize(15),
    },
    checkTbl: {
      borderRight: `${rSize(1)}px solid white`,
    },
  };

  useEffect(() => {
    if (stateConnectedUser) {
      if (stateConnectedUser.admin) {
        getShowcaseType();
        getPrioritizedShowcaseItems();
        getDefaultShowcaseItems();
        getPromoShowcaseItems();
        getShowcaseItems();
      }
    }
  }, [stateConnectedUser]);

  const getShowcaseType = async () => {
    try {
      const response: any = await getAdminPreferancesAsync();
      if (response.success) {
        setIsShowcaseTriplet(response.result.sliderType === 'TRIPLE');
      }
    } catch (error) {
      notifications.setMessage({
        message: "Une erreur s'est produite à 'getShowcaseType'",
        status: 'error',
      });
      console.error("Une erreur s'est produite à 'getShowcaseType'", error);
    }
  };

  const updateShowcaseStyle = async (sliderType: 'TRIPLE' | 'SINGLE') => {
    try {
      const response: any = await updateAdminPreferencesAsync({ sliderType });
      if (response.success && !response.result.error) {
        setIsShowcaseTriplet(response.result.sliderType === 'TRIPLE');
      } else {
        notifications.setMessage({
          message: response.result.message,
          status: 'error',
        });
      }
    } catch (error) {
      notifications.setMessage({
        message: 'An error occurred while updating a showcase style.',
        status: 'error',
      });
      console.error('updateShowcaseItem caught error', error);
    }
  };

  const getShowcaseItems = async () => {
    try {
      const response: any = await getShowcaseItemsAsync();
      if (response.success && !response.result.error) {
        setShowcaseItems(response.result);
      } else {
        notifications.setMessage({
          message: response.result.message,
          status: 'error',
        });
      }
    } catch (error) {
      notifications.setMessage({
        message: 'Une erreur est survenue lors de la récupération des éléments de vitrine.',
        status: 'error',
      });
      console.error('getShowcaseItems a attrapé une erreur', error);
    }
  };

  const getDefaultShowcaseItems = async () => {
    try {
      const response: any = await getDefaultShowcaseItemsAsync();
      if (response.success && !response.result.error) {
        setDefaultShowcaseItems(response.result);
      } else {
        notifications.setMessage({
          message: response.result.message,
          status: 'error',
        });
      }
    } catch (error) {
      notifications.setMessage({
        message: 'Une erreur est survenue lors de la récupération des éléments de vitrine par défaut.',
        status: 'error',
      });
      console.error('getDefaultShowcaseItems a attrapé une erreur', error);
    }
  };

  const getPromoShowcaseItems = async () => {
    try {
      const response: any = await getPromoShowcaseItemsAsync();
      if (response.success && !response.result.error) {
        setPromoShowcaseItems(response.result);
      } else {
        notifications.setMessage({
          message: response.result.message,
          status: 'error',
        });
      }
    } catch (error) {
      notifications.setMessage({
        message: 'Une erreur est survenue lors de la récupération des éléments de vitrine promotionnels.',
        status: 'error',
      });
      console.error('getPromoShowcaseItems a attrapé une erreur', error);
    }
  };

  const getPrioritizedShowcaseItems = async () => {
    try {
      const response: any = await getPrioritizedShowcaseItemsAsync();
      if (response.success && !response.result.error) {
        setPrioritizedShowcaseItems(response.result);
      } else {
        notifications.setMessage({
          message: response.result.message,
          status: 'error',
        });
      }
    } catch (error) {
      notifications.setMessage({
        message: 'Une erreur est survenue lors de la récupération des éléments de vitrine priorisés.',
        status: 'error',
      });
      console.error('getPrioritizedShowcaseItems a attrapé une erreur', error);
    }
  };

  const createShowcaseItem = async (item: ShowcaseItem) => {
    try {
      const response: any = await createShowcaseItemAsync(item);
      if (response.success && !response.result.error) {
        notifications.setMessage({
          message: 'Élément de vitrine créé avec succès.',
          status: 'success',
        });
        getShowcaseItems();
      } else {
        notifications.setMessage({
          message: response.result.message,
          status: 'error',
        });
      }
    } catch (error) {
      notifications.setMessage({
        message: "Une erreur est survenue lors de la création d'un élément de vitrine.",
        status: 'error',
      });
      console.error('createShowcaseItem a attrapé une erreur', error);
    }
  };

  const updateShowcaseItem = async (item: ShowcaseItem) => {
    try {
      const response: any = await updateShowcaseItemAsync(item);
      if (response.success && !response.result.error) {
        notifications.setMessage({
          message: 'Élément de vitrine mis à jour avec succès.',
          status: 'success',
        });
        getPrioritizedShowcaseItems();
        getDefaultShowcaseItems();
        getPromoShowcaseItems();
        getShowcaseItems();
      } else {
        notifications.setMessage({
          message: response.result.message,
          status: 'error',
        });
      }
    } catch (error) {
      notifications.setMessage({
        message: "Une erreur est survenue lors de la mise à jour d'un élément de vitrine.",
        status: 'error',
      });
      console.error('updateShowcaseItem a attrapé une erreur', error);
    }
  };

  const updatePromoShowcase = async (itemId: string, startDate?: Date, endDate?: Date) => {
    try {
      const response: any = await updatePromoShowcaseAsync(itemId, startDate, endDate);
      if (response.success && !response.result.error) {
        getPrioritizedShowcaseItems();
        getPromoShowcaseItems();
      } else {
        notifications.setMessage({
          message: response.result.message,
          status: 'error',
        });
      }
    } catch (error) {
      notifications.setMessage({
        message: "Une erreur est survenue lors de la mise à jour d'un élément de vitrine promotionnel.",
        status: 'error',
      });
      console.error('updatePromoShowcase a attrapé une erreur', error);
    }
  };

  const deleteShowcaseItem = async (id: string) => {
    try {
      const response: any = await deleteShowcaseItemAsync(id);
      if (response.success && response.result) {
        notifications.setMessage({
          message: 'Élément de vitrine supprimé avec succès.',
          status: 'success',
        });
        getPrioritizedShowcaseItems();
        getDefaultShowcaseItems();
        getPromoShowcaseItems();
        getShowcaseItems();
      } else {
        notifications.setMessage({
          message: response.result.message,
          status: 'error',
        });
      }
    } catch (error) {
      notifications.setMessage({
        message: "Une erreur est survenue lors de la suppression d'un élément de vitrine.",
        status: 'error',
      });
      console.error('deleteShowcaseItem a attrapé une erreur', error);
    }
  };

  const addDefaultShowcaseItem = async (itemId: string) => {
    try {
      const response: any = await addDefaultShowcaseItemAsync(itemId);
      if (response.success && !response.result.error) {
        notifications.setMessage({
          message: 'Élément de vitrine par défaut ajouté avec succès.',
          status: 'success',
        });
        getDefaultShowcaseItems();
        getPromoShowcaseItems();
        getShowcaseItems();
        getPrioritizedShowcaseItems();
      } else {
        notifications.setMessage({
          message: response.result.message,
          status: 'error',
        });
      }
    } catch (error) {
      notifications.setMessage({
        message: "Une erreur est survenue lors de l'ajout d'un élément de vitrine par défaut.",
        status: 'error',
      });
      console.error('addDefaultShowcaseItem a attrapé une erreur', error);
    }
  };

  const removeDefaultShowcaseItem = async (itemId: string) => {
    try {
      const response: any = await removeDefaultShowcaseItemAsync(itemId);
      if (response.success && response.result) {
        notifications.setMessage({
          message: 'Élément de vitrine par défaut supprimé avec succès.',
          status: 'success',
        });
        getShowcaseItems();
        getDefaultShowcaseItems();
        getPrioritizedShowcaseItems();
      } else {
        notifications.setMessage({
          message: response.result.message,
          status: 'error',
        });
      }
    } catch (error) {
      notifications.setMessage({
        message: "Une erreur est survenue lors de la suppression d'un élément de vitrine par défaut.",
        status: 'error',
      });
      console.error('removeDefaultShowcaseItem a attrapé une erreur', error);
    }
  };

  const addPromoShowcaseItem = async (itemId: string, startDate?: Date, endDate?: Date) => {
    try {
      const response: any = await addPromoShowcaseItemAsync(itemId, startDate, endDate);
      if (response.success && !response.result.error) {
        notifications.setMessage({
          message: 'Élément de vitrine promotionnel ajouté avec succès.',
          status: 'success',
        });
        getShowcaseItems();
        getDefaultShowcaseItems();
        getPromoShowcaseItems();
        getPrioritizedShowcaseItems();
      } else {
        notifications.setMessage({
          message: response.result.message,
          status: 'error',
        });
      }
    } catch (error) {
      notifications.setMessage({
        message: "Une erreur est survenue lors de l'ajout d'un élément de vitrine promotionnel",
        status: 'error',
      });
      console.error('addPromoShowcaseItem a attrapé une erreur', error);
    }
  };

  const removePromoShowcaseItem = async (itemId: string) => {
    try {
      const response: any = await removePromoShowcaseItemAsync(itemId);
      if (response.success && response.result) {
        notifications.setMessage({
          message: 'Élément de vitrine promotionnel supprimé avec succès.',
          status: 'success',
        });
        getShowcaseItems();
        getPromoShowcaseItems();
        getPrioritizedShowcaseItems();
      } else {
        notifications.setMessage({
          message: response.result.message,
          status: 'error',
        });
      }
    } catch (error) {
      notifications.setMessage({
        message: "Une erreur est survenue lors de la suppression d'un élément de vitrine promotionnel.",
        status: 'error',
      });
      console.error('removePromoShowcaseItem a attrapé une erreur', error);
    }
  };

  const switchDefaultShowcaseOrder = async (itemId: string, direction: 'up' | 'down') => {
    try {
      const response: any = await switchDefaultShowcaseOrderAsync(itemId, direction);
      if (response.success && response.result) {
        getPrioritizedShowcaseItems();
        getDefaultShowcaseItems();
      } else {
        notifications.setMessage({
          message: response.result.message,
          status: 'error',
        });
      }
    } catch (error) {
      notifications.setMessage({
        message: "Une erreur est survenue lors de la modification de l'ordre des éléments de vitrine par défaut.",
        status: 'error',
      });
      console.error('switchDefaultShowcaseOrder a attrapé une erreur', error);
    }
  };

  const switchPromoShowcaseOrder = async (itemId: string, direction: 'up' | 'down') => {
    try {
      const response: any = await switchPromoShowcaseOrderAsync(itemId, direction);
      if (response.success && response.result) {
        getPrioritizedShowcaseItems();
        getPromoShowcaseItems();
      } else {
        notifications.setMessage({
          message: response.result.message,
          status: 'error',
        });
      }
    } catch (error) {
      notifications.setMessage({
        message: "Une erreur est survenue lors de la modification de l'ordre des éléments de vitrine priorisés.",
        status: 'error',
      });
      console.error('switchPrioritizedShowcaseOrder a attrapé une erreur', error);
    }
  };

  const handleAddImage = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      // Check if the file size is greater than 2MB
      if (file.size > 2 * 1024 * 1024) {
        notifications.setMessage({
          message: 'La taille du fichier dépasse la limite de 2 Mo. Veuillez choisir un fichier plus petit.',
          status: 'error',
        });
        if (addImageFileInputRef.current) {
          addImageFileInputRef.current.value = ''; // Reset the file input
        }
        return;
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        if (reader.result) {
          const base64String = (reader.result as string).replace(/^data:image\/[a-z]+;base64,/, '');
          createShowcaseItem({ imageBase64: base64String });
          // Reset the file input value to allow reselection of the same file
          if (addImageFileInputRef.current) {
            addImageFileInputRef.current.value = '';
          }
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAddImageButtonClick = () => {
    addImageFileInputRef.current?.click();
  };

  const handleUpdateImage = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      // Check if the file size is greater than 2MB
      if (file.size > 2 * 1024 * 1024) {
        notifications.setMessage({
          message: 'La taille du fichier dépasse la limite de 2 Mo. Veuillez choisir un fichier plus petit.',
          status: 'error',
        });
        if (updateImageFileInputRef.current) {
          updateImageFileInputRef.current.value = ''; // Reset the file input
        }
        return;
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        if (reader.result) {
          const base64String = (reader.result as string).replace(/^data:image\/[a-z]+;base64,/, '');
          if (updateImageItemId) {
            updateShowcaseItem({ id: updateImageItemId, imageBase64: base64String });
          }
          // Reset the file input value to allow reselection of the same file
          if (updateImageFileInputRef.current) {
            updateImageFileInputRef.current.value = '';
          }
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUpdateImageButtonClick = (id: string) => {
    updateImageFileInputRef.current?.click();
    setUpdateImageItemId(id);
  };

  return (
    <Wrapper>
      <div className="shop-bx shop-profile">
        <div className="shop-bx-title clearfix">
          <h5 className="text-uppercase">Gestion de la vitrine d'accueil</h5>
        </div>
        <div className="widget">
          <div className="widget-header" style={{ ...rStyles.widgetHeader }}>
            <h6 className="widget-title widget-all-image-title" style={{ ...rStyles.global.h6 }}>
              Aperçu de la vitrine
            </h6>
            <AppButton
              label={isShowcaseTriplet ? 'Désactiver le style triplet' : 'Activer le style triplet'}
              disabled={prioritizedShowcaseItems && prioritizedShowcaseItems.length < 3}
              variant={isShowcaseTriplet ? 'v1' : 'v2'}
              action={() => {
                if (isShowcaseTriplet) {
                  updateShowcaseStyle('SINGLE');
                } else {
                  updateShowcaseStyle('TRIPLE');
                }
              }}
            />
          </div>

          {prioritizedShowcaseItems !== undefined && (
            <Showcase
              isTriplet={isShowcaseTriplet}
              imagesBase64={prioritizedShowcaseItems.map((item: ShowcaseItem) => item.imageBase64 ?? '')}
            />
          )}
        </div>
        <div className="widget">
          <h6 className="widget-title" style={{ ...rStyles.global.h6 }}>
            Images par défaut
          </h6>
          <div className="table-responsive">
            <table className="table check-tbl" style={{ ...rStyles.checkTbl }}>
              <thead>
                <tr>
                  {/* <th style={{ ...rStyles.global.th }}>Référence #</th> */}
                  <th style={{ ...rStyles.global.th }}>Image</th>
                  <th style={{ ...rStyles.global.th }}>Actions</th>
                  <th style={{ ...rStyles.global.th }}>Ordre</th>
                </tr>
              </thead>
              <tbody>
                {defaultShowcaseItems.length ? (
                  defaultShowcaseItems.map((item, i) => (
                    <tr
                      key={`showcase-default-container-key-${item.id}${i}`}
                      style={{ backgroundColor: i > 2 ? '#f2f3f5' : '#fff' }}
                    >
                      {/* <td className="showcase-item-reference" style={{ ...rStyles.global.p }}>
                        <b>{`#${String(item.id).slice(-6)}` ?? '-'}</b>
                      </td> */}
                      <td className="showcase-item-image">
                        <img
                          src={`data:image/jpg;base64,${item.imageBase64}`}
                          alt={`Showcase Item ${item.id}`}
                          style={{ ...rStyles.showcaseItemImage }}
                        />
                      </td>
                      <td className="showcase-item-actions" style={{ ...rStyles.actionsContainer }}>
                        <AppButton
                          label="Exclure"
                          disabled={false}
                          action={() => {
                            if (item.id) {
                              removeDefaultShowcaseItem(item.id);
                            }
                          }}
                        />
                      </td>
                      <td>
                        <div className="order-container" style={{ ...rStyles.orderContainer }}>
                          {i !== 0 && (
                            <button
                              className="btn-order btn-up"
                              style={{ ...rStyles.btnOrder }}
                              type="button"
                              disabled={i === 0}
                              onClick={() => {
                                if (item.id) {
                                  switchDefaultShowcaseOrder(item.id, 'up');
                                }
                              }}
                            >
                              <i className="ti-arrow-up" />
                            </button>
                          )}
                          {i !== defaultShowcaseItems.length - 1 && (
                            <button
                              className="btn-order btn-down"
                              style={{ ...rStyles.btnOrder }}
                              type="button"
                              disabled={i === defaultShowcaseItems.length - 1}
                              onClick={() => {
                                if (item.id) {
                                  switchDefaultShowcaseOrder(item.id, 'down');
                                }
                              }}
                            >
                              <i className="ti-arrow-down" />
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <div className="no-item-found" style={{ ...rStyles.noItemFound }}>
                    Il n'y a aucune images par défaut disponible.
                  </div>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="widget">
          <h6 className="widget-title" style={{ ...rStyles.global.h6 }}>
            Images promotionnelles
          </h6>
          <div className="table-responsive">
            <table className="table check-tbl" style={{ ...rStyles.checkTbl }}>
              <thead>
                <tr>
                  {/* <th style={{ ...rStyles.global.th }}>Référence #</th> */}
                  <th style={{ ...rStyles.global.th }}>Image</th>
                  <th style={{ ...rStyles.global.th }}>Date de début</th>
                  <th style={{ ...rStyles.global.th }}>Date de fin</th>
                  <th style={{ ...rStyles.global.th }}>Actions</th>
                  <th style={{ ...rStyles.global.th }}>Ordre</th>
                </tr>
              </thead>
              <tbody>
                {promoShowcaseItems.length ? (
                  promoShowcaseItems.map((item, i) => (
                    <tr
                      key={`showcase-promo-key-${item.id}${i}`}
                      style={{ backgroundColor: i > 2 ? '#f2f3f5' : '#fff' }}
                    >
                      {/* <td className="showcase-item-reference" style={{ ...rStyles.global.p }}>
                        <b>{`#${String(item.id).slice(-6)}` ?? '-'}</b>
                      </td> */}
                      <td className="showcase-item-image">
                        <img
                          src={`data:image/jpg;base64,${item.imageBase64}`}
                          alt={`Showcase Item ${item.id}`}
                          style={{ ...rStyles.showcaseItemImage }}
                        />
                      </td>
                      <td className="showcase-item-start-date" style={{ ...rStyles.global.p }}>
                        <input
                          type="date"
                          min={new Date().toISOString().split('T')[0]}
                          value={inputDate(item.startDate) ?? ''}
                          onChange={(e) => {
                            if (item.id) {
                              updatePromoShowcase(item.id, new Date(e.target.value));
                            }
                          }}
                        />
                      </td>
                      <td className="showcase-item-end-date" style={{ ...rStyles.global.p }}>
                        <input
                          type="date"
                          min={new Date().toISOString().split('T')[0]}
                          value={inputDate(item.endDate) ?? ''}
                          onChange={(e) => {
                            if (item.id) {
                              updatePromoShowcase(item.id, undefined, new Date(e.target.value));
                            }
                          }}
                        />
                      </td>
                      <td className="showcase-item-actions" style={{ ...rStyles.actionsContainer }}>
                        <AppButton
                          label="Exclure"
                          disabled={false}
                          action={() => {
                            if (item.id) {
                              removePromoShowcaseItem(item.id);
                            }
                          }}
                        />
                      </td>
                      <td>
                        <div className="order-container" style={{ ...rStyles.orderContainer }}>
                          {i !== 0 && (
                            <button
                              className="btn-order btn-up"
                              style={{ ...rStyles.btnOrder }}
                              type="button"
                              disabled={i === 0}
                              onClick={() => {
                                if (item.id) {
                                  switchPromoShowcaseOrder(item.id, 'up');
                                }
                              }}
                            >
                              <i className="ti-arrow-up" />
                            </button>
                          )}
                          {i !== promoShowcaseItems.length - 1 && (
                            <button
                              className="btn-order btn-down"
                              style={{ ...rStyles.btnOrder }}
                              type="button"
                              disabled={i === promoShowcaseItems.length - 1}
                              onClick={() => {
                                if (item.id) {
                                  switchPromoShowcaseOrder(item.id, 'down');
                                }
                              }}
                            >
                              <i className="ti-arrow-down" />
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <div className="no-item" style={{ ...rStyles.noItemFound }}>
                    Il n'y a aucune images promotionnelles disponible.
                  </div>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="widget">
          <div className="widget-header" style={{ ...rStyles.widgetHeader }}>
            <h6 className="widget-title widget-all-image-title" style={{ ...rStyles.global.h6 }}>
              Toutes les images
            </h6>
            <div className="widget-add-image">
              <AppButton label="Ajouter une image" variant="v2" action={handleAddImageButtonClick} />
              <input
                type="file"
                ref={addImageFileInputRef}
                accept=".jpg, .jpeg, .png"
                style={{ display: 'none' }}
                onChange={handleAddImage}
              />
            </div>
          </div>

          <div className="table-responsive">
            <table className="table check-tbl" style={{ ...rStyles.checkTbl }}>
              <thead>
                <tr>
                  {/* <th style={{ ...rStyles.global.th }}>Référence #</th> */}
                  <th style={{ ...rStyles.global.th }}>Image</th>
                  <th style={{ ...rStyles.global.th }}>Ajouté le</th>
                  <th style={{ ...rStyles.global.th }}>Modifié le</th>
                  <th style={{ ...rStyles.global.th }}>État</th>
                  <th style={{ ...rStyles.global.th }}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {showcaseItems.length ? (
                  showcaseItems.map((item, i) => (
                    <tr key={`showcase-key-${item.id}${i}`}>
                      {/* <td className="showcase-item-reference" style={{ ...rStyles.global.p }}>
                        <b>{`#${String(item.id).slice(-6)}` ?? '-'}</b>
                      </td> */}
                      <td className="showcase-item-image">
                        <img
                          src={`data:image/jpg;base64,${item.imageBase64}`}
                          alt={`Showcase Item ${item.id}`}
                          style={{ ...rStyles.showcaseItemImage }}
                        />
                      </td>
                      <td className="showcase-item-createdat" style={{ ...rStyles.global.p }}>
                        <div className="styled-text" style={{ ...rStyles.styledText }}>
                          {formatDate(item.createdAt) ?? '-'}
                        </div>
                      </td>
                      <td className="showcase-item-updatedat" style={{ ...rStyles.global.p }}>
                        <div className="styled-text" style={{ ...rStyles.styledText }}>
                          {formatDate(item.updatedAt) ?? '-'}
                        </div>
                      </td>
                      <td className="showcase-item-state" style={{ ...rStyles.global.p }}>
                        <div
                          className="styled-text"
                          style={{
                            ...rStyles.styledText,
                            backgroundColor:
                              item.type === 'Default' ? '#c0f0d7' : item.type === 'Promo' ? '#f0ebc0' : '#f0f0f0',
                          }}
                        >
                          {item.type === 'None' ? 'Non défini' : item.type === 'Default' ? 'par défaut' : 'Promo'}
                        </div>
                      </td>
                      <td className="showcase-item-actions" style={{ ...rStyles.actionsContainer }}>
                        <AppButton
                          label="Définir comme image par défaut"
                          variant="v2"
                          disabled={item.type === ShowcaseType.Default}
                          action={() => {
                            if (item.id) {
                              addDefaultShowcaseItem(item.id);
                            }
                          }}
                        />
                        <AppButton
                          label="Définir comme image de promo"
                          variant="v2"
                          disabled={item.type === ShowcaseType.Promo}
                          action={() => {
                            if (item.id) {
                              addPromoShowcaseItem(item.id);
                            }
                          }}
                        />
                        <div className="widget-add-image">
                          <AppButton
                            label="Remplacer l'image"
                            variant="v2"
                            action={() => {
                              if (item.id) {
                                handleUpdateImageButtonClick(item.id);
                              }
                            }}
                          />
                          <input
                            type="file"
                            ref={updateImageFileInputRef}
                            accept=".jpg, .jpeg, .png"
                            style={{ display: 'none' }}
                            onChange={(event) => {
                              handleUpdateImage(event);
                            }}
                          />
                        </div>
                        <AppButton
                          label="supprimer"
                          disabled={false}
                          action={() => {
                            if (item.id) {
                              deleteShowcaseItem(item.id);
                            }
                          }}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <div className="no-Item-found" style={{ ...rStyles.noItemFound }}>
                    Il n'y a actuellement aucune image de vitrine disponible.
                  </div>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .widget-header {
    display: flex;
    align-items: center; /* vertically align items in the center */
    gap: 1rem;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  .widget-all-image-title {
    flex: 0 1 auto;
  }
  .widget-add-image {
    flex: 0 1 auto; /* flex-shrink: 0; flex-grow: 1; auto width */
  }
  .check-tbl thead th {
    border-right: 1px solid white;
  }
  .showcase-item-reference {
    text-transform: uppercase;
  }

  .styled-text {
    background-color: #f0f0f0;
    border: none;
    color: #555;
    font-size: 16px;
    padding: 8px 12px;
    border-radius: 6px;
    text-align: center;
  }
  .order-container {
    display: flex;
    flex-direction: row;
    gap: 6px;
    align-items: center;
  }

  .btn-order {
    background-color: #f0f0f0;
    border: none;
    color: #555;
    font-size: 16px;
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 6px;
    transition: background-color 0.3s ease;
  }

  .btn-order:hover {
    background-color: #e0e0e0;
  }

  .btn-order i {
    font-size: 20px;
  }
`;
