import { useEffect, useState } from 'react';
import { DispatchAsyncResult } from 'react-redux-dispatch-async/lib/dispatchAsync';
import styled from 'styled-components';
import _ from 'lodash';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { rSize } from 'core/util/ResponsiveSizeAdapter';
import { PageTitle } from '../../../common/ui/layouts/PageTitle';
import { emptyUser, User } from '../../domain/entities/User';
import { AppButton } from '../../../common/ui/elements/AppButton';
import { isValidEmail } from '../../../configuration/helpers/HelperFunctions';
import { SignInForm } from '../../../common/ui/elements/SignInForm';
import { FormErrorList } from '../../../common/ui/elements/FormErrorList';
import {
  ERROR_EMPTY_FIELDS,
  ERROR_ENTER_VALID_EMAIL,
  ERROR_LOGIN,
  ERROR_WRONG_EMAIL_OR_PASSWORD,
} from '../../../configuration/helpers/constants';
import { useNotificationContext } from '../../../common/configuration/notificationContextProvider';

interface SignInProps {
  stateConnectedUser?: User;
  sendSignInAsync(newUser: User): Promise<DispatchAsyncResult<void>>;
}
export const SignInDumb = ({ stateConnectedUser, sendSignInAsync }: SignInProps) => {
  const history = useHistory();
  const notifications = useNotificationContext();
  const [user, setUser] = useState<User>(emptyUser);
  const [showError, setShowError] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<string[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const location = useLocation<{ expiredSession: boolean }>();
  const { expiredSession } = location.state || {};

  const rStyles = {
    global: {
      h4: {
        fontSize: rSize(24),
      },
      p: {
        fontSize: rSize(18),
      },
    },
    signUpText: {
      marginTop: rSize(10),
    },
  };

  useEffect(() => {
    if (expiredSession === true) {
      notifications.setMessage({
        message: 'La session a expiré. Veuillez essayer de vous reconnecter.',
        status: 'warn',
      });
    }
  }, [expiredSession]);

  useEffect(() => {
    if (stateConnectedUser?.id) {
      history.push('/');
    }
  }, [stateConnectedUser]);

  useEffect(() => {
    validateForm();
  }, [user]);

  const validateForm = () => {
    const errors: string[] = [];
    if (!isValidEmail(user.email)) {
      errors.push(ERROR_ENTER_VALID_EMAIL);
    }
    if (_.isEmpty(user.password)) {
      errors.push(ERROR_EMPTY_FIELDS);
    }
    setFormErrors(errors);
  };

  const sendSignIn = async () => {
    setShowError(true);
    if (formErrors.length) {
      return;
    }
    setIsSubmitting(true);
    try {
      const response: any = await sendSignInAsync(user);
      if (response.success) {
        // Scenario A: logged in successfully
        history.push('/');
        setFormErrors([]);
      } else {
        // Scenario B: failed to logged in
        setFormErrors([ERROR_WRONG_EMAIL_OR_PASSWORD]);
      }
    } catch (error) {
      // Handle other errors
      notifications.setMessage({
        message: ERROR_LOGIN,
        status: 'error',
      });
      console.error('sendSignIn caught error', error);
    } finally {
      setIsSubmitting(false);
    }
  };
  return (
    <Wrapper>
      <div className="page-content">
        <PageTitle pageTitle="Connexion" />
        <section className="content-inner shop-account">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-6 mb-4">
                <div className="login-area">
                  <h4 className="text-secondary" style={{ ...rStyles.global.h4 }}>
                    Connexion
                  </h4>
                  <p className="font-weight-600" style={{ ...rStyles.global.p }}>
                    Connectez-vous
                  </p>
                  <br />
                  {showError && <FormErrorList errors={formErrors} fontSize={rStyles.global.p.fontSize} />}
                  <SignInForm showError={showError} user={user} setUser={setUser} enterFunc={sendSignIn} />
                  <AppButton label="Se connecter" disabled={isSubmitting} action={sendSignIn} />
                  <p className="sign-up-text" style={{ ...rStyles.signUpText, ...rStyles.global.p }}>
                    Vous n'avez pas encore de compte?
                    <Link to="/inscription"> Inscrivez-vous !</Link>
                  </p>
                  <p className="sign-up-text" style={{ ...rStyles.signUpText, ...rStyles.global.p }}>
                    Mot de passe oublié?
                    <Link to="/mot-de-passe-oublie"> Réinitialisez-le ici !</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .sign-up-text {
    margin-top: 10px;
    font-size: 16px;
  }
`;
