/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import { DispatchAsyncResult } from 'react-redux-dispatch-async/lib/dispatchAsync';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { rSize } from 'core/util/ResponsiveSizeAdapter';
import { PageTitle } from '../../../common/ui/layouts/PageTitle';
import { Product } from '../../domain/entities/Product';
import noImageImage from '../../../assets/images/NoImageImage.png';
import { priceDisplay } from '../../../configuration/helpers/HelperFunctions';
import { CartButton } from '../../../common/ui/elements/CartButton';
import { Cart } from '../../domain/entities/Cart';

interface WishListProps {
  stateWishProducts: Product[] | undefined;
  stateCart: Cart | undefined;
  getWishProductsAsync(): Promise<DispatchAsyncResult<void>>;
  // wish
  saveWishProductAsync(product: Product): Promise<DispatchAsyncResult<Product>>;
  deleteWishProductAsync(Art_Ean13: string): Promise<DispatchAsyncResult<boolean>>;
  // cart
  saveCartProductAsync(product: Product): Promise<DispatchAsyncResult<Product>>;
  deleteCartProductAsync(Art_Ean13: string): Promise<DispatchAsyncResult<boolean>>;
}
export const WishListDumb = ({
  stateWishProducts,
  stateCart,
  getWishProductsAsync,
  saveWishProductAsync,
  deleteWishProductAsync,
  saveCartProductAsync,
  deleteCartProductAsync,
}: WishListProps) => {
  const rStyles = {
    global: {
      h4: {
        fontSize: rSize(24),
      },
      p: {
        fontSize: rSize(18),
      },
      th: {
        fontSize: rSize(18),
      },
    },
    quantity: {
      width: rSize(150),
    },
    quantityButton: {
      width: rSize(50),
      height: rSize(50),
      fontSize: rSize(16),
    },
    quantityInput: {
      fontSize: rSize(16),
      height: rSize(50),
    },
    closeButton: {
      width: rSize(40),
      height: rSize(40),
      lineHeight: `${rSize(40)}px`,
      fontSize: rSize(20),
    },
  };

  useEffect(() => {
    getWishProductsAsync();
  }, []);

  const handleDeleteClick = (product: Product) => {
    deleteWishProductAsync(product.Art_Ean13);
  };
  const handleNumPlus = (product: Product) => {
    saveWishProductAsync({ ...product, Art_Quantity: (product.Art_Quantity ?? 0) + 1 });
  };
  const handleNumMinus = (product: Product) => {
    if (product.Art_Quantity && product.Art_Quantity > 1) {
      saveWishProductAsync({ ...product, Art_Quantity: (product.Art_Quantity ?? 0) - 1 });
    }
  };

  return (
    <Wrapper className="page-content">
      <PageTitle pageTitle="Liste De Souhaits" />
      <section className="content-inner-1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="table-responsive">
                <table className="table check-tbl">
                  <thead>
                    <tr>
                      <th style={{ ...rStyles.global.th }}>Livre</th>
                      <th style={{ ...rStyles.global.th }}>Titre</th>
                      <th style={{ ...rStyles.global.th }}>Prix unitaire</th>
                      <th style={{ ...rStyles.global.th }}>Quantité</th>
                      <th style={{ ...rStyles.global.th }}>Ajouter Au Panier</th>
                      <th style={{ ...rStyles.global.th }}>Supprimer</th>
                    </tr>
                  </thead>
                  <tbody>
                    {stateWishProducts && stateWishProducts.length > 0 ? (
                      stateWishProducts.map((data, i) => {
                        const price = data.Art_Prix_Promo ?? data.Art_Prix;
                        const quantity = data.Art_Quantity ?? 1;
                        const isInCart = !!stateCart?.items.find((item) => item.Art_Ean13 === data.Art_Ean13);

                        return (
                          <tr key={`wish-prod-key-${data?.id}${i}`}>
                            <td className="product-item-img">
                              <img
                                src={data.Art_Images.length ? `${data.Art_Images[0]}` : `${noImageImage}`}
                                alt="product display"
                              />
                            </td>
                            <td className="product-item-name" style={{ ...rStyles.global.p }}>
                              {data.Art_Titre}
                            </td>
                            <td className="product-item-price" style={{ ...rStyles.global.p }}>
                              {priceDisplay(price)}
                            </td>
                            <td className="product-item-quantity">
                              <div className="quantity btn-quantity style-1 me-3" style={{ ...rStyles.quantity }}>
                                <button
                                  className="btn btn-plus"
                                  style={{ ...rStyles.quantityButton }}
                                  type="button"
                                  onClick={() => {
                                    handleNumPlus(data);
                                  }}
                                >
                                  <i className="ti-plus" />
                                </button>
                                <input
                                  type="text"
                                  className="quantity-input"
                                  style={{ ...rStyles.quantityInput }}
                                  value={quantity}
                                  disabled
                                />
                                <button
                                  className="btn btn-minus "
                                  style={{ ...rStyles.quantityButton }}
                                  type="button"
                                  onClick={() => {
                                    handleNumMinus(data);
                                  }}
                                >
                                  <i className="ti-minus" />
                                </button>
                              </div>
                            </td>
                            <td className="product-item-total" style={{ ...rStyles.global.p }}>
                              <CartButton
                                inCart={isInCart}
                                action={() => {
                                  if (isInCart) {
                                    deleteCartProductAsync(data.Art_Ean13);
                                  } else if (data) {
                                    saveCartProductAsync({ ...data, Art_Quantity: data.Art_Quantity });
                                  }
                                }}
                              />
                            </td>
                            <td className="product-item-close">
                              <Link
                                to="#"
                                className="ti-close"
                                style={{ ...rStyles.closeButton }}
                                onClick={() => handleDeleteClick(data)}
                              />
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <div className="no-orders" style={{ ...rStyles.global.p }}>
                        Vous n'avez pas encore d'articles dans la liste de souhaits
                      </div>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .no-orders {
    font-size: 20px;
    padding-left: 15px;
  }
`;
