export interface ResetPasswordProps {
  id?: number;
  accessToken?: string;
  newPassword: string;
  newPasswordConfirm: string;
}

export const emptyResetPassProps = {
  newPassword: '',
  newPasswordConfirm: '',
};
