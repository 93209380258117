import { Product } from './Product';

export interface ProductState {
  count: number;
  products: Product[];
  subCategoryMentions?: SubCategoryMention[];
}

export interface SubCategoryMention {
  subCategoryNumber: number;
  mentionsCount: number;
}

export const defaultProductState: ProductState = {
  count: 0,
  products: [],
};
