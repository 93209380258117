/* eslint-disable react/no-danger */
import { useEffect, useState } from 'react';
import { DispatchAsyncResult } from 'react-redux-dispatch-async/lib/dispatchAsync';
import { Link, useLocation } from 'react-router-dom';
import { Nav, Tab } from 'react-bootstrap';
import styled from 'styled-components';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useLoaderContext } from 'common/configuration/loaderContextProvider';
import { useNotificationContext } from 'common/configuration/notificationContextProvider';

import { GetProductsProps, Product } from 'generalContext/domain/entities/Product';
import { ProductState } from 'generalContext/domain/entities/ProductState';
import { rSize } from 'core/util/ResponsiveSizeAdapter';
import useResponsiveProductCount from 'core/util/ResponsiveSliderProductCount';
import { Review } from '../../domain/entities/Review';
import { SubCategory } from '../../domain/entities/SubCategory';
import { WishButton } from '../../../common/ui/elements/WishButton';
import {
  calculateAverageRating,
  getProductStock,
  productFromArray,
} from '../../../configuration/helpers/HelperFunctions';
import { CartButton } from '../../../common/ui/elements/CartButton';
import { Cart } from '../../domain/entities/Cart';
import { StarRating } from '../../../common/ui/elements/StarRating';
import { ProductReviews } from '../../../common/ui/elements/ProductReviews';
import { User } from '../../../authContext/domain/entities/User';
import { ProductDetailsTable } from '../../../common/ui/elements/ProductDetailsTable';
import { ProductPrice } from '../../../common/ui/elements/ProductPrice';
import noImageImage from '../../../assets/images/NoImageImage.png';
import { Color } from '../../../assets/styles/constantStyles';
import { SliderGeneral } from '../components/SliderGeneral';
import { ProductCard } from '../../../common/ui';

interface ProductDetailsProps {
  stateWishProducts: Product[] | undefined;
  stateCart: Cart | undefined;
  stateSubCategories: SubCategory[] | undefined;
  stateConnectedUser?: User | undefined;
  // products
  getProductsAsync(props?: GetProductsProps): Promise<DispatchAsyncResult<ProductState>>;
  // wish
  saveWishProductAsync(product: Product): Promise<DispatchAsyncResult<Product>>;
  getWishProductsAsync(): Promise<DispatchAsyncResult<void>>;
  deleteWishProductAsync(Art_Ean13: string): Promise<DispatchAsyncResult<boolean>>;
  // cart
  getCartProductsAsync(): Promise<DispatchAsyncResult<void>>;
  saveCartProductAsync(product: Product): Promise<DispatchAsyncResult<Product>>;
  deleteCartProductAsync(Art_Ean13: string): Promise<DispatchAsyncResult<boolean>>;
  // reviews
  getProductReviewsAsync(productEan: string): Promise<DispatchAsyncResult<Review[]>>;
  insertProductReviewAsync(newreview: Review): Promise<DispatchAsyncResult<Review>>;
  deleteProductReviewAsync(review: Review): Promise<DispatchAsyncResult<Review>>;
}
export const ProductDetailsDumb = ({
  stateWishProducts,
  stateCart,
  stateSubCategories,
  stateConnectedUser,
  getProductsAsync,
  saveWishProductAsync,
  deleteWishProductAsync,
  getWishProductsAsync,
  getCartProductsAsync,
  deleteCartProductAsync,
  saveCartProductAsync,
  getProductReviewsAsync,
  insertProductReviewAsync,
  deleteProductReviewAsync,
}: ProductDetailsProps) => {
  const location = useLocation();
  const loader = useLoaderContext();
  const notifications = useNotificationContext();
  const productEan = location.pathname.replace('/product-details/', '');
  const [product, setProduct] = useState<Product | undefined>();
  const [cartProduct, setCartProduct] = useState<Product | undefined>();
  const [reviews, setReviews] = useState<Review[]>([]);
  const [quantity, setQuantity] = useState<number>(1);
  const [sameAuthorProducts, setSameAuthorProducts] = useState<Product[]>([]);
  const [sameEditorProducts, setSameEditorProducts] = useState<Product[]>([]);
  const [newReviewSent, setNewReviewSent] = useState<boolean>(false);
  let wishListProduct: Product | undefined;

  const rStyles = {
    global: {
      h3: {
        fontSize: rSize(26),
        lineHeight: `normal`,
      },
      h4: {
        fontSize: rSize(24),
        lineHeight: `normal`,
      },
      h6: {
        fontSize: rSize(20),
        lineHeight: `normal`,
      },
      p: {
        fontSize: rSize(18),
      },
      navLink: {
        fontSize: rSize(18),
      },
    },
    productFayImageContainer: {
      minHeight: rSize(300),
      height: rSize(400),
    },
    quantity: {
      width: rSize(150),
    },
    quantityButton: {
      width: rSize(50),
      height: rSize(50),
      fontSize: rSize(16),
    },
    quantityInput: {
      fontSize: rSize(16),
      height: rSize(50),
    },
    productContainer: {
      width: rSize(300),
      padding: `${rSize(0)}px ${rSize(0)}px`,
      border: `${rSize(1)}px  solid var(--mono-grey-2)`,
    },
  };

  useEffect(() => {
    getWishProductsAsync();
    getCartProductsAsync();
  }, []);

  useEffect(() => {
    if (newReviewSent) {
      setTimeout(() => {
        setNewReviewSent(false);
      }, 3000);
    }
  }, [newReviewSent]);

  useEffect(() => {
    if (productEan) {
      getCurrentProduct();
    }
  }, [productEan]);

  useEffect(() => {
    if (product) {
      getProductReviews();
    }
  }, [product]);

  useEffect(() => {
    if (stateCart && product) {
      setCartProduct(productFromArray(stateCart.items, product));
      getProductReviews();
    }
  }, [stateCart, product]);

  useEffect(() => {
    if (stateWishProducts && product) {
      wishListProduct = productFromArray(stateWishProducts, product);
    }
  }, [stateWishProducts, product]);

  useEffect(() => {
    if (quantity > 0 && cartProduct) {
      saveCartProductAsync({ ...cartProduct, Art_Quantity: quantity });
    }
  }, [quantity]);

  const getCurrentProduct = async () => {
    try {
      loader.setMessage('_');
      const response = await getProductsAsync({
        Art_Ean13: productEan,
      });
      if (!response.success) {
        notifications.setMessage({
          message: "Une erreur s'est produite à 'getCurrentProduct'",
          status: 'error',
        });
        console.error('getCurrentProduct caught error', response.error);
      } else {
        setProduct(response.result.products[0]);
        getSameAuthorProducts(response.result.products[0].Art_Auteur);
      }
    } catch (error) {
      notifications.setMessage({
        message: "Une erreur s'est produite à 'getCurrentProduct'",
        status: 'error',
      });
      console.error('getCurrentProduct caught error', error);
    } finally {
      loader.setMessage('');
    }
  };

  const getSameAuthorProducts = async (author: string) => {
    try {
      const response = await getProductsAsync({
        Art_Auteur: author,
      });
      if (!response.success) {
        console.error('getCurrentProduct caught error', response.error);
      } else {
        setSameAuthorProducts(response.result.products);
        getSameEditorProducts(response.result.products[0].Art_Editeur);
      }
    } catch (error) {
      console.error('getCurrentProduct caught error', error);
    } finally {
      loader.setMessage('');
    }
  };

  const getSameEditorProducts = async (editor: string) => {
    try {
      const response = await getProductsAsync({
        Art_Editeur: editor,
      });
      if (!response.success) {
        console.error('getCurrentProduct caught error', response.error);
      } else {
        setSameEditorProducts(response.result.products);
      }
    } catch (error) {
      console.error('getCurrentProduct caught error', error);
    } finally {
      loader.setMessage('');
    }
  };

  const getProductReviews = () => {
    if (!product) {
      return;
    }
    getProductReviewsAsync(product.Art_Ean13)
      .then((res) => {
        if (res.success) {
          setReviews(res.result);
        } else {
          // console.log('getProductReviewsAsync error: ', res.error);
        }
      })
      .catch((err) => {
        // console.log('getProductReviewsAsync caught error: ', err);
      });
  };

  const sendNewReview = (reviewTosend: Review) => {
    insertProductReviewAsync(reviewTosend)
      .then((res) => {
        if (!res.success) {
          // console.log('insertProductReviewAsync error: ', res.error);
        } else {
          getProductReviews();
          setNewReviewSent(true);
        }
      })
      .catch((err) => {
        // console.log('insertProductReviewAsync caught error: ', err);
      });
  };

  const deleteReview = (review: Review) => {
    deleteProductReviewAsync(review)
      .then((res) => {
        if (!res.success) {
          // console.log('deleteProductReviewAsync error: ', res.error);
        } else {
          getProductReviews();
        }
      })
      .catch((err) => {
        // console.log('deleteProductReviewAsync caught error: ', err);
      });
  };

  const cartButtonClicked = () => {
    if (product) {
      if (cartProduct) {
        deleteCartProductAsync(product.Art_Ean13);
      } else if (quantity > 0) {
        saveCartProductAsync({ ...product, Art_Quantity: quantity });
      }
    }
  };

  const wishButtonClicked = () => {
    if (wishListProduct) {
      deleteWishProductAsync(wishListProduct.Art_Ean13);
    } else if (product) {
      saveWishProductAsync({ ...product, Art_Quantity: quantity });
    }
  };

  const centerSlidePercentage = () => {
    if (window.innerWidth <= 456) {
      return 90;
    }
    if (window.innerWidth <= 650) {
      return 50;
    }
    if (window.innerWidth <= 1001) {
      return 36;
    }
    if (window.innerWidth <= 1342) {
      return 28;
    }
    if (window.innerWidth <= 1418) {
      return 23;
    }
    return 28;
  };

  if (!product || !stateSubCategories) {
    return (
      <div className="container">
        <div style={{ margin: '100px 0px' }}>
          <h3>
            Désolé, ce produit n'existe pas.{' '}
            <Link style={{ textDecoration: 'underline', color: Color.PRIMARY }} to="/boutique">
              Retour à la boutique?
            </Link>
          </h3>
        </div>
      </div>
    );
  }

  return (
    <Wrapper className="page-content">
      <section className="content-inner-1">
        <div className="container">
          <div className="row my-4">
            <div
              className="col-lg-3 col-md-4 product-image-container mx-2 mb-4"
              style={{
                backgroundImage: product?.Art_Images?.length ? `url(${product.Art_Images[0]})` : `url(${noImageImage})`,
                ...rStyles.productFayImageContainer,
              }}
            />
            <div className="col-lg-8 col-md-6">
              <div className="product-header-container mb-3 ">
                <h4 className="title mb-1" style={{ ...rStyles.global.h4 }}>
                  {product?.Art_Titre}
                </h4>
                <h6 className="title mb-2" style={{ ...rStyles.global.h6 }}>
                  ISBN: {product?.Art_Ean13}
                </h6>
                <div className="shop-item-rating">
                  <StarRating value={calculateAverageRating(reviews)} size={rStyles.global.p.fontSize} showNumber />
                  {/* <SocialLinksShare /> */}
                </div>
              </div>
              <div className="product-body-container mx-2 mb-2 gap-2">
                <div className="book-details">
                  <ProductPrice product={product} fontSize={rStyles.global.h3.fontSize} />
                  <ul className="details-container">
                    {product?.Art_Auteur && (
                      <li>
                        <div className="writer-info">
                          <div className="detail" style={{ ...rStyles.global.h6 }}>
                            <span style={{ ...rStyles.global.p }}>Auteur</span>
                            {product?.Art_Auteur}
                          </div>
                        </div>
                      </li>
                    )}
                    {product?.Art_DateParu && (
                      <li>
                        <div className="date-info">
                          <div className="detail" style={{ ...rStyles.global.h6 }}>
                            <span style={{ ...rStyles.global.p }}>Année</span>
                            {product?.Art_DateParu && new Date(product?.Art_DateParu).getFullYear()}
                          </div>
                        </div>
                      </li>
                    )}
                    {product?.Art_EtatCom && (
                      <li>
                        <div className="writer-info">
                          <div className="detail" style={{ ...rStyles.global.h6 }}>
                            <span style={{ ...rStyles.global.p }}>Etat</span>
                            {product?.Art_EtatCom}
                          </div>
                        </div>
                      </li>
                    )}
                    <li>
                      <div className="writer-info">
                        <div className="detail" style={{ ...rStyles.global.h6 }}>
                          <span style={{ ...rStyles.global.p }}>Stock</span>
                          {getProductStock(product?.ProductStock)}
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="product-num d-flex flex-row">
                  <div className="quantity btn-quantity style-1 me-3" style={{ ...rStyles.quantity }}>
                    <button
                      className="btn btn-plus"
                      style={{ ...rStyles.quantityButton }}
                      type="button"
                      onClick={() => setQuantity(quantity + 1)}
                    >
                      <i className="ti-plus" />
                    </button>
                    <input
                      className="quantity-input"
                      style={{ ...rStyles.quantityInput }}
                      type="text"
                      value={quantity ?? 1}
                      name="demo_vertical2"
                      disabled
                    />
                    <button
                      className="btn btn-minus "
                      style={{ ...rStyles.quantityButton }}
                      type="button"
                      onClick={() =>
                        setQuantity((val) => {
                          if (val - 1 > 1) {
                            return val - 1;
                          }
                          return 1;
                        })
                      }
                    >
                      <i className="ti-minus" />
                    </button>
                  </div>
                  <CartButton
                    inCart={!!stateCart && !!stateCart?.items?.find((item) => item.Art_Ean13 === product.Art_Ean13)}
                    action={cartButtonClicked}
                  />
                  <WishButton
                    id={product.Art_Ean13}
                    disabled={!stateCart}
                    isActive={!!wishListProduct}
                    toggleWished={wishButtonClicked}
                  />
                </div>
              </div>
              <p
                className="product-details-description"
                style={{ ...rStyles.global.p }}
                dangerouslySetInnerHTML={{ __html: product?.Art_Description }}
              />
            </div>
          </div>
          <div className="row">
            <div>
              <Tab.Container defaultActiveKey="details">
                <div className="book-description tabs-site-button">
                  <Nav as="ul" className="nav nav-tabs">
                    <Nav.Item as="li">
                      <Nav.Link eventKey="details" style={{ ...rStyles.global.navLink }}>
                        Détails du livre
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item as="li">
                      <Nav.Link eventKey="review" style={{ ...rStyles.global.navLink }}>
                        Avis des clients
                      </Nav.Link>
                    </Nav.Item>

                    {!!sameAuthorProducts.length && (
                      <Nav.Item as="li">
                        <Nav.Link eventKey="author-products" style={{ ...rStyles.global.navLink }}>
                          Articles du même auteur
                        </Nav.Link>
                      </Nav.Item>
                    )}
                    {!!sameEditorProducts.length && (
                      <Nav.Item as="li">
                        <Nav.Link eventKey="editor-products" style={{ ...rStyles.global.navLink }}>
                          Articles du même editeur
                        </Nav.Link>
                      </Nav.Item>
                    )}
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="details">
                      <ProductDetailsTable product={product} subCategories={stateSubCategories} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="review">
                      <ReviewSubmitNotice newReviewSent={newReviewSent}>
                        <p style={{ ...rStyles.global.p }}>Votre commentaire a été soumis pour examen.</p>
                      </ReviewSubmitNotice>
                      <ProductReviews
                        stateConnectedUser={stateConnectedUser}
                        product={product}
                        reviews={reviews}
                        sendNewReview={sendNewReview}
                        deleteReview={deleteReview}
                      />
                    </Tab.Pane>
                    {!!sameAuthorProducts.length && (
                      <Tab.Pane eventKey="author-products">
                        <Carousel
                          autoPlay
                          interval={3000}
                          transitionTime={600}
                          showArrows
                          infiniteLoop
                          showStatus={false}
                          showThumbs={false}
                          emulateTouch
                          showIndicators={false}
                          swipeable
                          centerMode
                          centerSlidePercentage={centerSlidePercentage()}
                        >
                          {sameAuthorProducts.map((sameAuthorproduct, i) => (
                            <div className="product-card-container carousel-slide" key={`carousel-slide-${i}`}>
                              <ProductCard product={sameAuthorproduct} />
                            </div>
                          ))}
                        </Carousel>
                      </Tab.Pane>
                    )}
                    {!!sameEditorProducts.length && (
                      <Tab.Pane eventKey="editor-products">
                        <Carousel
                          autoPlay
                          interval={3000}
                          transitionTime={600}
                          showArrows
                          infiniteLoop
                          showStatus={false}
                          showThumbs={false}
                          emulateTouch
                          showIndicators={false}
                          swipeable
                          centerMode
                          centerSlidePercentage={centerSlidePercentage()}
                        >
                          {sameEditorProducts.map((sameEditorProduct, i) => (
                            <div className="product-card-container carousel-slide" key={`carousel-slide-${i}`}>
                              <ProductCard product={sameEditorProduct} />
                            </div>
                          ))}
                        </Carousel>
                      </Tab.Pane>
                    )}
                  </Tab.Content>
                </div>
              </Tab.Container>
            </div>
          </div>
        </div>
      </section>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  .product-image-container {
    min-height: 300px;
    justify-content: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  .product-body-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .dz-media {
    flex: 1;
  }
  .dz-content {
    flex: 2;
  }
  .nav.nav-tabs a:hover,
  .nav.nav-tabs a:focus {
    text-decoration: none;
  }

  @media (max-width: 1000px) {
    li {
      width: 50%;
      text-align: center;
    }
  }

  @media (max-width: 456px) {
    li {
      width: 100%;
      text-align: center;
    }
  }
  .product-details-description {
    white-space: pre-line;
    font-size: 1.1rem;
  }
  .details-container {
    display: flex;
    flex-direction: row;
    gap: 3rem;
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  .detail {
    display: flex;
    flex-direction: column;
    font-weight: bold;
  }
  span {
    font-weight: 500;
  }
  .carousel .control-arrow,
  .carousel.carousel-slider .control-arrow {
    -webkit-transition: all 0.25s ease-in;
    -moz-transition: all 0.25s ease-in;
    -ms-transition: all 0.25s ease-in;
    -o-transition: all 0.25s ease-in;
    transition: all 0.25s ease-in;
    opacity: 1;
    filter: alpha(opacity=100);
    z-index: 2;
    background: rgba(0, 0, 0, 0.4);
    width: 50px;
    height: 50px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .carousel .slide {
    filter: none !important; /* Remove any brightness filter applied */
  }

  .carousel .control-prev.control-arrow {
    left: 0;
  }

  .carousel .control-next.control-arrow {
    right: 0;
  }

  .carousel .control-prev.control-arrow:before {
    border-right: 8px solid #fff; /* White arrow */
  }

  .carousel .control-next.control-arrow:before {
    border-left: 8px solid #fff; /* White arrow */
  }

  .product-card-container {
    display: flex;
    width: 90%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 0px;
    border: 1px solid var(--mono-grey-2);
  }
`;

const ReviewSubmitNotice = styled.h4<{ newReviewSent: boolean }>`
  max-height: ${({ newReviewSent }) => (newReviewSent === true ? '100px' : '0px')};
  width: 100%;
  height: 100px;
  overflow: hidden;
  display: block;
  transition: all 300ms ease;
`;
