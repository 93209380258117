import { Action } from 'redux';
import { connect } from 'react-redux';
import { dispatchAsync } from 'react-redux-dispatch-async';
import { HomeDumb } from './Home.dumb';
import { AppState } from '../../../common/configuration/app.states';
import { getConnectedUser } from '../../../authContext/usecases/Auth.selectors';
import {
  getPrioritizedShowcaseItemsRequest,
  getProductsBySubCategoryNumberRequest,
} from '../../usecases/General.actions';
import { getAdminPreferances, getStateCategories, getStateSubCategories } from '../../usecases/General.selectors';

const mapState = (state: AppState) => ({
  stateAdminPreferances: getAdminPreferances(state),
  stateConnectedUser: getConnectedUser(state),
  stateCategories: getStateCategories(state),
  stateSubCategories: getStateSubCategories(state),
});

const mapDispatch = (dispatch: (a: Action) => void) => ({
  // navigateTo: (nav: NavigationPayload) => dispatch(navigateTo(nav)),
  getProductsBySubCategoryNumberAsync: (id: number) =>
    dispatchAsync(dispatch, getProductsBySubCategoryNumberRequest(id)),
  getPrioritizedShowcaseItemsAsync: () => dispatchAsync(dispatch, getPrioritizedShowcaseItemsRequest()),
});

export const HomeSmart = connect(mapState, mapDispatch)(HomeDumb);
