import { useEffect, useState } from 'react';
import { DispatchAsyncResult } from 'react-redux-dispatch-async/lib/dispatchAsync';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { Checkbox, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import { Product } from 'generalContext/domain/entities/Product';
import {
  appCurrency,
  DAY_IN_MILISECONDS,
  DELAYED_DELIVERY_ESTIMATED_DELAY,
  DELIVERY_PRODUCTS_ID_STANDARD,
  ERROR_GENERAL,
  ERROR_GETTING_DATA,
  ERROR_PAYMENT,
  GROUP_DELIVERY_QUESTION,
  GROUP_DELIVERY_TEXT,
  OUT_OF_STOCK_TEXT,
  STANDARD_DELIVERY_ESTIMATED_DELAY,
  TAKE_OUT_ADDRESS_QUESTION,
  TAKE_OUT_DELIVERY_QUESTION,
  TAKEOUT_DELIVERY_TEXT,
} from 'configuration/helpers/constants';
import { Magasin, MagasinState } from 'generalContext/domain/entities/Magasin';
import { AppButton } from 'common/ui/elements/AppButton';
import { InitializePaymentProps } from 'generalContext/domain/entities/Payment';
import { rSize } from 'core/util/ResponsiveSizeAdapter';
import { OrderDetails } from './components/OrderDetails';
import { OrderDisplayTable } from './components/OrderDisplayTable';
import { PaymentButtons } from './components/PaymentButtons';
import { emptyUser, User } from '../../../authContext/domain/entities/User';
import { PageTitle } from '../../../common/ui/layouts/PageTitle';
import { Cart } from '../../domain/entities/Cart';
import { UserDataForm } from '../../../common/ui/elements/UserDataForm';
import { useLoaderContext } from '../../../common/configuration/loaderContextProvider';
import { Order } from '../../domain/entities/Order';
import {
  formatDate,
  getProductPrice,
  getProductStock,
  getProfileDataFormErrors,
} from '../../../configuration/helpers/HelperFunctions';
import { useNotificationContext } from '../../../common/configuration/notificationContextProvider';

interface CheckoutProps {
  stateConnectedUser?: User;
  stateCart: Cart | undefined;
  stateMagasins: MagasinState | undefined;
  stateDeliveryProducts: Product[] | undefined;
  initializePaymentResponse: any;
  assertPaymentResponse: any;
  getDeliveryProductsAsync(): Promise<DispatchAsyncResult<void>>;
  getCartProductsAsync(): Promise<DispatchAsyncResult<void>>;
  insertOrderAsync(order: Order): Promise<DispatchAsyncResult<Order>>;
  initializePaymentAsync(data: InitializePaymentProps): Promise<DispatchAsyncResult<any>>;
  assertPaymentAsync(token: string): Promise<DispatchAsyncResult<any>>;
}

export const CheckoutDumb = ({
  stateConnectedUser,
  stateCart,
  stateMagasins,
  stateDeliveryProducts,
  initializePaymentResponse,
  assertPaymentResponse,
  getDeliveryProductsAsync,
  getCartProductsAsync,
  insertOrderAsync,
  initializePaymentAsync,
  assertPaymentAsync,
}: CheckoutProps) => {
  const loader = useLoaderContext();
  const history = useHistory();
  const notifications = useNotificationContext();
  const [user, setUser] = useState<User>(emptyUser);
  const [deliveryProduct, setDeliveryProduct] = useState<Product | undefined>(
    stateDeliveryProducts?.find((del) => del.id === DELIVERY_PRODUCTS_ID_STANDARD),
  );
  const [selectedMagasin, setSelectedMagasin] = useState<Magasin>();
  const [isTakeOut, setIsTakeOut] = useState<boolean>(true);
  const [outOfStockItems, setOutOfStockItems] = useState<Product[]>([]);
  const [inStockItems, setInStockItems] = useState<Product[]>([]);
  const [grouppedDelivery, setGrouppedDelivery] = useState<boolean>(true);
  const [orderResponse, setOrderResponse] = useState<any>();
  const [deliveryCost, setDeliveryCost] = useState<number>(0);

  const rStyles = {
    global: {
      h3: {
        fontSize: rSize(26),
      },
      h4: {
        fontSize: rSize(24),
      },
      p: {
        fontSize: rSize(18),
      },
    },
    wrapper: {
      margin: `${rSize(40)}px 0`,
    },
    cartSummaryTotal: {
      gap: rSize(20),
    },
  };

  useEffect(() => {
    getCartProductsAsync();
    getDeliveryProducts();
    /* initializePaymentAsync({
      amount: 1000,
      currency: 'USD',
      successUrl: `${window.location.origin}`,
      failUrl: 'https://example.com/fail',
      abortUrl: 'https://example.com/abort',
    })
      .then((result) => {
        if (result.success) {
          console.log('Payment initialized successfully:', result.result.ResponseHeader);
        } else {
          console.log('Payment initialized failed:', result.error);
        }
      })
      .catch((error) => {
        console.error('Unexpected error:', error);
      }); */
  }, []);

  useEffect(() => {
    if (stateCart) {
      const outItems = stateCart.items.filter((item) => getProductStock(item.ProductStock) < (item.Art_Quantity ?? 1));
      setOutOfStockItems(outItems);
      setInStockItems(stateCart.items.filter((item) => getProductStock(item.ProductStock) >= (item.Art_Quantity ?? 0)));
    }
  }, [stateCart]);

  useEffect(() => {
    if (stateMagasins) {
      setSelectedMagasin(stateMagasins.current);
      if (isTakeOut && deliveryProduct !== undefined) {
        setDeliveryProduct(undefined);
      }
    }
  }, [stateMagasins, stateDeliveryProducts]);

  useEffect(() => {
    if (!isTakeOut) {
      setDeliveryProduct(stateDeliveryProducts?.find((del) => del.id === DELIVERY_PRODUCTS_ID_STANDARD));
    }
  }, [stateDeliveryProducts]);

  useEffect(() => {
    if (stateDeliveryProducts?.length) {
      setDeliveryCost(isTakeOut ? 0 : (deliveryProduct?.Art_Prix ?? 0) * (grouppedDelivery ? 1 : 2));
    }
  }, [deliveryProduct, grouppedDelivery, isTakeOut, stateDeliveryProducts]);

  useEffect(() => {
    if (orderResponse?.status === 'COMPLETED') {
      let orderItems = (stateCart?.items ?? []).map((cartItem) => ({
        Art_Quantity: cartItem.Art_Quantity ?? 1,
        price: getProductPrice(cartItem),
        Art_Ean13: cartItem.Art_Ean13,
      }));
      if (deliveryProduct) {
        orderItems = [
          ...orderItems,
          {
            Art_Quantity: isTakeOut ? 0 : grouppedDelivery ? 1 : 2,
            price: deliveryProduct.Art_Prix,
            Art_Ean13: deliveryProduct.Art_Ean13,
          },
        ];
      }
      const newOrder: Order = {
        transactionId: orderResponse.id,
        magasinId: selectedMagasin?.magasinId ?? '',
        magasinAddress: selectedMagasin?.magasinAddress ?? '',
        deliveryAddress: selectedMagasin?.magasinAddress,
        // deliveryAddress: isTakeOut
        //   ? selectedMagasin?.magasinAddress ?? ''
        //   : `${user.addressMain}, ${user.addressSecond}, ${user.city}, ${user.zip}, ${user.country}`,
        transactionInfo: JSON.stringify(orderResponse),
        total: +((stateCart?.total ?? 0) + deliveryCost).toFixed(),
        estimatedDate: getDeliveryTimeText(),
        status: 'PAID',
        items: orderItems.filter((item) => item.Art_Quantity),
      };
      insertOrder(newOrder);
    }
  }, [orderResponse]);

  useEffect(() => {
    if (stateConnectedUser) {
      setUser({ ...stateConnectedUser });
    }
  }, [stateConnectedUser]);

  const getDeliveryProducts = async () => {
    try {
      const response: any = await getDeliveryProductsAsync();
      if (!response.success) {
        notifications.setMessage({
          message: ERROR_GETTING_DATA,
          status: 'error',
        });
      }
    } catch (error) {
      notifications.setMessage({
        message: ERROR_GETTING_DATA,
        status: 'error',
      });
      console.error('getdeliveryProduct caught error', error);
    }
  };

  const getDeliveryTimeText = () => {
    const time = Date.now();
    if (inStockItems.length && !outOfStockItems.length) {
      return `${formatDate(new Date(time + DAY_IN_MILISECONDS * STANDARD_DELIVERY_ESTIMATED_DELAY).toString())}`;
    }
    if (
      (!inStockItems.length && outOfStockItems.length) ||
      (inStockItems.length && outOfStockItems.length && grouppedDelivery)
    ) {
      return `${formatDate(new Date(time + DAY_IN_MILISECONDS * DELAYED_DELIVERY_ESTIMATED_DELAY).toString())}`;
    }
    return `${formatDate(
      new Date(time + DAY_IN_MILISECONDS * STANDARD_DELIVERY_ESTIMATED_DELAY).toString(),
    )} et ${formatDate(new Date(time + DAY_IN_MILISECONDS * DELAYED_DELIVERY_ESTIMATED_DELAY).toString())}`;
  };

  const insertOrder = async (order: Order) => {
    if (!stateCart) {
      return;
    }
    loader.setMessage('Redirection...');
    try {
      const response: any = await insertOrderAsync(order);
      if (!response.success) {
        notifications.setMessage({
          message: response.error.payload.toString(),
          status: 'success',
        });
      } else {
        getCartProductsAsync();
        setTimeout(() => {
          loader.setMessage('');
          history.replace('profile#historique');
        }, 3000);
      }
    } catch (error) {
      notifications.setMessage({
        message: "Une erreur s'est produite à 'insertOrder'",
        status: 'error',
      });
      console.error('insertOrder caught error', error);
    }
  };

  // Saferpay functions -------------------------

  const handlePaymentInitialization = async (data: InitializePaymentProps) => {
    try {
      loader.setMessage('Paiement en cours...');
      const result = await initializePaymentAsync(data);
      if (result.success) {
        const { RedirectUrl } = result.result;
        // console.log('Redirect URL:', RedirectUrl);
        // Open the redirect URL in a new window
        const paymentWindow = window.open(RedirectUrl, '_blank', 'width=600,height=800');
        // Check if the window is closed
        const checkWindowClosed = setInterval(() => {
          if (paymentWindow?.closed) {
            clearInterval(checkWindowClosed);
            loader.setMessage('');
            handlePaymentAssertion(result.result.Token);
          }
        }, 500);
      } else {
        loader.setMessage('');
        notifications.setMessage({
          message: ERROR_PAYMENT,
          status: 'error',
        });
      }
    } catch (error) {
      loader.setMessage('');
      notifications.setMessage({
        message: ERROR_GENERAL,
        status: 'error',
      });
      console.error('handlePaymentInitialization caught error', error);
    }
  };

  const handlePaymentAssertion = async (token: string) => {
    try {
      loader.setMessage('Vérification du paiement...');
      const result = await assertPaymentAsync(token);
      if (result.success) {
        loader.setMessage('');
        notifications.setMessage({
          message: 'Paiement réussi ! Merci pour votre achat.',
          status: 'success',
        });
        setOrderResponse({ id: Math.floor(100000 + Math.random() * 900000).toString(), status: 'COMPLETED' });
      } else {
        loader.setMessage('');
        notifications.setMessage({
          message: ERROR_PAYMENT,
          status: 'error',
        });
      }
    } catch (error) {
      loader.setMessage('');
      console.error('Unexpected error:', error);
      notifications.setMessage({
        message: ERROR_GETTING_DATA,
        status: 'error',
      });
    }
  };

  if (!stateConnectedUser) {
    return (
      <Wrapper style={{ ...rStyles.wrapper }}>
        <div className="page-content">
          <PageTitle pageTitle="Paiment" />
          <section className="content-inner-1 container">
            <h3 style={{ ...rStyles.global.h3 }}>Vous devez être connecté pour continuer.</h3>
            <p className="sign-up-text" style={{ ...rStyles.global.p }}>
              Vous avez déjà un compte?
              <Link to="/login"> Se connecter ici !</Link>
            </p>
          </section>
        </div>
      </Wrapper>
    );
  }
  if (!stateCart) {
    return null;
  }
  if (stateCart.items.length === 0) {
    return (
      <Wrapper style={{ ...rStyles.wrapper }}>
        <div className="page-content">
          <PageTitle pageTitle="Paiment" />
          <section className="content-inner-1 container">
            <h3 style={{ ...rStyles.global.h3 }}>Aucun article dans votre panier pour passer à la caisse.</h3>
          </section>
        </div>
      </Wrapper>
    );
  }
  return (
    <Wrapper style={{ ...rStyles.wrapper }}>
      <div className="container">
        <PageTitle pageTitle="Paiment" />
        <div className="payment-container row g-4">
          <div className="delivery-information col-12 col-lg-6">
            <div className="widget">
              <h4 className="widget-title" style={{ ...rStyles.global.h4 }}>
                Informations sur la livraison
              </h4>
              {getProfileDataFormErrors(user).length ? (
                <p style={{ ...rStyles.global.p }}>
                  Veuillez <Link to="/profile#profile">cliquer ici</Link> pour compléter les informations de votre
                  profil avant de continuer.
                </p>
              ) : (
                <>
                  <UserDataForm user={user} setUser={setUser} disabled />
                  <AppButton variant="v3" label="Modifier mon adresse" to="/profile#profile" />
                </>
              )}
            </div>
          </div>
          <div className="cart-summary-total column col-12 col-lg-6" style={{ ...rStyles.cartSummaryTotal }}>
            <div className="cart-summary col-12">
              <OrderDisplayTable stateCart={stateCart} />
            </div>
            <div className="total-commands col-12">
              <OrderDetails
                stateCart={stateCart}
                deliveryLabel={isTakeOut ? TAKEOUT_DELIVERY_TEXT : deliveryProduct?.Art_Titre ?? '-'}
                deliveryCost={deliveryCost}
                deliveryTime={getDeliveryTimeText()}
              />
              {/* <div className="group-delivery-container">
      {outOfStockItems.length !== 0 && <p>{OUT_OF_STOCK_TEXT}</p>}
      {outOfStockItems.length && inStockItems.length ? (
        <>
          <p>{GROUP_DELIVERY_TEXT}</p>
          <FormControlLabel
            label={GROUP_DELIVERY_QUESTION}
            control={<Checkbox checked={grouppedDelivery} onChange={() => setGrouppedDelivery((v) => !v)} />}
          />
        </>
      ) : null}
      {!isTakeOut && <p>Livraison estimée le : {getDeliveryTimeText()}</p>}
      <FormControlLabel
        label={`${TAKE_OUT_DELIVERY_QUESTION} le ${
          outOfStockItems.length
            ? formatDate(
                new Date(Date.now() + DAY_IN_MILISECONDS * DELAYED_DELIVERY_ESTIMATED_DELAY).toString(),
              )
            : formatDate(
                new Date(Date.now() + DAY_IN_MILISECONDS * STANDARD_DELIVERY_ESTIMATED_DELAY).toString(),
              )
        }`}
        control={<Checkbox checked={isTakeOut} onChange={() => setIsTakeOut((v) => !v)} />}
      />
      {isTakeOut && stateMagasins && stateMagasins.all.length > 1 && selectedMagasin && (
        <FormControl>
          <FormLabel id="magasin-radio-buttons-group-label">{TAKE_OUT_ADDRESS_QUESTION}</FormLabel>
          <RadioGroup
            aria-labelledby="magasin-radio-buttons-group-label"
            defaultValue={selectedMagasin?.magasinAddress}
            name="radio-buttons-group"
            onChange={(e) => {
              setSelectedMagasin(stateMagasins.all.find((mag) => mag.magasinAddress === e.target.value));
            }}
          >
            {stateMagasins.all?.map((magasin, i) => (
              <FormControlLabel
                key={`magasin-num-${i}`}
                value={magasin.magasinAddress}
                control={<Radio />}
                label={`${magasin.magasinName}: ${magasin.magasinAddress}`}
              />
            ))}
          </RadioGroup>
        </FormControl>
      )}
    </div> */}

              {getProfileDataFormErrors(stateConnectedUser).length ? (
                <p style={{ ...rStyles.global.p }}>
                  Veuillez <Link to="/profile#profile">cliquer ici</Link> pour compléter les informations de votre
                  profil avant de continuer.
                </p>
              ) : stateCart ? (
                /* <PaymentButtons
                      deliveryCost={deliveryCost}
                      stateCart={stateCart}
                      setOrderResponse={setOrderResponse}
                    /> */
                // <AppButton // this is for saferpay which is not available on this website
                //   label="Terminer la commande"
                //   action={() => {
                //     const amountInCents = (stateCart.total + deliveryCost) * 100;
                //     const roundedAmountInCents = Math.round(amountInCents);
                //     handlePaymentInitialization({
                //       amount: roundedAmountInCents, // amount is sent in the smallest currency unit
                //       currency: appCurrency.name,
                //       successUrl: `${window.location.origin}/successful-payment`,
                //       failUrl: `${window.location.origin}/successful-payment`,
                //       abortUrl: `${window.location.origin}/successful-payment`,
                //     });
                //   }}
                // />
                <PaymentButtons deliveryCost={deliveryCost} stateCart={stateCart} setOrderResponse={setOrderResponse} />
              ) : (
                <p style={{ ...rStyles.global.p }}>Désolé, vous devez être connecté pour continuer</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .group-delivery-container {
    margin-bottom: 30px;
    flex-direction: column;
    display: flex;
  }
  .payment-container {
    display: flex;
    flex-wrap: wrap;
  }

  .cart-summary-total {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;
