export interface ChangePasswordProps {
  currentPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
}

export const emptyChangePasswordProps: ChangePasswordProps = {
  currentPassword: '',
  newPassword: '',
  newPasswordConfirm: '',
};
