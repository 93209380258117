import { useEffect, useState } from 'react';
import { DispatchAsyncResult } from 'react-redux-dispatch-async/lib/dispatchAsync';
import { useHistory } from 'react-router-dom';
import { ProductState } from 'generalContext/domain/entities/ProductState';
import { PROMO_SUBCATEGORY_NUMBER } from 'configuration/helpers/constants';
import { AdminScrollingMessage } from 'common/ui/elements/AdminScrollingMessage';
import { AdminPreferances } from 'generalContext/domain/entities/AdminPreferances';
import { isDateBetween } from 'configuration/helpers/HelperFunctions';
import ResponsiveScreenAdapter from 'core/util/ResponsiveScreenAdapter';
import { MiddleHeader } from './components/MiddleHeader';
import { User } from '../../../../authContext/domain/entities/User';
import { Cart } from '../../../../generalContext/domain/entities/Cart';
import { CategoriesMenu } from '../../../../generalContext/ui/components/CategoriesMenu';
import { Category } from '../../../../generalContext/domain/entities/Category';
import { SubCategory } from '../../../../generalContext/domain/entities/SubCategory';
import { Product } from '../../../../generalContext/domain/entities/Product';
import { AuthTokens } from '../../../domain/entities/AuthTokens';
import { useNotificationContext } from '../../../configuration/notificationContextProvider';

interface HeaderProps {
  stateAuthTokens?: AuthTokens;
  stateAdminPreferances?: AdminPreferances;
  stateConnectedUser?: User;
  stateWishProducts: Product[] | undefined;
  stateCart: Cart | undefined;
  stateCategories: Category[] | undefined;
  stateSubCategories: SubCategory[] | undefined;
  getAdminPreferancesAsync(): Promise<DispatchAsyncResult<void>>;
  getUserDataAsync(): Promise<DispatchAsyncResult<void>>;
  getCategoriesAsync(): Promise<DispatchAsyncResult<void>>;
  getSubCategoriesAsync(): Promise<DispatchAsyncResult<void>>;
  signOutAsync(): Promise<DispatchAsyncResult<void>>;
  // promo products
  getProductsBySubCategoryNumberAsync(id: number): Promise<DispatchAsyncResult<ProductState>>;
  // wish
  getWishProductsAsync(): Promise<DispatchAsyncResult<void>>;
  // cart
  getCartProductsAsync(): Promise<DispatchAsyncResult<void>>;
  deleteCartProductAsync(Art_Ean13: string): Promise<DispatchAsyncResult<void>>;
  // magasin
  getMagasinListAsync(): Promise<DispatchAsyncResult<void>>;
  // delivery products
  getDeliveryProductsAsync(): Promise<DispatchAsyncResult<void>>;
}

export const HeaderDumb = ({
  stateAuthTokens,
  stateAdminPreferances,
  stateConnectedUser,
  stateWishProducts,
  stateCategories,
  stateSubCategories,
  stateCart,
  getAdminPreferancesAsync,
  getUserDataAsync,
  getCartProductsAsync,
  getWishProductsAsync,
  getCategoriesAsync,
  getSubCategoriesAsync,
  signOutAsync,
  getProductsBySubCategoryNumberAsync,
  deleteCartProductAsync,
  getMagasinListAsync,
  getDeliveryProductsAsync,
}: HeaderProps) => {
  const history = useHistory();
  const notifications = useNotificationContext();
  const [showPromo, setShowPromo] = useState<boolean>(false);
  const [adminMessage, setAdminMessage] = useState<string>('false');

  useEffect(() => {
    getAdminPreferancesAsync();
    getCategoriesAsync();
    getCategoriesAsync();
    getSubCategoriesAsync();
    getMagasinListAsync();
    getDeliveryProductsAsync();
    getPromoProducts(PROMO_SUBCATEGORY_NUMBER);
  }, []);

  useEffect(() => {
    getCartProductsAsync();
    if (stateConnectedUser) {
      getWishProductsAsync();
    }
  }, [stateConnectedUser]);

  useEffect(() => {
    if (stateAuthTokens) {
      getUserDataAsync();
    }
  }, [stateAuthTokens]);

  useEffect(() => {
    if (
      stateAdminPreferances?.adminMessage &&
      stateAdminPreferances?.messageStartDate &&
      stateAdminPreferances?.messageEndDate &&
      isDateBetween(new Date(stateAdminPreferances?.messageStartDate), new Date(stateAdminPreferances?.messageEndDate))
    ) {
      setAdminMessage(stateAdminPreferances?.adminMessage);
    } else {
      setAdminMessage('');
    }
  }, [stateAdminPreferances]);

  const getPromoProducts = async (subCatNum: number) => {
    try {
      const response: any = await getProductsBySubCategoryNumberAsync(subCatNum);
      if (response.success && !response.result.error) {
        setShowPromo(response.result.products);
      } else {
        notifications.setMessage({
          message: response.result.message,
          status: 'success',
        });
      }
    } catch (error) {
      notifications.setMessage({
        message: "Une erreur s'est produite à 'getRecommendedProducts'",
        status: 'error',
      });
      console.error('getRecommendedProducts caught error', error);
    }
  };

  const deleteCartProduct = async (Art_Ean13: string) => {
    try {
      const response: any = await deleteCartProductAsync(Art_Ean13);
      if (!response.success) {
        notifications.setMessage({
          message: response.error.payload.toString(),
          status: 'success',
        });
        console.error('deleteCartProduct failed:', response);
      }
    } catch (error) {
      notifications.setMessage({
        message: "Une erreur s'est produite à 'deleteCartProduct'",
        status: 'error',
      });
      console.error('deleteCartProduct caught error', error);
    }
  };

  const signOut = async () => {
    try {
      const response: any = await signOutAsync();
      if (response.success) {
        history.replace('/');
      }
    } catch (error) {
      // console.log('signOutAsync caught error', error);
    }
  };

  const DesktopLayout = (
    <>
      <MiddleHeader
        stateConnectedUser={stateConnectedUser}
        stateWishProducts={stateWishProducts}
        stateCart={stateCart}
        signOut={signOut}
        deleteCartProduct={deleteCartProduct}
      />
      <AdminScrollingMessage message={adminMessage} />
      <CategoriesMenu
        showPromo={showPromo}
        stateCategories={stateCategories || []}
        stateSubCategories={stateSubCategories || []}
      />
    </>
  );

  const MobileLayout = (
    <>
      <MiddleHeader
        stateConnectedUser={stateConnectedUser}
        stateWishProducts={stateWishProducts}
        stateCart={stateCart}
        signOut={signOut}
        deleteCartProduct={deleteCartProduct}
      />
      <AdminScrollingMessage message={adminMessage} />
    </>
  );

  return (
    <>
      {/* <TopHeader magasins={stateMagasins} /> */}
      <ResponsiveScreenAdapter defaultScreen={DesktopLayout} screenMobile={MobileLayout} />
    </>
  );
};
