import React, { ReactElement, useEffect, useState } from 'react';

const useWindowWidth = (): number => {
  const [width, setWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return width;
};

interface ResponsiveScreenAdapterProps {
  defaultScreen?: ReactElement;
  screenMobile?: ReactElement;
  screenTablet?: ReactElement;
  screenMobileTablet?: ReactElement;
  screenDesktop?: ReactElement;
}

const ResponsiveScreenAdapter: React.FC<ResponsiveScreenAdapterProps> = ({
  defaultScreen,
  screenMobile,
  screenTablet,
  screenMobileTablet,
  screenDesktop,
}) => {
  const width = useWindowWidth();

  if (screenMobile && width < 600) {
    return screenMobile;
  }
  if (screenTablet && width >= 600 && width < 1024) {
    return screenTablet;
  }
  if (screenMobileTablet && width < 1024) {
    return screenMobileTablet;
  }
  if (screenDesktop && width >= 1024) {
    return screenDesktop;
  }
  if (defaultScreen) {
    return defaultScreen;
  }

  // Return null if no screens are set and defaultScreen is not provided
  return null;
};

export default ResponsiveScreenAdapter;
