import styled from 'styled-components';
import { rSize } from 'core/util/ResponsiveSizeAdapter';
import { AppContainer } from './AppContainer';

interface AdminScrollingMessageProps {
  message?: string;
}
export const AdminScrollingMessage = ({ message }: AdminScrollingMessageProps) => {
  const rStyles = {
    global: {
      height: rSize(40),
      borderRadius: rSize(10),
    },
    scrollingText: { fontSize: rSize(18) },
  };

  if (!message) {
    return null;
  }
  return (
    <AppContainer>
      <Wrapper style={{ ...rStyles.global }}>
        <div className="bg" />
        <div className="scrolling-text" style={{ ...rStyles.scrollingText }}>
          {message}
        </div>
      </Wrapper>
    </AppContainer>
  );
};

const animDuration = 30;

const Wrapper = styled.div`
  background-color: var(--mono-grey-3);
  height: 40px;
  overflow: hidden;
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: row;
  .bg {
    width: 100%;
    height: 100%;
    background: rgb(244, 246, 250);
    background: linear-gradient(
      90deg,
      rgba(244, 246, 250, 1) 0%,
      rgba(244, 246, 250, 0) 10%,
      rgba(244, 246, 250, 0) 90%,
      rgba(244, 246, 250, 1) 100%
    );
    position: absolute;
    z-index: 2;
  }

  .scrolling-text {
    white-space: nowrap;
    overflow: hidden;
    display: flex;
    align-items: center;
    z-index: 1;
    height: 100%;
    width: 100%;
    animation: scrolltext ${animDuration}s linear infinite;
  }
  @keyframes scrolltext {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  @keyframes scrollalt {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  &:hover .scrolling-text {
    animation-play-state: paused;
  }
`;
