import { SagaIterator } from 'redux-saga';
// import { DependenciesType } from '../configuration/dependencies'
// import { Action } from 'redux-actions'
import { all, getContext } from 'redux-saga/effects';

export function* getDependencies(): SagaIterator {
  const dependencies = yield getContext('dependencies');
  return dependencies;
}

export function* commonSagas() {
  yield all([
    // takeLatest(loadFBDitemsRequest, loadFBDitemsSaga),
    // takeEvery(insertFBDitemsRequest, insertFBDitemSaga),
  ]);
}
