export interface MailObj {
  email?: string;
  name?: string;
  phone?: string;
  subject?: string;
  message?: string;
}

export const emptyMailObj: MailObj = {
  email: '',
  name: '',
  phone: '',
  subject: '',
  message: '',
};
