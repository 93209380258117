import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { rSize } from 'core/util/ResponsiveSizeAdapter';

type AppButtonVariant = 'v1' | 'v2' | 'v3';
interface AppButtonProps {
  variant?: AppButtonVariant;
  label: string;
  to?: string;
  disabled?: boolean;
  action?: () => void;
}
export const AppButton = ({ variant = 'v1', label, to, disabled, action }: AppButtonProps) => {
  const rStyles = {
    global: {
      p: {
        fontSize: rSize(16),
      },
    },
  };
  return (
    <Wrapper variant={variant}>
      {to ? (
        <Link
          to={to}
          className="btn btnhover mt-2"
          style={{
            ...rStyles.global.p,
          }}
        >
          {label}
        </Link>
      ) : (
        <button
          type="button"
          className={`btn btnhover mt-2 ${disabled ? 'disabled' : ''}`}
          style={{
            ...rStyles.global.p,
          }}
          onClick={action}
          disabled={disabled}
        >
          {label}
        </button>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ variant: AppButtonVariant }>`
  button,
  a {
    transition: all 300ms ease;
    font-size: 13px;
    width: 100%;
    white-space: nowrap;
    background-color: ${({ variant }) =>
      variant === 'v1' ? 'var(--primary)' : variant === 'v2' ? 'var(--secondary)' : 'var(--mono-grey-3)'};
    border-color: ${({ variant }) =>
      variant === 'v1' ? 'var(--primary)' : variant === 'v2' ? 'var(--secondary)' : 'var(--black)'};
    color: ${({ variant }) => (variant === 'v1' ? 'var(--white)' : variant === 'v2' ? 'var(--white)' : 'var(--black)')};
  }
  a,
  a:hover {
    text-decoration: none;
  }
  button:hover,
  a:hover {
    background-color: ${({ variant }) =>
      variant === 'v1' ? 'var(--primary)' : variant === 'v2' ? 'var(--secondary)' : 'var(--primary)'};
    border-color: ${({ variant }) =>
      variant === 'v1' ? 'var(--primary)' : variant === 'v2' ? 'var(--secondary)' : 'var(--primary)'};
    color: ${({ variant }) => (variant === 'v1' ? 'var(--white)' : variant === 'v2' ? 'var(--white)' : 'var(--white)')};
  }
  button.disabled {
    background-color: grey;
    border-color: grey;
  }
`;
