/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Link } from 'react-router-dom';

import bg3 from '../../../assets/images/background/bg3.jpg';

export const ErrorPage = () => (
  <div className="error-page overlay-secondary-dark" style={{ backgroundImage: `url(${bg3})` }}>
    <div className="error-inner text-center">
      <div className="dz_error" data-text="404">
        404
      </div>
      <h2 className="error-head">Nous sommes désolés. Mais la page que vous recherchez est introuvable.</h2>
      <Link to="/" className="btn btn-primary btn-border btnhover white-border">
        RETOUR À LA PAGE D'ACCUEIL
      </Link>
    </div>
  </div>
);
