import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Navigator } from './navigationContext/index';
import { AppState } from './common/configuration/app.states';

// Css
import './assets/styles/css/style.css';
import './assets/styles/css/style2.css';
import { LoaderOverlay } from './common/ui/elements/LoaderOverlay';
import { useLoaderContext } from './common/configuration/loaderContextProvider';
import { Notifications } from './common/ui/elements/Notifications';

const mapStateToProps = (state: AppState) => ({});

const mapDispatchToProps = {};

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const PlainApp: React.FC<Props> = ({ ...props }: Props) => {
  const loading = useSelector((state: AppState) => state.auth.loading);
  const loader = useLoaderContext();

  useEffect(() => {
    loader.setMessage(loading ? '_' : '');
  }, [loading]);

  if (loading) {
    return (
      <div
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100vw', height: '100vh' }}
      />
    );
  }

  return (
    <div className="App">
      <Notifications />
      <LoaderOverlay />
      <Navigator />
    </div>
  );
};
export const App = connect(mapStateToProps, mapDispatchToProps)(PlainApp);
