import { Action } from 'redux';
import { connect } from 'react-redux';
import { SideMenuDumb } from './SideMenu.dumb';
import { getStateCategories, getStateSubCategories } from '../../../../generalContext/usecases/General.selectors';
import { AppState } from '../../../configuration/app.states';

const mapState = (state: AppState) => ({
  stateCategories: getStateCategories(state),
  stateSubCategories: getStateSubCategories(state),
});

const mapDispatch = (dispatch: (a: Action) => void) => ({});

export const SideBarSmart = connect(mapState, mapDispatch)(SideMenuDumb);
