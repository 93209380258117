import { useEffect, useState } from 'react';
import { DispatchAsyncResult } from 'react-redux-dispatch-async/lib/dispatchAsync';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { Order } from 'generalContext/domain/entities/Order';
import { useNotificationContext } from 'common/configuration/notificationContextProvider';
import { formatDate, priceDisplay } from 'configuration/helpers/HelperFunctions';
import { User } from 'authContext/domain/entities/User';
import { rSize } from 'core/util/ResponsiveSizeAdapter';
import { PageTitle } from '../../../common/ui/layouts/PageTitle';
import noImageImage from '../../../assets/images/NoImageImage.png';

interface OrderInformationProps {
  stateConnectedUser?: User;
  getOrderHistoryAsync(): Promise<DispatchAsyncResult<void>>;
}
export const OrderInformationDumb = ({ stateConnectedUser, getOrderHistoryAsync }: OrderInformationProps) => {
  const notifications = useNotificationContext();
  const location = useLocation();
  const [order, setOrder] = useState<Order>();

  const searchParams = new URLSearchParams(location.search);
  const orderId: number = parseInt(searchParams.get('order') ?? '-1');

  const rStyles = {
    global: {
      h4: {
        fontSize: rSize(24),
      },
      p: {
        fontSize: rSize(18),
      },
      th: {
        fontSize: rSize(18),
      },
    },
    wrapper: {
      marginBottom: rSize(24),
    },
    orderItemRow: {
      maxWidth: rSize(700),
      margin: `${rSize(30)}px auto`,
      gap: rSize(30),
    },
    title: {
      fontSize: rSize(20),
    },
    orderItemImg: {
      maxWidth: rSize(200),
      maxHeight: rSize(240),
    },
    box: {
      fontSize: rSize(15),
      marginTop: rSize(20),
    },
    header: {
      padding: rSize(10),
    },
    orderInfoRow: {
      gap: rSize(10),
      margin: `${rSize(10)}px 0`,
    },
  };

  useEffect(() => {
    getOrderHistory();
  }, [location]);

  const getOrderHistory = async () => {
    try {
      const response: any = await getOrderHistoryAsync();
      if (response.success && !response.result.error) {
        setOrder(response.result.find((ord: Order) => ord.id === orderId));
      } else {
        notifications.setMessage({
          message: response.result.message,
          status: 'error',
        });
      }
    } catch (error) {
      notifications.setMessage({
        message: "Une erreur s'est produite à 'getOrderHistory'",
        status: 'error',
      });
      console.error('getOrderHistory caught error', error);
    }
  };

  if (!order) {
    return null;
  }
  return (
    <Wrapper style={{ ...rStyles.wrapper }}>
      <div className="page-content">
        <div className="container">
          <PageTitle pageTitle="Informations sur la commande" />
          <div className="box" style={{ ...rStyles.box }}>
            <h4 className="header" style={{ ...rStyles.header, ...rStyles.global.h4 }}>
              Information générales
            </h4>
            <div className="order-info-row" style={{ ...rStyles.orderInfoRow }}>
              <div className="order-info-col bolder" style={{ ...rStyles.global.p }}>
                Référence
              </div>
              <div className="order-info-col" style={{ ...rStyles.global.p }}>
                {order?.Order_Elesoft}
              </div>
            </div>
            <div className="order-info-row">
              <div className="order-info-col bolder" style={{ ...rStyles.global.p }}>
                Date
              </div>
              <div className="order-info-col" style={{ ...rStyles.global.p }}>
                {formatDate(order.createdAt) ?? '-'}
              </div>
            </div>
            <div className="order-info-row">
              <div className="order-info-col bolder" style={{ ...rStyles.global.p }}>
                Montant
              </div>
              <div className="order-info-col" style={{ ...rStyles.global.p }}>
                {priceDisplay(order?.total)}
              </div>
            </div>
            <div className="order-info-row">
              <div className="order-info-col bolder" style={{ ...rStyles.global.p }}>
                Statut
              </div>
              <div className="order-info-col" style={{ ...rStyles.global.p }}>
                {order?.Suivi}
              </div>
            </div>
            <div className="order-info-row">
              <div className="order-info-col bolder" style={{ ...rStyles.global.p }}>
                Adresse de livraison
              </div>
              <div className="order-info-col" style={{ ...rStyles.global.p }}>
                <span>
                  {stateConnectedUser?.firstName} {stateConnectedUser?.lastName},{' '}
                </span>
                {order?.deliveryAddress}
                {/* {(order?.deliveryAddress ?? '').split(',').map((line, i) => (
                  <p key={`address-line-${i}`}>{line}</p>
                ))} */}
              </div>
            </div>
            <div className="order-info-row">
              <div className="order-info-col bolder" style={{ ...rStyles.global.p }}>
                Facture
              </div>
              <div className="order-info-col" style={{ ...rStyles.global.p }}>
                {order?.FileInvoice ? (
                  <a href={order?.FileInvoice ?? '#'} target="_blank" rel="noreferrer">
                    Téléchargez votre facture
                  </a>
                ) : (
                  'En cours de traitement'
                )}
              </div>
            </div>
          </div>

          <div className="box">
            <h4 className="header" style={{ ...rStyles.global.h4 }}>
              Détails
            </h4>
            {order.items.map((item, i) => (
              <div key={`order-item-${i}`} className="order-item-row" style={{ ...rStyles.orderItemRow }}>
                <div className="order-item-img" style={{ ...rStyles.orderItemImg }}>
                  <img
                    src={
                      item.product?.Art_Images.length
                        ? `${(item.product?.Art_Images[0] as any).url}` // the images here are returned differently than the rest of the app, not worth it to change things now
                        : `${noImageImage}`
                    }
                    alt="product display"
                  />
                </div>
                <div className="order-item-desc">
                  <div className="title" style={{ ...rStyles.title }}>
                    {item?.product?.Art_Titre}
                  </div>
                  <div className="quantity-price">
                    <div className="quantity" style={{ ...rStyles.global.p }}>
                      {item.Art_Quantity} x {priceDisplay(item?.product?.Art_Prix)}
                    </div>
                    <div className="price" style={{ ...rStyles.global.p }}>
                      {priceDisplay(item?.product?.Art_Prix)}
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div className="sub-total">
              <span style={{ ...rStyles.global.h4 }}>Total</span>
              <span style={{ ...rStyles.global.h4 }}>{priceDisplay(order.total)}</span>
            </div>
            {/* <div className="delivery">
              <span>
                {() => {
                  const deliveryProd: OrderItem = order.items.filter(
                    (item) => +item.Art_Ean13 === +DELIVERY_PRODUCTS_ID_STANDARD,
                  )[0];
                  if (deliveryProd) {
                    return <p>{deliveryProd.price * deliveryProd.Art_Quantity}</p>;
                  }
                  return <p>Gratuit</p>;
                }}
              </span>
            </div> */}
            {/* <div className="total" >
            <span>Sous-total</span>
            </div> */}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-bottom: 20px;
  .sub-total,
  .delivery,
  .total {
    background-color: var(--secondary-ghost-darker);
    color: var(--secondary);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 40px;
    font-weight: 600;
    font-size: 24px;
    padding: 10px;
  }
  .order-item-row {
    max-width: 700px;
    margin: 30px auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 30px;
    .order-item-desc {
      flex: 1;
      height: 120px;
      max-height: 80%;
      .title {
        font-size: 20px;
        font-weight: 500;
        height: 50%;
        flex: 1;
        text-align: left;
      }
      .quantity-price {
        height: 50%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: end;
        flex: 1;
        .price {
          color: var(--secondary);
          font-size: 22px;
        }
        .quantity {
          color: var(--primary);
          font-size: 18px;
        }
      }
    }
    .order-item-img {
      flex: 1;
      overflow: hidden;
      max-width: 200px;
      max-height: 240px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .quantity-price {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .price {
      font-size: 24px;
      font-weight: 600;
    }
  }
  .order-row {
    border: 1px solid var(--primary);
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 10px;
  }
  .box {
    font-size: 15px;
    margin-top: 20px;
    border: 1px solid var(--secondary-ghost-darker);
    .header {
      font-weight: 600;
      background-color: var(--secondary-ghost-darker);
      text-align: center;
      padding: 10px;
      width: 100%;
    }
    .order-info-row {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: start;
      gap: 10px;
      margin: 10px 0;
      .bolder {
        font-weight: 600;
      }
      .order-info-col {
        width: 100%;
      }

      .order-info-col:nth-of-type(1) {
        text-align: right;
      }
    }
  }
  .transaction-name,
  .transaction-status,
  .transaction-total {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }
  .order-content-row {
    padding: 10px;
  }
  .order-content-row:not(:last-child) {
    border-bottom: 1px dashed var(--primary);
  }
`;
