import styled from 'styled-components';
import { rSize } from 'core/util/ResponsiveSizeAdapter';
import { Product } from '../../../generalContext/domain/entities/Product';
import { priceDisplay } from '../../../configuration/helpers/HelperFunctions';
import { Color } from '../../../assets/styles/constantStyles';

interface ProductPriceProps {
  product: Product;
  fontSize?: number;
}
export const ProductPrice = ({ product, fontSize = 18 }: ProductPriceProps) => {
  const rStyles = {
    fayProductPrice: {
      margin: `${rSize(5)}px 0`,
    },
    oldPrice: {
      fontSize: rSize(15),
      margin: `0 ${rSize(20)}px`,
    },
  };
  return (
    <Wrapper fontSize={fontSize}>
      {product.Art_Prix_Promo ? (
        <div
          className="fay-product-price"
          style={{
            margin: rStyles.fayProductPrice.margin,
          }}
        >
          <h4>{priceDisplay(product.Art_Prix_Promo)}</h4>
          <del
            className="old-price"
            style={{
              fontSize: rStyles.oldPrice.fontSize,
              margin: rStyles.oldPrice.margin,
            }}
          >
            {priceDisplay(product.Art_Prix)}
          </del>
        </div>
      ) : (
        <div
          className="fay-product-price"
          style={{
            margin: rStyles.fayProductPrice.margin,
          }}
        >
          <h5>{priceDisplay(product.Art_Prix)}</h5>
        </div>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ fontSize: number }>`
  .fay-product-price {
    font-size: 1.5em;
    font-weight: 600;
    margin: 5px 0;
    h5,
    h4 {
      font-weight: 900;
      font-size: ${({ fontSize }) => `${fontSize}px`};
      display: inline-block;
    }
    h4 {
      color: red;
    }
    h5 {
      color: var(--secondary);
    }
  }
  .old-price {
    color: ${Color.SECONDARY};
    font-size: 15px;
    margin: 0 20px;
  }
`;
