import { all, delay, put, take, takeEvery, takeLatest } from 'redux-saga/effects';
import { replace } from 'connected-react-router';
import { matchActionTypeRehydrate } from '../../../authContext/usecases/Auth.actions';
import { AuthTokens } from '../../domain/entities/AuthTokens';
import { getTokensToFetch } from '../../../configuration/helpers/fetch.helper';

// function* triggerAutoTokenRenewalSaga() {
//   if (ACCESS_TOKEN_LIFE_SPAN) {
//     yield delay(ACCESS_TOKEN_LIFE_SPAN - 10 * 1000);
//     yield put(renewTokensRequest());
//   }
// }

// function* handleFailureSaga(action: any) {
//   const { error } = action.payload;
//   // Check if the request failed due to unauthorized access
//   if (error.status === 401) {
//     yield put(replace('/'));
//   }
// }

function* tokensWatcherSaga() {
  /* const tokens: AuthTokens = getTokensToFetch();
  if (tokens.accessTokenExpiryTime) {
    const now = new Date();
    const beforeExpiry = new Date(new Date(tokens.accessTokenExpiryTime).getTime() - 60000);
    const isDue = beforeExpiry < now;

    if (isDue) {
      yield put(renewTokensRequest());
    }

    while (true) {
      if (isDue) {
        yield put(renewTokensRequest());
      }
    }
  } */
}

export function* applicationSagas(): any {
  yield all([
    tokensWatcherSaga(),
    // takeLatest([matchActionTypeRehydrate, renewTokensRequest], triggerAutoTokenRenewalSaga),
    // takeEvery(matchActionTypeFailure, handleFailureSaga),
  ]);
}
