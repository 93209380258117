import { Action } from 'redux';
import { connect } from 'react-redux';
import { dispatchAsync } from 'react-redux-dispatch-async';
import { WishListDumb } from './WishList.dumb';
import { AppState } from '../../../common/configuration/app.states';
import { getConnectedUser } from '../../../authContext/usecases/Auth.selectors';
import {
  deleteCartProductRequest,
  deleteWishProductRequest,
  getWishProductsRequest,
  saveCartProductRequest,
  saveWishProductRequest,
} from '../../usecases/General.actions';
import { getStateCart, getStateWishProducts } from '../../usecases/General.selectors';
import { Product } from '../../domain/entities/Product';

const mapState = (state: AppState) => ({
  stateConnectedUser: getConnectedUser(state),
  stateCart: getStateCart(state),
  stateWishProducts: getStateWishProducts(state),
});

const mapDispatch = (dispatch: (a: Action) => void) => ({
  getWishProductsAsync: () => dispatchAsync(dispatch, getWishProductsRequest()),
  saveWishProductAsync: (product: Product) => dispatchAsync(dispatch, saveWishProductRequest(product)),
  deleteWishProductAsync: (Art_Ean13: string) => dispatchAsync(dispatch, deleteWishProductRequest(Art_Ean13)),
  saveCartProductAsync: (product: Product) => dispatchAsync(dispatch, saveCartProductRequest(product)),
  deleteCartProductAsync: (Art_Ean13: string) => dispatchAsync(dispatch, deleteCartProductRequest(Art_Ean13)),
});

export const WishListSmart = connect(mapState, mapDispatch)(WishListDumb);
