import { Action } from 'redux-actions';
import { all, put, takeLatest } from 'redux-saga/effects';
import { goBack, push, replace } from 'connected-react-router';
import {
  navigateBackRequest,
  navigateReplaceFailure,
  navigateReplaceRequest,
  navigateToFailure,
  navigateToRequest,
} from './navigation.actions';
import { NavigationPayload } from '../domain/entities/NavigationPayload';
import { sendSignOutFailure, sendSignOutSuccess } from '../../authContext/usecases/Auth.actions';

function* navigateToSaga(action: Action<NavigationPayload>) {
  try {
    const { routeName, params } = action.payload;
    if (routeName) {
      yield put(push(`/${routeName}`, params));
    }
  } catch (error) {
    yield put(navigateToFailure(error));
  }
}

function* navigateReplaceSaga(action: Action<NavigationPayload>) {
  try {
    const { routeName, params } = action.payload;
    if (routeName) {
      yield put(replace(`/${routeName}`, params));
    }
  } catch (error) {
    yield put(navigateReplaceFailure(error));
  }
}

function* navigateBackSaga() {
  yield put(goBack());
}

function* navigateHomeSaga() {
  yield put(replace(`/`));
}

export function* navigationSagas() {
  yield all([
    takeLatest(navigateToRequest, navigateToSaga),
    takeLatest(navigateReplaceRequest, navigateReplaceSaga),
    takeLatest(navigateBackRequest, navigateBackSaga),
    takeLatest([sendSignOutSuccess, sendSignOutFailure], navigateHomeSaga),
  ]);
}
