import { combineReducers } from 'redux';
import persistReducer from 'redux-persist/es/persistReducer';
import localforage from 'localforage';
import { connectRouter } from 'connected-react-router';
import { authReducer } from '../../authContext/usecases/Auth.reducers';
import { generalReducer } from '../../generalContext/usecases/General.reducers';

const persistConfig = {
  key: 'auth-ecompixelshades',
  storage: localforage,
  whitelist: ['authTokens'],
  blacklist: ['connectedUser'],
};
const generalPersistConfig = {
  key: 'general-ecompixelshades',
  storage: localforage,
  whitelist: ['products', 'categories', 'subCategories', 'offlineCart'],
  blacklist: ['cart', 'wish'],
};

export const createRootReducer = (history: any) =>
  combineReducers({
    router: connectRouter(history),
    auth: persistReducer(persistConfig, authReducer),
    general: persistReducer(generalPersistConfig, generalReducer),
  });
