import _ from 'lodash';
import { useState } from 'react';
import styled from 'styled-components';
import { rSize } from 'core/util/ResponsiveSizeAdapter';
import { PassEye } from '../../../assets/svg/PassEye';

interface AppTextInputProps {
  label?: string;
  currentVal?: string;
  showError?: boolean;
  onChange: (str: string) => void;
  placeholder?: string;
  isPass?: boolean;
  disabled?: boolean;
  errorHint?: string;
}
export const AppTextInput = ({
  label,
  currentVal,
  showError,
  onChange,
  placeholder,
  isPass,
  disabled,
  errorHint = 'Ce champ contient une erreur',
}: AppTextInputProps) => {
  const [passIsShown, setPassIsShown] = useState<boolean>(false);
  const rStyles = {
    errorHint: {
      marginBottom: rSize(5),
      fontSize: rSize(16),
    },
    inputContainer: {
      marginBottom: rSize(4),
    },
    input: {
      fontSize: rSize(20),
    },
    button: {
      right: rSize(12),
    },
  };
  return (
    <Wrapper>
      {label && <label className="form-label">{label}</label>}
      <div className="input-container" style={{ ...rStyles.inputContainer }}>
        <input
          disabled={!!disabled}
          className={`form-control ${showError && 'error-input'}`}
          style={{ ...rStyles.input }}
          type={isPass ? (passIsShown ? 'text' : 'password') : 'text'}
          required
          value={currentVal ?? ''}
          placeholder={placeholder ?? ''}
          onChange={(e) => onChange(e.target.value)}
          onBlur={() => {
            onChange(_.trim(currentVal));
          }}
        />
        {isPass && (
          <button
            type="button"
            style={{ ...rStyles.button }}
            onClick={() => {
              setPassIsShown((v) => !v);
            }}
          >
            <PassEye open={passIsShown} />
          </button>
        )}
      </div>
      {showError && errorHint && (
        <div className="error-hint" style={{ ...rStyles.errorHint }}>
          {errorHint}
        </div>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-bottom: 20px;
  .form-control:focus {
    border-color: var(--secondary);
  }
  .error-input {
    color: var(--error);
    border: 1px solid var(--error);
  }
  .error-hint {
    margin-bottom: 5px;
    font-size: 16px;
    color: var(--error);
  }
  input::placeholder {
    color: var(--mono-grey-1);
    font-weight: 400;
  }
  .input-container {
    position: relative;
    margin-bottom: 4px;
    input {
      font-size: 20px;
      font-weight: 600;
    }
    button {
      position: absolute;
      top: 50%;
      right: 12px;
      transform: translate(0, -50%);
    }
  }
`;
