import { Dispatch, SetStateAction, useRef } from 'react';
import styled from 'styled-components';
import ReactPaginate from 'react-paginate';
import { CircularProgress } from '@mui/material';
import { ProductState } from 'generalContext/domain/entities/ProductState';
import { rSize } from 'core/util/ResponsiveSizeAdapter';
import { productsPerPage } from '../../../../configuration/helpers/constants';
import { ProductCard } from '../../../../common/ui';

interface ProductsGridProps {
  isLoading?: boolean;
  productsState: ProductState;
  setCurrentPage: Dispatch<SetStateAction<number>>;
}
export const ProductsGrid = ({ isLoading, productsState, setCurrentPage }: ProductsGridProps) => {
  const scrollTarget = useRef<HTMLDivElement>(null);

  const rStyles = {
    wrapper: {
      padding: `${rSize(50)}px 0`,
    },
    row: {
      rowGap: rSize(0),
      gap: '0%',
    },
    productContainer: {
      padding: `${rSize(0)}px ${rSize(0)}px`,
      border: `${rSize(1)}px  solid var(--mono-grey-2)`,
    },
    noArticlesFoundText: { fontSize: rSize(24) },
    pagination: {
      marginTop: rSize(20),
    },
    pageText: { fontSize: rSize(16) },
  };

  const handlePageClick = (data: any) => {
    window.scroll(0, 0);
    setCurrentPage(data.selected);
  };

  const pageCount = Math.ceil(productsState.count / productsPerPage);

  if (!productsState.products.length) {
    return (
      <div className="no-articles-found-text" style={{ ...rStyles.noArticlesFoundText }}>
        Aucun article n'a été trouvé.
      </div>
    );
  }
  return (
    <Wrapper>
      <div className="bg-darkness" style={isLoading ? { display: 'block' } : {}}>
        <CircularProgress size={18} color="inherit" />
      </div>
      <div
        ref={scrollTarget}
        className="row justify-content-lg-start justify-content-md-center justify-content-sm-center justify-content-center"
        style={{ ...rStyles.row }}
      >
        {productsState.products.map((product, i) => (
          <div
            key={`-grid-key-${product.Art_Ean13}${i}`}
            className="product-container"
            style={{ ...rStyles.productContainer }}
          >
            <ProductCard product={product} />
          </div>
        ))}
      </div>
      <div className="show-count">
        <p className="page-text col-md-6 text-lg-start text-md-center text-center" style={{ ...rStyles.pageText }}>
          Affichage de {Math.min(productsPerPage, productsState.products.length)} produit(s) sur {productsState.count}
        </p>
        <ReactPaginate
          previousLabel="Previous"
          nextLabel="Next"
          breakLabel="..."
          breakClassName="break-me"
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName="pagination"
          activeClassName="active"
          className="pagination"
        />
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .bg-darkness {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    width: 100vw;
    height: 100vh;
    transition: all 300ms ease;
    display: none;
    z-index: 206;
  }

  .product-container {
    display: flex;
    width: 25%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 10px;
    border: 1px solid var(--mono-grey-2);
  }

  @media (max-width: 1418px) {
    .product-container {
      width: 30%;
    }
  }

  @media (max-width: 858px) {
    .product-container {
      width: 48%;
    }
  }

  @media (max-width: 456px) {
    .product-container {
      width: 90%;
    }
  }

  .no-articles-found-text {
    font-size: 36px;
  }

  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 2vh;
    gap: 1vh;
    flex-wrap: wrap;
  }

  .pagination li {
    display: inline-block;
    margin: 0 5px;
    padding: 0;
    border-radius: 5px;
    background-color: #f1f1f1;
    color: var(--black);
    cursor: pointer;
  }

  .pagination li.active {
    background-color: var(--secondary);
    color: #fff;
    cursor: default;
  }

  .pagination li:hover {
    background-color: #ddd;
    color: var(--black);
  }

  a {
    color: var(--secondary);
    text-decoration: none;
    font-size: 1.5rem;
    padding: 5px 10px;
  }

  .show-count {
    font-weight: 500;
    font-size: 16px;
    margin-top: 20px;
    text-align: center;
  }

  @media (max-width: 768px) {
    .pagination li a {
      font-size: 1.2rem;
    }
  }

  @media (max-width: 576px) {
    .pagination li a {
      font-size: 1rem;
    }
  }
`;
