import { Product } from './Product';

export interface Order {
  id?: number;
  userId?: number;
  total: number;
  magasinId: string;
  transactionId: string;
  FileInvoice?: string;
  deliveryAddress?: string;
  Order_Elesoft?: string;
  Suivi?: string;
  transactionInfo: string;
  magasinAddress: string;
  estimatedDate: string;
  status: OrderType;
  items: OrderItem[];
  createdAt?: string;
  updatedAt?: string;
}

export type OrderType =
  | 'CREATED'
  | 'PAID'
  | 'DELIVERED'
  | 'PREPARING'
  | 'DELIVERING'
  | 'PARTLY_DELIVERED'
  | 'DELIVERED'
  | 'ERRORED';

export interface OrderItem {
  id?: number;
  orderId?: number;
  Art_Quantity: number;
  price: number;
  Art_Ean13: string;
  product?: Product;
  createdAt?: string;
  updatedAt?: string;
}

export const hardCodedEmptyOrder: Order = {
  id: 1,
  total: 0,
  magasinId: '',
  transactionId: '',
  transactionInfo: '',
  magasinAddress: '',
  estimatedDate: '',
  status: 'ERRORED',
  items: [],
};
