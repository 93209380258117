import { ReactNode } from 'react';
import styled from 'styled-components';
import { rSize } from 'core/util/ResponsiveSizeAdapter';

export interface FooterColumnProps {
  title?: string;
  content: ReactNode | null;
}

export const FooterColumn = ({ title, content }: FooterColumnProps) => {
  const rStyles = {
    wrapper: {
      gap: rSize(30),
    },
    footerColumnTitle: {
      fontSize: rSize(26),
    },
    footerLinks: {
      fontSize: rSize(16),
      gap: rSize(15),
    },
  };
  return (
    <Wrapper style={{ ...rStyles.wrapper }}>
      {title != null && (
        <h5 className="footer-column-title" style={{ ...rStyles.footerColumnTitle }}>
          {title}
        </h5>
      )}
      <div className="footer-links" style={{ ...rStyles.footerLinks }}>
        {content}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 30px;
  .footer-column-title {
    font-size: 24px;
    font-weight: 800;
    height: 30px;
  }
  .footer-links {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-weight: 600;
    gap: 15px;
    text-transform: capitalize;
    a {
      color: var(--black);
    }
    .email {
      text-transform: lowercase;
    }
  }
`;
