import { Color } from '../styles/constantStyles';

export interface ProfileSVGProps {
  color?: string;
  size?: number;
}

export const ProfileSVG = ({ color = Color.SECONDARY, size = 86 }: ProfileSVGProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 86 86"
    style={{ width: size, height: size, minWidth: size, minHeight: size }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M42.7981 55.36C58.1075 55.36 70.5181 43.6388 70.5181 29.18C70.5181 14.7212 58.1075 3 42.7981 3C27.4888 3 15.0781 14.7212 15.0781 29.18C15.0781 43.6388 27.4888 55.36 42.7981 55.36Z"
      stroke={color}
      fill="none"
      strokeWidth="5"
      strokeMiterlimit="10"
    />
    <path
      d="M2.75781 79.9994C6.81521 72.508 12.6515 66.287 19.6801 61.9617C26.7086 57.6365 34.6817 55.3594 42.7978 55.3594C50.9139 55.3594 58.887 57.6365 65.9155 61.9617C72.9441 66.287 78.7804 72.508 82.8378 79.9994"
      stroke={color}
      fill="none"
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
