import { AuthTokens } from '../../common/domain/entities/AuthTokens';
import { ChangePasswordProps } from '../../common/domain/entities/ChangePasswordProps';
import { ResetPasswordProps } from '../../common/domain/entities/ResetPasswordProps';
import { createAsyncActions } from '../../common/usecases/helpers/actions.helper';
import { User } from '../domain/entities/User';

export const matchActionTypeRehydrate = ({ type }: { type: string }) => {
  if (typeof type !== 'string') {
    return false;
  }
  return type.endsWith('REHYDRATE');
};

export const matchActionTypeFailure = (action: { type: string; payload?: { status?: number } }) => {
  if (!action || typeof action.type !== 'string') {
    return false;
  }

  if (action.payload && typeof action.payload.status === 'number') {
    return action.type.endsWith('FAILURE');
  }

  return false;
};

export const {
  request: sendSignUpRequest,
  success: sendSignUpSuccess,
  failure: sendSignUpFailure,
} = createAsyncActions<User, AuthTokens, unknown>('SEND_SIGN_UP');

export const {
  request: sendSignInRequest,
  success: sendSignInSuccess,
  failure: sendSignInFailure,
} = createAsyncActions<User, AuthTokens, unknown>('SEND_SIGN_IN');

export const {
  request: sendAutoSignInRequest,
  success: sendAutoSignInSuccess,
  failure: sendAutoSignInFailure,
} = createAsyncActions<void, AuthTokens, unknown>('SEND_AUTO_SIGN_IN');

/* export const {
  request: renewTokensRequest,
  success: renewTokensSuccess,
  failure: renewTokensFailure,
} = createAsyncActions<void, AuthTokens, unknown>('RENEW_TOKENS'); */

export const {
  request: removeTokensRequest,
  success: removeTokensSuccess,
  failure: removeTokensFailure,
} = createAsyncActions<void, void, unknown>('REMOVE_TOKENS');

export const {
  request: sendSignOutRequest,
  success: sendSignOutSuccess,
  failure: sendSignOutFailure,
} = createAsyncActions<void, boolean, unknown>('SEND_SIGN_OUT');

export const {
  request: getUserDataRequest,
  success: getUserDataSuccess,
  failure: getUserDataFailure,
} = createAsyncActions<void, User, unknown>('GET_USER_DATA');

export const {
  request: updateUserDataRequest,
  success: updateUserDataSuccess,
  failure: updateUserDataFailure,
} = createAsyncActions<User, User, unknown>('UPDATE_USER_DATA');

export const {
  request: setLoadingRequest,
  success: setLoadingSuccess,
  failure: setLoadingFailure,
} = createAsyncActions<boolean, boolean, unknown>('SET_LOADING');

export const {
  request: updatePasswordRequest,
  success: updatePasswordSuccess,
  failure: updatePasswordFailure,
} = createAsyncActions<ChangePasswordProps, boolean, unknown>('UPDATE_PASSWORD');

export const {
  request: sendPasswordForgottenEmailRequest,
  success: sendPasswordForgottenEmailSuccess,
  failure: sendPasswordForgottenEmailFailure,
} = createAsyncActions<string, boolean, unknown>('PASSWORD_FORGOTTEN');

export const {
  request: resetPasswordRequest,
  success: resetPasswordSuccess,
  failure: resetPasswordFailure,
} = createAsyncActions<ResetPasswordProps, AuthTokens, unknown>('RESET_PASSWORD');
