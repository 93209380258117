import { Action } from 'redux';
import { connect } from 'react-redux';
import { dispatchAsync } from 'react-redux-dispatch-async';
import { ShopCartDumb } from './ShopCart.dumb';
import { AppState } from '../../../common/configuration/app.states';
import { getConnectedUser } from '../../../authContext/usecases/Auth.selectors';
import { getStateCart } from '../../usecases/General.selectors';
import {
  deleteCartProductRequest,
  getCartProductsRequest,
  saveCartProductRequest,
} from '../../usecases/General.actions';
import { Product } from '../../domain/entities/Product';

const mapState = (state: AppState) => ({
  stateCart: getStateCart(state),
  stateConnectedUser: getConnectedUser(state),
});

const mapDispatch = (dispatch: (a: Action) => void) => ({
  getCartProductsAsync: () => dispatchAsync(dispatch, getCartProductsRequest()),
  saveCartProductAsync: (product: Product) => dispatchAsync(dispatch, saveCartProductRequest(product)),
  deleteCartProductAsync: (Art_Ean13: string) => dispatchAsync(dispatch, deleteCartProductRequest(Art_Ean13)),
});

export const ShopCartSmart = connect(mapState, mapDispatch)(ShopCartDumb);
