import { Color } from '../styles/constantStyles';

export interface MenuIconProps {
  color?: string;
  size?: number;
  strokeWidth?: number;
}

export const MenuIcon = ({ color = Color.SECONDARY, size = 90, strokeWidth = 24 }: MenuIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 416 249.33"
    style={{ width: size, height: size, minWidth: size, minHeight: size }}
  >
    <path
      d="M8,124.67H408"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={strokeWidth}
    />
    <path
      d="M8,241.33H408"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={strokeWidth}
    />
    <path
      d="M8,8H408"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={strokeWidth}
    />
  </svg>
);
