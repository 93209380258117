import { ReactNode } from 'react';
import styled from 'styled-components';
import { rSize } from 'core/util/ResponsiveSizeAdapter';

export interface ImgTextProps {
  img?: ReactNode;
  text: string;
}

export const ImgText = ({ img, text }: ImgTextProps) => {
  const rStyles = {
    global: {
      p: {
        fontSize: rSize(16),
      },
    },
    wrapper: {
      gap: rSize(10),
    },
  };
  return (
    <Wrapper style={{ ...rStyles.wrapper }}>
      <div className="img-container">{img}</div>
      <p style={{ ...rStyles.global.p }}>{text}</p>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 10px;
  p {
    font-size: 16px;
    font-weight: 600;
    flex: 1;
  }
`;
