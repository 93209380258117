import { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { rSize } from 'core/util/ResponsiveSizeAdapter';
import { AppTextInput } from './AppTextInput';
import { User } from '../../../authContext/domain/entities/User';

interface UserDataFormProps {
  showError?: boolean;
  disabled?: boolean;
  user: User;
  setUser: Dispatch<SetStateAction<User>>;
}
export const UserDataForm = ({ showError, disabled = false, user, setUser }: UserDataFormProps) => (
  <Wrapper>
    <div className="row">
      <div className="col-12 col-lg-6 col-md-6">
        <AppTextInput
          disabled={disabled}
          showError={showError && _.isEmpty(user?.firstName)}
          label="Prénom*"
          placeholder="Mehdi"
          currentVal={user?.firstName}
          onChange={(newVal: string) => {
            setUser((v) => ({ ...v, firstName: newVal }));
          }}
        />
      </div>
      <div className="col-12 col-lg-6 col-md-6">
        <AppTextInput
          disabled={disabled}
          showError={showError && _.isEmpty(user?.lastName)}
          label="Nom*"
          placeholder="Ben Larbi"
          currentVal={user?.lastName}
          onChange={(newVal: string) => {
            setUser((v) => ({ ...v, lastName: newVal }));
          }}
        />
      </div>
    </div>

    <div className="row m-b30">
      <div className="col-12 col-lg-6 col-md-6">
        <AppTextInput
          disabled={disabled}
          showError={showError && _.isEmpty(user?.phone)}
          label="Téléphone*"
          placeholder="0612345678"
          currentVal={user?.phone?.toString()}
          onChange={(newVal: string) => {
            setUser((v) => ({ ...v, phone: newVal }));
          }}
        />
      </div>
    </div>

    <div className="row">
      <div className="col-12 col-lg-6 col-md-6">
        <AppTextInput
          disabled={disabled}
          showError={showError && _.isEmpty(user?.addressMain)}
          label="Adresse*"
          placeholder="Rue Oujada"
          currentVal={user?.addressMain?.toString()}
          onChange={(newVal: string) => {
            setUser((v) => ({ ...v, addressMain: newVal }));
          }}
        />
      </div>
      <div className="col-12 col-lg-6 col-md-6">
        <AppTextInput
          disabled={disabled}
          label="Adresse secondaire"
          placeholder="Résidence Chazal, Appartement 74"
          currentVal={user?.addressSecond?.toString()}
          onChange={(newVal: string) => {
            setUser((v) => ({ ...v, addressSecond: newVal }));
          }}
        />
      </div>
      <div className="col-12 col-lg-6 col-md-6">
        <AppTextInput
          disabled={disabled}
          showError={showError && _.isEmpty(user?.city)}
          label="Ville*"
          placeholder="Casablanca"
          currentVal={user?.city?.toString()}
          onChange={(newVal: string) => {
            setUser((v) => ({ ...v, city: newVal }));
          }}
        />
      </div>
      <div className="col-12 col-lg-6 col-md-6">
        <AppTextInput
          disabled={disabled}
          showError={showError && _.isEmpty(user?.zip)}
          label="Code postal*"
          placeholder="1000"
          currentVal={user?.zip?.toString()}
          onChange={(newVal: string) => {
            setUser((v) => ({ ...v, zip: newVal }));
          }}
        />
      </div>
      <div className="col-12 col-lg-6 col-md-6">
        <AppTextInput
          disabled={disabled}
          showError={showError && _.isEmpty(user?.state)}
          label="Province*"
          placeholder="Grand Casablanca"
          currentVal={user?.state?.toString()}
          onChange={(newVal: string) => {
            setUser((v) => ({ ...v, state: newVal }));
          }}
        />
      </div>
      <div className="col-12 col-lg-6 col-md-6">
        <AppTextInput
          disabled={disabled}
          showError={showError && _.isEmpty(user?.country)}
          label="Pays*"
          placeholder="Maroc"
          currentVal={user?.country?.toString()}
          onChange={(newVal: string) => {
            setUser((v) => ({ ...v, country: newVal }));
          }}
        />
      </div>
    </div>
  </Wrapper>
);

const Wrapper = styled.div``;
