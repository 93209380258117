import { AdminPreferances } from 'generalContext/domain/entities/AdminPreferances';
import { GetProductsProps, Product } from 'generalContext/domain/entities/Product';
import { ProductState } from 'generalContext/domain/entities/ProductState';
import { MagasinState } from 'generalContext/domain/entities/Magasin';
import { MailObj } from 'generalContext/domain/entities/MailObj';
import { InitializePaymentProps } from 'generalContext/domain/entities/Payment';
import { ShowcaseItem } from 'generalContext/domain/entities/ShowcaseItem';
import { createAsyncActions } from '../../common/usecases/helpers/actions.helper';
import { Category } from '../domain/entities/Category';

import { Review } from '../domain/entities/Review';
import { SubCategory } from '../domain/entities/SubCategory';
import { Order } from '../domain/entities/Order';

// PRODUCTS ------------------------------------------------------------------------------
export const {
  request: getDeliveryProductsRequest,
  success: getDeliveryProductsSuccess,
  failure: getDeliveryProductsFailure,
} = createAsyncActions<void, Product[], unknown>('GET_DELIVERY_PRODUCTS');

export const {
  request: getProductsRequest,
  success: getProductsSuccess,
  failure: getProductsFailure,
} = createAsyncActions<GetProductsProps | undefined, ProductState, unknown>('GET_PRODUCTS');

export const {
  request: getProductsBySubCategoryNumberRequest,
  success: getProductsBySubCategoryNumberSuccess,
  failure: getProductsBySubCategoryNumberFailure,
} = createAsyncActions<number, ProductState, unknown>('GET_PRODUCTS_BY_SUB_CAT');

// CART ------------------------------------------------------------------------------
export const {
  request: getOfflineCartProductsRequest,
  success: getOfflineCartProductsSuccess,
  failure: getOfflineCartProductsFailure,
} = createAsyncActions<void, Product[], unknown>('GET_OFFLINE_CART_PRODUCTS');

export const {
  request: getCartProductsRequest,
  success: getCartProductsSuccess,
  failure: getCartProductsFailure,
} = createAsyncActions<void, Product[], unknown>('GET_CART_PRODUCTS');

export const {
  request: saveCartProductRequest,
  success: saveCartProductSuccess,
  failure: saveCartProductFailure,
} = createAsyncActions<Product, Product, unknown>('SAVE_CART_PRODUCT');

export const {
  request: deleteCartProductRequest,
  success: deleteCartProductSuccess,
  failure: deleteCartProductFailure,
} = createAsyncActions<string, boolean, unknown>('DELETE_CART_PRODUCT');

// WISH ------------------------------------------------------------------------------
export const {
  request: getWishProductsRequest,
  success: getWishProductsSuccess,
  failure: getWishProductsFailure,
} = createAsyncActions<void, Product[], unknown>('GET_WISH_PRODUCTS');

export const {
  request: saveWishProductRequest,
  success: saveWishProductSuccess,
  failure: saveWishProductFailure,
} = createAsyncActions<Product, Product, unknown>('INSERT_WISH_PRODUCT');

export const {
  request: updateWishProductRequest,
  success: updateWishProductSuccess,
  failure: updateWishProductFailure,
} = createAsyncActions<Product, Product, unknown>('UPDATE_WISH_PRODUCT');

export const {
  request: deleteWishProductRequest,
  success: deleteWishProductSuccess,
  failure: deleteWishProductFailure,
} = createAsyncActions<string, boolean, unknown>('DELETE_WISH_PRODUCT');

// CATEGORIES ------------------------------------------------------------------------------

export const {
  request: getCategoriesRequest,
  success: getCategoriesSuccess,
  failure: getCategoriesFailure,
} = createAsyncActions<void, Category[], unknown>('GET_CATEGORIES');

export const {
  request: getSubCategoriesRequest,
  success: getSubCategoriesSuccess,
  failure: getSubCategoriesFailure,
} = createAsyncActions<void, SubCategory[], unknown>('GET_SUBCATEGORIES');

// REVIEWS ------------------------------------------------------------------------------

export const {
  request: getProductReviewsRequest,
  success: getProductReviewsSuccess,
  failure: getProductReviewsFailure,
} = createAsyncActions<string, Review[], unknown>('GET_PRODUCT_REVIEWS');

export const {
  request: getAdminReviewsRequest,
  success: getAdminReviewsSuccess,
  failure: getAdminReviewsFailure,
} = createAsyncActions<void, Review[], unknown>('GET_ADMIN_REVIEWS');

export const {
  request: approveReviewRequest,
  success: approveReviewSuccess,
  failure: approveReviewFailure,
} = createAsyncActions<Review, Review, unknown>('APPROVE_REVIEW_REVIEWS');

export const {
  request: insertProductReviewRequest,
  success: insertProductReviewSuccess,
  failure: insertProductReviewFailure,
} = createAsyncActions<Review, Review, unknown>('INSERT_PRODUCT_REVIEW');

export const {
  request: deleteProductReviewRequest,
  success: deleteProductReviewSuccess,
  failure: deleteProductReviewFailure,
} = createAsyncActions<Review, Review, unknown>('DELETE_PRODUCT_REVIEW');

// ORDERS ------------------------------------------------------------------------------
export const {
  request: getOrderHistoryRequest,
  success: getOrderHistorySuccess,
  failure: getOrderHistoryFailure,
} = createAsyncActions<void, Order[], unknown>('GET_ORDER_HISTORY');

export const {
  request: insertOrderRequest,
  success: insertOrderSuccess,
  failure: insertOrderFailure,
} = createAsyncActions<Order, Order, unknown>('INSERT_ORDER');

// MAGASIN ------------------------------------------------------------------------------
export const {
  request: getMagasinListRequest,
  success: getMagasinListSuccess,
  failure: getMagasinListFailure,
} = createAsyncActions<void, MagasinState, unknown>('GET_MAGASIN_LIST');

// EMAILS ------------------------------------------------------------------------------
export const {
  request: sendEmailRequest,
  success: sendEmailSuccess,
  failure: sendEmailFailure,
} = createAsyncActions<MailObj, MailObj, unknown>('SEND_EMAIL');

export const {
  request: sendNewsLettersSignUpRequest,
  success: sendNewsLettersSignUpSuccess,
  failure: sendNewsLettersSignUpFailure,
} = createAsyncActions<string, boolean, unknown>('SEND_NEWSLETTERS_SIGN_UP');

// ADMIN SETTINGS ------------------------------------------------------------------------------
export const {
  request: getAdminPreferancesRequest,
  success: getAdminPreferancesSuccess,
  failure: getAdminPreferancesFailure,
} = createAsyncActions<void, AdminPreferances, unknown>('GET_ADMIN_SETTINGS');

export const {
  request: updateAdminPreferencesRequest,
  success: updateAdminPreferencesSuccess,
  failure: updateAdminPreferencesFailure,
} = createAsyncActions<AdminPreferances, AdminPreferances, unknown>('UPDATE_ADMIN_SETTINGS');

// COOKIES SETTINGS ------------------------------------------------------------------------------
export const {
  request: cookiesAcceptRequest,
  success: cookiesAcceptSuccess,
  failure: cookiesAcceptFailure,
} = createAsyncActions<void, boolean, unknown>('COOKIES_ACCEPTANCE');

// PAYMENT ------------------------------------------------------------------------------

export const {
  request: initializePaymentRequest,
  success: initializePaymentSuccess,
  failure: initializePaymentFailure,
} = createAsyncActions<InitializePaymentProps, any, unknown>('INITIALIZE_PAYMENT');

export const {
  request: assertPaymentRequest,
  success: assertPaymentSuccess,
  failure: assertPaymentFailure,
} = createAsyncActions<string, any, unknown>('ASSERT_PAYMENT');

// SHOWCASE ------------------------------------------------------------------------------

export const {
  request: getShowcaseItemsRequest,
  success: getShowcaseItemsSuccess,
  failure: getShowcaseItemsFailure,
} = createAsyncActions<void, ShowcaseItem[], unknown>('GET_SHOWCASE_ITEMS');

export const {
  request: getDefaultShowcaseItemsRequest,
  success: getDefaultShowcaseItemsSuccess,
  failure: getDefaultShowcaseItemsFailure,
} = createAsyncActions<void, ShowcaseItem[], unknown>('GET_DEFAULT_SHOWCASE_ITEMS');

export const {
  request: getPromoShowcaseItemsRequest,
  success: getPromoShowcaseItemsSuccess,
  failure: getPromoShowcaseItemsFailure,
} = createAsyncActions<void, ShowcaseItem[], unknown>('GET_PROMO_SHOWCASE_ITEMS');

export const {
  request: getPrioritizedShowcaseItemsRequest,
  success: getPrioritizedShowcaseItemsSuccess,
  failure: getPrioritizedShowcaseItemsFailure,
} = createAsyncActions<void, ShowcaseItem[], unknown>('GET_PRIORITIZED_SHOWCASE_ITEMS');

export const {
  request: createShowcaseItemRequest,
  success: createShowcaseItemSuccess,
  failure: createShowcaseItemFailure,
} = createAsyncActions<ShowcaseItem, ShowcaseItem, unknown>('CREATE_SHOWCASE_ITEM');

export const {
  request: updateShowcaseItemRequest,
  success: updateShowcaseItemSuccess,
  failure: updateShowcaseItemFailure,
} = createAsyncActions<ShowcaseItem, ShowcaseItem, unknown>('UPDATE_SHOWCASE_ITEM');

export const {
  request: updatePromoShowcaseRequest,
  success: updatePromoShowcaseSuccess,
  failure: updatePromoShowcaseFailure,
} = createAsyncActions<{ itemId: string; startDate?: Date; endDate?: Date }, ShowcaseItem, unknown>(
  'UPDATE_PROMO_SHOWCASE',
);

export const {
  request: deleteShowcaseItemRequest,
  success: deleteShowcaseItemSuccess,
  failure: deleteShowcaseItemFailure,
} = createAsyncActions<string, boolean, unknown>('DELETE_SHOWCASE_ITEM');

export const {
  request: addDefaultShowcaseItemRequest,
  success: addDefaultShowcaseItemSuccess,
  failure: addDefaultShowcaseItemFailure,
} = createAsyncActions<string, ShowcaseItem, unknown>('ADD_DEFAULT_SHOWCASE_ITEM');

export const {
  request: removeDefaultShowcaseItemRequest,
  success: removeDefaultShowcaseItemSuccess,
  failure: removeDefaultShowcaseItemFailure,
} = createAsyncActions<string, boolean, unknown>('REMOVE_DEFAULT_SHOWCASE_ITEM');

export const {
  request: addPromoShowcaseItemRequest,
  success: addPromoShowcaseItemSuccess,
  failure: addPromoShowcaseItemFailure,
} = createAsyncActions<{ itemId: string; startDate?: Date; endDate?: Date }, ShowcaseItem, unknown>(
  'ADD_PROMO_SHOWCASE_ITEM',
);

export const {
  request: removePromoShowcaseItemRequest,
  success: removePromoShowcaseItemSuccess,
  failure: removePromoShowcaseItemFailure,
} = createAsyncActions<string, boolean, unknown>('REMOVE_PROMO_SHOWCASE_ITEM');

export const {
  request: switchDefaultShowcaseOrderRequest,
  success: switchDefaultShowcaseOrderSuccess,
  failure: switchDefaultShowcaseOrderFailure,
} = createAsyncActions<{ itemId: string; direction: 'up' | 'down' }, boolean, unknown>('SWITCH_DEFAULT_SHOWCASE_ORDER');

export const {
  request: switchPromoShowcaseOrderRequest,
  success: switchPromoShowcaseOrderSuccess,
  failure: switchPromoShowcaseOrderFailure,
} = createAsyncActions<{ itemId: string; direction: 'up' | 'down' }, boolean, unknown>('SWITCH_PROMO_SHOWCASE_ORDER');
