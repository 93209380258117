import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import styled from 'styled-components';
import { useState } from 'react';
import { rSize } from 'core/util/ResponsiveSizeAdapter';
import { SideBar } from 'common/ui';
import { UserMenu } from './UserMenu';
import { User } from '../../../../../authContext/domain/entities/User';
import { Cart } from '../../../../../generalContext/domain/entities/Cart';
import noImageImage from '../../../../../assets/images/NoImageImage.png';
import { Product } from '../../../../../generalContext/domain/entities/Product';
import { getProductPrice, priceDisplay } from '../../../../../configuration/helpers/HelperFunctions';
import { MenuIcon } from '../../../../../assets/svg/MenuIcon';
import { WishSVG } from '../../../../../assets/svg/WishSVG';
import { CartSVG } from '../../../../../assets/svg/CartSVG';
import { ProfileSVG } from '../../../../../assets/svg/ProfileSVG';
import ResponsiveScreenAdapter from 'core/util/ResponsiveScreenAdapter';

interface MiddleHeaderRightProps {
  stateConnectedUser?: User;
  stateWishProducts: Product[] | undefined;
  stateCart: Cart | undefined;
  toggleSidebar: () => void;
  signOut: () => void;
  deleteCartProduct: (Art_Ean13: string) => void;
}

export const MiddleHeaderRight = ({
  stateConnectedUser,
  stateWishProducts = [],
  stateCart = { items: [], total: 0 },
  toggleSidebar,
  signOut,
  deleteCartProduct,
}: MiddleHeaderRightProps) => {
  const rStyles = {
    global: {},
    svgButton: {
      svgSize: rSize(22),
    },
    menuIcon: {
      fontSize: rSize(24),
      color: '#000000',
    },
    item: {},

    middleHeaderRightContainer: {
      gap: rSize(10),
    },

    cartContainer: {
      padding: rSize(15),
    },

    itemCount: {
      width: rSize(20),
      height: rSize(20),
      fontSize: rSize(12),
    },

    cartItem: {
      global: { width: rSize(300), h6: { fontSize: rSize(20) } },
      mediaLeft: { width: rSize(60), height: rSize(80) },
      mediaBody: {
        h6: { fontSize: rSize(18) },
        span: { fontSize: rSize(16) },
        itemClose: { width: rSize(26), height: rSize(26), fontSize: rSize(22), lineHeight: rSize(0) },
      },
    },
    appButton: {
      height: rSize(46),
      FontSize: rSize(15),
    },
  };

  return (
    <Wrapper>
      <ul className="middle-header-right-container" style={{ ...rStyles.middleHeaderRightContainer }}>
        <li className="item" style={{ ...rStyles.item }}>
          <Link to="/wishlist" className="item-toggle">
            <div className="cart-container" style={{ ...rStyles.cartContainer }}>
              <WishSVG size={rStyles.svgButton.svgSize} />
              <div className="item-count" style={{ ...rStyles.itemCount }}>
                <span>{stateWishProducts.length}</span>
              </div>
            </div>
          </Link>
        </li>
        <Dropdown as="li" className="item" style={{ ...rStyles.item }}>
          <Dropdown.Toggle as="div" className="item-toggle">
            <div className="cart-container" style={{ ...rStyles.cartContainer }}>
              <CartSVG size={rStyles.svgButton.svgSize} />
              <div className="item-count" style={{ ...rStyles.itemCount }}>
                <span>{stateCart.items.length}</span>
              </div>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu as="ul" className="cart-list">
            {stateCart.items.map((cartProduct, i) => (
              <li key={`${cartProduct.id}-cart-prod-key-${i}`} className="cart-item">
                <div className="media">
                  <div className="media-left" style={{ ...rStyles.cartItem.mediaLeft }}>
                    <Link to={`/product-details/${cartProduct.Art_Ean13}`}>
                      <div
                        className="product-fay-image-container"
                        style={{
                          backgroundImage: cartProduct.Art_Images[0]
                            ? `url(${cartProduct.Art_Images[0]})`
                            : `url(${noImageImage})`,
                        }}
                      />
                    </Link>
                  </div>
                  <div className="media-body">
                    <h6 className="dz-title" style={{ ...rStyles.cartItem.mediaBody.h6 }}>
                      <Link to={`/product-details/${cartProduct.Art_Ean13}`} className="media-heading">
                        {cartProduct.Art_Titre}
                      </Link>
                    </h6>
                    <span className="dz-price" style={{ ...rStyles.cartItem.mediaBody.span }}>
                      {priceDisplay(getProductPrice(cartProduct))} (x{cartProduct.Art_Quantity})
                    </span>
                    <button
                      className="item-close"
                      style={{ ...rStyles.cartItem.mediaBody.itemClose }}
                      type="button"
                      onClick={() => deleteCartProduct(cartProduct.Art_Ean13)}
                    >
                      &times;
                    </button>
                  </div>
                </div>
              </li>
            ))}
            <li className="cart-item text-center">
              <h6 className="text-secondary" style={{ ...rStyles.cartItem.global.h6 }}>
                Total: {priceDisplay(stateCart?.total)}
              </h6>
            </li>
            <li className="text-center d-flex">
              <Dropdown.Item
                as={Link}
                to="/panier"
                className="btn btn-sm btn-primary me-2 btnhover w-100 app-button"
                style={{
                  height: rStyles.appButton.height,
                  fontSize: rStyles.appButton.FontSize,
                }}
              >
                Voir le Panier
              </Dropdown.Item>
              <Dropdown.Item
                as={Link}
                to="/paiement"
                className="btn btn-sm outline-primary btnhover w-100 app-button"
                style={{
                  height: rStyles.appButton.height,
                  fontSize: rStyles.appButton.FontSize,
                }}
              >
                Paiement
              </Dropdown.Item>
            </li>
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown as="li" className="item dropdown profile-dropdown" style={{ ...rStyles.item }}>
          <Dropdown.Toggle as="div" className="item-toggle">
            <div className="cart-container" style={{ ...rStyles.cartContainer }}>
              <ProfileSVG size={rStyles.svgButton.svgSize} />
              <div
                className="item-count"
                style={{
                  ...rStyles.itemCount,
                  backgroundColor: stateConnectedUser ? `var(--secondary)` : `#9b090c`,
                }}
              >
                <span>{stateConnectedUser ? '✓' : 'x'}</span>
              </div>
            </div>

            {/* {stateConnectedUser?.firstName && (
                <span className="connected-user-name">{`${stateConnectedUser?.firstName}`}</span>
              )} */}
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu py-0 dropdown-menu-end">
            <UserMenu stateConnectedUser={stateConnectedUser} signOut={signOut} />
          </Dropdown.Menu>
        </Dropdown>
        <ResponsiveScreenAdapter
          screenMobile={
            <li className="item" style={{ ...rStyles.item }}>
              <button
                className="menu-button"
                type="button"
                style={{ ...rStyles.cartContainer }}
                onClick={toggleSidebar}
              >
                <MenuIcon size={rStyles.menuIcon.fontSize} />
              </button>
            </li>
          }
        />
      </ul>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .middle-header-right-container {
    display: flex;
    justify-content: space-between;
    gap: 10px; /* Adjust the gap size as needed */
  }

  .item {
    display: flex;
    align-items: center;
  }

  .item-toggle {
    display: block;
    background: white;
    text-decoration: none;
    cursor: pointer;
    -webkit-appearance: none; /* For Chrome, Safari, and Opera */
    -moz-appearance: none; /* For Firefox */
    appearance: none; /* Standard syntax */
  }

  .item-toggle::after {
    display: none !important;
  }

  .cart-item {
  }

  .item-menu {
  }

  .cart-container {
    position: relative;
    display: inline-block;
    padding: 30px;
  }

  .product-fay-image-container {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .item-count {
    position: absolute;
    bottom: 0px;
    right: 0px;
    background-color: #9b090c;
    color: white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75rem;
  }

  .extra-nav .extra-cell .header-right {
    justify-content: end;
    .dropdown-body {
      svg,
      i {
        fill: var(--primary);
        color: var(--primary);
      }
    }
  }
  svg {
    transform: scale(1.5);
    max-width: 30px;
  }
  .extra-nav .extra-cell .header-right .nav-item .nav-link {
    max-width: 60px;
  }
  .extra-nav .extra-cell .header-right .nav-item .nav-link:hover {
    background: transparent;
  }
  .extra-nav .extra-cell .header-right .nav-item .nav-link .badge {
    border-radius: 50%;
    bottom: 0;
    top: auto;
  }
  .badge.connected {
    background: var(--secondary);
  }
  .profile-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    * {
      flex-shrink: 0;
    }
    .connected-user-name {
      font-size: 20px;
    }
  }
  .app-button {
    height: 46px;
  }
  .outline-primary {
    border: 1px solid var(--primary);
    &:hover {
      background-color: var(--primary);
      color: var(--white);
    }
  }

  .menu-button {
    align-self: flex-end;
    border: none;
    background: none;
    cursor: pointer;
  }
`;
