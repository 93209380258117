import styled from 'styled-components';
import { rSize } from 'core/util/ResponsiveSizeAdapter';
import { getProductPrice, priceDisplay } from '../../../../configuration/helpers/HelperFunctions';
import { Cart } from '../../../domain/entities/Cart';
import noImageImage from '../../../../assets/images/NoImageImage.png';

interface OrderDisplayTableProps {
  stateCart: Cart;
}

export const OrderDisplayTable = ({ stateCart }: OrderDisplayTableProps) => {
  const rStyles = {
    global: {
      h4: {
        fontSize: rSize(24),
      },
      p: {
        fontSize: rSize(18),
      },
      th: {
        fontSize: rSize(18),
      },
    },
    quantity: {
      width: rSize(150),
    },
    quantityButton: {
      width: rSize(50),
      height: rSize(50),
      fontSize: rSize(16),
    },
    quantityInput: {
      fontSize: rSize(16),
      height: rSize(50),
    },
    closeButton: {
      width: rSize(40),
      height: rSize(40),
      lineHeight: `${rSize(40)}px`,
      fontSize: rSize(20),
    },
  };
  return (
    <Wrapper className="widget">
      <h4 className="widget-title" style={{ ...rStyles.global.h4 }}>
        Récapitulatif
      </h4>
      <div className="table-responsive">
        <table className="table-bordered check-tbl">
          <thead className="text-center">
            <tr>
              <th style={{ ...rStyles.global.th }}>Image</th>
              <th style={{ ...rStyles.global.th }}>Titre</th>
              <th style={{ ...rStyles.global.th }}>Prix unitaire</th>
              <th style={{ ...rStyles.global.th }}>Quantité</th>
              <th style={{ ...rStyles.global.th }}>Total</th>
            </tr>
          </thead>
          <tbody>
            {stateCart.items.map((product, i) => (
              <tr key={`${product.id}-cat-key-${i}`}>
                <td className="product-item-img">
                  <img
                    src={product.Art_Images.length ? `${product.Art_Images[0]}` : `${noImageImage}`}
                    alt="product display"
                  />
                </td>
                <td className="product-item-name" style={{ ...rStyles.global.p }}>
                  {product.Art_Titre}
                </td>
                <td className="product-price" style={{ ...rStyles.global.p }}>
                  {priceDisplay(getProductPrice(product))}
                </td>
                <td className="product-item-name" style={{ ...rStyles.global.p }}>
                  {product.Art_Quantity}
                </td>
                <td className="product-price" style={{ ...rStyles.global.p }}>
                  {priceDisplay(getProductPrice(product) * (product?.Art_Quantity ?? 0))}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div``;
