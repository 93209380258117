import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { DispatchAsyncResult } from 'react-redux-dispatch-async/lib/dispatchAsync';
import { Link } from 'react-router-dom';
import { rSize } from 'core/util/ResponsiveSizeAdapter';
import { Order } from '../../../domain/entities/Order';
import { deliveryStatusDisplay, formatDate, priceDisplay } from '../../../../configuration/helpers/HelperFunctions';
import { useNotificationContext } from '../../../../common/configuration/notificationContextProvider';

interface OrderHistoryProps {
  getOrderHistoryAsync(): Promise<DispatchAsyncResult<void>>;
}
export const OrderHistory = ({ getOrderHistoryAsync }: OrderHistoryProps) => {
  const notifications = useNotificationContext();
  const [orders, setOrders] = useState<Order[]>([]);

  const rStyles = {
    global: {
      h5: {
        fontSize: rSize(24),
      },
      p: {
        fontSize: rSize(22),
      },
      th: {
        fontSize: rSize(20),
      },
    },
    noOrders: {
      fontSize: rSize(22),
      paddingLeft: rSize(15),
    },
    checkTbl: {
      borderRight: `${rSize(1)} solid white`,
    },
    productItemTitle: {
      padding: rSize(20),
    },
    tableTd: {
      padding: rSize(20),
      minWidth: rSize(140),
    },
  };

  useEffect(() => {
    getOrderHistory();
  }, []);

  const getOrderHistory = async () => {
    try {
      const response: any = await getOrderHistoryAsync();
      if (response.success && !response.result.error) {
        setOrders(response.result);
      } else {
        notifications.setMessage({
          message: response.result.message,
          status: 'error',
        });
      }
    } catch (error) {
      notifications.setMessage({
        message: "Une erreur s'est produite à 'getOrderHistory'",
        status: 'error',
      });
      console.error('getOrderHistory caught error', error);
    }
  };

  return (
    <Wrapper>
      <div className="shop-bx shop-profile">
        <div className="shop-bx-title clearfix">
          <h5 className="text-uppercase" style={{ ...rStyles.global.h5 }}>
            Historique des commandes
          </h5>
        </div>
        <div className="table-responsive">
          <table className="table check-tbl" style={{ ...rStyles.productItemTitle, ...rStyles.global.p }}>
            <thead>
              <tr>
                <th className="product-item-title" style={{ ...rStyles.productItemTitle, ...rStyles.global.th }}>
                  Référence
                </th>
                <th className="product-item-title" style={{ ...rStyles.productItemTitle, ...rStyles.global.th }}>
                  Date
                </th>
                <th className="product-item-title" style={{ ...rStyles.productItemTitle, ...rStyles.global.th }}>
                  Montant
                </th>
                <th className="product-item-title" style={{ ...rStyles.productItemTitle, ...rStyles.global.th }}>
                  Statut
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {orders.length ? (
                orders
                  .sort((a: Order, b: Order) => {
                    if (a.createdAt && b.createdAt) {
                      return Date.parse(b.createdAt) - Date.parse(a.createdAt);
                    }
                    return -1;
                  })
                  .map((order, i) => (
                    <tr key={`order-key-${order.id}${i}`}>
                      <td className="table-td product-item-name" style={{ ...rStyles.tableTd, ...rStyles.global.p }}>
                        {order.Order_Elesoft ?? '-'}
                      </td>
                      <td className="table-td product-item-name" style={{ ...rStyles.tableTd, ...rStyles.global.p }}>
                        {formatDate(order.createdAt) ?? '-'}
                      </td>
                      <td className="table-td product-item-price" style={{ ...rStyles.tableTd, ...rStyles.global.p }}>
                        {priceDisplay(order.total)}
                      </td>
                      <td className="table-td product-item-price" style={{ ...rStyles.tableTd, ...rStyles.global.p }}>
                        {deliveryStatusDisplay(order.status)}
                      </td>
                      <td className="table-td product-item-price" style={{ ...rStyles.tableTd, ...rStyles.global.p }}>
                        <Link to={`/order-information/?order=${order.id}`}>Voir plus</Link>
                      </td>
                    </tr>
                  ))
              ) : (
                <div className="no-orders" style={{ ...rStyles.noOrders, ...rStyles.global.p }}>
                  Vous n'avez pas encore passé de commande.
                </div>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .no-orders {
    font-size: 20px;
    padding-left: 15px;
  }
  .check-tbl thead th {
    border-right: 1px solid white;
  }
  .product-item-title {
    padding: 20px;
    text-overflow: ellipsis;
    text-align: left;
  }
  .table-td {
    padding: 20px;
    text-overflow: ellipsis;
    text-align: left;
    min-width: 120px;
  }
`;
