import { Action } from 'redux';
import { connect } from 'react-redux';
import { dispatchAsync } from 'react-redux-dispatch-async';
import { SignInDumb } from './SignIn.dumb';
import { AppState } from '../../../common/configuration/app.states';
import { sendSignInRequest } from '../../usecases/Auth.actions';
import { User } from '../../domain/entities/User';
import { getConnectedUser } from '../../usecases/Auth.selectors';

const mapState = (state: AppState) => ({
  stateConnectedUser: getConnectedUser(state),
});

const mapDispatch = (dispatch: (a: Action) => void) => ({
  sendSignInAsync: (newUser: User) => dispatchAsync(dispatch, sendSignInRequest(newUser)),
});

export const SignInSmart = connect(mapState, mapDispatch)(SignInDumb);
