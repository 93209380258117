import { Dispatch, SetStateAction, useEffect } from 'react';
import styled from 'styled-components';
import { AppTextInput } from './AppTextInput';
import { ERROR_PASS_LENGTH } from '../../../configuration/helpers/constants';
import { ChangePasswordProps } from '../../domain/entities/ChangePasswordProps';

interface PasswordChangeFormProps {
  showError?: boolean;
  passChangeProps: ChangePasswordProps;
  setPassChangeProps: Dispatch<SetStateAction<ChangePasswordProps>>;
  enterFunc: () => void;
}
export const PasswordChangeForm = ({
  showError,
  passChangeProps,
  setPassChangeProps,
  enterFunc,
}: PasswordChangeFormProps) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        enterFunc();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [enterFunc]);

  return (
    <Wrapper>
      <div className="row">
        <AppTextInput
          showError={
            !!(
              showError &&
              (!passChangeProps.currentPassword ||
                (passChangeProps.currentPassword && passChangeProps.currentPassword?.length < 6))
            )
          }
          isPass
          label="Mot de passe actuel*"
          placeholder="Mot de passe actuel"
          errorHint={ERROR_PASS_LENGTH}
          currentVal={passChangeProps.currentPassword}
          onChange={(newVal: string) => {
            setPassChangeProps((v) => ({ ...v, currentPassword: newVal }));
          }}
        />
        <AppTextInput
          showError={
            !!(
              showError &&
              (!passChangeProps.newPassword || (passChangeProps.newPassword && passChangeProps.newPassword?.length < 6))
            )
          }
          isPass
          label="Nouveau mot de passe*"
          placeholder="Nouveau mot de passe"
          errorHint={ERROR_PASS_LENGTH}
          currentVal={passChangeProps.newPassword}
          onChange={(newVal: string) => {
            setPassChangeProps((v) => ({ ...v, newPassword: newVal }));
          }}
        />
        <AppTextInput
          showError={showError && passChangeProps.newPasswordConfirm !== passChangeProps.newPassword}
          isPass
          label="Confirmation mot de passe*"
          placeholder="Confirmation mot de passe"
          errorHint="les mots de passe ne correspondent pas"
          currentVal={passChangeProps.newPasswordConfirm}
          onChange={(newVal: string) => {
            setPassChangeProps((v) => ({ ...v, newPasswordConfirm: newVal }));
          }}
        />
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div``;
