import { Action } from 'redux';
import { connect } from 'react-redux';
import { dispatchAsync } from 'react-redux-dispatch-async';
import { InitializePaymentProps } from 'generalContext/domain/entities/Payment';
import { CheckoutDumb } from './Checkout.dumb';
import { AppState } from '../../../common/configuration/app.states';
import { getConnectedUser } from '../../../authContext/usecases/Auth.selectors';
import {
  assertPaymentRequest,
  getCartProductsRequest,
  getDeliveryProductsRequest,
  initializePaymentRequest,
  insertOrderRequest,
} from '../../usecases/General.actions';
import {
  assertPaymentResponse,
  getDeliveryProducts,
  getStateCart,
  getStateMagasins,
  initializePaymentResponse,
} from '../../usecases/General.selectors';
import { Order } from '../../domain/entities/Order';

const mapState = (state: AppState) => ({
  stateCart: getStateCart(state),
  stateConnectedUser: getConnectedUser(state),
  stateMagasins: getStateMagasins(state),
  stateDeliveryProducts: getDeliveryProducts(state),
  // payment -------------------------------------------
  initializePaymentResponse: initializePaymentResponse(state),
  assertPaymentResponse: assertPaymentResponse(state),
});

const mapDispatch = (dispatch: (a: Action) => void) => ({
  getDeliveryProductsAsync: () => dispatchAsync(dispatch, getDeliveryProductsRequest()),
  getCartProductsAsync: () => dispatchAsync(dispatch, getCartProductsRequest()),
  insertOrderAsync: (order: Order) => dispatchAsync(dispatch, insertOrderRequest(order)),
  // payment -------------------------------------------
  initializePaymentAsync: (data: InitializePaymentProps) => dispatchAsync(dispatch, initializePaymentRequest(data)),
  assertPaymentAsync: (token: string) => dispatchAsync(dispatch, assertPaymentRequest(token)),
});

export const CheckoutSmart = connect(mapState, mapDispatch)(CheckoutDumb);
