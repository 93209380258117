import _ from 'lodash';
import { User } from 'authContext/domain/entities/User';
import { appCurrency, ERROR_EMPTY_FIELDS } from './constants';
import { Product, ProductStock } from '../../generalContext/domain/entities/Product';
import { Review } from '../../generalContext/domain/entities/Review';
import { OrderType } from '../../generalContext/domain/entities/Order';

export const test = 1;

export const productFromArray = (arr: Product[], product?: Product) =>
  arr.find((arrProd) => arrProd.Art_Ean13 === product?.Art_Ean13);

export const formatDate = (dateString?: string): string | undefined => {
  if (!dateString) {
    return undefined;
  }
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear().toString();
  return `${day}/${month}/${year}`;
};

export const inputDate = (dateString?: string): string | undefined => {
  if (!dateString) {
    return undefined;
  }
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear().toString();
  return `${year}-${month}-${day}`;
};

export const calculateAverageRating = (reviews: Review[]) => {
  const totalRating = reviews.reduce((sum, review) => sum + review.rating, 0);
  const average = totalRating / reviews.length;
  return average;
};

export const findMinMaxPrice = (products: Product[]) => {
  if (!Array.isArray(products) || products.length === 0) {
    return { min: null, max: null };
  }

  const prices = products.map((product) => product.Art_Prix);

  return {
    min: Math.min(...prices),
    max: Math.max(...prices),
  };
};

export const searchArrayForString = (searchQuery: string, searchPool: any[]) => {
  if (_.trim(searchQuery).length === 0) {
    return searchPool;
  }
  const trimmedQuery: string = _.trim(searchQuery).toLowerCase();
  const foundResults: any[] = searchPool.filter((item) => JSON.stringify(item).toLowerCase().includes(trimmedQuery));
  return foundResults;
};

export const getProductPrice = (products: Product) => products.Art_Prix_Promo ?? products.Art_Prix;

// export const createTokenExpiryTime = (lifeSpan = ACCESS_TOKEN_LIFE_SPAN) => {
//   const expTime = Date.now() + lifeSpan;
//   return expTime;
// };

export const isValidEmail = (email: string | undefined) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (typeof email === 'string' && email.length) {
    return re.test(_.trim(String(email).toLowerCase()));
  }
  return false;
};

export const sleep = (ms: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

export const priceDisplay = (price?: number) => `${(price ?? 0).toFixed(2)}${appCurrency.symbol}`;

export const deliveryStatusDisplay = (status?: OrderType) => {
  switch (status) {
    case 'PAID':
    case 'CREATED':
      return 'En cours';
    case 'DELIVERED':
      return 'Complété';
    default:
      return 'Échoué';
  }
};

export const removeDuplicates = (nums: number[]): number[] => {
  // Create a Set to store unique numbers
  const uniqueSet = new Set(nums);

  // Convert the Set back to an array
  const uniqueArray = Array.from(uniqueSet);

  return uniqueArray;
};

export const getProductStock = (stocks?: ProductStock[]): number => {
  const finalStock = stocks?.reduce((sum, stock) => sum + stock.Art_Stock, 0);
  return finalStock ?? 0;
};

export const isDateBetween = (startDate: Date, endDate: Date): boolean => {
  const currentDate = new Date();
  return startDate <= currentDate && currentDate <= endDate;
};

export const getProfileDataFormErrors = (user: User) => {
  const errors: string[] = [];
  if (
    _.isEmpty(user.firstName) ||
    _.isEmpty(user.lastName) ||
    _.isEmpty(user.phone) ||
    _.isEmpty(user.addressMain) ||
    _.isEmpty(user.city) ||
    _.isEmpty(user.zip) ||
    _.isEmpty(user.state) ||
    _.isEmpty(user.country)
  ) {
    errors.push(ERROR_EMPTY_FIELDS);
  }
  return errors;
};
