import { Color } from '../styles/constantStyles';

export interface ArrowExpandMoreProps {
  color?: string;
  size?: number;
}

export const ArrowExpandMore = ({ color = Color.APPBLACK, size = 56 }: ArrowExpandMoreProps) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="m8 10 4 4 4-4" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
