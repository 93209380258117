import styled from 'styled-components';
import { rSize } from 'core/util/ResponsiveSizeAdapter';

export const EventsDumb = () => {
  const rStyles = {
    wrapper: {
      minHeight: rSize(300),
    },
    h3: {
      fontSize: rSize(26),
    },
  };
  return (
    <Wrapper style={{ ...rStyles.wrapper }}>
      <h3 className="no-event" style={{ ...rStyles.h3 }}>
        Aucun événement n'est disponible pour le moment.
      </h3>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  min-height: 300px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .no-event {
    text-align: center;
  }
`;
