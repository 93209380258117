import { Action } from 'redux';
import { connect } from 'react-redux';
import { dispatchAsync } from 'react-redux-dispatch-async';
import { getPrioritizedShowcaseItems } from 'generalContext/usecases/General.selectors';
import { ShowcaseItem } from 'generalContext/domain/entities/ShowcaseItem';
import { AdminPreferances } from 'generalContext/domain/entities/AdminPreferances';
import { ProfileDumb } from './Profile.dumb';
import { AppState } from '../../../common/configuration/app.states';
import { getConnectedUser } from '../../../authContext/usecases/Auth.selectors';
import { User } from '../../../authContext/domain/entities/User';
import {
  sendSignOutRequest,
  updatePasswordRequest,
  updateUserDataRequest,
} from '../../../authContext/usecases/Auth.actions';
import {
  addDefaultShowcaseItemRequest,
  addPromoShowcaseItemRequest,
  approveReviewRequest,
  createShowcaseItemRequest,
  deleteProductReviewRequest,
  deleteShowcaseItemRequest,
  getAdminPreferancesRequest,
  getAdminReviewsRequest,
  getDefaultShowcaseItemsRequest,
  getOrderHistoryRequest,
  getPrioritizedShowcaseItemsRequest,
  getPromoShowcaseItemsRequest,
  getShowcaseItemsRequest,
  removeDefaultShowcaseItemRequest,
  removePromoShowcaseItemRequest,
  switchDefaultShowcaseOrderRequest,
  switchPromoShowcaseOrderRequest,
  updateAdminPreferencesRequest,
  updatePromoShowcaseRequest,
  updateShowcaseItemRequest,
} from '../../usecases/General.actions';
import { Review } from '../../domain/entities/Review';
import { ChangePasswordProps } from '../../../common/domain/entities/ChangePasswordProps';

const mapState = (state: AppState) => ({
  stateConnectedUser: getConnectedUser(state),
  prioritizedShowcaseItems: getPrioritizedShowcaseItems(state),
});

const mapDispatch = (dispatch: (a: Action) => void) => ({
  updateUserDataAsync: (userData: User) => dispatchAsync(dispatch, updateUserDataRequest(userData)),
  getOrderHistoryAsync: () => dispatchAsync(dispatch, getOrderHistoryRequest()),
  getAdminReviewsAsync: () => dispatchAsync(dispatch, getAdminReviewsRequest()),
  approveReviewAsync: (rev: Review) => dispatchAsync(dispatch, approveReviewRequest(rev)),
  deleteReviewAsync: (rev: Review) => dispatchAsync(dispatch, deleteProductReviewRequest(rev)),
  updatePasswordAsync: (props: ChangePasswordProps) => dispatchAsync(dispatch, updatePasswordRequest(props)),
  sendSignOutAsync: () => dispatchAsync(dispatch, sendSignOutRequest()),
  // admin preferences ----------------------
  getAdminPreferancesAsync: () => dispatchAsync(dispatch, getAdminPreferancesRequest()),
  updateAdminPreferencesAsync: (adminPreferences: AdminPreferances) =>
    dispatchAsync(dispatch, updateAdminPreferencesRequest(adminPreferences)),
  // showcase -----------------
  getShowcaseItemsAsync: () => dispatchAsync(dispatch, getShowcaseItemsRequest()),
  getDefaultShowcaseItemsAsync: () => dispatchAsync(dispatch, getDefaultShowcaseItemsRequest()),
  getPromoShowcaseItemsAsync: () => dispatchAsync(dispatch, getPromoShowcaseItemsRequest()),
  getPrioritizedShowcaseItemsAsync: () => dispatchAsync(dispatch, getPrioritizedShowcaseItemsRequest()),
  createShowcaseItemAsync: (item: ShowcaseItem) => dispatchAsync(dispatch, createShowcaseItemRequest(item)),
  updateShowcaseItemAsync: (item: ShowcaseItem) => dispatchAsync(dispatch, updateShowcaseItemRequest(item)),
  updatePromoShowcaseAsync: (itemId: string, startDate?: Date, endDate?: Date) =>
    dispatchAsync(dispatch, updatePromoShowcaseRequest({ itemId, startDate, endDate })),
  deleteShowcaseItemAsync: (id: string) => dispatchAsync(dispatch, deleteShowcaseItemRequest(id)),
  addDefaultShowcaseItemAsync: (itemId: string) => dispatchAsync(dispatch, addDefaultShowcaseItemRequest(itemId)),
  removeDefaultShowcaseItemAsync: (itemId: string) => dispatchAsync(dispatch, removeDefaultShowcaseItemRequest(itemId)),
  addPromoShowcaseItemAsync: (itemId: string, startDate?: Date, endDate?: Date) =>
    dispatchAsync(dispatch, addPromoShowcaseItemRequest({ itemId, startDate, endDate })),
  removePromoShowcaseItemAsync: (itemId: string) => dispatchAsync(dispatch, removePromoShowcaseItemRequest(itemId)),
  switchDefaultShowcaseOrderAsync: (itemId: string, direction: 'up' | 'down') =>
    dispatchAsync(dispatch, switchDefaultShowcaseOrderRequest({ itemId, direction })),
  switchPromoShowcaseOrderAsync: (itemId: string, direction: 'up' | 'down') =>
    dispatchAsync(dispatch, switchPromoShowcaseOrderRequest({ itemId, direction })),
});

export const ProfileSmart = connect(mapState, mapDispatch)(ProfileDumb);
