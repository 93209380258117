import { Action } from 'redux';
import { connect } from 'react-redux';
import { dispatchAsync } from 'react-redux-dispatch-async';
import { ProductDetailsDumb } from './ProductDetails.dumb';
import { AppState } from '../../../common/configuration/app.states';
import { getConnectedUser } from '../../../authContext/usecases/Auth.selectors';
import {
  deleteCartProductRequest,
  deleteProductReviewRequest,
  deleteWishProductRequest,
  getCartProductsRequest,
  getProductReviewsRequest,
  getProductsRequest,
  getWishProductsRequest,
  insertProductReviewRequest,
  saveCartProductRequest,
  saveWishProductRequest,
} from '../../usecases/General.actions';
import { getStateCart, getStateSubCategories, getStateWishProducts } from '../../usecases/General.selectors';
import { GetProductsProps, Product } from '../../domain/entities/Product';
import { Review } from '../../domain/entities/Review';

const mapState = (state: AppState) => ({
  stateWishProducts: getStateWishProducts(state),
  stateCart: getStateCart(state),
  stateConnectedUser: getConnectedUser(state),
  stateSubCategories: getStateSubCategories(state),
});

const mapDispatch = (dispatch: (a: Action) => void) => ({
  // navigateTo: (nav: NavigationPayload) => dispatch(navigateTo(nav)),
  getProductsAsync: (props?: GetProductsProps) => dispatchAsync(dispatch, getProductsRequest(props)),
  // wish
  getWishProductsAsync: () => dispatchAsync(dispatch, getWishProductsRequest()),
  saveWishProductAsync: (product: Product) => dispatchAsync(dispatch, saveWishProductRequest(product)),
  deleteWishProductAsync: (Art_Ean13: string) => dispatchAsync(dispatch, deleteWishProductRequest(Art_Ean13)),
  // cart
  getCartProductsAsync: () => dispatchAsync(dispatch, getCartProductsRequest()),
  saveCartProductAsync: (product: Product) => dispatchAsync(dispatch, saveCartProductRequest(product)),
  deleteCartProductAsync: (Art_Ean13: string) => dispatchAsync(dispatch, deleteCartProductRequest(Art_Ean13)),
  // reviews
  getProductReviewsAsync: (productEan: string) => dispatchAsync(dispatch, getProductReviewsRequest(productEan)),
  insertProductReviewAsync: (review: Review) => dispatchAsync(dispatch, insertProductReviewRequest(review)),
  deleteProductReviewAsync: (review: Review) => dispatchAsync(dispatch, deleteProductReviewRequest(review)),
});

export const ProductDetailsSmart = connect(mapState, mapDispatch)(ProductDetailsDumb);
