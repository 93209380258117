import { appStore } from 'configuration/redux/store';
import { removeTokensRequest } from 'authContext/usecases/Auth.actions';
import { magasinIdCurrent } from './constants';
import { API_KEY, ENDPOINTS } from '..';
import { AuthTokens } from '../../common/domain/entities/AuthTokens';

const tokenContainer: AuthTokens = {
  accessToken: undefined,
  renewToken: undefined,
};

export const setTokensToFetch = (tokens: AuthTokens | undefined) => {
  tokenContainer.accessToken = tokens?.accessToken;
  tokenContainer.renewToken = tokens?.renewToken;
};
export const getTokensToFetch = () => tokenContainer;
export const deleteTokensTofetch = () => {
  tokenContainer.accessToken = undefined;
  tokenContainer.renewToken = undefined;
};

export const HTTP_ERROR = 'HTTP_ERROR';
export const HTTP_STATUS_EXPIRED_TOKEN = 401;
export const EMAIL_ACTIVATION_ERROR = 402;
export const HTTP_STATUS_NOT_FOUND = 404;
export const ACCOUNT_ALREADY_EXISTS = 422;

export const fetchWithApi = async (url: string, options?: RequestInit): Promise<Response> => {
  const headers = {
    ...options?.headers,
    apiKey: API_KEY,
    magasin_id: magasinIdCurrent,
  };

  // Perform the fetch request with the updated headers
  const response = await fetch(url, { ...options, headers });
  return response;
};

export const fetchWithToken = async (url: string, options?: RequestInit): Promise<Response> => {
  const { accessToken } = getTokensToFetch();

  if (!accessToken) {
    throw new Error('No access token available');
  }

  const headers = {
    Accept: 'application/json',
    'Accept-Encoding': 'gzip',
    'Content-Type': 'application/json',
    ...options?.headers,
    Authorization: accessToken,
    magasin_id: magasinIdCurrent,
  };

  let response = await fetch(url, { ...options, headers });

  // Check if the response indicates an expired access token
  if (response.status === 401) {
    const res = await response.json();
    if (res.error === 'Expired Access Token') {
      // Refresh the access token
      const newAccessToken = await renewAccessToken();
      if (newAccessToken) {
        const newHeaders = {
          Accept: 'application/json',
          'Accept-Encoding': 'gzip',
          'Content-Type': 'application/json',
          ...options?.headers,
          Authorization: newAccessToken,
          magasin_id: magasinIdCurrent,
        };
        response = await fetch(url, { ...options, headers: newHeaders });
      } else {
        // Create a custom response if newAccessToken is null
        const customResponse = new Response(
          JSON.stringify({ error: 'Expired Session' }), // response body as JSON string
          {
            status: 401,
            statusText: 'Expired Session',
            headers: { 'Content-Type': 'application/json' },
          },
        );
        return customResponse;
      }
    }
  }

  return response;
};

// Function to refresh the access token
const renewAccessToken = async (): Promise<string | null> => {
  const { renewToken } = getTokensToFetch();
  if (!renewToken) {
    throw new Error('No renew token available');
  }

  const response = await fetchWithApi(`${ENDPOINTS.renewAccess}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Accept-Encoding': 'gzip',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ renewToken }),
  });

  const res = await response.json();

  if (response.ok) {
    const newTokens: AuthTokens = {
      accessToken: res.accessToken,
      renewToken,
    };
    setTokensToFetch(newTokens);
    return newTokens.accessToken ?? null;
  }

  if (response.status === 401 && res.error === 'Expired Renew Token') {
    deleteTokensTofetch();
    appStore.store.dispatch(removeTokensRequest());
  } else if (!response.ok || res.error) {
    const error = new Error(res.message);
    error.cause = res.data;
    throw error;
  }

  return null;
};
