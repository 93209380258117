import { Link } from 'react-router-dom';
import ListIcon from '@mui/icons-material/List';
import styled from 'styled-components';
import { useRef, useState } from 'react';
import { OTHER_CATEGORY_NUMBER } from 'configuration/helpers/constants';
import { rSize } from 'core/util/ResponsiveSizeAdapter';
import { CategoriesFilter } from './CategoriesFilter';
import { ArrowExpandMore } from '../../../../assets/svg/ArrowExpandMore';
import { ArrowExpandLess } from '../../../../assets/svg/ArrowExpandLess';
import { Category } from '../../../domain/entities/Category';
import { SubCategory } from '../../../domain/entities/SubCategory';

interface ShopSidebarProps {
  stateCategories: Category[];
  stateSubCategories: SubCategory[];
}

export const ShopSidebar = ({ stateCategories, stateSubCategories }: ShopSidebarProps) => {
  const [isCategoriesExpanded, setIsCategoriesExpanded] = useState(true);
  const [isForYouExpanded, setIsForYouExpanded] = useState(true);
  const categoriesRef = useRef<HTMLDivElement>(null);
  const forYouRef = useRef<HTMLDivElement>(null);

  const rStyles = {
    priceSliderText: {
      padding: `${rSize(20)}px 0 0`,
      fontSize: rSize(20),
    },
    sideBarTitle: {
      fontSize: rSize(20),
      gap: rSize(10),
      paddingBottom: rSize(0),
      marginBottom: rSize(0),
    },
    second: { marginTop: rSize(30) },
    collapsible: {
      expanded: {
        maxHeight: rSize(1400),
      },
    },
    expandIcon: {
      size: rSize(32),
    },
  };

  const handleCategoriesToggle = () => {
    setIsCategoriesExpanded(!isCategoriesExpanded);
  };

  const handleForYouToggle = () => {
    setIsForYouExpanded(!isForYouExpanded);
  };

  return (
    <Wrapper>
      <div className="shop-filter">
        <div className="d-flex justify-content-between">
          <Link to="/" className="panel-close-btn">
            <i className="flaticon-close" />
          </Link>
        </div>
        <button
          className="side-bar-title"
          onClick={handleCategoriesToggle}
          aria-expanded={isCategoriesExpanded}
          style={{ ...rStyles.sideBarTitle }}
          type="button"
        >
          <ListIcon style={{ fontSize: rStyles.sideBarTitle.fontSize }} />
          <h5 style={{ fontSize: rStyles.sideBarTitle.fontSize }}>Catégories</h5>
          {isCategoriesExpanded ? (
            <ArrowExpandLess size={rStyles.expandIcon.size} />
          ) : (
            <ArrowExpandMore size={rStyles.expandIcon.size} />
          )}
        </button>
        <div
          ref={categoriesRef}
          className={`collapsible ${isCategoriesExpanded ? 'expanded' : ''}`}
          style={{ maxHeight: isCategoriesExpanded ? '55vh' : '0' }}
        >
          <CategoriesFilter
            categories={stateCategories.filter((cat) => cat.categoryNumber !== OTHER_CATEGORY_NUMBER)}
            subCategories={stateSubCategories.filter((subCat) => subCat.categoryNumber !== OTHER_CATEGORY_NUMBER)}
          />
        </div>
        <button
          className="side-bar-title second"
          style={{ ...rStyles.sideBarTitle, ...rStyles.second }}
          onClick={handleForYouToggle}
          aria-expanded={isForYouExpanded}
          type="button"
        >
          <ListIcon style={{ fontSize: rStyles.sideBarTitle.fontSize }} />
          <h5 style={{ fontSize: rStyles.sideBarTitle.fontSize }}>Pour vous</h5>
          {isForYouExpanded ? (
            <ArrowExpandLess size={rStyles.expandIcon.size} />
          ) : (
            <ArrowExpandMore size={rStyles.expandIcon.size} />
          )}
        </button>
        <div
          ref={forYouRef}
          className={`collapsible ${isForYouExpanded ? 'expanded' : ''}`}
          style={{ maxHeight: isForYouExpanded ? '55vh' : '0' }}
        >
          <CategoriesFilter
            categories={stateCategories.filter((cat) => cat.categoryNumber === OTHER_CATEGORY_NUMBER)}
            subCategories={stateSubCategories.filter((subCat) => subCat.categoryNumber === OTHER_CATEGORY_NUMBER)}
          />
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .side-bar-title {
    border-bottom: 1px solid black;
    font-size: 20px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 10px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    &.second {
      margin-top: 10px;
    }
  }
  .collapsible {
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
    max-height: 0; /* Initial state */
    overflow-y: auto;
  }
  .collapsible.expanded {
    max-height: 1000px; /* Adjust as necessary */
  }
`;
