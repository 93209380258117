import { ProductState } from 'generalContext/domain/entities/ProductState';
import { MagasinState } from 'generalContext/domain/entities/Magasin';
import { MailObj } from 'generalContext/domain/entities/MailObj';
import { AdminPreferances } from 'generalContext/domain/entities/AdminPreferances';
import { InitializePaymentProps } from 'generalContext/domain/entities/Payment';
import { ShowcaseItem } from 'generalContext/domain/entities/ShowcaseItem';
import { Category } from '../../domain/entities/Category';
import { Order } from '../../domain/entities/Order';
import { Product } from '../../domain/entities/Product';
import { Review } from '../../domain/entities/Review';
import { SubCategory } from '../../domain/entities/SubCategory';
import { GeneralInteractor } from '../../domain/gateways/General.interactor';

export class InMemoryGeneralInteractor implements GeneralInteractor {
  updateAdminPreferances(adminPreferences: AdminPreferances): Promise<AdminPreferances> {
    throw new Error('Method not implemented.');
  }

  assertPayment(token: string): Promise<any> {
    throw new Error('Method not implemented.');
  }

  getAdminPreferances(): Promise<AdminPreferances> {
    throw new Error('Method not implemented.');
  }

  sendNewsLettersSignUp(email: string): Promise<boolean> {
    throw new Error('Method not implemented.');
  }

  sendEmail(order: MailObj): Promise<boolean> {
    throw new Error('Method not implemented.');
  }

  getDeliveryProducts(): Promise<Product[]> {
    throw new Error('Method not implemented.');
  }

  getMagasinList(): Promise<MagasinState> {
    throw new Error('Method not implemented.');
  }

  getProducts(): Promise<ProductState> {
    throw new Error('Method not implemented.');
  }

  approveReview(review: Review): Promise<Review> {
    throw new Error('Method not implemented.');
  }

  getAdminReviews(): Promise<Review[]> {
    throw new Error('Method not implemented.');
  }

  getOrderHistory(): Promise<Order[]> {
    throw new Error('Method not implemented.');
  }

  insertOrder(order: Order): Promise<Order> {
    throw new Error('Method not implemented.');
  }

  saveCartProduct(product: Product): Promise<Product> {
    throw new Error('Method not implemented.');
  }

  deleteCartProduct(Art_Ean13: string): Promise<boolean> {
    throw new Error('Method not implemented.');
  }

  saveWishProduct(product: Product): Promise<Product> {
    throw new Error('Method not implemented.');
  }

  deleteWishProduct(Art_Ean13: string): Promise<boolean> {
    throw new Error('Method not implemented.');
  }

  getProductReviews(productEan: string): Promise<Review[]> {
    throw new Error('Method not implemented.');
  }

  insertProductReview(review: Review): Promise<Review> {
    throw new Error('Method not implemented.');
  }

  deleteProductReview(review: Review): Promise<Review> {
    throw new Error('Method not implemented.');
  }

  getCategories(): Promise<Category[]> {
    throw new Error('Method not implemented.');
  }

  getSubCategories(): Promise<SubCategory[]> {
    throw new Error('Method not implemented.');
  }

  getWishProducts(): Promise<Product[]> {
    throw new Error('Method not implemented.');
  }

  getCartProducts(): Promise<Product[]> {
    throw new Error('Method not implemented.');
  }

  async getProductsBySubCategoryNumber(): Promise<ProductState> {
    throw new Error('Method not implemented.');
  }

  // showcase ------------------------------
  getShowcaseItems(): Promise<ShowcaseItem[]> {
    throw new Error('Method not implemented.');
  }

  getDefaultShowcaseItems(): Promise<ShowcaseItem[]> {
    throw new Error('Method not implemented.');
  }

  getPromoShowcaseItems(): Promise<ShowcaseItem[]> {
    throw new Error('Method not implemented.');
  }

  getPrioritizedShowcaseItems(): Promise<ShowcaseItem[]> {
    throw new Error('Method not implemented.');
  }

  createShowcaseItem(item: ShowcaseItem): Promise<ShowcaseItem> {
    throw new Error('Method not implemented.');
  }

  updateShowcaseItem(item: ShowcaseItem): Promise<ShowcaseItem> {
    throw new Error('Method not implemented.');
  }

  updatePromoShowcase(itemId: string, startDate?: Date, endDate?: Date): Promise<ShowcaseItem> {
    throw new Error('Method not implemented.');
  }

  deleteShowcaseItem(id: string): Promise<boolean> {
    throw new Error('Method not implemented.');
  }

  addDefaultShowcaseItem(itemId: string): Promise<ShowcaseItem> {
    throw new Error('Method not implemented.');
  }

  removeDefaultShowcaseItem(itemId: string): Promise<boolean> {
    throw new Error('Method not implemented.');
  }

  addPromoShowcaseItem(itemId: string, startDate?: Date, endDate?: Date): Promise<ShowcaseItem> {
    throw new Error('Method not implemented.');
  }

  removePromoShowcaseItem(itemId: string): Promise<boolean> {
    throw new Error('Method not implemented.');
  }

  switchDefaultShowcaseOrder(itemId: string, direction: 'up' | 'down'): Promise<boolean> {
    throw new Error('Method not implemented.');
  }

  switchPromoShowcaseOrder(itemId: string, direction: 'up' | 'down'): Promise<boolean> {
    throw new Error('Method not implemented.');
  }

  initializePayment(data: InitializePaymentProps): Promise<any> {
    throw new Error('Method not implemented.');
  }
}
