import styled from 'styled-components';
import { useEffect, useRef, useState } from 'react';
import { SearchBox, SideBar } from 'common/ui';
import { rSize } from 'core/util/ResponsiveSizeAdapter';
import ResponsiveScreenAdapter from 'core/util/ResponsiveScreenAdapter';
import { Logo } from './Logo';
import { MiddleHeaderRight } from './MiddleHeaderRight';
import { User } from '../../../../../authContext/domain/entities/User';
import { Cart } from '../../../../../generalContext/domain/entities/Cart';
import { Product } from '../../../../../generalContext/domain/entities/Product';
import { middleHeaderHeight } from '../../../../../assets/styles/constantStyles';

interface MiddleHeaderProps {
  stateConnectedUser?: User;
  stateWishProducts: Product[] | undefined;
  stateCart: Cart | undefined;
  signOut: () => void;
  deleteCartProduct: (Art_Ean13: string) => void;
}
export const MiddleHeader = ({
  stateConnectedUser,
  stateWishProducts,
  stateCart,
  signOut,
  deleteCartProduct,
}: MiddleHeaderProps) => {
  const [isSticky, setIsSticky] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const rMiddleHeaderHeight = rSize(middleHeaderHeight);

  const rStyles = {
    container: {
      margin: `${rSize(10)}px ${rSize(0)}px`,
    },
    middleReplacement: {
      height: rMiddleHeaderHeight,
    },
    sticky: {
      boxShadow: `0 ${rSize(2)}px ${rSize(2)}px rgba(0, 0, 0, 0.1)`,
    },
    logo: {
      width: rSize(160),
    },
    mobile: {
      searchBox: {
        margin: `${rSize(10)}px 0`,
      },
    },
  };

  useEffect(() => {
    const handleScroll = () => {
      const containerHeight = containerRef.current?.clientHeight ?? 100;
      setIsSticky(window.scrollY > containerHeight);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const DesktopLayout = (
    <>
      <Logo width={rStyles.logo.width} />
      <div style={{ width: `60%` }}>
        <SearchBox />
      </div>
      <MiddleHeaderRight
        stateConnectedUser={stateConnectedUser}
        stateWishProducts={stateWishProducts}
        stateCart={stateCart}
        signOut={signOut}
        deleteCartProduct={deleteCartProduct}
        toggleSidebar={toggleSidebar}
      />
    </>
  );

  const MobileTabletLayout = (
    <>
      <Logo width={rStyles.logo.width} />
      <MiddleHeaderRight
        stateConnectedUser={stateConnectedUser}
        stateWishProducts={stateWishProducts}
        toggleSidebar={toggleSidebar}
        stateCart={stateCart}
        signOut={signOut}
        deleteCartProduct={deleteCartProduct}
      />
      <div style={{ width: `100%`, ...rStyles.mobile.searchBox }}>
        <SearchBox />
      </div>
    </>
  );

  return (
    <>
      <SideBar isOpen={isSidebarOpen} toggleSideMenu={toggleSidebar} />
      <NotSticky
        style={{
          boxShadow: isSticky ? rStyles.sticky.boxShadow : 'none',
        }}
      >
        <div className="container" ref={containerRef}>
          <Wrapper
            style={{
              ...rStyles.container,
            }}
          >
            <ResponsiveScreenAdapter
              defaultScreen={DesktopLayout}
              screenMobile={MobileTabletLayout}
              screenTablet={MobileTabletLayout}
              screenDesktop={DesktopLayout}
            />
          </Wrapper>
        </div>
      </NotSticky>
      {isSticky && (
        <Sticky
          isSticky={isSticky}
          style={{
            boxShadow: isSticky ? rStyles.sticky.boxShadow : 'none',
          }}
        >
          <div className="container" ref={containerRef}>
            <Wrapper
              style={{
                ...rStyles.container,
              }}
            >
              <ResponsiveScreenAdapter
                defaultScreen={DesktopLayout}
                screenMobile={MobileTabletLayout}
                screenTablet={MobileTabletLayout}
                screenDesktop={DesktopLayout}
              />
            </Wrapper>
          </div>
        </Sticky>
      )}
    </>
  );
};

const Wrapper = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
`;

const Sticky = styled.div<{ isSticky: boolean }>`
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${({ isSticky }) => (isSticky ? 301 : 9)};
  top: ${({ isSticky }) => (isSticky ? '0' : 'auto')};
  right: 0;
  left: 0;
  width: 100%;
  transition: all 0.3s ease-in-out;
  box-shadow: ${({ isSticky }) => (isSticky ? '0 2px 5px rgba(0, 0, 0, 0.1)' : 'none')};
  position: ${({ isSticky }) => (isSticky ? 'fixed' : 'relative')};
  transform: translateY(${({ isSticky }) => (isSticky ? '0' : '-100%')});
`;

const NotSticky = styled.div`
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  box-shadow: 'none';
  position: 'relative';
`;
