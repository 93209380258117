import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { IoMdArrowDropright } from 'react-icons/io';
import { BiReset } from 'react-icons/bi';
import { rSize } from 'core/util/ResponsiveSizeAdapter';
import { AppProductsSlider } from './AppProductsSlider';
import { AppContainer } from '../../../common/ui/elements/AppContainer';
import { Product } from '../../domain/entities/Product';
import { Category } from '../../domain/entities/Category';
import { SubCategory } from '../../domain/entities/SubCategory';

interface CategoryProductsSliderProps {
  title: string;
  color?: string;
  products: Product[];
  stateCategories: Category[];
  stateSubCategories: SubCategory[];
}

export const CategoryProductsSlider = ({
  title,
  color,
  products,
  stateCategories,
  stateSubCategories,
}: CategoryProductsSliderProps) => {
  const [categoriesToShow, setCategoriesToShow] = useState<Category[]>([]);
  const [subCategoriesToShow, setSubCategoriesToShow] = useState<SubCategory[]>([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState<SubCategory>();

  const rStyles = {
    global: {
      h3: {
        fontSize: rSize(26),
        lineHeight: `normal`,
      },
      h4: {
        fontSize: rSize(24),
        lineHeight: `normal`,
      },
      h5: {
        fontSize: rSize(22),
        lineHeight: `normal`,
      },
      h6: {
        fontSize: rSize(20),
        lineHeight: `normal`,
      },
      p: {
        fontSize: rSize(18),
      },
      navLink: {
        fontSize: rSize(18),
      },
    },
    wrapper: {
      marginTop: rSize(10),
    },
    resetIcon: {
      size: rSize(18),
    },
    arrowIcon: {
      size: rSize(18),
    },
    productSlider: {
      marginTop: rSize(40),
    },
  };

  useEffect(() => {
    getSubCategories();
  }, [products]);

  const getSubCategories = () => {
    const subCatsToShow: SubCategory[] = stateSubCategories.filter((subCat) =>
      products.find((prod) => prod.subCategoryNumbers.includes(subCat.subCategoryNumber)),
    );
    setSubCategoriesToShow(subCatsToShow);
    const catsToShow: Category[] = stateCategories.filter((cat) =>
      subCatsToShow.find((subCat) => subCat.categoryNumber === cat.categoryNumber),
    );
    setCategoriesToShow(catsToShow);
  };

  if (!products || !products.length) {
    return null;
  }

  return (
    <Wrapper style={{ ...rStyles.wrapper }} color={color}>
      <AppContainer>
        <div className="title-container">
          <div className="title" style={{ ...rStyles.global.h6 }}>
            {title}
          </div>
          {categoriesToShow && (
            <div className="categories-container">
              <div className="dropdown">
                <div className="cat-name">
                  {selectedSubCategory && (
                    <div className="reset-icon">
                      <BiReset size={rStyles.resetIcon.size} onClick={() => setSelectedSubCategory(undefined)} />
                    </div>
                  )}
                  <span style={{ ...rStyles.global.p }}>
                    {selectedSubCategory ? selectedSubCategory.name : 'Tous les catégories'}
                  </span>
                  <div className="arrow-icon">
                    <IoMdArrowDropright size={rStyles.arrowIcon.size} />
                  </div>
                </div>
                <ul>
                  {categoriesToShow.map((category) => (
                    <li
                      key={category.id}
                      className={`${category.categoryNumber === selectedSubCategory?.categoryNumber ? 'selected' : ''}`}
                    >
                      <div className="category" style={{ ...rStyles.global.p }}>
                        {category.name}
                      </div>
                      <IoMdArrowDropright size={rStyles.arrowIcon.size} />
                      <ul className="sub-menu">
                        {subCategoriesToShow
                          .filter((subCategory) => subCategory.categoryNumber === category.categoryNumber)
                          .map((subCategory) => (
                            <li key={subCategory.id} onClick={() => setSelectedSubCategory(subCategory)}>
                              <div style={{ ...rStyles.global.p }}>{subCategory.name}</div>
                            </li>
                          ))}
                      </ul>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>
        <div style={{ ...rStyles.productSlider }}>
          <AppProductsSlider
            products={
              selectedSubCategory
                ? products.filter((prod) =>
                    prod.subCategoryNumbers.includes(selectedSubCategory?.subCategoryNumber ?? -1),
                  )
                : products
            }
          />
        </div>
      </AppContainer>
    </Wrapper>
  );
};
const Wrapper = styled.div<{ color: string | undefined }>`
  width: 100%;
  margin-bottom: 20px;
  * {
    transition: all 300ms ease;
  }

  .title-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    background-color: ${({ color }) => `${color ?? 'var(--primary)'}`};
    border-radius: 4px;
    padding: 0px 15px;
    height: auto;
    gap: 20px;
    min-height: 50px;
  }

  .title {
    color: white;
    font-size: 14px;
    font-weight: 700;
  }
  .selected {
    filter: brightness(1.2);
  }
  .dropdown {
    color: white;
    position: relative;
    height: 100%;
    padding: 10px 0;
    :hover > ul {
      display: block;
    }
    .cat-name {
      height: 100%;
      display: block;
      margin: 0;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }
    :hover .arrow-icon {
      transform: rotate(90deg);
    }
    ul {
      list-style-type: none;
      padding: 0;
      display: none;
      position: absolute;
      top: 40px;
      left: 0;
      z-index: 3;
      background-color: ${({ color }) => `${color ?? 'var(--primary)'}`};
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

      .sub-menu {
        display: none;
        background-color: ${({ color }) => `${color ?? 'var(--primary)'}`};
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        position: absolute;
        top: 0;
        left: 100%;
      }

      li:hover .sub-menu {
        display: block;
      }
    }
    .reset-icon:hover {
      transform: rotate(-360deg);
    }
    li {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    ul li ul li:hover {
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.3);
    }
    li div {
      margin: 0;
      padding: 10px;
    }
  }
`;
